import React, { useEffect, useState } from "react";
import { getParameterByName, removeQString } from "../js/params";
import { useSelector } from "react-redux";
import { useSetAdwordsMutation } from "../features/gcild/gcildAPI";
export default function Google() {
  const [setAdwords] = useSetAdwordsMutation();
  const [code, setCode] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);
  const [customerId, setCustomerId] = useState(false);
  const [isRenderDone, setIsRenderDone] = useState(false);
  const [isReportQueued, setIsReportQueued] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [error, setError] = useState(false);
  const [duration, setDuration] = useState(1);
  const { appEligible } = useSelector(state => state.plan)
  useEffect(() => {
    if (getParameterByName("code") && getParameterByName("scope")) {
      setCode(getParameterByName("code"));
      getRefresh(getParameterByName("code"));
    } else {
      status();
    }
  }, []);

  const stateSetterFunctions = {
    code: setCode,
    refreshToken: setRefreshToken,
    customerId: setCustomerId,
    isRenderDone: setIsRenderDone,
    isReportQueued: setIsReportQueued,
    customers: setCustomers,
    error: setError,
    duration: setDuration,
  };

  const setState = (key, value) => {
    if (key === "refresh_token") key = "refreshToken";
    if (key === "customer_id") key = "customerId";
    if (key === "is_render_done") key = "isRenderDone";
    if (stateSetterFunctions[key]) {
      stateSetterFunctions[key](value);
    } else {
      console.error(`State setter function for "${key}" does not exist.`);
    }
  };

  const onFieldChange = (event) => {
    var field = event.target.name;
    setState(field, event.target.value);
  };


  const setCustomerID = async (customer_id, login_customer_id, event) => {
    if (event) event.preventDefault();
    try {
      const data = await setAdwords(
        {
          action: "savecustomerid",
          client_customer_id: customer_id.toString(),
          login_customer_id: login_customer_id.toString(),
        }
      ).unwrap();
      console.log("setCustomerId", data);
      setCustomerID(true);
      setCustomers(false);
      status();
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerIds = async () => {
    setIsRenderDone(false);
    try {
      const data = await setAdwords(
        {
          action: "getcustomers",
        }
      ).unwrap();
      console.log("getCustomerIds", data);
      setCustomers(data);
      setIsRenderDone(true);
    } catch (error) {
      setError("Service Unavailable. Please try again later.");
      setIsRenderDone(true);
    }
  };

  const status = async () => {
    try {
      const response = await setAdwords(
        {
          action: "status",
        }
      ).unwrap();
      let data = JSON.parse(JSON.stringify(response));
      console.log("getCustomerIds", data);
      if (data.refresh_token && !data.customer_id) {
        getCustomerIds();
      } else {
        data["is_render_done"] = true;
      }
      for (let key in data) {
        setState(key, data[key]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRefresh = async (code) => {
    try {
      const data = await setAdwords(
        {
          action: "refresh",
          code: code,
        }
      ).unwrap();
      console.log("getCustomerIds", data);
      if (data.refresh_token) {
        window.location = removeQString("code");
      }

      for (let key in data) {
        setState(key, data[key]);
      }
    } catch (error) {
      console.log(error);
      window.location = removeQString("code");
    }
  };

  const onAuth = async (event) => {
    event.preventDefault();
    try {
      const data = await setAdwords(
        {
          action: "auth",
        }
      ).unwrap();
      console.log("getCustomerIds", data);
      window.location.href = data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const generateReport = async (event) => {
    event.preventDefault();
    setIsRenderDone(false);
    try {
      const data = await setAdwords(
        {
          action: "getreport",
          duration: parseInt(duration),
        }
      ).unwrap();
      console.log("getCustomerIds", data);
      setIsRenderDone(true);
      setIsReportQueued(true);
    } catch (error) {
      console.log(error);

      setError("Some error occurred, please try again later.");
      setIsRenderDone(true);
    }
  };

  const getYesterdaysDate = () => {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${
      months[yesterday.getMonth()]
    } ${yesterday.getDate()}, ${yesterday.getFullYear()}`;
  };

  return (
    <div>
      {!isRenderDone && (
          <div>
          <div className="fa-3x">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
          <p>Please wait...</p>
          
        </div>
      )}

      {isRenderDone && (
        <h2 className="text-2xl font-medium">
          HandL GCLID Reporter{" "}
          <a
            target="_blank"
            href="https://docs.utmgrabber.com/books/internal-apps/page/handl-gclid-reporter"
          >
            <i className="fa fa-info-circle"></i>
          </a>
        </h2>
      )}

      {isRenderDone && isReportQueued && (
        <div className="max-w-md">
          <p>
            We are in the process of generating your report and will send it to
            your email address once it is completed. This could take up to 10
            minutes, depending on the size of the report.
          </p>
        </div>
      )}

      {isRenderDone && error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {isRenderDone && !refreshToken && (
        <div>
          <p>
            Link your advertising account to download click performance (GCLID)
            report from Google AdWords.
          </p>
          <button onClick={onAuth} className="btn btn-warning mt-2">
            <i className="fa fa-google"></i> Authorize with Google
          </button>
        </div>
      )}

      {isRenderDone && customerId && !isReportQueued && (
        <div>
          <p>Click the button below to download the report (CSV format)</p>

          {!appEligible && (
            <p>
              This will only download the report from yesterday ({" "}
              {getYesterdaysDate()} ).
            </p>
          )}

          {!appEligible && (
            <div className="bs-callout bs-callout-danger">
              If you want to download for more than yesterday data, please
              upgrade your plan to Starter+ or Premium
            </div>
          )}

          {appEligible && (
            <div className="flex justify-center mb-2">
              <div className="flex flex-row items-center gap-3">
                Duration:
                <select
                  name="duration"
                  onChange={onFieldChange}
                  className="g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-min"
                >
                  <option value="1">1 day</option>
                  <option value="7">7 days</option>
                  <option value="14">14 days</option>
                  <option value="30">1 month</option>
                </select>
              </div>
            </div>
          )}
          <button onClick={generateReport} className="btn btn-warning">
            
            Download
          </button>
        </div>
      )}
      {isRenderDone && customers && (
        <div>
          <p>
            Please select the advertising account to generate the report for:
          </p>
          <div className="flex flex-col gap-1 items-start">
            {customers.map(
              (item, id) =>
                !item.testAccount &&
                !item.canManageClients && (
                  <div key={id} className="text-sm">
                    {item.customerId != "" && (
                      <div
                        className="text-blue-500 underline hover:text-blue-600 cursor-pointer"
                        onClick={(e) => {
                          setCustomerID(
                            item.customerId,
                            item.login_customer_id,
                            e
                          );
                        }}
                      >
                        {item.descriptiveName
                          ? item.descriptiveName
                          : item.customerId}
                      </div>
                    )}

                    {item.customerId == "" && (
                      <span>
                        {item.login_customer_id} [No Access/Deactivated]
                      </span>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
