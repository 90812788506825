import React, { useState } from 'react';
import { useOnUpgradeMutation } from '../../features/payment/paymentAPI';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, Elements, ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { closeUpdatePaymentMethodModal } from '../../features/plan/planSlice';
import { toast } from 'react-toastify';

function UpdatePaymentMethodModal() {
    const elements = useElements();
    const stripe = useStripe();
    const { customerId } = useSelector((state) => state.plan.updatePaymentMethod)
    const {
        tmpUser,
    } = useSelector((state) => state.auth.data || {});

    const [doUpgrade] = useOnUpgradeMutation();
    const [error, setError] = useState(null);
    const [ccFielderror, setccFieldError] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cc");
    const [processing, setProcessing] = useState(false);
    const [ccFieldDisabled, setccFieldDisabled] = useState(true);
    const [visibility, setVisibility] = useState('loading');
    const dispatch = useDispatch();
    const onReady = ({ availablePaymentMethods }) => {
        if (!availablePaymentMethods) {
            setVisibility('unavailable');
        } else {
            setVisibility('initial');
        }
    };
    const onConfirm = async (event) => {
        if (!stripe || processing) {
            return;
        }
        setProcessing(true)
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements,
            params: {
                billing_details: event.billingDetails
            }
        });

        if (error) {
            setError(error.message ?? "")
        }
        else {
            await attachPaymentMethodToCustomer(paymentMethod.id);
        }
        setProcessing(false)

    };
    const onClick = ({ resolve }) => {
        if (processing) {
            return;
        }
        setSelectedPaymentMethod(null)
        resolve();
    }

    const handleCardFieldChanged = (event) => {
        setccFieldDisabled(!event.complete);
        setccFieldError(event.error ? event.error.message : "");
        setError(null)
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name: tmpUser.first_name,
                email: tmpUser.email,
            },
        });

        if (error) {
            setError(error.message ?? "")
        }
        else {
            await attachPaymentMethodToCustomer(paymentMethod.id);
        }
        setProcessing(false)
    };

    const attachPaymentMethodToCustomer = async (paymentMethodId) => {
        setError(null);
        try {
            const data = await doUpgrade(
                {
                    pay_id: "stripe",
                    type: "update-card",
                    customerId: customerId,
                    paymentMethodId: paymentMethodId,
                }
            ).unwrap();
            if (data) {
                if (!data.error) {
                    dispatch(closeUpdatePaymentMethodModal())
                    toast.success("Payment method updated successfully")
                } else {
                    setError(data.error.message);
                }
            } else {
                setError("Unknown error. Please contact support");
            }
        } catch (error) {
            setError("Unknown error. Please contact support");
        }
    }

    return (

        <div className=" p-5 w-[90vw] !max-w-md">
            <div className="w-full flex flex-col items-center">
                <h3 className="mx-auto text-xl md:text-2xl font-semibold mb-3">
                    Update your payment method
                </h3>
                {
                    visibility === "loading" && <UpdatePaymentMethodModalSkeleton />
                }

                <>

                    <div className='flex flex-col w-full gap-2'>
                        {
                            visibility !== "unavailable" &&
                            <ExpressCheckoutElement onCancel={() => setProcessing(false)} options={{
                                layout: {
                                    maxRows: 5,
                                    maxColumns: 1
                                },
                                paymentMethods: {
                                    link: "never"
                                }

                            }} onConfirm={onConfirm} onReady={onReady} onClick={onClick}
                            />
                        }

                        {
                            visibility === "initial" &&
                            <button
                                className={`mb-4 w-full h-[45px] font-medium text-lg bg-[#ffe457] text-[#374048] hover:bg-[#374048] px-5 hover:text-white ${selectedPaymentMethod === "cc" ? "!bg-[#374048] rounded text-white" : ""}`}
                                onClick={() => setSelectedPaymentMethod("cc")}
                            >
                                <i className="fa fa-credit-card"></i>&nbsp; Credit Card
                            </button>
                        }

                    </div>
                    {
                        (visibility !== "loading" && selectedPaymentMethod === "cc") &&
                        <form
                            onSubmit={handleSubmit}
                            className="text-center items-center w-full"
                        >
                            <CardElement
                                className="shrink-0 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 m-auto"
                                options={{
                                    style: {
                                        base: {
                                            fontFamily: '"Poppins",sans-serif',
                                            fontSmoothing: "antialiased",
                                            fontSize: "17px",
                                        },
                                        invalid: {
                                            color: "#fa755a",
                                            iconColor: "#fa755a",
                                        },
                                    },
                                }}
                                onChange={handleCardFieldChanged}
                            />
                            {ccFielderror && (
                                <div className="text-red-500 text-xs md:text-sm mt-1" role="alert">
                                    {ccFielderror}
                                </div>
                            )}
                            <div className="">
                                <button
                                    disabled={
                                        processing || ccFieldDisabled || !stripe
                                    }
                                    className={`mt-2 text-base md:text-lg px-4 py-2 font-medium rounded bg-blue-500 text-white hover:opacity-80 disabled:bg-gray-300`}
                                >
                                    <span id="button-text">
                                        {processing ? (
                                            <span>
                                                <i className="fa fa-spin fa-spinner"></i>{" "}
                                                Processing...
                                            </span>
                                        ) : (
                                            "Update"
                                        )}
                                    </span>
                                </button>
                            </div>

                        </form>
                    }
                    {
                        error && <div className="text-red-500 text-sm mt-1 text-wrap">{error}</div>
                    }
                </>
            </div>
        </div>
    )
}

const UpdatePaymentMethodModalSkeleton = () => {
    return <>
        <div className="flex flex-col w-full">
            <div className="w-full h-[45px] bg-gray-300 rounded animate-pulse"></div>
            <div className="w-full h-16 bg-gray-300 rounded mt-2 animate-pulse"></div>
        </div>
    </>
}

export default UpdatePaymentMethodModal