import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SessionLink from './SessionLink';
import { useGetAllSessionsQuery } from '../../../features/chat/chatAPI';
import { setCurrentChatSession, openDeleteChatSessionModal } from "../../../features/chat/chatSlice";
function ChatSessions() {
    const { data: sessions, isLoading: sessionsLoading } = useGetAllSessionsQuery({
        from: 0,
        size: 50,
    });
    const dispatch = useDispatch();
    const { currentSession: {
        sessionId
    } } = useSelector((state) => state.chat)
    if (sessionsLoading) return <div className='mx-auto'><i className="fa fa-spin fa-spinner" /></div>;
    return (
        <>
            {(!sessions || !sessions?.length) ? (
                <p className="font-light text-sm text-gray-800 font-[poppins] text-center">Chat to get started</p>
            ) : (
                sessions?.map((session) => (
                    <SessionLink
                        key={session.session_id}
                        title={session.title}
                        active={sessionId === session.session_id}
                        onClick={() => dispatch(setCurrentChatSession({
                            sessionId: session.session_id,
                        }))}
                        onDelete={() => dispatch(openDeleteChatSessionModal({
                            sessionId: session.session_id,
                            sessionTitle: session.title
                        }))}
                    />
                ))
            )}
        </>
    );
}

export default ChatSessions;
