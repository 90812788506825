import React, { createContext } from 'react';

export const LandingPageContext = createContext();

export const LandingPageProvider = ({ children, planId, discountCoupon, handleCTAClick }) => {
  return (
    <LandingPageContext.Provider
      value={{
        planId,
        discountCoupon,
        handleCTAClick,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};
