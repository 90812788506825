import React, { Component } from 'react';
import $ from 'jquery';
import { withLayout } from './Layout';

function Disclaimer() {
  return (
    <div id='disclaimer' className='container mx-auto p-4'>
      <div className='header-title text-center'>
        <h1 className='entry-title text-4xl mt-5 mb-2.5'>Disclaimer</h1>
        <p className='lead'></p>
      </div>
      <div>
        <h2 className='text-2xl mt-5 mb-2.5'>In Plain English</h2>
        <p>
          UTM Grabber V2 and UTM Grabber V3 owned and operated by HandL Digital
          LLC. It is our policy to create awesome products and we hold all our
          customers and prospects in high regard. We will never do anything to
          cheat you or deceive you, we will not spam you or spy on you and we
          will never share your information with any third party. We’ve done our
          best to present all information on this site accurately and all the
          testimonials are real testimonials from real people.
        </p>

        <h2 className='text-2xl mt-5 mb-2.5'>Disclaimer</h2>
        <p>
          HandL Digital LLC makes no representations, warranties, or assurances
          as to the accuracy, currency or completeness of the content contain on
          this website or any sites linked to this site.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>E-mail Policy</h2>
        <p>
          We are committed to keeping your e-mail address confidential. We do
          not sell, rent, or lease our subscription lists to third parties, and
          we will not provide your personal information to any third party
          individual, government agency, or company at any time unless compelled
          to do so by law.
        </p>
        <p>
          We will use your e-mail address solely to provide timely information
          about HandL Digital LLC and/or its products
        </p>
        <p>
          HandL Digital LLC will maintain the information you send via e-mail in
          accordance with applicable federal law.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>CAN-SPAM Compliance</h2>
        <p>
          In compliance with the CAN-SPAM Act, all e-mail sent from our
          organization will clearly state who the e-mail is from and provide
          clear information on how to contact the sender. In addition, all
          e-mail messages will also contain concise information on how to remove
          yourself from our mailing list so that you receive no further e-mail
          communication from us.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Choice/Opt-Out</h2>
        <p>
          Our site provides users the opportunity to opt-out of receiving
          communications from us and our partners by reading the unsubscribe
          instructions located at the bottom of any e-mail they receive from us
          at anytime.
        </p>
        <p>
          Users who no longer wish to receive our newsletter or promotional
          materials may opt-out of receiving these communications by clicking on
          the unsubscribe link in the e-mail
        </p>
        <p>
          If there is no unsubscription link in the email, user shall be in
          contact with HandL to opt-out herself/himself.{' '}
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Anti-spam Policy</h2>
        <p>
          HandL Digital LLC prohibits the use of their website and/or services
          in any manner associated with the transmission, distribution or
          delivery of any unsolicited bulk or unsolicited commercial e-mail
          (“Spam”). You may not use any HandL Digital LLC services to send Spam.
          You also may not deliver Spam or cause Spam to be delivered to any
          customers of HandL Digital LLC.
        </p>
        <p>
          In compliance with the CAN-SPAM Act of 2003 (15 U.S.C. 7701, et seq.,
          Public Law No. 108-187, was S.877 of the 108th United States
          Congress), e-mail sent, or caused to be sent, to or through the HandL
          Digital LLC website or service may not:
        </p>
        <ul>
          <li>use or contain invalid or forged headers;</li>
          <li>use or contain invalid or non-existent domain names;</li>
          <li>
            employ any technique to otherwise misrepresent, hide or obscure any
            information in identifying the point of origin or the transmission
            path;
          </li>
          <li>use other means of deceptive addressing;</li>
          <li>
            use a third party’s internet domain name, or be relayed from or
            through a third party’s equipment, without permission of the third
            party;
          </li>
          <li>
            contain false or misleading information in the subject line or
            otherwise contain false or misleading content;
          </li>
          <li>
            fail to comply with additional technical standards described below;
          </li>
          <li>otherwise violate our Terms of Use.</li>
          <li>
            HandL Digital LLC does not authorize the harvesting, mining or
            collection of e-mail addresses or other information from or through
            the website or its services. HandL Digital LLC does not permit or
            authorize others to use the HandL Digital LLC services to collect,
            compile or obtain any information about its customers or
            subscribers, including but not limited to subscriber e-mail
            addresses.
          </li>
        </ul>
        <p>
          HandL Digital LLCdoes not permit or authorize any attempt to use its
          services in a manner that could damage, disable, overburden or impair
          any aspect of any of the services, or that could interfere with any
          other party’s use and enjoyment of any HandL Digital LLC service.
        </p>
        <p>
          If HandL Digital LLC believes that unauthorized or improper use is
          being made of any service, it may, without notice, take such action as
          it, in its sole discretion, deems appropriate, including blocking
          messages from a particular internet domain, mail server or IP address.
          HandL Digital LLC may immediately terminate any account on any service
          which it determines, in its sole discretion, is transmitting or is
          otherwise connected with any e-mail that violates this policy.
        </p>
        <p>
          Nothing in this policy is intended to grant any right to transmit or
          send e-mail to, or through, the HandL Digital LLC website and/or
          services. Failure to enforce this policy in every instance does not
          amount to a waiver of HandL Digital LLC’s rights.
        </p>
        <p>
          Unauthorized use of any HandL Digital LLC services in connection with
          the transmission of unsolicited e-mail, including the transmission of
          e-mail in violation of this policy, may result in civil, criminal, or
          administrative penalties against the sender and those assisting the
          sender.
        </p>
        <p>
          Violators of the CAN SPAM Act may be subject to “cease and desist”
          orders or penalties from the FTC (Federal Trade Commission) of up to
          $11,000 per violation. Criminal prosecution is also possible in the
          case of egregious offenders, which can result in penalties such as
          fines, forfeiture of proceeds and equipment. The worst offenders can
          also be subject to prison time.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Terms Of Use</h2>
        <p>
          By accessing this web site, you are agreeing to be bound by these web
          site <a href='/terms'>Terms and Conditions of Use</a>, all applicable
          laws and regulations, and agree that you are responsible for
          compliance with any applicable local laws. If you do not agree with
          any of these terms, you are prohibited from using or accessing this
          site. The materials contained in this web site are protected by
          applicable copyright and trade mark law.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Intellectual Property Rights</h2>
        <p>
          All copyrights, trademarks, patents and other intellectual property
          rights in and on our website and all content and software located on
          the site shall remain the sole property of HandL Digital LLC or its
          licensors. The use of our trademarks, content and intellectual
          property is forbidden without the express written consent from HandL
          Digital LLC.
        </p>
        <p>You must not:</p>
        <ul>
          <li>
            Republish material from our website without prior written consent.
          </li>
          <li>Sell or rent material from our website.</li>
          <li>
            Reproduce, duplicate, create derivative, copy or otherwise exploit
            material on our website for any purpose.
          </li>
          <li>
            Redistribute any content from our website, including onto another
            website.
          </li>
        </ul>
        <h2></h2>
        <h2 className='text-2xl mt-5 mb-2.5'>Acceptable Use</h2>
        <p>
          You agree to use our website only for lawful purposes, and in a way
          that does not infringe the rights of, restrict or inhibit anyone
          else’s use and enjoyment of the website. Prohibited behavior includes
          harassing or causing distress or inconvenience to any other user,
          transmitting obscene or offensive content or disrupting the normal
          flow of dialogue within our website.
        </p>
        <p>
          You must not use our website to send unsolicited commercial
          communications. You must not use the content on our website for any
          marketing related purpose without our express written consent.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Restricted Access</h2>
        <p>
          We may in the future need to restrict access to parts (or all) of our
          website and reserve full rights to do so. If, at any point, we provide
          you with a username and password for you to access restricted areas of
          our website, you must ensure that both your username and password are
          kept confidential.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Revisions</h2>
        <p>
          HandL Digital LLC may change these terms from time to time and so you
          should check these terms regularly. Your continued use of our website
          will be deemed acceptance of the updated or amended terms. If you do
          not agree to the changes, you should cease using our website
          immediately.
        </p>
        <p>
          If any of these terms are determined to be illegal, invalid or
          otherwise unenforceable, it shall be severed and deleted from these
          terms and the remaining terms shall survive and continue to be binding
          and enforceable.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Limitation of Liability</h2>
        <p>
          THE MATERIALS AT THIS SITE ARE PROVIDED “AS IS” WITHOUT ANY EXPRESS OR
          IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY,
          NONINFRINGEMENT OF INTELLECTUAL PROPERTY, OR FITNESS FOR ANY
          PARTICULAR PURPOSE. IN NO EVENT SHALL HandL Digital LLC OR ITS AGENTS
          OR OFFICERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT
          LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS
          OF INFORMATION, INJURY OR DEATH) ARISING OUT OF THE USE OF OR
          INABILITY TO USE THE MATERIALS, EVEN IF HandL Digital LLC HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES.
        </p>
        <h2 className='text-2xl mt-5 mb-2.5'>Testimonial Disclaimer</h2>
        <p>
          IN ACCORDANCE WITH THE FTC GUIDE LINES CONCERNING USE OF ENDORSEMENTS
          AND TESTIMONIALS IN ADVERTISING, PLEASE BE AWARE OF THE FOLLOWING:
        </p>
        <p>
          TESTIMONIALS APPEARING ON THIS SITE ARE ACTUALLY RECEIVED VIA TEXT,
          AUDIO OR VIDEO SUBMISSION. THEY ARE INDIVIDUAL EXPERIENCES, REFLECTING
          REAL LIFE EXPERIENCES OF THOSE WHO HAVE USED OUR PRODUCTS AND/OR
          SERVICES IN SOME WAY OR ANOTHER. HOWEVER, THEY ARE INDIVIDUAL RESULTS
          AND RESULTS DO VARY. WE DO NOT CLAIM THAT THEY ARE TYPICAL RESULTS
          THAT CONSUMERS WILL GENERALLY ACHIEVE. THE TESTIMONIALS ARE NOT
          NECESSARILY REPRESENTATIVE OF ALL OF THOSE WHO WILL USE OUR PRODUCTS
          AND/OR SERVICES.
        </p>
        <p>
          THE TESTIMONIALS DISPLAYED (TEXT, AUDIO AND/OR VIDEO) ARE GIVEN
          VERBATIM EXCEPT FOR CORRECTION OF GRAMMATICAL OR TYPING ERRORS. SOME
          HAVE BEEN SHORTENED. IN OTHER WORDS, NOT THE WHOLE MESSAGE RECEIVED BY
          THE TESTIMONIAL WRITER IS DISPLAYED, WHEN IT SEEMED LENGTHY OR NOT THE
          WHOLE TESTIMONIAL SEEMED RELEVANT FOR THE GENERAL PUBLIC.
        </p>
        <p>
          HandL Digital LLC IS NOT RESPONSIBLE FOR ANY OF THE OPINIONS OR
          COMMENTS POSTED TO OUR SITE. HandL Digital LLC IS NOT A FORUM FOR
          TESTIMONIALS, HOWEVER PROVIDES TESTIMONIALS AS A MEANS FOR CUSTOMERS
          TO SHARE THEIR EXPERIENCES WITH ONE ANOTHER. TO PREVENT AGAINST ABUSE,
          ALL TESTIMONIALS APPEAR AFTER THEY HAVE BEEN REVIEWED BY MANAGEMENT OF
          HandL Digital LLC.
        </p>
        <p>
          If you have any questions regarding the information in this
          disclaimer, please <a href='#'>contact us</a>.
        </p>
      </div>
    </div>
  );
}

export default withLayout(Disclaimer);
