import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';

export const knowledgeBaseAPI = createApi({
    reducerPath: 'knowledgeBaseAPI',
    baseQuery: amplifyBaseQuery,
    endpoints: (builder) => ({
        searchKnowledgeBase: builder.query({
            query: ({ from, size, query }) => ({
                url: '/knowledge_base/search?from=' + from + '&size=' + size + '&query=' + query + '',
                method: 'GET',
                useAuth: false,
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        }),

    }),
});

export const {
    useLazySearchKnowledgeBaseQuery,
    useSearchKnowledgeBaseQuery,

} = knowledgeBaseAPI;
