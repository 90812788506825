import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';
import { planAPI } from '../plan/planAPI';

export const myAccountAPI = createApi({
    reducerPath: 'myAccountAPI',
    baseQuery: amplifyBaseQuery,
    tagTypes: ['MyAccount'],
    endpoints: (builder) => ({
        changeEmail: builder.mutation({
            query: (data) => ({
                url: '/users/change_email',
                method: 'POST',
                useAuth: true,
                data: {
                    code: data.code,
                    accessToken: data.accessToken,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
        forgotPassword: builder.mutation({
            query: (data) => ({
                url: '/users/forgot_password',
                method: 'POST',
                useAuth: false,
                data: {
                    email: data.email,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
        resendVerification: builder.mutation({
            query: (data) => ({
                url: '/users/verify_email',
                method: 'POST',
                useAuth: false,
                data: {
                    email: data.email,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
        removeDomain: builder.mutation({
            query: (data) => ({
                url: `/users/remove_domain`,
                method: 'POST',
                useAuth: true,
                data: {
                    domain: data.domain,
                    license_key: data.license_key,
                },
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                // Optimistic updates
                const patchResult = dispatch(
                    planAPI.util.updateQueryData('getPlans',null, (draft) => {
                        draft.subscriptions.forEach((subscription) => {
                            if (subscription.license_key === args.license_key) {
                                subscription.domains = subscription.domains.filter((domain) => domain !== args.domain);
                            }
                        });
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
                dispatch(planAPI.util.invalidateTags([{ type: 'Plans', id: "LIST" }]));
            },
        }),
    }),
});

export const {
    useChangeEmailMutation,
    useForgotPasswordMutation,
    useResendVerificationMutation,
    useRemoveDomainMutation,
} = myAccountAPI;
