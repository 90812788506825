import { useCallback, useEffect, useState } from "react";
const CountdownTimer = ({ targetDate }) => {
  const [countDownTime, setCountDownTIme] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const getTimeDifference = (countDownTime) => {
    const currentTime = new Date().getTime();
    const timeDiffrence = countDownTime - currentTime;
    let days =
      Math.floor(timeDiffrence / (24 * 60 * 60 * 1000)) >= 10
        ? Math.floor(timeDiffrence / (24 * 60 * 60 * 1000))
        : `0${Math.floor(timeDiffrence / (24 * 60 * 60 * 1000))}`;
    const hours =
      Math.floor((timeDiffrence % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)) >=
        10
        ? Math.floor((timeDiffrence % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60))
        : `0${Math.floor(
          (timeDiffrence % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
        )}`;
    const minutes =
      Math.floor((timeDiffrence % (60 * 60 * 1000)) / (1000 * 60)) >= 10
        ? Math.floor((timeDiffrence % (60 * 60 * 1000)) / (1000 * 60))
        : `0${Math.floor((timeDiffrence % (60 * 60 * 1000)) / (1000 * 60))}`;
    const seconds =
      Math.floor((timeDiffrence % (60 * 1000)) / 1000) >= 10
        ? Math.floor((timeDiffrence % (60 * 1000)) / 1000)
        : `0${Math.floor((timeDiffrence % (60 * 1000)) / 1000)}`;
    if (timeDiffrence < 0) {
      setCountDownTIme({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      });
      clearInterval();
    } else {
      setCountDownTIme({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      });
    }
  };
  const startCountDown = useCallback(() => {
    return setInterval(() => {
      getTimeDifference(targetDate.getTime());
    }, 1000);
  }, []);
  useEffect(() => {
    startCountDown();
    return () => clearInterval(startCountDown());
  }, [startCountDown]);
  return (
    <div className="flex justify-center gap-3 sm:gap-8">
      {
        countDownTime?.days !== "00" && (<div className="flex flex-col gap-2 relative">

          <div className="h-16 w-16 sm:w-28 sm:h-28 flex justify-between items-center bg-landingAccent2-950 rounded-lg">
            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-white"></div>
            <span className="lg:text-5xl sm:text-4xl text-3xl font-semibold text-landingAccent2-300">
              {countDownTime?.days}
            </span>
            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-white"></div>
          </div>
          <span className="text-slate-800 font-medium text-xs sm:text-2xl text-center capitalize">
            {countDownTime?.days == 1 ? "Day" : "Days"}
          </span>
        </div>)
      }
      <div className="flex flex-col gap-2 relative">
        <div className="h-16 w-16 sm:w-28 sm:h-28 flex justify-between items-center bg-landingAccent2-950 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-white"></div>
          <span className="lg:text-5xl sm:text-4xl text-3xl font-semibold text-landingAccent2-300">
            {countDownTime?.hours}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-white"></div>
        </div>
        <span className="text-slate-800 font-medium text-xs sm:text-2xl text-center">
          {countDownTime?.hours == 1 ? "Hour" : "Hours"}
        </span>
      </div>
      <div className="flex flex-col gap-2 relative">
        <div className="h-16 w-16 sm:w-28 sm:h-28 flex justify-between items-center bg-landingAccent2-950 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-white"></div>
          <span className="lg:text-5xl sm:text-4xl text-3xl font-semibold text-landingAccent2-300">
            {countDownTime?.minutes}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-white"></div>
        </div>
        <span className="text-slate-800 font-medium text-xs sm:text-2xl text-center capitalize">
          {countDownTime?.minutes == 1 ? "Minute" : "Minutes"}
        </span>
      </div>
      <div className="flex flex-col gap-2 relative">
        <div className="h-16 w-16 sm:w-28 sm:h-28 flex justify-between items-center bg-landingAccent2-950 rounded-lg">
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-white"></div>
          <span className="lg:text-5xl sm:text-4xl text-3xl font-semibold text-landingAccent2-300">
            {countDownTime?.seconds}
          </span>
          <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-white"></div>
        </div>
        <span className="text-slate-800 font-medium text-xs sm:text-2xl text-center capitalize">
          {countDownTime?.seconds == 1 ? "Second" : "Seconds"}
        </span>
      </div>
    </div>
  );
};
export default CountdownTimer;
