import React from 'react';
import ChatSuggestionBubble from './ChatSuggestionBubble';
import UtmGrabberLogoPng from '../../images/handl-utm-grabber-g.png';
import UtmGrabberLogoWebp from '../../images/handl-utm-grabber-g.webp';
function WelcomeChat({handleSubmit}) {
    return (
        <div className="flex flex-col justify-between flex-1 font-[poppins]">
            <div className="flex-1">
                <div class="flex h-full flex-col items-center justify-center">
                    <div class="relative">
                        <div class="h-[72px] w-[72px]">
                            <picture>
                                <source srcSet={UtmGrabberLogoWebp} />
                                <img src={UtmGrabberLogoPng} alt="utm grabber logo" />
                            </picture>
                        </div>
                    </div>
                    <div class="mb-5 text-2xl font-medium">How can I help you today?</div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
                {[
                    {
                        question: 'Track UTMs in Gravity Form',
                        answer:`To track UTM parameters in Gravity Forms using our plugin, you'll need to add hidden fields to your form that correspond to the UTM parameters you want to capture.`,
                    },
                    {
                        question: 'Track UTMs in Elementor',
                        answer:`To track UTMs in Elementor, you'll want to use the Native Elementor Form Support method provided by HandL UTM Grabber`
                    },
                    {
                        question: 'What parameters I can track?',
                        answer:`You can track a wide range of parameters using HandL UTM Grabber, including but not limited to:`
                    },
                    {
                        question: `How can I track Google Ads?`,
                        answer:`To track Google Ads using HandL UTM Grabber, you need to set up tracking templates in your Google Ads account that include UTM parameters. Here's a step-by-step guide on how to do this`
                    },
                    {
                        question: `How can I track Facebook Ads?`,
                        answer:`To track Facebook Ads, you should include UTM parameters in your final destination URLs (landing URLs) when setting up your ads. `
                    },
                    {
                        question: `Track UTMs in Calendly`,
                        answer:`To track UTMs in Calendly, you can pass UTM parameters through the URL when initiating the Calendly widget`
                    },
                    {
                        question: `Track UTMs in WooCommerce`,
                        answer:`Our plugin is designed to work seamlessly with WooCommerce and captures UTM parameters at the order level. This means that for each order placed on your WooCommerce site, the UTM parameters associated with  `
                    },
                    {
                        question: `How can I capture referrer URL?`,
                        answer:`To capture the referrer URL, you can use the handl_original_ref parameter provided by HandL UTM Grabber. This parameter allows you to capture the original referrer of the traffic `
                    },
                    {
                        question: `Is there a way to capture first and last touch utm_campaign?`,
                        answer:`Yes, there is a way to capture both first and last touch UTM parameters, including utm_campaign. HandL UTM Grabber automatically captures and stores these parameters when a user visits your site. `
                    },
                    {
                        question: `Can I send the tracked data to my CRM?                        `,
                        answer:`Yes, you can send the tracked UTM data to your CRM. Our plugin captures UTM parameters and can pass them to various CRMs through form submissions or integrations like Zapier`
                    },
                ].map((message) => (
                    <ChatSuggestionBubble
                        question={message.question}
                        answer={message.answer}
                        onClick={() => {
                            handleSubmit(message.question)
                        }}
                        key={message.question}
                    />
                ))}
            </div>
        </div>
    );
}

export default WelcomeChat;
