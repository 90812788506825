// HOC that has an non scrollable layout (fixed height) 
import Footer from "./Footer";
import Navbar from "./Navbar";

const withFixedLayout = (WrappedComponent) => (props) => {
    return <div className="h-screen overflow-hidden flex flex-col">
        <Navbar className="!relative"/>
        <WrappedComponent {...props}/>
        <Footer/>
    </div>
}

export default withFixedLayout;