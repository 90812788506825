import React from "react";


const Contact = () => {
  return (
    <section className="contact-us m-t-80 m-b-100 text-center" id="contact">
      <div className="text-[22px] font-light text-center flex justify-center mb-3">
        Need some help? Or an integration question? &nbsp;
      </div>
      <a
        onClick={() => {
          const { $crisp } = window;
          $crisp?.push(["do", "chat:toggle"]);
        }}
        className="btn btn-sm btn-primary py-3 cursor-pointer"
      >
        Chat Now
      </a>
    </section>
  );
};

export default Contact;
