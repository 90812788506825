import React from 'react';

export default function WhyGood({ isOpened, setCurrentOpened }) {
  return (
    <div className='mb-2'>
      <h2>
        <button
          onClick={() => setCurrentOpened('whygood')}
          type='button'
          className='flex items-center justify-between w-full py-2 px-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-md focus:ring-4 focus:ring-gray-200 bg-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <i className='fa fa-check-circle text-green-600 m-1'></i> Why are we
            a good fit?
          </span>
        </button>
      </h2>
      <div className={isOpened ? '' : 'hidden'}>
        <div className='px-5 py-2 border border-gray-200'>
          <p className='text-gray-700'>
            Based on the information you provided to us, we compiled the reasons
            why we think we are a good fit for your business. Expand each
            section to learn more.
          </p>
        </div>
      </div>
    </div>
    // <div className="panel panel-default">
    //     <div className="panel-heading" role="tab" id="headingOne">
    //         <h4 className="panel-title">
    //             <a role="button" data-bs-toggle="collapse" data-parent="#RWF_accordion" href="#whygood">
    //                 <i className="fa fa-check-circle text-success"></i> Why are we a good fit?
    //             </a>
    //         </h4>
    //     </div>

    //     <div id="whygood" className="panel-collapse collapse in">
    //         <div className="panel-body">Based on the information you provided to us, we compiled the reasons why we think we are a good fit for your business. Expand each section to learn more.</div>
    //     </div>
    // </div>
  );
}
