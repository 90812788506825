import React, { useCallback, useMemo } from 'react';
// import { Tooltip } from 'react-tooltip';
import MONEYBACK from '../images/14daymoneyback.png';
import MONEYBACKW from '../images/14daymoneyback.webp';
import BasicSupport from './BasicSupport';
import PremiumSupport from './PremiumSupport';
import { ImgW } from '../js';
import Price from './Price';
import HandLApps from './HandLApps';
import MultiEnvironments from './MultiEnviroments';
import { useSelector } from 'react-redux';

function Prices() {
  const { prices } = useSelector(
    (state) => state.auth.data || {}
  );
  const { activeCoupon } = useSelector((state) => state.coupon);
  const getDiscountDetail = (couponData) => {
    if (!couponData) return '';
    const { amount_off, percent_off, duration, duration_in_months } = couponData;

    if (amount_off) {
      const discountAmount = `$${amount_off.toFixed(2).replace(/\.00$/, '')} off`;
      return formatDiscountDetail(discountAmount, duration, duration_in_months);
    } else if (percent_off) {
      const discountDetail = `${percent_off}% off`;
      return formatDiscountDetail(discountDetail, duration, duration_in_months);
    }

    return '';
  };

  const formatDiscountDetail = (discount, duration, duration_in_months) => {
    switch (duration) {
      case 'forever':
        return `${discount} for every year`;
      case 'once':
        return `${discount} for the first year`;
      case 'repeating':
        const durationText = duration_in_months % 12 === 0 ? `${duration_in_months / 12} ${duration_in_months === 12 ? "year" : "years"}` : `${duration_in_months} months`;
        return `${discount} for the first ${durationText}`;
      default:
        return '';
    }
  };
  const calculateDiscountPrice = useCallback((product_price) => {
    let discountedPrice = product_price;
    let discountDetail = '';
    if (activeCoupon?.coupon) {
      const couponData = activeCoupon.coupon;
      if (couponData.amount_off) {
        discountedPrice = Math.max(0, product_price - couponData.amount_off).toFixed(2).replace(/\.00$/, '');
      } else if (couponData.percent_off) {
        discountedPrice = (product_price * (1 - couponData.percent_off / 100)).toFixed(2).replace(/\.00$/, '');
      }
      discountDetail = getDiscountDetail(couponData);
    }
    return { discountedPrice, discountDetail };
  }, [activeCoupon]);
  // callback function to know if discount is available for the plan
  // if allowed_plans is null then discount is available for all plans
  // If there is no active coupon then discount is not available
  const isDiscountAvailable = useCallback((plan_id) => {
    if (!activeCoupon?.coupon) return false;
    if (!activeCoupon.coupon.allowed_plans) return true;
    return activeCoupon.coupon.allowed_plans.includes(plan_id);
  }
    , [activeCoupon]);
  return (
    <section className='row prices m-t-80 p-b-80' id='prices'>
      <div className='container max-w-screen-xl mx-auto px-12'>
        <h3 className='text-center m-b-40 text-[24px] font-medium'>
          The <span className='wp-title'>highest rated</span> and{' '}
          <span className='wp-title'>most downloaded</span> WordPress UTM
          tracking plugin.
          <br /> Over <span className='wp-title'>100,000+</span>
          sites trust HandL UTM Grabber to grow their business with confidence.
        </h3>
        <div className='flex flex-wrap pricesRow seven-cols justify-center items-end'>
          <Price
            first='true'
            plan='premium'
            colorClass='blue4'
            price={
              prices["premium"]
            }

            features={
              <ul>
                <PremiumSupport />
                <li>Customization</li>
                <li>24/7 Chat & Email</li>

                <HandLApps />
              </ul>
            }
            logo='bluev3'
            calculateDiscountPrice={calculateDiscountPrice}
            discountAvailable={isDiscountAvailable(prices["premium"].stripe)}
          />

          <Price
            plan='starterp'
            colorClass='blue3'
            price={
              prices["starterp"]
            }
            features={
              <ul>
                <BasicSupport />
                <MultiEnvironments />
                <HandLApps />
              </ul>
            }
            logo='bluev3'
            bestseller='true'
            calculateDiscountPrice={calculateDiscountPrice}
            discountAvailable={isDiscountAvailable(prices["starterp"].stripe)}
          />

          <Price
            plan='starter'
            colorClass='blue1'
            price={
              prices["starter"]
            }

            features={
              <ul>
                <BasicSupport />
              </ul>
            }
            logo='bluev3'
            calculateDiscountPrice={calculateDiscountPrice}
            discountAvailable={isDiscountAvailable(prices["starter"].stripe)}
          />
          <Price
            price={
              prices["free"]
            }
            plan='free' colorClass='orange1' features='' logo='orangev2' calculateDiscountPrice={calculateDiscountPrice} />
        </div>

        <div className='lg:flex md:flex sm:block mt-[20px] items-center xl:w-[1170px] mx-auto'>
          <div className='basis-1/4 flex justify-center px-[15px]'>
            <ImgW
              src={MONEYBACKW}
              fallback={MONEYBACK}
              className='img-responsive'
            />
          </div>
          <div className='basis-3/4 moneyback px-[15px]'>
            <h2 className='text-[35px] font-medium mt-[20px] mb-[10px]'>
              Risk-Free 14 Days Money Back Guarantee
            </h2>
            <ul className='pl-[40px]'>
              <li>
                Buy HandL UTM license(s) now{' '}
                <span className='font-bold'>completely risk-free</span>.
              </li>
              <li>
                Your investment is covered by no-hassle money back guaranteed
                for <span className='font-bold'>14 days</span>.
              </li>
              <li>
                In the highly unlikely event, you don't feel HandL UTM Grabber
                lives up to every claim on this page, just &nbsp;
                <button
                  className='pointer text-[#1972f5]'
                  onClick={() => {
                    window.$crisp.push(['do', 'chat:toggle']);
                  }}
                >
                  let us know
                </button>
                . We'll be happy to work with you to find solution. If you are
                still unsatisfied, we'll{' '}
                <span className='font-bold'>
                  return 100% of your investment
                </span>
                .
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Prices;
