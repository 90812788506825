import { createApi } from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery } from '../../store/utils';
import { planAPI } from '../plan/planAPI';

export const paymentAPI = createApi({
    reducerPath: 'paymentAPI',
    baseQuery: axiosBaseQuery({
        baseUrl:process.env.REACT_APP_API_ENDPOINT
    }),
    tagTypes: ['Payment'],
    endpoints: (builder) => ({
        onCharge: builder.mutation({
            query: (data) => ({
                url: 'http/charge',
                method: 'POST',
                useAuth: false,
                data: data
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    if (_.pay_id === "stripe" && _.type === "sub") {
                        // A stripe subscription was created
                        // Invalidate the plans list
                        dispatch(planAPI.util.invalidateTags([{ type: 'Plans', id: "LIST" }]));
                    }
                } catch{
                }
            },
        }),
        onUpgrade: builder.mutation({
            query: (data) => ({
                url: 'http/upgrade',
                method: 'POST',
                useAuth: true,
                data: data
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    if (_.pay_id === "stripe" && (_.type === "update" || _.type === "update-card")) {
                        // A stripe subscription was upgraded
                        dispatch(planAPI.util.invalidateTags([{ type: 'Plans', id: "LIST" }]));
                    }
                    else if (_.type === "cancel") {
                        // Subscription was cancelled
                        dispatch(planAPI.util.invalidateTags([{ type: 'Plans', id: "LIST" }]));
                    }
                   
                } catch{
                }
            },
        }),
        getGeo: builder.query({
            query: () => ({
                url: 'http/geo',
                method: 'GET',
                useAuth: false,
            }),
        }),
    }),
});

export const {
    useOnChargeMutation,
    useLazyGetGeoQuery,
    useOnUpgradeMutation

} = paymentAPI;
