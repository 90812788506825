import React from 'react';
import { useNavigate } from 'react-router-dom';

const Schedule = () => {
  const navigate = useNavigate();

  return (
    <section className='row grey-section' id='schedule-expert'>
      <div className='container max-w-screen-xl mx-auto px-12 text-center'>
        <h1 className='text-[36px] font-medium'>
          Schedule a 30-minutes FREE call with an expert?
        </h1>

        <p className='text-[16px] font-[400] mb-[10px]'>
          If you’re not sure where to start, or you’re not sure if you’re ready
          to take the plunge, you can always schedule a call with an expert.
          <br />
          At the very least, you’ll get some great advice and a better
          understanding of what you need to do to get started.
          <br /> You can also ask about the best way to get started with your
          business.
          <br />
          You might be surprised at how much you can learn from an expert.
        </p>

        <span
          onClick={() => navigate('/schedule')}
          className='btn btn-warning btn-sm py-[12.5px] cursor-pointer'
        >
          Schedule Now
        </span>
      </div>
    </section>
  );
};

export default Schedule;
