import React from 'react';

export default function LeadTracking({ isOpened, setCurrentOpened }) {
  return (
    <div className='mb-2'>
      <h2>
        <button
          onClick={() => setCurrentOpened('leadtracking')}
          type='button'
          className='flex items-center justify-between w-full py-2 px-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-md focus:ring-4 focus:ring-gray-200 bg-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <i className='fa fa-check-circle text-green-600 m-1'></i> We support
            lead tracking/capturing
          </span>
        </button>
      </h2>
      <div className={isOpened ? '' : 'hidden'}>
        <div className='px-5 py-2 border border-gray-200'>
          <p className='text-gray-700'>
            {' '}
            HandL UTM Grabber integrates with all the major optin forms
            including (
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/contact-form-7-integration'
              target='_blank'
              rel='noreferrer'
            >
              Contact Form 7
            </a>
            ,{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/ninja-forms-integration'
              target='_blank'
              rel='noreferrer'
            >
              Ninja Form
            </a>
            ,{' '}
            <a
              href='https://docs.utmgrabber.com/books/gravity-forms-integration'
              target='_blank'
              rel='noreferrer'
            >
              Gravity Forms
            </a>
            ,{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/wp-forms-integration'
              target='_blank'
              rel='noreferrer'
            >
              WP Forms
            </a>
            ,{' '}
            <a
              href='https://docs.utmgrabber.com/books/caldera-forms-integration'
              target='_blank'
              rel='noreferrer'
            >
              Caldera Forms
            </a>
            ,{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/thrive-leads-integration'
              target='_blank'
              rel='noreferrer'
            >
              Thrive Leads
            </a>{' '}
            and{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books'
              target='_blank'
              rel='noreferrer'
            >
              more
            </a>
            ), so basically you can collect the UTM parameters through the
            various forms and <b>sync them to your favorite CRM</b> using either
            the form’s connectors or <b>Zapier/Integromat</b> integration. In
            your CRM, every leads will have UTMs attached so it will help you to
            know/segment your list based on the UTMs.
          </p>
        </div>
      </div>
    </div>
    // <div className="panel panel-default">
    //     <div className="panel-heading" role="tab" id="headingOne">
    //         <h4 className="panel-title">
    //             <a role="button" data-bs-toggle="collapse" data-parent="#RWF_accordion" href="#acc_lead_tracking">
    //                 <i className="fa fa-check-circle text-success"></i> We support lead tracking/capturing
    //             </a>
    //         </h4>
    //     </div>

    //     <div id="acc_lead_tracking" className="panel-collapse collapse">
    //         <div className="panel-body"> HandL UTM Grabber integrates with all the major optin forms including (<a href="https://docs.utmgrabber.com/books/contact-form-7-integration" target="_blank">Contact Form 7</a>, <a href="https://docs.utmgrabber.com/books/ninja-forms-integration" target="_blank">Ninja Form</a>, <a href="https://docs.utmgrabber.com/books/gravity-forms-integration" target="_blank">Gravity Forms</a>, <a href="https://docs.utmgrabber.com/books/wp-forms-integration" target="_blank">WP Forms</a>, <a href="https://docs.utmgrabber.com/books/caldera-forms-integration" target="_blank">Caldera Forms</a>, <a href="https://docs.utmgrabber.com/books/thrive-leads-integration" target="_blank">Thrive Leads</a> and <a href="https://docs.utmgrabber.com/books" target="_blank">more</a>), so basically you can collect the UTM parameters through the various forms and <b>sync them to your favorite CRM</b> using either the form’s connectors or <b>Zapier/Integromat</b> integration. In your CRM, every leads will have UTMs attached so it will help you to know/segment your list based on the UTMs.</div>
    //     </div>
    // </div>
  );
}
