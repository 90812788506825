import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { openChatRatingModal } from "../../../features/chat/chatSlice"
import styles from "./chatMessage.module.css";
import { useDispatch, useSelector } from "react-redux";
import remarkGfm from 'remark-gfm'
import "./markdown.css"
export const ChatMessage = ({ message, loading, isLastMessage = false, scrollContainerRef, lastMessageRef }) => {
  const { ratedChats } = useSelector((state) => state.chat)

  const dispatch = useDispatch()
  const isMessageInView = () => {
    const scrollContainer = scrollContainerRef.current;
    const messageEl = lastMessageRef?.current;

    if (!messageEl) return false;
    const containerTop = scrollContainer.scrollTop;
    const containerBottom = containerTop + scrollContainer.clientHeight;

    const messageTop = messageEl.offsetTop;
    const messageBottom = messageTop + messageEl.clientHeight;

    // Check if the message is within the container's visible bounds
    return messageTop < containerBottom && messageBottom > containerTop;
  };

  useEffect(() => {
    if (isMessageInView()) {
      lastMessageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: 'end'
      })
    }
  }, [message])
  return (
    <div className={`${styles.container}`} ref={lastMessageRef}>
      <div
        className={`flex flex-col ${message.role === "user" ? "items-end" : "items-start"}`}
      >
        <div
          className={`p-2.5 flex-col justify-start items-start gap-2 flex max-w-[80%] font-[poppins] ${message.role === "user"
            ? "bg-blue-600 rounded-tr-2xl rounded-bl-2xl rounded-tl-2xl self-end "
            : "bg-white rounded-tr-2xl rounded-br-2xl rounded-tl-2xl border border-gray-200"
            } ${message.role !== "assistant" ? "mb-5" : ""} rounded-2xl px-3 py-2 whitespace-pre-wrap overflow-x-auto`}
          style={{ overflowWrap: "anywhere" }}
        >
          {message.role === "user" ? (
            <div
              className={`self-stretch text-sm font-light leading-normal text-left text-white`}
            >
              {message.content}
            </div>
          ) : (
            <ReactMarkdown
            remarkPlugins={[remarkGfm]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      wrapLongLines
                      children={String(children).replace(/\n$/, "")}
                      language={match[1]}
                      style={materialDark}
                      {...props}
                    />
                  ) : (
                    <code
                      className={`${className} text-sm font-light leading-normal text-left`}
                      {...props}
                    >
                      {children}
                    </code>
                  );
                },
                a({ node, inline, className, children, ...props }) {
                  return (
                    <a
                      className={`text-blue-600 hover:text-blue-800 visited:text-purple-600`}
                      {...props}
                      target="_blank"
                    >
                      {children}
                    </a>
                  );
                },
                ol({ node, inline, className, children, ...props }) {
                  return (
                    <ol className="markdown-numbered-list flex flex-col gap-2"
                      {...props}
                    >
                      {children}
                    </ol>
                  );
                },
                ul({ node, inline, className, children, ...props }) {
                  return (
                    <ul className="flex flex-col gap-2"
                      {...props}
                    >
                      {children}
                    </ul>
                  );
                },
              }}
              
            >
              {message.content}
            </ReactMarkdown>

          )}
        </div>
      </div>
      <div className={(ratedChats[message.id ?? ""] || message.rating) ? "opacity-0 pointer-events-none " : ""}
      >
        {
          message.role === "assistant" &&
          <div className={`flex flex-row flex-wrap w-full items-center font-[poppins] gap-x-5 gap-y-1 px-3 mt-1 ${(isLastMessage && !loading) ? "!opacity-100 !pointer-events-auto" : ""} ${(isLastMessage && loading) && "!opacity-0 !pointer-events-none hover:!opacity-0 hover:!pointer-events-none"} ${styles.chatBox} transition-opacity duration-300`}>
            <h2 className="font-normal text-gray-700 text-xs whitespace-nowrap">How would you rate this response?</h2>
            <div className={`flex flex-row gap-1 text-sm`}>
              {["😠", "😞", "😐", "🙂", "😄"].map((emoji, idx) => {
                return (
                  <button key={idx} className="focus:outline-transparent outline-transparent" onClick={() => dispatch(openChatRatingModal({
                    chatId: message.id,
                    rating: idx + 1,
                  }))}>
                    {emoji}
                  </button>
                )
              })}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
