import React, { Fragment, useState } from 'react';
import { usePopper } from 'react-popper'
import { Popover, Transition } from '@headlessui/react';
function SessionLink({
    title,
    active,
    onClick,
    onDelete,
}) {
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement)
    return (

        <Popover as={"div"}>

            {({ open, close }) => (
                <>
                    <button
                        className={`${(active) && "!bg-gray-200"} ${(open) && "bg-gray-200 bg-opacity-70"}  ${active && "active"} group whitespace-nowrap px-4 py-3 text-left font-[poppins] md:whitespace-normal text-gray-900 rounded-lg hover:bg-gray-200 hover:bg-opacity-70 cursor-pointer bg-gray-100 ss-bg-gray-200 transition-opacity transition-colors ease-in-out w-full`}
                        as="button"
                        onClick={() => !active && onClick()}
                    >
                        <Popover.Overlay className="fixed inset-0 opacity-0 z-[499]" />
                        <div className="flex w-full gap-2 items-center justify-center">
                            <div className="flex w-full items-center justify-between">
                                <div className="flex flex-col overflow-hidden text-gray-700 text-sm">
                                    <div className="truncate font-light">{title}</div>
                                </div>
                                <div className={`flex opacity-0 group-hover:opacity-100 ${open && 'opacity-100'} group-[.active]:opacity-100`}>
                                    <Popover.Button className={"self-center relative"}
                                        ref={setReferenceElement}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents the click from bubbling up and triggering the onClick on the parent element, which would change th eaclive session
                                        }}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-[1.125rem] h-[1.125rem]"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </Popover.Button>
                                    <Popover.Panel
                                            ref={setPopperElement}
                                            style={styles.popper}
                                            {...attributes.popper}
                                            className="absolute z-[500] left-1/2 mt-1 -translate-x-1/2 transform min-w-[200px]">
                                            <div className="rounded-lg overflow-visible shadow-lg bg-white p-0.5" onClick={(e) => {
                                                onDelete();
                                                e.stopPropagation(); // Prevents the click from bubbling up and triggering the onClick on the parent element, which would change th eaclive session
                                                close();
                                            }}>
                                                <div className="flex items-center gap-2 m-1.5 rounded-md p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 text-red-500">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-[1.125rem] h-[1.125rem]"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.5555 4C10.099 4 9.70052 4.30906 9.58693 4.75114L9.29382 5.8919H14.715L14.4219 4.75114C14.3083 4.30906 13.9098 4 13.4533 4H10.5555ZM16.7799 5.8919L16.3589 4.25342C16.0182 2.92719 14.8226 2 13.4533 2H10.5555C9.18616 2 7.99062 2.92719 7.64985 4.25342L7.22886 5.8919H4C3.44772 5.8919 3 6.33961 3 6.8919C3 7.44418 3.44772 7.8919 4 7.8919H4.10069L5.31544 19.3172C5.47763 20.8427 6.76455 22 8.29863 22H15.7014C17.2354 22 18.5224 20.8427 18.6846 19.3172L19.8993 7.8919H20C20.5523 7.8919 21 7.44418 21 6.8919C21 6.33961 20.5523 5.8919 20 5.8919H16.7799ZM17.888 7.8919H6.11196L7.30423 19.1057C7.3583 19.6142 7.78727 20 8.29863 20H15.7014C16.2127 20 16.6417 19.6142 16.6958 19.1057L17.888 7.8919ZM10 10C10.5523 10 11 10.4477 11 11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V11C13 10.4477 13.4477 10 14 10Z" fill="currentColor"></path></svg>
                                                    Delete chat
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                </div>

                            </div>
                        </div>
                    </button>
                </>
            )}

        </Popover>
    );
}

export default SessionLink;
