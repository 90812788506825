import React, { Component, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Tooltip({ children, tooltipContent, className = "" }) {
    const triggerRef = useRef()
    const timeOutRef = useRef()

    const handleEnter = (isOpen) => {
        clearTimeout(timeOutRef.current)
        !isOpen && triggerRef.current?.click()
    }

    const handleLeave = (isOpen) => {
        timeOutRef.current = setTimeout(() => {
            isOpen && triggerRef.current?.click()
        }, 120)
    }
    return (
        <Popover className={`relative ${className}`}>
            {({ open }) => (
                <div
                    onMouseEnter={() => handleEnter(open)}
                    onMouseLeave={() => handleLeave(open)}
                >
                    <Popover.Button as={"div"} ref={triggerRef} className={"pointer-events-none"} >
                        {children}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="left-1/2 -translate-x-1/2 mt-1 absolute z-10 bg-white text-sm font-normal leading-normal text-left rounded-md shadow !max-[280px]">
                            {
                                <div className=" p-2 relative">
                                    <div class="absolute left-1/2 transform -translate-x-1/2 top-0 -translate-y-[56%] fa fa-caret-up text-2xl text-white"></div>
                                    <div className="text-black text-xs font-normal font-[poppins] whitespace-nowrap">
                                        {tooltipContent}
                                    </div>
                                </div>
                            }

                        </Popover.Panel>
                    </Transition>

                </div>
            )}

        </Popover>
    )
}
