import React, { useState } from 'react';

const CouponInputComponent = ({ onApplyCoupon, loading }) => {
  const [coupon, setCoupon] = useState('');

  const handleApplyCoupon = () => {
    if(!coupon) return;
    onApplyCoupon(coupon);
  };

  return (
    <div className='flex flex-row items-center gap-1 max-[380px]:flex-col max-[380px]:items-start max-[380px]:w-full'>
      <label htmlFor="coupon" className="block text-base font-normal text-gray-900">Coupon Code</label>
      <div className="flex max-[380px]:w-full">
        <input
          type="text"
          id="coupon"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          className="block w-32 p-2 border border-gray-300 rounded-l-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm max-[380px]:w-full"
        />
        <button
          onClick={handleApplyCoupon}
          className="px-4 py-2 border border-l-0 border-gray-300 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {
            loading ? <i className="fa fa-spinner fa-spin"></i> :
              "Apply"
          }

        </button>
      </div>
    </div>
  );
};

export default CouponInputComponent;
