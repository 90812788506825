import React from 'react';
import { useFormik } from 'formik';
import Modal from '../Modal/Modal';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { closeDeleteChatSessionModal, openNewChatSession } from "../../features/chat/chatSlice"
import { useDeleteSessionMutation } from '../../features/chat/chatAPI';
function DeleteSession() {
    const dispatch = useDispatch();
    const { sessionToDelete: {
        sessionId,
        sessionTitle,
        sessionToDeleteModalOpen
    } } = useSelector((state) => state.chat)
    const handleCloseModal = () => {
        dispatch(closeDeleteChatSessionModal())
    };
    const [deleteSession] = useDeleteSessionMutation();
    const handleDeleteSession = async () => {
        try {
            handleCloseModal();
            dispatch(openNewChatSession())
            await deleteSession(sessionId).unwrap();
        }
        catch (error) {
            console.error(error);
            toast.error(`Error deleting chat: ${error?.message}`, {
                position: 'bottom-right',
                autoClose: 3000
            });
        }
    }
    return (
        <Modal isOpen={Boolean(sessionToDeleteModalOpen)} onClose={handleCloseModal}>
            <div
                className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-xl text-left font-[poppins] shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white max-w-md pointer-events-auto"
            >
                <div className="px-4 pb-4 pt-5 sm:p-6 flex items-center justify-between border-b border-black/10">
                    <div className="flex">
                        <div className="flex items-center">
                            <div className="flex grow flex-col gap-1">
                                <h2
                                    id="radix-:r66:"
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Delete chat?
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 sm:p-6">
                    This will delete <span className='font-semibold'>{sessionTitle}</span>.
                    <div className="mt-5 sm:mt-4">
                        <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
                            <button onClick={handleDeleteSession} className="bg-red-500 rounded hover:bg-red-800 px-3 py-2 font-medium text-white text-sm" as="button">
                                Delete
                            </button>
                            <button onClick={handleCloseModal} className="hover:bg-gray-100 rounded px-3 py-2 font-medium border border-gray-200 text-sm" as="button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div></Modal>
    );
}

export default DeleteSession;
