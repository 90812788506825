import React, { useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import FreeFeatures from "../Common/FreeFeatures";
import PremiumFeatures from "../Common/PremiumFeatures";
import { useSelector, useDispatch } from "react-redux";
import { setStoreData } from "../../store/auth";
import { useOnChargeMutation } from "../../features/payment/paymentAPI";
import CouponInput from "./CouponInput";
import CouponDetails from "./CouponDetails";
import { useValidateCouponMutation } from "../../features/coupon/couponAPI";
import { toast } from 'react-toastify';
import { useEffect } from "react";
import { removeActiveCoupon } from "../../features/coupon/couponSlice";
import PaypalDiscountWarning from "./PaypalDiscountWarning";
import ExpressCheckout from "./ExpressCheckout";
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PayFlowMain = ({
  selectedMethod,
  setCurrentStep,
  setSelectedFlow,
  setSelectedMethod,
}) => {
  const dispatch = useDispatch();
  const [onPay] = useOnChargeMutation();
  const {
    title,
    subtitle,
    msrp,
    membership,
    isSupportable,
    prices,
    product_price,
    coupon,
    multiplier,
    tmpUser,
    paypal_plan_id,
    stripe_plan_id,
    quantity,
    showPayment,
  } = useSelector((state) => state.auth.data || {});
  const { activeCoupon } = useSelector((state) => state.coupon);

  const [validateCouponMutation, {
    isLoading: couponLoading,
  }] = useValidateCouponMutation();
  const [couponData, setCouponData] = useState(null);
  const [state, setState] = useState({
    email: "",
    first_name: "",
    password: "",
    confirm_password: "",
    errorMsg: "",
    ccProcessing: false,
    // paypalProcessing: false,
    quantity: 1,
  });

  const [paypalProcessing, setPaypalProcessing] = useState(false);
  // 'cc' | 'paypal'
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const onUpgrade = (event) => {
    event.preventDefault();
    window.location.href = "/#prices";
  };

  const onDownload = (event) => {
    event.preventDefault();
    window.location =
      "https://downloads.wordpress.org/plugin/handl-utm-grabber.latest-stable.zip";
  };


  const onPayPalPay = async () => {
    setPaypalProcessing(true);
    setSelectedMethod("paypal");
    if (isSupportable) {
      setSelectedFlow("paypalSupport");
    }
    localStorage.setItem("paidMethod", "paypal");
    localStorage.setItem("isSupportable", isSupportable);
    localStorage.setItem("isPaypalLastPay", !isSupportable);
    try {
      console.log("here");
      const response = await onPay({
        pay_id: "paypal",
        email: tmpUser.email,
        coupon: coupon,
        planId: paypal_plan_id,
        membership: membership,
        quantity: quantity,
        multiplier: multiplier ? 2 : 1,
        marketing: window.HandL?.getAll(),
        redirect_urls: {
          return_url: '/?thanks=1&pay_id=paypal',
          cancel_url: '/?cancel=1&pay_id=paypal',
        }
      }).unwrap();

      window.gtag("event", "begin_checkout", {
        items: [
          {
            id: "HandL002",
            name: "HandL UTM Grabber V3",
            brand: "HandL",
            category: "WordPress Plugins",
            variant: "V3",
            list_position: 2,
            quantity: quantity,
            price: `${product_price}.00`,
          },
        ],
        coupon: coupon,
        email: state.email,
      });

      window.fbq("track", "InitiateCheckout", {
        currency: "USD",
        value: `${product_price}.00`,
      });

      window.rdt("track", "AddToCart", {
        currency: "USD",
        itemCount: quantity,
        value: `${product_price}.00`,
      });

      let approveLink = response.links.filter(
        (link) => link.rel == "approve"
      );
      // console.log(approveLink[0].href)
      window.location = approveLink[0].href;
    } catch (err) {
      console.log(err);
      setPaypalProcessing(false);
      setState({ ...state, paypalProcessing: false });
    }
  };
  const validateCoupon = async (code) => {
    try {
      const coupon = await validateCouponMutation({
        coupon: code
      }).unwrap();
      if (coupon.allowed_plans && !coupon.allowed_plans.includes(stripe_plan_id)) {
        throw "Coupon not applicable to this plan"
      }
      setCouponData(coupon);
      toast.success(
        "Discount coupon applied"
        , {
          position: 'bottom-right',
          autoClose: 3000
        });
    } catch (error) {
      toast.error(
        `Error applying coupon: ${error}`
        , {
          position: 'bottom-right',
          autoClose: 3000
        });
      if (activeCoupon) {
        dispatch(removeActiveCoupon())
      }
    }
  }
  const totalPrice = useMemo(() => {
    let discountedPrice = product_price;

    if (couponData) {
      if (couponData.amount_off) {
        discountedPrice = Math.max(0, product_price - couponData.amount_off);
      } else if (couponData.percent_off) {
        discountedPrice = product_price * (1 - couponData.percent_off / 100);
      }
    }

    const total = (state.quantity * discountedPrice) + (isSupportable ? prices.support.msrp : 0);
    return parseFloat(total.toFixed(2));
  },
    [state.quantity, product_price, isSupportable, prices.support.msrp, couponData]
  );
  useEffect(() => {
    if (activeCoupon) {
      // If there is an active coupon apply it
      validateCoupon(activeCoupon?.couponStripe);
    }
  }, [activeCoupon]);
  const resetActiveCoupon = () => {
    if (activeCoupon) {
      dispatch(removeActiveCoupon())
    }
    setCouponData(null);

  };

  if (activeCoupon && couponLoading) {
    // Loading the coupon from promo
    return <PayFlowMain.Skeleton />
  }
  return (
    <div className="container max-w-screen-xl mx-auto md:px-8 sm:px-3 min-h-[calc(100vh-363.18px)] overflow-y-auto flex flex-col justify-start">
      <PaypalDiscountWarning modalOpen={Boolean(couponData && selectedPaymentMethod == "paypal")} handleCloseModal={() => {
        setSelectedPaymentMethod(null)
      }} handleContinueWithCreditCard={() => {
        setSelectedPaymentMethod("cc")
      }} handleContinueWithPaypal={onPayPalPay} isLoading={paypalProcessing} />
      <div className="product-desc font-[poppins]">
        <div className="md:px-4 lg:px-8">
          {showPayment ? (
            <div className="flow-root">
              <div className="-mx-4 my-2 sm:-my-2 overflow-x-auto lg:-mx-8 [&::-webkit-scrollbar]:[height:6px]
            [&::-webkit-scrollbar-thumb]:bg-gray-200">
                <div className="inline-block min-w-full sm:py-2 align-middle md:px-2 lg:px-8">
                  <div
                    className="min-w-full flex flex-col"
                    id="cart"
                  >
                    <div className="w-full flex flex-col gap-2 md:gap-4">
                      <div className="sm:flex w-full border-b-[1px] border-gray-300 pb-2 md:pb-4 items-center md:gap-[5px] text-sm hidden">
                        <div className="font-semibold text-gray-900 pl-7 md:pl-11 lg:pl-16 w-4/6">
                          Product
                        </div>
                        <div className="font-semibold text-gray-900 w-1/6">
                          Price
                        </div>
                        <div className="font-semibold text-gray-900 w-1/6">
                          Subtotal
                        </div>
                      </div>

                      <div className="flex sm:flex-row w-full sm:border-b-[1px] border-gray-200 sm:shadow-none sm:p-0 sm:pb-2 md:pb-4 sm:items-center md:gap-[5px] text-sm sm:flex-nowrap flex-wrap  shadow-md p-2">
                        <div className="flex flex-col pl-8 md:pl-11 lg:pl-16 sm:w-4/6 w-full">
                          <h4 className="text-xl sm:text-sm">{title}</h4>
                          <p>{subtitle}</p>
                        </div>
                        <div
                          className="whitespace-nowrap text-sm font-medium text-gray-900 sm:w-1/6 flex ml-8 pr-5 sm:ml-0 sm:pr-0 mt-2"
                        >
                          <div className="pr-1 sm:hidden">Price:</div>
                          <div>
                            ${msrp}&nbsp;
                            {membership ? "/year" : ""}
                          </div>
                        </div>
                        <div
                          className="whitespace-nowrap text-sm font-medium text-gray-900 sm:w-1/6 flex mt-2"
                        >
                          <div className="pr-1 sm:hidden max-[380px]:ml-8">Subtotal:</div>
                          <div>
                            ${state.quantity * msrp}
                            &nbsp;
                            {membership ? "/year" : ""}
                          </div>
                        </div>
                      </div>

                      <div className="flex sm:flex-row w-full sm:border-b-[1px] border-gray-200 sm:shadow-none sm:p-0 sm:pb-2 md:pb-4 sm:items-center md:gap-[5px] text-sm sm:flex-nowrap flex-wrap shadow-md p-2">
                        <div className="flex sm:w-4/6 sm:items-center lg:pl-5 w-full">
                          <input
                            className="mt-3 sm:mt-0"
                            type="checkbox"
                            checked={isSupportable || false}
                            onChange={(e) => {
                              if (selectedMethod === "paypal") {
                                setSelectedFlow(
                                  isSupportable ? "other" : "paypalSupport"
                                );
                              }
                              dispatch(
                                setStoreData({
                                  data: {
                                    isSupportable: !isSupportable,
                                  },
                                })
                              );
                            }}
                          />
                          <div className="flex flex-col md:pl-6 sm:pl-3 pl-3">
                            <h4 className="text-xl sm:text-sm">
                              {prices.support.title}
                            </h4>
                            <p className="" >{prices.support.subtitle}</p>
                          </div>
                        </div>
                        <div
                          className="whitespace-nowrap text-sm font-medium text-gray-900 sm:pl-0 sm:w-1/6 flex  ml-7 pr-5 sm:ml-0 sm:pr-0 mt-2"
                        >
                          <div className="pr-1 sm:hidden">Price:</div>
                          <div>
                            ${prices.support.msrp}
                            &nbsp;
                            {prices.support.membership ? "/year" : ""}
                          </div>
                        </div>
                        <div
                          className="whitespace-nowrap text-sm font-medium text-gray-900 sm:pl-0 sm:w-1/6 flex mt-2"
                        >
                          <div className="pr-1 sm:hidden  max-[380px]:ml-7">Subtotal:</div>
                          <div>
                            ${prices.support.msrp}
                            {" "} {prices.support.membership ? "/year" : ""}
                          </div>
                        </div>
                      </div>
                    </div>


                    {couponData && (
                      <CouponDetails coupon={couponData} price={state.quantity * msrp} />
                    )}
                    <div className="w-full text-right">
                      <div className=" whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-0">
                        <strong>
                          Total $
                          {totalPrice}
                          &nbsp;
                          {membership ? "/year" : ""}
                        </strong>
                      </div>
                    </div>
                  </div>
                  {
                    !couponData &&
                    <div className="flex flex-row min-[380px]:justify-end items-center mb-4">
                      <CouponInput loading={couponLoading} onApplyCoupon={validateCoupon} />
                    </div>
                  }
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col sm:flex-row justify-end w-full items-center gap-2">
                  <button
                    className={`w-full h-[45px] font-medium text-lg bg-[#ffe457] text-[#374048] hover:bg-[#374048] px-5 hover:text-white ${selectedPaymentMethod === "cc" ? "!bg-[#374048] rounded text-white" : ""}`}
                    onClick={() => setSelectedPaymentMethod("cc")}
                  >
                    <i className="fa fa-credit-card"></i>&nbsp; Credit Card
                  </button>
                  <button
                    className={`w-full h-[45px] font-medium text-lg bg-[#ffe457] text-[#374048] hover:bg-[#374048] px-5 hover:text-white ${paypalProcessing ? "!bg-[#374048] rounded text-white" : ""}`}
                    onClick={(e) => {
                      setSelectedPaymentMethod("paypal");
                      if (!couponData) {
                        e.preventDefault();
                        onPayPalPay();

                      }
                    }}
                  >
                    {paypalProcessing ? (
                      <>
                        <i className="fa fa-spin fa-spinner"></i> Processing...
                      </>
                    ) : (
                      <>
                        <i className="fa fa-paypal"></i>&nbsp; Pay With Paypal
                      </>
                    )}
                  </button>
                </div>
                <Elements stripe={promise} options={{
                  mode: "setup",
                  currency: "usd",
                  setupFutureUsage: "off_session",
                  paymentMethodCreation: "manual",
                }}>
                  <ExpressCheckout setCurrentStep={setCurrentStep}
                    setSelectedMethod={setSelectedMethod}
                    coupon={couponData ? {
                      id: couponData?.id,
                      type: couponData?.type,
                    } : undefined}
                    resetActiveCoupon={resetActiveCoupon}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    />
                </Elements>

                {(selectedPaymentMethod === "cc") && (
                  <div className="billing-info slide-down">
                    <Elements stripe={promise}>
                      <CheckoutForm
                        setCurrentStep={setCurrentStep}
                        setSelectedMethod={setSelectedMethod}
                        coupon={couponData ? {
                          id: couponData?.id,
                          type: couponData?.type,
                        } : undefined}
                        resetActiveCoupon={resetActiveCoupon}
                      />
                    </Elements>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center" id="js-download-info">
              <div className="row offer-box">
                <div className="col-md-6">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">UTM Grabber V2 (FREE)</h3>
                    </div>
                    <div className="panel-body greencheck nope text-left">
                      <FreeFeatures />
                    </div>
                    <div className="panel-footer">
                      <a
                        className="btn btn-sm btn-primary"
                        onClick={onDownload}
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="panel panel-success">
                    <div className="panel-heading">
                      <h3 className="panel-title">UTM Grabber V3</h3>
                    </div>
                    <div className="panel-body greencheck text-left">
                      <PremiumFeatures />
                    </div>
                    <div className="panel-footer">
                      <a
                        href=""
                        className="btn btn-sm btn-success"
                        onClick={onUpgrade}
                      >
                        Upgrade Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PayFlowMain.Skeleton = () => {
  return <div className="container max-w-screen-xl mx-auto md:px-12 sm:px-3 h-[calc(100vh-363.18px)] overflow-y-auto flex flex-col justify-start animate-pulse">
    <div className="flex justify-between items-center py-4">
      <div className="h-6 w-1/2 max-w-48 bg-gray-300 rounded"></div>
      <div className="h-6 w-1/4 max-w-24 bg-gray-300 rounded"></div>
    </div>
    <div className="h-8 w-1/2 max-w-48 bg-gray-300 rounded my-4"></div>
    <div className="h-8 w-1/2 max-w-48 bg-gray-300 rounded mb-6"></div>
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="h-6 w-1/4 max-w-24 bg-gray-300 rounded my-4"></div>
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center">
          <div className="h-6 w-1/4 max-w-24 bg-gray-300 rounded"></div>
          <div className="h-6 w-1/12 bg-gray-300 rounded"></div>
        </div>
        <div className="flex justify-between items-center">
          <div className="h-6 w-1/4 max-w-24 bg-gray-300 rounded"></div>
          <div className="h-6 w-1/12 bg-gray-300 rounded"></div>
        </div>
      </div>
      <div className="h-6 w-1/6 bg-gray-300 rounded my-4"></div>
      <div className="h-6 w-1/6 bg-gray-300 rounded mb-4"></div>
      <div className="h-6 w-1/3 bg-gray-300 rounded mb-4"></div>
      <div className="flex space-x-4">
        <div className="h-10 w-1/4 max-w-24 bg-gray-300 rounded"></div>
        <div className="h-10 w-1/4 max-w-24 bg-gray-300 rounded"></div>
      </div>
    </div>
  </div>
}
export default PayFlowMain;
