import React, { Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreData } from '../../store/auth';

export default function FreeButton() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth.data || {});
  const onFreeClick = (event) => {
    event.preventDefault();
    // $.magnificPopup.close();

    if (!isAuthenticated) {
      dispatch(
        setStoreData({
          data: {
            showAreWeFit: false,
            showLogin: true,
          },
        })
      );
      return;
    }
    dispatch(
      setStoreData({
        data: {
          showAreWeFit: false,
          flowSelected: 'free',
          showPayment: true,
        },
      })
    );
  };

  return (
    <div className='m-t-20 text-center'>
      <a
        onClick={(e) => {
          onFreeClick(e);
        }}
        className='focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2'
      >
        Try FREE now!
      </a>
    </div>
  );
}
