import React from "react";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { setStoreData } from "../store/auth";
import { withLayout } from "./Layout";

const Terms = () => {
  const dispatch = useDispatch();

  return (
    <div id="terms" className="container mx-auto mt-[150px] mb-[30px]">
      <h1 className="text-center font-bold text-[40px] mb-[30px]">Terms</h1>
      <div>
        <p className="font-bold">
          In using this website, you are deemed to have read and agreed to the
          following terms &amp; conditions:
        </p>
        <p className="pl-2">
          <br />
          In this terms and conditions document as well as in our &nbsp;
          <a title="Privacy Policy" href="/privacy" className="text-[#1972f5]">
            privacy policy
          </a>
          &nbsp; and our &nbsp;
          <a title="Disclaimer" href="/disclaimer" className="text-[#1972f5]">
            disclaimer
          </a>
          , the following terminology applies: “customer”, “client”, “you” and
          “yours” refer to you, the person accessing our website and accepting
          the terms. “The company”, “ourselves”, “we” and “us” refers to Handl
          Digital LLC brand and its products. “Party”, “parties” and “us” refers
          to both the customer and ourselves. All terms refer to the offer,
          acceptance and consideration of payment necessary to undertake the
          process of our assistance to the customer in the most appropriate
          manner. Any use of the above terminolgy or other words in the
          singular, plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to same.
          <br />
          <br />
          You agree that by accessing the Site and/or the Marketplace Offerings,
          you have read, understood, and agree to be bound by all of these Terms
          of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU
          ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND/OR THE MARKETPLACE
          OFFERINGS AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          <br />
          <br />
          Supplemental terms and conditions or documents that may be posted on
          the Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Use at any time and for any
          reason. It is your responsibility to periodically review these Terms
          of Use to stay informed of updates. You will be subject to, and will
          be deemed to have been made aware of and to have accepted, the changes
          in any revised Terms of Use by your continued use of the Site after
          revised Terms of Use are posted.
          <br />
          <br />
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
          <br />
          <br />
          HandL Digital may modify the services or discontinue their
          availability at any time. HandL Digital may, with or without notice,
          add features to the Services, or change or remove features of the
          Services, at any time.
        </p>

        <h3 className="font-bold mt-[20px]">CORRECTIONS</h3>
        <p className="pl-2">
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions that may relate to the Marketplace
          Offerings, including descriptions, pricing, availability, and various
          other information. We reserve the right to correct any errors,
          inaccuracies, or omissions and to change or update the information on
          the Site at any time, without prior notice.
        </p>

        <h3 className="font-bold mt-[20px]">LIMITATIONS OF LIABILITY</h3>
        <p className="lowercase pl-2">
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SITE OR THE MARKETPLACE OFFERINGS, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOT WITHSTANDING ANYTHING
          TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
          WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
          BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US
        </p>

        <h3 className="font-bold mt-[20px]">WARRANTY</h3>
        <p className="pl-2">
          HandL Digital makes no representation, warranty, or guarantee as to
          the reliability, timeliness, quality, suitability, availability,
          accuracy or completeness of the Services. HandL Digital does not
          represent or warrant that (a) the use of the Services will be secure,
          timely, uninterrupted or error-free or operate in combination with any
          other hardware, software, system or data, (b) the Service will meet
          your requirements or expectations (c) errors or defects will be
          corrected, or (d) the Services are free of viruses or other harmful
          components.
        </p>

        <h3 className="font-bold mt-[20px]">RELEASE and INDEMNITY </h3>
        <p className="pl-2">
          You hereby expressly and irrevocably release and forever discharge
          HandL Digital, including the company's founder(s), directors,
          employees, agents, representatives, independent and dependent
          contractors, licensees, successors and assigns of and from any and all
          actions, causes of action, suits, proceedings, liability, debts,
          judgments, claims and demands whatsoever in law or equity which you
          ever had, now have, or hereafter can, shall or may have, for or by
          reason of, or arising directly or indirectly out of your use of the
          Services. You hereby agree to indemnify and hold harmless HandL
          Digital, including the company's founder(s), directors, officers,
          employees, agents, representatives, independent and dependent
          contractors, licensees, successors and assigns from and against all
          claims, losses, expenses, damages and costs (including, but not
          limited to, direct, incidental, consequential, exemplary and indirect
          damages), and reasonable attorneys’ fees, resulting from or arising
          out of (i) a breach of this Agreement, (ii) the use of the Services,
          by You or any person using your account, or (iii) any violation of any
          rights of a third party.
        </p>

        <h3 className="font-bold mt-[20px]">PRICING</h3>
        <p className="pl-2">
          You are fully responsible for timely payment of all fees for the HandL
          UTM Grabber V3 License and for any obligations to pay taxes and
          charges arising from your purchase of the HandL UTM Grabber V3
          License.
          <br />
          We reserve the right to not honor typographical errors which may cause
          incorrect pricing on the HandL UTM Grabber V3 Website.
          <br />
          HandL Digital reserve the right, at any time, to modify or
          discontinue, temporarily or permanently, support or any of our
          products with or without notice. Prices of all products are subject to
          change. Notice of price changes will be made on the site.
          <br />
          <b>Recurring Billing:</b> &nbsp;By purchasing, renewing, or upgrading
          a HandL UTM Grabber V3 License and providing a valid Payment Method,
          you authorize us to charge you a license renewal fee for HandL UTM
          Grabber V3 Licenses at the specified rate on the HandL UTM Grabber V3
          Website to your Payment Method. The amount and future billing date
          will be clearly stated on the My Subscription Menu for each HandL UTM
          Grabber V3 License.
          <br />
          <b>Payment Methods:</b> &nbsp;The payment method used to purchase,
          renew or upgrade a HandL UTM Grabber V3 License will be stored
          securely with our payment processor and will be re-billed when a HandL
          UTM Grabber V3 License is renewed
        </p>

        <h3 className="font-bold mt-[20px]">SUBSCRIPTIONS</h3>
        <p className="pl-2">
          Terms herein that govern Subscriptions are in addition to and
          cumulative with the other terms in this Agreement. Subscriptions are
          valid for one year from the date of purchase. A valid and active
          license key is required for automatic upgrades and support for
          purchased extensions. At the end of each year, if you have not
          canceled your Subscription or opted out of automatic renewal, your
          Subscription will automatically renew at regular rate as provided in
          the website, and you will be charged the purchase price at the time
          you purchased your Subscription or the Renewal Rate, whichever is
          higher, after applying any discounts for which you may be eligible.
          You may cancel automatic renewal by following the procedure at
          https://utmgrabber.com/ or by means as we may otherwise communicate to
          you. Cancellation automatically and immediately terminates your
          eligibility for any discounts or other benefits that may be associated
          with your Subscription.
          <br />
          You may not transfer your Subscription to another individual or
          organization without the express consent of HandL Digital LLC.
          Transfer of your Subscription automatically and immediately terminates
          your Subscription and your eligibility for any discounts or other
          benefits that may be associated with your Subscription.
        </p>

        <h3 className="font-bold mt-[20px]">SOFTWARE UPDATES</h3>
        <p className="pl-2">
          If you have a valid and active license key as a result of having
          purchased a Subscription, you will receive updates for the duration of
          your Subscription. Updates will be delivered automatically from the
          WordPress dashboard if your license key is active under Plugin> HandL
          UTM Grabber V3 -> Licenses in your WordPress dashboard. If you are
          unable to get automatic updates from your dashboard, updates can be
          downloaded from your account page while your license is active.
        </p>

        <h3 className="font-bold mt-[20px]">SUPPORT SERVICES</h3>
        <p className="pl-2">
          The scope of our support services is outlined here:
          https://utmgrabber.com/
          <br />
          Subject to the Terms and while you maintain a valid and active HandL
          UTM Grabber V3 License, HandL Digital LLC offers support for HandL UTM
          Grabber V3 to you (“Support") at such support levels as you purchase .
          HandL Digital LLC may amend the Support Page from time to time in its
          sole discretion.
          <br />
          Support Services are available via our contact form and/or chat for
          all accounts for which there is a current and active Subscription. If
          you wish to receive Support after one year, you must renew your
          Subscription or purchase a new license key. Support is not available
          for accounts without an active Subscription. If a purchased extension
          is discontinued, HandL Digital may, at its sole discretion, offer a
          replacement or other extension at no or reduced cost to purchasers of
          the discontinued extension. However, we undertake no obligation
          whatsoever to extend support for discontinued extensions or to offer
          any replacement or refund in the event that an extension is
          discontinued.
          <br />
          Support requests are handled on a first-come, first-serve basis. We
          will undertake Commercially Reasonable Efforts to resolve issues in a
          timely, courteous, and professional manner. However, we make NO
          WARRANTY of any kind with respect to the Software or with respect to
          whether the use of Support Services may resolve any particular issue.
          Support Services purchased from us are sold “AS IS” and WITHOUT ANY
          WARRANTY. We hereby disclaim any and all warranty not expressly
          provided herein, included but not limited to the warranties of
          MERCHANTABILITY and of FITNESS FOR A PARTICULAR PURPOSE. You use our
          Support Services at your own risk. We are not responsible for any
          harm, including but not limited to data loss, that may occur as a
          result of using our Support Services, and our liability for harm
          resulting from your use of Support is expressly limited as provided
          herein.
          <br />
          Basic support initial response is 2 business day.
          <br />
          Premium support initial response is 1 business day.
          <br />
          HandL UTM Grabber V3 Starter and Basic license holders have access to
          our Basic Support system.
          <br />
          HandL UTM Grabber V3 Standard, Premium and Ultimate license holders
          have access to our Priority Support system.
          <br />
          HandL Digital LLC’s provision of Support is contingent upon and
          subject to your full and timely cooperation and your provision of
          complete and accurate information.
          <br />
          You may not use Support, the Support Page, the Products, or HandL UTM
          Grabber (i) in any way that could harm HandL Digital LLC, its
          affiliates or any other service provider or any computer network or
          system or impair anyone else’s use thereof or (ii) to try to gain
          unauthorized access to any service, data, account or network by any
          means.
          <br />
          You may not permit any other person or entity to use Support. You are
          responsible for all activities that occur under your HandL UTM Grabber
          V3 License or use of Support services. You must not use a false
          identity, impersonate others, or otherwise misrepresent your identity
          in any way. You agree to comply with any terms of use applicable to
          https://utmgrabber.com/ and as may be updated from time to time in
          HandL Digital LLC’s sole discretion.
          <br />
          HandL Digital LLC may elect not to provide Support for non-current
          versions of HandL UTM Grabber (or any of its Products) or versions of
          HandL UTM Grabber that do not have all current Updates installed.
          <br />
          HandL Digital LLC offers WordPress plugins "as is" and with no implied
          meaning.
        </p>

        <h3 className="font-bold mt-[20px]">HandL UTM Grabber V3 LICENSE</h3>
        <p className="pl-2">
          Your initial purchase of HandL UTM Grabber V3 includes a twelve-month
          subscription for Updates and Support (“Initial Subscription”). At the
          end of the Initial Subscription, the subscription for Updates and
          Support will be auto-renewed for successive twelve-month periods (each
          a “Renewal Subscription” and, together with the Initial Subscription,
          the “HandL UTM Grabber V3 License”) unless, prior to the renewal date,
        </p>
        <ul className="pl-3">
          <li>
            (i) you cancel your HandL UTM Grabber V3 License by disabling
            auto-renewal,
          </li>
          <li>(ii) your payment cannot be successfully processed,</li>
          <li>
            (iii) your payment is subject to a chargeback or other fraud
            process,
          </li>
          <li>
            (iv) you request a refund pursuant to Refund Policy Section or
          </li>
          <li>
            (v) HandL Digital LLC, cancels or suspends your HandL UTM Grabber V3
            License or the Software License.
          </li>
        </ul>
        <p className="pl-2">
          You may upgrade your HandL UTM Grabber V3 License by paying a prorated
          amount of the price of the upgraded HandL UTM Grabber V3 License based
          upon the remaining number of days of the HandL UTM Grabber V3 License
          on the day immediately prior to the Upgrade. Prorated credit is only
          available for HandL UTM Grabber V3 Licenses under an active Initial or
          Renewal Subscription, and is not available for canceled or transferred
          HandL UTM Grabber V3 Licenses.
        </p>

        <h3 className="font-bold mt-[20px]">
          HandL UTM Grabber V3 LICENSE HOLDERS
        </h3>
        <p className="pl-2">
          The HandL UTM Grabber V3 License is registered to the email address of
          the original purchaser of the HandL UTM Grabber V3 License. HandL UTM
          Grabber V3 License holders cannot distribute, giveaway, permit others
          to use, or resell their HandL UTM Grabber V3 License. The HandL UTM
          Grabber V3 License is transferable, provided that (i) you require the
          party to which you transfer the license (“Transferee”) to abide by
          these Terms and (ii) you complete the “Transfer” option for the HandL
          UTM Grabber V3 License you wish to transfer via contacting with HandL
          Digital LLC.
          <br />
          HandL UTM Grabber V3 is intended to be used on your web site(s). Use
          of HandL UTM Grabber V3 on your client’s site is permitted in
          situations where you maintain an ongoing relationship with the client
          as a consultant. You are prohibited from providing HandL UTM Grabber
          V3 as a service or providing HandL UTM Grabber V3 as part of a web
          hosting solution. Providing HandL UTM Grabber V3 as a service or
          providing HandL UTM Grabber V3 as part of a web hosting solution does
          not constitute an ongoing client/consultant relationship under this
          Section.
        </p>

        <h3 className="font-bold mt-[20px]">TRANSACTIONAL EMAILS</h3>
        <p className="pl-2">
          HandL Digital LLC may on occasion send you email notifications related
          to the HandL UTM Grabber V3 License to the registered email address as
          described in Section 'HandL UTM Grabber V3 License Holders'. These
          transactional emails may include notification of licensing changes,
          changes to our Terms and Conditions, license expiration notices and
          other transactional emails related to your purchase of a HandL UTM
          Grabber V3 License. By purchasing the HandL UTM Grabber V3 License you
          agree to receive these transactional emails and that these
          transactional emails are binding as if set forth in these Terms and
          Conditions.
        </p>

        <h3 className="font-bold mt-[20px]">AUTOMATIC UPDATES</h3>
        <p className="pl-2">
          HandL Digital LLC, may from time to time in its sole discretion
          develop and provide updates, which may include upgrades, bug fixes,
          patches, other error corrections, and/or new features for its Products
          (collectively, including related Documentation, "Updates"). Updates
          may modify or delete in their entirety certain features and
          functionality. You agree that we have no obligation to provide any
          Updates or to continue to provide or enable any particular features or
          functionality. You further agree that all Updates will be deemed part
          of the Products and be subject to the Terms.
          <br />
          Updates are available to only websites that are registered with a
          valid and non-expired HandL UTM Grabber V3 License.
        </p>

        <h3 className="font-bold mt-[20px]">OWNERSHIP </h3>
        <p className="pl-2">
          You may not claim intellectual or exclusive ownership rights to any of
          our Products, Support, or Documentation. All Products, Support, and
          Documentation are property of HandL Digital LLC. All Products,
          Support, and Documentation are provided "as is" without warranty of
          any kind, express or implied. We shall not be liable for any damages,
          including but not limited to, direct, indirect, special, incidental or
          consequential damages or losses that occur out of the use or inability
          to use our Products, Support, or Documentation.
        </p>

        <h3 className="font-bold mt-[20px]">THIRD-PARTY MATERIALS</h3>
        <p className="pl-2">
          The Products, the Documentation, Support, and the HandL UTM Grabber
          Website may display, include, or make available third-party content
          (including data, information, applications, add-ons, and other
          products, services, and/or materials) or provide links to third-party
          websites or services, including through third-party advertising
          ("Third-Party Materials"). You acknowledge and agreeHandL Digital LLC
          is not responsible for Third-Party Materials, including their
          accuracy, completeness, timeliness, validity, copyright compliance,
          legality, decency, quality, or any other aspect thereof. HandL Digital
          LLC does not assume and will not have any liability or responsibility
          to you or any other person or entity for any Third-Party Materials.
          Third-Party Materials and links thereto are provided solely as a
          convenience to you, and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </p>

        <h3 className="font-bold mt-[20px]">MISCELLANEOUS </h3>
        <p className="pl-2">
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to the Marketplace Offerings constitute the
          entire agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Terms of Use shall
          not operate as a waiver of such right or provision. These Terms of Use
          operate to the fullest extent permissible by law. We may assign any or
          all of our rights and obligations to others at any time. We shall not
          be responsible or liable for any loss, damage, delay, or failure to
          act caused by any cause beyond our reasonable control. If any
          provision or part of a provision of these Terms of Use is determined
          to be unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Terms of Use and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms of
          Use or use of the Marketplace Offerings. You agree that these Terms of
          Use will not be construed against us by virtue of having drafted them.
          You hereby waive any and all defenses you may have based on the
          electronic form of these Terms of Use and the lack of signing by the
          parties hereto to execute these Terms of Use.
        </p>

        <h3 className="font-bold mt-[20px]">PRIVACY STATEMENT</h3>
        <p className="pl-2">
          We are committed to protecting your privacy. Authorized employees
          within the company access any information collected from individual
          customers on a need-to-know basis. Please see our full{" "}
          <a
            title="Privacy Policy"
            href="#"
            onClick={() =>
              dispatch(setStoreData({ data: { showPrivacy: true } }))
            }
          >
            privacy policy
          </a>{" "}
          on all details concerning your privacy, while using our website.
        </p>

        <h3 className="font-bold mt-[20px]">CONFIDENTIALITY</h3>
        <p className="pl-2">
          Any information concerning the customer and the respective customer
          records are regarded as confidential and will therefore not be
          divulged to any third party, unless we are legally required to do so
          by the appropriate authorities. Customer data is passed on only to
          services required to maintain our business, such as payment processors
          (PayPal, Stripe, others) and customer databases (Cognito). These third
          party services guarantee the privacy of your data in their own terms.
          Customers have the right to request sight of and copies of any and all
          customer records we keep, on the provisio that we are given reasonable
          notice of such a request. We will not sell, share or rent your
          personal information to any third party or use your email address for
          unsolicited mail. Any mails sent by HandL Digital LLC will only be in
          connection with the provision of agreed services and products.
        </p>

        <h3 className="font-bold mt-[20px]">PAYMENT</h3>
        <p className="pl-2">
          Payments are processed through third-party payment providers like
          PayPal and Stripe, to insure the best possible customer experience and
          ensure the customer’s privacy. All our products are delivered as
          downloads, on receipt of payment. All goods remain property of the
          company until paid for in full. The download instructions are sent by
          email, after the payment is received. Additional charges, late fees,
          chargeback fees and other fees are determined by our payment
          providers.
        </p>

        <h3 className="font-bold mt-[20px]">SUBMISSIONS</h3>
        <p className="pl-2">
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site or the
          Marketplace Offerings ("Submissions") provided by you to us are
          non-confidential and shall become our sole property. We shall own
          exclusive rights, including all intellectual property rights, and
          shall be entitled to the unrestricted use and dissemination of these
          Submissions for any lawful purpose, commercial or otherwise, without
          acknowledgment or compensation to you. You hereby waive all moral
          rights to any such Submissions, and you hereby warrant that any such
          Submissions are original with you or that you have the right to submit
          such Submissions. You agree there shall be no recourse against us for
          any alleged or actual infringement or misappropriation of any
          proprietary right in your Submissions.
        </p>

        <h3 className="font-bold mt-[20px]">CANCELLATION AND REFUND POLICY</h3>
        <p className="pl-2">
          All our products come with a 14 day refund guarantee. Customers can
          request a refund by contacting our support staff. Refunds are only
          granted within 14 days of the original payment. Refund requests that
          reach us outside of 14 days or refund requests for subsequent billings
          of a subscription (all payments after the first payment) will not be
          granted. Both the customers and ourselves have the right to
          terminate/cancel for any reason.
          <br />
          These are the terms concerning refunds for our customers:
        </p>
        <ul className="pl-3">
          <li>
            In the event that you are not happy with any product you purchase
            from HandL Digital LLC then you are protected by a 14 day money back
            guarantee.
          </li>
          <li>
            If you ask for a refund beyond 14 days after the initial purchase
            date of the product then you will not be granted a refund and there
            are no exceptions to this rule.
          </li>
          <li>
            By asking for a refund you are agreeing to remove the HandL products
            associated with the purchase from all websites that you own and/or
            use.
          </li>
          <li>
            We reserve the right to refuse a refund if you continue to use HandL
            products on your site after requesting the refund.
          </li>
          <li>
            Refunds are made via Paypal or Stripe and will processed within 24
            hours by our customer support team.
          </li>
        </ul>
        <h3 className="font-bold mt-[20px]">ABUSIVE BUYER POLICY</h3>
        <p className="pl-2">
          Our abusive buyer policy lays out unacceptable buying behavior. This
          policy outlines our expectations for buyer behavior. When buyers don't
          meet these standards, HandL Digital LLC can take some actions about
          the abusive buyer.
          <br />
          Buyers may not misuse our Return Policy and products. Our full policy
          below outlines HandL Digital LLC's expectations for buyer behavior.
        </p>
        <h4 className="pl-2">Behaviors we don't allow;</h4>
        <li className="pl-3">
          Don't misuse refund policy. Demanding Refund for the same product of
          HandL digital LLC more than once will be consider abusive buyer
          policy. If this happens, the buyer will not get a refund for the
          second time.{" "}
        </li>
        <h3 className="font-bold mt-[20px]">ACCOUNT SUSPENSION</h3>
        <p className="pl-2">
          HandL Digital LLC, reserves the right at any time to modify or
          discontinue, temporarily or permanently, a Subscription with or
          without notice and for any reason or no reason. If an account is
          suspended no refund will be granted.
          <br />
          Account can also be suspended if HandL Digital LLC detects non-fair
          usage of the software and its products.
        </p>

        <h3 className="font-bold mt-[20px]">EXCLUSIONS AND LIMITATIONS</h3>
        <p className="pl-2">
          The information on this site is provided "as is". To the fullest
          extent permitted by law, the company excludes all representations and
          warranties relating to this website and its contents or which is or
          may be provided by any affiliates or any other third party, including
          in relation to any inaccuracies or omissions in this website and/or
          the company’s literature. The company excludes all liability for
          damages arising out of or in connection with your use of this website.
          Please see the&nbsp;
          <a title="Privacy Policy" href="/privacy" className="text-[#1972f5]">
            privacy policy
          </a>
          &nbsp;and&nbsp;
          <a title="Disclaimer" href="/disclaimer" className="text-[#1972f5]">
            disclaimer
          </a>
          &nbsp; for full details. For licensing terms, see the corresponding
          section in the disclaimer.
        </p>
      </div>
    </div>
  );
};

export default withLayout(Terms);
