import { API } from "aws-amplify";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import handlV3 from "../../images/handl-v3.png"
import handlV2 from "../../images/handl-v2.png"
import { useLazyGetPlansQuery, useLazyGetSignedDownloadUrlQuery } from "../../features/plan/planAPI";
import NetworkErrorScreen from "../Error/NetworkErrorScreen";
import { toast } from "react-toastify";
export default function Downloads() {
  const { accountType: stateAccountType } = useSelector(state => state.plan);

  const [getPlans, {
    isLoading: plansLoading,
    isError: plansError,
    data: plans
  }] = useLazyGetPlansQuery();
  const accountType = ((stateAccountType ==="paid") && plans?.subscriptions?.filter(e => !Boolean(e.addon_id)).length > 0) ? "paid" : "free"
  const [getDownloadUrl, {
    isLoading: downloadUrlLoading,
  }] = useLazyGetSignedDownloadUrlQuery();
  const [downloadURL, setDownloadURL] = useState("");
  const navigate = useNavigate();

  const { isAuthenticated, user } = useSelector(
    (state) => state.auth.data || {}
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      getPlans(null, true);
    } else if (!isAuthenticated) {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    if (plansLoading) return;
    if (accountType == "paid") {
      getDownloadUrl().unwrap()
        .then((response) => {
          setDownloadURL(response.url);
        })
        .catch((error) => {
          toast.error(`
          Error downloading plugin: ${error}`, {
            position: 'bottom-right',
            autoClose: 3000
          });
        });
    } else {
      setDownloadURL(
        "https://downloads.wordpress.org/plugin/handl-utm-grabber.latest-stable.zip"
      );
    }
  }, [accountType, plansLoading]);


  const utmGrabber
    = React.useMemo(() => {
      if (accountType == "paid") {
        return {
          text: "UTM Grabber v3",
          image: handlV3,
        }
      }
      return {
        text: "UTM Grabber v2",
        image: handlV2,
      }
    }, [plansLoading, accountType])
  if (plansError) return <NetworkErrorScreen onRetry={() => getPlans()} loading={plansLoading} />;
  if (plans === undefined || plansLoading) return <div className='flex justify-center'>
    <div className='fa-3x'>
      <i className='fa fa-spinner fa-spin'></i>
    </div>
  </div>
  return (
    <div
      className="max-w-sm bg-white border border-gray-200 rounded-lg shadow cursor-pointer"
      onClick={() => {
        if (plansLoading || !downloadURL) return;
        const link = document.createElement("a");
        link.href = downloadURL;
        link.click();
      }}
    >
      <div>
        <img className="rounded-t-lg" src={utmGrabber.image} alt="" />
      </div>
      <div className="p-5">
        <div>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
            {
              utmGrabber.text
            }
          </h5>
        </div>
        <p className="mb-3 font-normal text-gray-700]">
          Track all the UTM variables (and more) as soon as user hits your
          website. No more missing conversions and no more headache.
        </p>
        <button disabled={plansLoading || downloadUrlLoading} className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white disabled:bg-gray-700 disabled:cursor-progress bg-blue-700 rounded-lg hover:bg-blue-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300`}>
          Download
          {
            plansLoading || downloadUrlLoading ? <i className="fa fa-spin fa-spinner ml-1" /> : <svg
              className="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          }
        </button>
      </div>
    </div>
  );
}
