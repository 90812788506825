import React, { useState } from "react";
import YouTube from "react-youtube";


const Header = () => {
  const [completed, setCompleted] = useState(false);
  let timer;

  const _onReady = (event) => {
    event.target.getIframe().setAttribute("data-hj-allow-iframe", "");
    event.target.getIframe().setAttribute("title", "HandL UTM Grabber / The future of tracking here");
  };

  const _onPlay = (event) => {
    timer = setInterval(() => checkPlayer(event.target), 1000);
  };

  const _onPause = () => {
    clearInterval(timer);
  };

  const checkPlayer = (player) => {
    const p = player.getCurrentTime();
    const d = player.getDuration();
    let c = (p / d) * 100; // calculate % complete
    c = Math.round(c); // round to a whole number

    if (!completed && c >= 80) {
      console.log("Video Completed!");
      setCompleted(true);
      const { gtag } = window;
      gtag?.("event", "Video Watched", {
        event_category: "UTM Sales Video",
        event_label: "Watched",
      });
      clearInterval(timer);
    }
  };

  const opts = {
    height: "400  ",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 0,
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      hd: 1,
      wmode: "transparent",
    },
  };

  return (
    <header id="header" className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3">
      <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-8 lg:max-w-screen-2xl mx-auto">

        <h2 className="font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl">AI-Powered UTM Tracking & Lead Attribution</h2>
        <p className="text-base font-light md:text-lg min-[2000px]:text-2xl">
          Experience Superior Tracking with Our Top-Rated UTM Plugin. Effortlessly Monitor All Your Traffic Sources - Paid, Organic, Social, Referral, Direct - Right from Your WordPress Site. Say Goodbye to Missed Conversions.
        </p>
      </div>
      <div className="lg:max-w-[65%] min-[2000px]:max-w-[50vw] mx-auto">
        <div className="relative w-full pb-[56.25%] mt-[1.5rem] mb-[12px] mx-auto">
          <YouTube
            videoId="fRf3P4HPGIA"
            className="absolute top-0 left-0 w-full h-full"
            opts={opts}
            onPlay={_onPlay}
            onPause={_onPause}
            onReady={_onReady}
          />
        </div>
      </div>
      <a href="#compare" className="btn btn-primary btn-sm scrollable py-3 w-min">
        <i className="fa fa-cloud-download"></i> DOWNLOAD NOW
      </a>
    </header>
  );
};

export default Header;
