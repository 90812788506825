const formatNumber = (value) => {
    if(!value) return value;
    if (value === "-") return value;
    return new Intl.NumberFormat().format(Number(value));
};

const parseNumber = (value) => {
    if(!value) return value;
    return value.replace(/[^0-9-]+/g, '');
};

const NumberInput = ({ item, formik }) => {
    return <input placeholder={item.placeholder} className={`${Boolean(item.label) ? "font-normal !pl-6" : ""} border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`} onChange={(val) => {
        const parsedValue = parseNumber(val.target.value);
        formik.setFieldValue(item.id, {
            value: parsedValue,
            question: item.question,
            type: item.type
        })
    }} onBlur={(val) => {
        formik.setFieldTouched(item.id, true)
    }} value={formatNumber(formik.values[item.id]?.value)} name={item.id}/>
}
export default NumberInput;