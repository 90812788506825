import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLazyGetPlansQuery } from '../../features/plan/planAPI';
import Modal from "../Modal/Modal";
import { useRemoveDomainMutation } from '../../features/myAccount/myAccountAPI';
import NetworkErrorScreen from '../Error/NetworkErrorScreen';
import utmIcon from '../../images/handl-utm-grabber-g.png'
function LicensePlans({ plan }) {
  const [selectedPlan, setSelectedPlan] = React.useState({});

  const [removeDomain] = useRemoveDomainMutation()
  const handleRemoveDomain = async () => {
    const { domain, license_key } = selectedPlan;
    try {
      closeRemoveDomainModal();
      await removeDomain({
        domain: domain,
        license_key: license_key
      }).unwrap();
    } catch (err) {
      setSelectedPlan({
        domain,
        license_key,
        showRemoveModal: true
      })
      toast.error(
        err, {
        position: 'bottom-right',
        autoClose: 3000,
      });
      console.error(err);
    }
  }
  const closeRemoveDomainModal = () => {
    setSelectedPlan((plan) => ({ ...plan, showRemoveModal: false }))
  }
  let domainCount = plan?.domains?.filter(e => e !== '').length ?? 0;
  const planCancelled = plan.subscription_status.toLowerCase() === 'canceled' || plan.subscription_status.toLowerCase() === 'cancelled';

  return (
    <div className='border border-gray-200 mb-2 p-3 rounded-md max-w-md'>
      <p className=''>
        {domainCount} / {plan.license_number} : <span className={planCancelled ? 'line-through' : ''}>{plan.license_key}</span>{' '}
        {planCancelled
          ? '(Canceled)'
          : ''}
      </p>
      <ul className='max-w-md divide-y divide-gray-200'>

        {domainCount ? plan.domains.filter(e => e !== '').map((domain) => {
          return <LicensePlan
            domain={domain}
            key={domain}
            license_key={plan.license_key}
            planCancelled={planCancelled}
            setSelectedPlan={setSelectedPlan}
          />;
        }) : <LicensePlan domain={'No available domains.'} />}
      </ul>
      <Modal dialogClassName='!z-[999]' isOpen={Boolean(selectedPlan.showRemoveModal)} onClose={closeRemoveDomainModal} onAfterClosed={() => setSelectedPlan({})}>
        <div
          className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-xl text-left font-[poppins] shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white max-w-md pointer-events-auto"
        >
          <div className="px-4 pb-4 pt-5 sm:p-6 flex items-center justify-between border-b border-black/10">
            <div className="flex">
              <div className="flex items-center">
                <div className="flex grow flex-col gap-1">
                  <h2
                    id="radix-:r66:"
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Remove domain?
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 sm:p-6">
            This will remove <span className='font-semibold'>{selectedPlan.domain}</span> from your domain list.
            <div className="mt-5 sm:mt-4">
              <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
                <button onClick={handleRemoveDomain} className="bg-red-500 rounded hover:bg-red-800 px-3 py-2 font-medium text-white text-sm" as="button">
                  Remove
                </button>
                <button onClick={closeRemoveDomainModal} className="hover:bg-gray-100 rounded px-3 py-2 font-medium border border-gray-200 text-sm" as="button">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function LicensePlan({ domain, license_key, setSelectedPlan, planCancelled}) {
  return (
    <li className='pb-2 pt-2' key={domain}>
      <div className='flex items-center space-x-4'>
        <div className='flex-shrink-0'>
          <img className='w-8' src={utmIcon} alt='utmicon' />
        </div>
        <div className='flex-1 min-w-0'>
          <p className='text-sm font-medium text-gray-900 truncate'>{''}</p>
        </div>
        <div className='inline-flex items-center text-base font-semibold text-gray-900'>
          {domain}
        </div>
        {license_key && !planCancelled && (
          <div className='inline-flex items-center text-base font-semibold text-gray-900'>
            <button onClick={(e) => {
              setSelectedPlan({ domain, license_key, showRemoveModal: true });
            }}>
              <svg width="20px" height="20px" viewBox="0 0 1024 1024" fill="#000000" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M512 897.6c-108 0-209.6-42.4-285.6-118.4-76-76-118.4-177.6-118.4-285.6 0-108 42.4-209.6 118.4-285.6 76-76 177.6-118.4 285.6-118.4 108 0 209.6 42.4 285.6 118.4 157.6 157.6 157.6 413.6 0 571.2-76 76-177.6 118.4-285.6 118.4z m0-760c-95.2 0-184.8 36.8-252 104-67.2 67.2-104 156.8-104 252s36.8 184.8 104 252c67.2 67.2 156.8 104 252 104 95.2 0 184.8-36.8 252-104 139.2-139.2 139.2-364.8 0-504-67.2-67.2-156.8-104-252-104z" fill="" /><path d="M707.872 329.392L348.096 689.16l-31.68-31.68 359.776-359.768z" fill="" />
                <path d="M328 340.8l32-31.2 348 348-32 32z" fill="" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </li>
  );
}

export default function Sites() {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector(
    (state) => state.auth.data || {}
  );
  const [getPlans, {
    data: plans = {
      subscriptions: [],
    },
    isLoading: plansLoading,
    isError: plansError,
  }] = useLazyGetPlansQuery();
  useEffect(() => {
    if (isAuthenticated && user) {
      getPlans(null, true);
    } else if (!isAuthenticated) {
      navigate('/');
    }
  }, [getPlans, isAuthenticated, navigate, user]);
  if (plansError) return <NetworkErrorScreen onRetry={() => getPlans()} loading={plansLoading} />;
  if (plansLoading) {
    return (
      <div className='flex justify-center'>
        <div className='fa-3x'>
          <i className='fa fa-spinner fa-spin'></i>
        </div>
      </div>
    );
  }

  if (!plans.subscriptions || plans.subscriptions.filter(e => !Boolean(e.addon_id)).length === 0) {
    return <div>No sites to display.</div>;
  }

  return plans.subscriptions.map((plan) => {
    if (plan.addon_id) {
      return null;
    }
    return <LicensePlans plan={plan} key={plan.license_key} />
  });
}
