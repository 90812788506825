// import "./utmgrabber"
import React from "react";
import "../vendors/magnific-popup/jquery.magnific-popup.min";

const WOW = require("wowjs");
window.wow = new WOW.WOW({
  live: false,
});
window.wow.init();

export const ImgW = ({ src, fallback, type = "image/webp", ...delegated }) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} {...delegated} alt={src} />
    </picture>
  );
};

export var dateReformat = function (ts) {
  let date = new Date(ts);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${month}, ${day} ${year}`;
};
