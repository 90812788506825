import React from 'react';
import ReviewCarousel from './ReviewCarousel';
import fireLottie from '../../images/lottie/fire.json';
import { useLottie } from 'lottie-react';
import { withLayout } from '.././Layout';
import styles from './featuresList.module.css';
import FeaturesList from './FeaturesList';
import withOfferCheck from './withOfferCheck';
import useApplyCoupon from './useApplyCoupon';
import withAuthCheck from '../Auth/withAuthCheck';

const OneTimeOffer = ({ campaign }) => {
    const { View: FireLottieL } = useLottie({
        animationData: fireLottie,
        loop: true,
        className: "h-[24px] md:h-[32px] min-[2000px]:h-[48px] inline-block translate-y-[1px] mr-0.5"
    })
    const { View: FireLottieR } = useLottie({
        animationData: fireLottie,
        loop: true,
        className: "h-[24px] md:h-[32px] min-[2000px]:h-[48px] inline-block translate-y-[1px] ml-0.5"
    })

    return (
        <div className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3 font-[poppins]" >
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 lg:max-w-screen-2xl mx-auto">
                <h2 className="font-semibold text-[#374048] text-2xl md:text-4xl min-[2000px]:text-5xl">{FireLottieL} Limited Time Offer!{FireLottieR} <br /> Get 20% Off Premium Membership for the Next 24 Hours Only!</h2>
                <p className="text-lg md:text-2xl font-medium">
                    Unlock All Premium Features and Take Your WordPress and Marketing Efforts to the Next Level – Don’t Miss Out!
                </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
                <div className='text-left'>
                    <p className="text-base min-[2000px]:text-lg">
                        We hope you’ve enjoyed exploring our platform over the last week! To help you unlock the full potential of our services, we’re offering you an exclusive 20% discount on our Premium Membership – but hurry, this offer is valid for only 24 hours!
                    </p>
                </div>
                <div className='text-left pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Here’s what you can get with Premium:</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        <FeaturesList />
                    </p>
                </div>
                <div className='text-left pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>How to Redeem Your Discount:</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        <ol className={`${styles["stepsList"]}`}>
                            <li>Click on the "Upgrade Now" button below.</li>
                            <li>The promo code will be applied at checkout automatically.</li>
                            <li>Unlock all these premium features!</li>
                        </ol>
                    </p>
                    <p className="text-base min-[2000px]:text-lg mt-1.5">
                        Remember, this offer expires in 24 hours! Once it’s gone, it’s gone. Don’t wait – upgrade now and take your experience to the next level!
                    </p>
                    <UpgradeButton coupon={campaign.coupon_stripe} campaign_key={campaign.campaign_key} label="Upgrade Now" />                    <p className="text-base min-[2000px]:text-lg mt-1.5">
                        Still unsure? Here’s what our existing premium members are saying:
                    </p>
                    <ReviewCarousel />
                    <p className="text-base min-[2000px]:text-lg mt-2">
                        Don’t miss out on this exclusive offer. Upgrade today and save!
                    </p>
                    <UpgradeButton coupon={campaign.coupon_stripe} campaign_key={campaign.campaign_key} label="Upgrade Now" />
                </div>
                <hr />
            </div>
            <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-5 md:max-w-[1080px] mx-auto py-5">
                <p className='text-xl md:text-2xl min-[2000px]:text-3xl font-medium'>
                    Thank you for trusting us with your lead tracking and attribution needs!
                </p>
                <p className='text-base min-[2000px]:text-lg'>
                    We're excited for you to start capturing UTM parameters effortlessly with UTM Grabber.
                </p>
            </div>

        </div>
    )
}
export const UpgradeButton = ({ coupon, label,campaign_key}) => {
    const { applyCoupon, couponLoading } = useApplyCoupon({
        couponCode: coupon,
        campaign_key
    });

    return (
        <button
            className="mx-auto disabled:bg-gray-400 flex justify-center items-center my-4 uppercase bg-green-500 font-medium text-base sm:text-lg md:text-xl text-white py-3 px-4 sm:py-4 sm:px-6 hover:bg-black hover:text-white"
            onClick={applyCoupon}
            disabled={couponLoading}
        >
            {label}
            {couponLoading ? <i className="ml-1 fa fa-spinner fa-spin"></i> : null}
        </button>
    );
};

export default withLayout(withAuthCheck(withOfferCheck(OneTimeOffer, "oto")));