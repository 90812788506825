import React, { Component } from "react";
import { ImgW } from "../js";

import ActiveCampaign from "../images/supported/active_campaign.png";
import ActiveCampaignW from "../images/supported/active_campaign.webp";
import Borlabs from "../images/supported/borlabs_cookie.png";
import BorlabsW from "../images/supported/borlabs_cookie.webp";
import Brave from "../images/supported/brave.png";
import BraveW from "../images/supported/brave.webp";
import CalderaForms from "../images/supported/caldera_forms.png";
import CalderaFormsW from "../images/supported/caldera_forms.webp";
import Calendly from "../images/supported/calendly.png";
import CalendlyW from "../images/supported/calendly.webp";
import CognitoForms from "../images/supported/cognito_forms.png";
import CognitoFormsW from "../images/supported/cognito_forms.webp";
import Complianz from "../images/supported/complianz.png";
import ComplianzW from "../images/supported/complianz.webp";
import ContactForm7 from "../images/supported/contact_form_7.png";
import ContactForm7W from "../images/supported/contact_form_7.webp";
import ConvertKit from "../images/supported/convert_kit.png";
import ConvertKitW from "../images/supported/convert_kit.webp";
import CookieNotice from "../images/supported/Cookie_notice.png";
import CookieNoticeW from "../images/supported/Cookie_notice.webp";
import Divi from "../images/supported/DIVI.png";
import DiviW from "../images/supported/DIVI.webp";
import Elementor from "../images/supported/Elementor.png";
import ElementorW from "../images/supported/Elementor.webp";
import FormidableForms from "../images/supported/formidable2.png";
import FormidableFormsW from "../images/supported/formidable2.webp";
import Forminator from "../images/supported/Forminator.png";
import ForminatorW from "../images/supported/Forminator.webp";
import FormStack from "../images/supported/Formstack.png";
import FormStackW from "../images/supported/Formstack.webp";
import GravityForm from "../images/supported/gravity_form.png";
import GravityFormW from "../images/supported/gravity_form.webp";
import HubSpot from "../images/supported/HubSpot.png";
import HubSpotW from "../images/supported/HubSpot.webp";
import InfusionSoft from "../images/supported/infusionsoft_by_keap.png";
import InfusionSoftW from "../images/supported/infusionsoft_by_keap.webp";
import Integromat from "../images/supported/integromat.png";
import IntegromatW from "../images/supported/integromat.webp";
import JotForm from "../images/supported/jotform.png";
import JotFormW from "../images/supported/jotform.webp";
import Logiforms from "../images/supported/logiforms.png";
import LogiformsW from "../images/supported/logiforms.webp";
import Mailchimp from "../images/supported/mailchimp.png";
import MailchimpW from "../images/supported/mailchimp.webp";
import Marketo from "../images/supported/marketo.png";
import MarketoW from "../images/supported/marketo.webp";
import MemberPress from "../images/supported/memberpress.png";
import MemberPressW from "../images/supported/memberpress.webp";
import NinjaForm from "../images/supported/ninjaforms.png";
import NinjaFormW from "../images/supported/ninjaforms.webp";
import Ontraport from "../images/supported/ontraport.png";
import OntraportW from "../images/supported/ontraport.webp";
import OptimizePress from "../images/supported/optimizepress.png";
import OptimizePressW from "../images/supported/optimizepress.webp";
import OxygenBuilder from "../images/supported/oxygen_builder.png";
import OxygenBuilderW from "../images/supported/oxygen_builder.webp";
import Pardot from "../images/supported/pardot.png";
import PardotW from "../images/supported/pardot.webp";
import SalesForce from "../images/supported/salesforce.png";
import SalesForceW from "../images/supported/salesforce.webp";
import Sendgrid from "../images/supported/sendgrid.png";
import SendgridW from "../images/supported/sendgrid.webp";
import ThriveArchitect from "../images/supported/thrive_artchitect.png";
import ThriveArchitectW from "../images/supported/thrive_artchitect.webp";
import ThriveCart from "../images/supported/thrive_cart.png";
import ThriveCartW from "../images/supported/thrive_cart.webp";
import ThriveLeads from "../images/supported/thrive_leads.png";
import ThriveLeadsW from "../images/supported/thrive_leads.webp";
import Typeform from "../images/supported/typeform.png";
import TypeformW from "../images/supported/typeform.webp";
import UltimateGDPR from "../images/supported/Ultimate_GDPR_CCPA.png";
import UltimateGDPRW from "../images/supported/Ultimate_GDPR_CCPA.webp";
import Webflow from "../images/supported/webflow.png";
import WebflowW from "../images/supported/webflow.webp";
import WooCommerce from "../images/supported/woocommerce.png";
import WooCommerceW from "../images/supported/woocommerce.webp";
import WPFluentForms from "../images/supported/wp_fluent_forms.png";
import WPFluentFormsW from "../images/supported/wp_fluent_forms.webp";
import WPForms from "../images/supported/WP_Forms.png";
import WPFormsW from "../images/supported/WP_Forms.webp";
import Zapier from "../images/supported/zapier.png";
import ZapierW from "../images/supported/zapier.webp";
import Zoho from "../images/supported/zoho.png";
import ZohoW from "../images/supported/zoho.webp";

class SupportedFormsVisuals extends Component {
  constructor(props) {
    super(props);

    const forms = [
      {
        t: "Gravity Forms",
        i1: GravityForm,
        i2: GravityFormW,
      },
      {
        t: "Elementor",
        i1: Elementor,
        i2: ElementorW,
      },
      {
        t: "Contact Form 7",
        i1: ContactForm7,
        i2: ContactForm7W,
      },
      {
        t: "Ninja Form",
        i1: NinjaForm,
        i2: NinjaFormW,
      },
      {
        t: "WP Forms",
        i1: WPForms,
        i2: WPFormsW,
      },
      {
        t: "Caldera Forms",
        i1: CalderaForms,
        i2: CalderaFormsW,
      },
      {
        t: "Forminator",
        i1: Forminator,
        i2: ForminatorW,
      },
      {
        t: "ActiveCampaign",
        i1: ActiveCampaign,
        i2: ActiveCampaignW,
      },
      {
        t: "Borlabs",
        i1: Borlabs,
        i2: BorlabsW,
      },
      {
        t: "Brave",
        i1: Brave,
        i2: BraveW,
      },
      {
        t: "Calendly",
        i1: Calendly,
        i2: CalendlyW,
      },
      {
        t: "Cognito",
        i1: CognitoForms,
        i2: CognitoFormsW,
      },
      {
        t: "Complianz",
        i1: Complianz,
        i2: ComplianzW,
      },
      {
        t: "ConvertKit",
        i1: ConvertKit,
        i2: ConvertKitW,
      },
      {
        t: "Cookie Notice",
        i1: CookieNotice,
        i2: CookieNoticeW,
      },
      {
        t: "Divi",
        i1: Divi,
        i2: DiviW,
      },
      {
        t: "Formstack",
        i1: FormStack,
        i2: FormStackW,
      },
      {
        t: "HubSpot",
        i1: HubSpot,
        i2: HubSpotW,
      },
      {
        t: "InfusionSoft",
        i1: InfusionSoft,
        i2: InfusionSoftW,
      },
      {
        t: "Integromat",
        i1: Integromat,
        i2: IntegromatW,
      },
      {
        t: "Jotform",
        i1: JotForm,
        i2: JotFormW,
      },
      {
        t: "Logiforms",
        i1: Logiforms,
        i2: LogiformsW,
      },
      {
        t: "Mailchimp",
        i1: Mailchimp,
        i2: MailchimpW,
      },
      {
        t: "Marketo",
        i1: Marketo,
        i2: MarketoW,
      },
      {
        t: "MemberPress",
        i1: MemberPress,
        i2: MemberPressW,
      },
      {
        t: "Ontraport",
        i1: Ontraport,
        i2: OntraportW,
      },
      {
        t: "OptimizePress",
        i1: OptimizePress,
        i2: OptimizePressW,
      },
      {
        t: "Oxygen Builder",
        i1: OxygenBuilder,
        i2: OxygenBuilderW,
      },
      {
        t: "Pardot",
        i1: Pardot,
        i2: PardotW,
      },
      {
        t: "Salesforce",
        i1: SalesForce,
        i2: SalesForceW,
      },
      {
        t: "Sendgrid",
        i1: Sendgrid,
        i2: SendgridW,
      },
      {
        t: "Thrive Architect",
        i1: ThriveArchitect,
        i2: ThriveArchitectW,
      },
      {
        t: "Thrive Cart",
        i1: ThriveCart,
        i2: ThriveCartW,
      },
      {
        t: "Thrive Leads",
        i1: ThriveLeads,
        i2: ThriveLeadsW,
      },
      {
        t: "WP Fluent Forms",
        i1: WPFluentForms,
        i2: WPFluentFormsW,
      },
      {
        t: "Formidable Forms",
        i1: FormidableForms,
        i2: FormidableFormsW,
      },
      {
        t: "Typeform",
        i1: Typeform,
        i2: TypeformW,
      },
      {
        t: "Ultimate GDPR",
        i1: UltimateGDPR,
        i2: UltimateGDPRW,
      },
      {
        t: "Webflow",
        i1: Webflow,
        i2: WebflowW,
      },
      {
        t: "WooCommerce",
        i1: WooCommerce,
        i2: WooCommerceW,
      },
      {
        t: "Zapier",
        i1: Zapier,
        i2: ZapierW,
      },
      {
        t: "Zoho",
        i1: Zoho,
        i2: ZohoW,
      },
    ];

    const forms_others = [
      {
        t: "AffiliateWP",
      },
      {
        t: "ARForms",
      },
      {
        t: "Bloom",
      },
      {
        t: "Drip",
      },
      {
        t: "Easy!Appointments",
      },
      {
        t: "ERForms",
      },
      {
        t: "Form Titan",
      },
      {
        t: "GetResponse",
      },
      {
        t: "Klaviyo",
      },
      {
        t: "MailerLite",
      },
      {
        t: "MailiGen",
      },
      {
        t: "NEX-Forms",
      },
      {
        t: "Piotnet",
      },
      {
        t: "Quform",
      },
      {
        t: "SamCart",
      },
      {
        t: "Spiffy",
      },
      {
        t: "Ultimate Member",
      },
      {
        t: "WP Everest",
      },
      {
        t: "Brilliant Web to Lead",
      },
    ];

    this.state = {
      forms: forms,
      forms_others: forms_others,
    };
  }

  render() {
    return (
      <section className="row" id="supported-by-handl">
        <div className="container max-w-screen-xl mx-auto px-12">
          <h1 className="text-center text-4xl font-medium mt-[20px] mb-[10px]">
            <span className="wp-title">Seamless Integration</span> with{" "}
            <span className="wp-title">50+</span> major marketing tools
          </h1>
          <p className="mt-[44px] mb-[20px] text-[22px] font-[400]">
            HandL UTM Grabber / Tracker V3 seamlessly integrates with major
            marketing tools, forms and CRMs. <br />
            We challenge you to prove us wrong 😁
          </p>

          <div className="flex flex-wrap">
            {this.state.forms.map((item) => (
              <div key={item.t} className="lg:basis-1/6 md:basis-1/3 basis-1/2">
                <ImgW
                  className="img-responsive"
                  src={item.i1}
                  fallback={item.i2}
                  alt=""
                />
                <h3 className="card-title">{item.t}</h3>
              </div>
            ))}
          </div>

          <div className="row">
            <h3 className="mt-[40px] mb-[10px] text-[24px] font-medium">
              And many more including...
            </h3>
            <ul className="other_forms">
              {this.state.forms_others.map((item) => (
                <li key={item.t} className="-indent-2 break-all">
                  - {item.t}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-5 mb-10 lg:mt-20 lg:mb-28 md:mt-20 md:mb-28 text-center lg:flex md:flex sm:block justify-center items-center">
            <span className="text-[22px] font-[400]">
              You do not see your tool above? No problem &nbsp;
            </span>
            <button
              onClick={() => {
                window.$crisp.push(["do", "chat:toggle"]);
              }}
              className="btn btn-sm btn-primary"
            >
              let's have a chat
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default SupportedFormsVisuals;
