import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
function SocialProofCarousel({items}) {
    return (
        <Swiper
            slidesPerView={2}
            spaceBetween={10}
            loop={true}
            autoplay={{ delay: 5000 }}
            breakpoints={{
                769: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            }}
            modules={[Autoplay]}
            className="w-full"
        >
            {items.map((_, i) => (
                <SwiperSlide key={i}>
                    <picture>
                        <source srcSet={_.fallback}  />
                        <img className="h-24 lg:h-28 2xl:h-36 grayscale opacity-80 m-auto" src={_.src} alt={_.alt} />
                    </picture>
                </SwiperSlide>
            ))}
        </Swiper>

    )
}

export default SocialProofCarousel