
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { ClipLoader } from 'react-spinners';
import { FixedSizeList as List } from 'react-window';
import { useSearchKnowledgeBaseQuery } from '../../features/knowledgeBase/knowledgeBaseAPI';

const ITEM_SIZE = 60
const PAGE_SIZE = 10
function PluginDocs({
}) {
    const [query, setQuery] = useState('')
    const [page, setPage] = useState(0)

    const listRef = useRef(null);
    const page_number = (page / PAGE_SIZE) + 1
    const lastResult = useSearchKnowledgeBaseQuery({
        query,
        from: page - PAGE_SIZE,
        size: PAGE_SIZE
    }, { skip: page - PAGE_SIZE < 0 }) // don't fetch pages before 0
    const currentResult = useSearchKnowledgeBaseQuery({
        query,
        from: page,
        size: PAGE_SIZE
    })
    const nextResult = useSearchKnowledgeBaseQuery({
        query,
        from: page + PAGE_SIZE,
        size: PAGE_SIZE
    })


    const combined = useMemo(() => {
        const arr = new Array(PAGE_SIZE * (page_number + 1))
        for (const data of [lastResult.data, currentResult.data, nextResult.data]) {
            if (data) {
                arr.splice(data.offset, data.items.length, ...data.items)
            }
        }
        return arr
    }, [PAGE_SIZE, page_number, lastResult.data, currentResult.data, nextResult.data])
    useEffect(() => {
        setPage(0)
        if (listRef.current) {
            listRef.current.scrollToItem(0, 'start');
        }
    }, [query])
    const fetchMore = () => {
        if (nextResult.data && nextResult.data?.items.length === 0) {
            return
        }
        setPage(page + PAGE_SIZE)
    }
    const fetchPrev = () => {
        if (page - PAGE_SIZE < 0) {
            return
        }
        setPage(page - PAGE_SIZE)
    }
    const loading = lastResult.isFetching || currentResult.isFetching || nextResult.isFetching
    const handleScroll = useCallback(({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
        if (scrollDirection === 'forward' && scrollOffset > (combined.length - PAGE_SIZE * 2) * ITEM_SIZE && !loading) {
            fetchMore();
        } else if (scrollDirection === 'backward' && scrollOffset < (combined.length - PAGE_SIZE * 2) * ITEM_SIZE) {
            fetchPrev();
        }
    }, [combined, loading]);
    return <div className='text-left pb-3 pt-2'>
        <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 3: What Form Plugin Are You Using?</h3>
        <p className="text-base min-[2000px]:text-lg">
            We want to make your experience with HandL UTM Grabber as seamless as possible. Please let us know what form plugin you're using on your WordPress site, and we'll provide customized documentation to ensure smooth integration.
            <br />
            <div className='mt-1' />
            <PluginSearchDocsInput listRef={listRef} hasMore={nextResult.data && nextResult.data?.items.length > 0} isLoading={loading} query={query} setQuery={setQuery} filteredUrls={combined} handleScroll={handleScroll} />
        </p>
    </div>}
const PluginSearchDocsInput = ({ listRef, isLoading, query, setQuery, filteredUrls, handleScroll }) => {
    const debounceTimeout = useRef(null);
    const [inputValue, setInputValue] = useState(query);
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setQuery(event.target.value);
        }, 300);
    };
    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);
    return <div >
        <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden border rounded-md border-gray-300 bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <input
                    value={inputValue}
                    className="w-full border-none py-2.5 pl-2 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                    onChange={handleInputChange}
                    placeholder="Enter your form plugin name (e.g., Contact Form 7, Gravity Forms)"
                />
                {
                    isLoading &&
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ClipLoader
                            loading={isLoading}
                            color="#005EFF"
                            size={20}
                        />
                    </div>
                }
            </div>
            <Transition
                as={Fragment}
                show={Boolean(query)}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div static id="scrollableDiv" className="sticky mt-1 !z-[10000] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {filteredUrls.length === 0 && query !== '' ? (
                        <>

                            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                Nothing found.
                            </div>
                        </>
                    ) : (<>
                        <List
                            height={240}
                            itemCount={filteredUrls.length}
                            itemSize={ITEM_SIZE}
                            onScroll={handleScroll}
                            width="100%"
                            ref={listRef}
                        >
                            {
                                ({ index, style }) => {
                                    const item = filteredUrls[index];
                                    const isEven = index % 2 === 0;
                                    if (!item) {
                                        return;
                                    }
                                    return (
                                        <a
                                            style={style}
                                            href={`https://docs.utmgrabber.com${item.url}`}
                                            target="_blank"
                                            rel='noreferrer'
                                            className={`flex flex-col justify-center cursor-pointer select-none px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-600 ${isEven ? 'bg-gray-50' : ''}`}

                                        >
                                            <span
                                                className={`block truncate font-normal`}
                                            >
                                                {item.title} <br /> <span className='text-[10px]'>Last Updated: {dayjs(item.updated_at).format('MMM YYYY')}</span>
                                            </span>

                                        </a>
                                    );
                                }
                            }
                        </List>
                    </>
                    )}
                </div>
            </Transition>
        </div>
    </div>
}
export default PluginDocs