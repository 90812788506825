import React from "react";
import { createRoot } from "react-dom/client";
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import $ from "jquery";
import { store } from "./store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import ErrorScreen from "./components/Error/ErrorScreen";
import { ErrorBoundary } from "react-error-boundary";
import Toast from "./components/Toast/Toast";

require("./js/index");

window.jQuery = $;
window.$ = $;
global.$ = $;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_DEFAULT_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "HandLAPI",
        endpoint: process.env.REACT_APP_API_ENDPOINT + "http",
        custom_header: async () => {
          // return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
    ],
  },
});
// replace console.* for disable log on production
if (process.env.REACT_APP_ENV_MODE === 'prod') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
const root = createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary FallbackComponent={ErrorScreen}>
    <Toast />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
