import { createSlice } from '@reduxjs/toolkit';
let prices = {
  starter: {
    paypal:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'P-75D68092MY5799226MMIJM4A'
        : 'P-81V27725KA635982SMMIKCUY',
    stripe:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'price_1LdBTKAazLOJAQUChJ0Feh7H'
        : 'price_1LdBs8AazLOJAQUCQjKbgEUG',
    product_price: 299, //79
    original_price: 299,
    msrp: 299,
    membership: true,
    title: 'Starter',
    subtitle: 'Single Site',
    license: 1,
  },
  starterp: {
    paypal:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'P-06359480LH237960XMMIJNGQ'
        : 'P-8VY03507L9343632HMMIKC5I',
    stripe:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'price_1LdBThAazLOJAQUCv37baZ3V'
        : 'price_1LdBsOAazLOJAQUCYJbbfgtr',
    product_price: 399, //
    original_price: 399,
    msrp: 399,
    membership: true,
    title: 'Starter+',
    subtitle: '3 Sites',
    license: 3,
  },
  premium: {
    paypal:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'P-3PF06300WA541010NMMIJNOI'
        : 'P-66X12173XY7725009MMIKDEY',
    stripe:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'price_1LdBTvAazLOJAQUCpp6BgLcJ'
        : 'price_1LdBsjAazLOJAQUCUPMAV4sl',
    product_price: 1199, //499
    original_price: 1199,
    msrp: 1199,
    membership: true,
    title: 'Premium',
    subtitle: '25 Sites',
    license: 25,
  },
  support: {
    paypal:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'P-4FB891062N6206238MQNEQKQ'
        : 'P-43H13868DN284114WMQIJHGQ',
    stripe:
      process.env.REACT_APP_ENV_MODE === 'prod'
        ? 'price_1MoFHAAazLOJAQUCAYAXiImT'
        : 'price_1NtCBkAazLOJAQUCfGlaBNGJ',
    product_price: 299,
    original_price: 299,
    msrp: 299,
    membership: true,
    title: 'Support',
    subtitle: 'Our experts will handle installation and integration.',
    license: 1,
  },
};

const initialState = {
  data: {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    showLogin: false,
    showMyAccount: false,
    showThanks: false,
    showLicense: false,
    tmpUser: null,
    flowSelected: 'paid',
    showFreeDownload: false,
    showPayment: false,
    showForgotPassword: false,
    msrp: 299,
    original_price: 299.0,
    product_price: 299.0,
    discount_applied: false,
    discount_type: '',
    membership: true,
    quantity: 1,
    selected_plan: 'starter',
    paypal_plan_id: 'P-5FY67961N7583800ML5JF57A',
    stripe_plan_id: 'price_1HMlXlAazLOJAQUCgtBrMnC0',
    title: 'Life Time',
    subtitle: 'Single Site',
    plan: null,
    prices: prices,
    multiplier: false,
    showAreWeFit: false,
    showApps: false,
    isSupportable: false,
    showSignup: false,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload.data,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStoreData } = authSlice.actions;

export default authSlice.reducer;
