import React from 'react';
import ChatSessions from './ChatSessions';
import ChatHeader from './ChatHeader';
function ChatSidebar() {
    return (
        <div className="w-64 p-2 bg-gray-50 flex-col hidden md:flex gap-2 border-gray-200 border-l">
            <ChatHeader.NewChat />
            <div className='flex flex-col h-[calc(100vh-240px)] gap-2 w-full overflow-y-auto -mr-2 pr-2 [&::-webkit-scrollbar]:[width:3px]
        [&::-webkit-scrollbar-thumb]:bg-gray-200'>

                <ChatSessions />
            </div>
        </div>
    )
}

export default ChatSidebar