import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "./reviewCarousel.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import reviews from "./reviews.json";
function index() {
    return (
        <Swiper
            slidesPerView={2}
            spaceBetween={10}
            loop={true}
            pagination={{
                clickable: true,
                bulletActiveClass: "!bg-blue-600 !opacity-80 active",
            }}
            autoplay={{ delay: 10000 }}
            breakpoints={{
                1024: {
                    slidesPerView: 3,
                },
            }}
            modules={[Pagination, Autoplay]}
            className={"p-0 custom-swiper-review-carousel !pt-5"}
        >
            {reviews.map((_, i) => (
                <SwiperSlide key={i}>
                    <a href={_.url} target="_blank" className="flex h-full">
                        <div className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg bg-white p-4 border border-gray-300 rounded-lg shadow-lg hover:border-blue-600 transition-colors ease-in-out">
                            <div className="flex flex-row">

                                <img
                                    className="w-10 h-10 sm:w-14 sm:h-14 rounded-full float-left mr-2 border-2 border-gray-300"
                                    src={_.avatar}
                                    alt="User Avatar"
                                />
                                <h1 className="mb-2 text-gray-800 text-base sm:text-lg font-semibold cursor-pointer !leading-normal">
                                    {_.title}
                                </h1>
                            </div>
                            <p className="mb-2 text-xs sm:text-sm font-light">
                                {_.review}
                            </p>
                            <h3 className="text-xs sm:text-sm font-medium text-red-500">
                                {_.author}
                            </h3>
                        </div>
                    </a>
                </SwiperSlide>
            ))}
        </Swiper>

    )
}

export default index