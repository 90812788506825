import React from 'react';
const SidebarNavItem = ({item, level=null, getID}) => {
        return (
            item.content !== '' &&
            <li className={`flex flex-col ${level? 'pl-5 ' : ''}`}>
                <a className={`px-2 py-2  rounded text-[#1966be] block text-base font-normal ${level ? "" : ""}`} href={`#${item.id ? item.id : getID(item.title)}`}>{item.title}</a>
                {item.subs && item.subs.map((item, id) =>
                    <ul>
                        <SidebarNavItem key={id} item={item} getID={getID} level={2} />
                    </ul>
                )}
            </li>
        );
}
SidebarNavItem.DrawerNav = ({item, level=null, getID}) => {
        return (
            item.content !== '' &&
            <li className={`flex flex-col ${level? 'pl-5 ' : ''}`}>
                <a className={`px-2 py-2  rounded text-[#1966be] block text-base font-normal ${level ? "" : ""}`} href={`/utm#${item.id ? item.id : getID(item.title)}`}>{item.title}</a>
                {item.subs && item.subs.map((item, id) =>
                    <ul>
                        <SidebarNavItem.DrawerNav key={id} item={item} getID={getID} level={2} />
                    </ul>
                )}
            </li>
        );
}

export default SidebarNavItem;