import React from "react";
import V2 from "../images/handl-free.png";
import V2W from "../images/handl-free.webp";
import V3 from "../images/handl-v3.png";
import V3W from "../images/handl-v3.webp";
import { ImgW } from "../js/index";
import FreeFeatures from "./Common/FreeFeatures";
import PremiumFeatures from "./Common/PremiumFeatures";
import { useSelector } from "react-redux";

function Compare() {
  const { isAuthenticated } = useSelector((state) => state.auth.data || {});

  const onFreeDownload = (event) => {
    event.preventDefault();
    window.location =
      "https://downloads.wordpress.org/plugin/handl-utm-grabber.latest-stable.zip";
  };

  return (
    <section className="comparison pb-[80px]" id="compare">
      <div className="container mx-auto flex flex-col items-center">
        <div className="mt-[100px] pb-[20px] text-[36px] text-[#333] font-[500] text-center">
          <h1>Why should I upgrade?</h1>
        </div>
        <div className="flex flex-col md:flex-row space-x-4 max-w-[1170px]">
          <div className="md:basis-1/2 compare wow fadeInUp greencheck nope px-3">
            <ImgW src={V2W} fallback={V2} className="img-responsive" />
            <FreeFeatures />
            <div className="product-desc text-center no-price">
              {!isAuthenticated && (
                <a
                  href="#prices"
                  className="btn btn-primary btn-sm scrollable py-[10px]"
                >
                  Free Download
                </a>
              )}

              {isAuthenticated && (
                <a
                  href=""
                  className="btn btn-primary btn-sm scrollable py-[10px]"
                  onClick={onFreeDownload}
                >
                  Free Download
                </a>
              )}
            </div>
          </div>

          <div className="md:basis-1/2 compare wow fadeInUp greencheck">
            <ImgW src={V3W} fallback={V3} className="img-responsive" />
            <PremiumFeatures />
            <div className="product-desc text-center">
              <a
                href="#prices"
                className="btn btn-primary btn-sm scrollable py-[10px] cursor-pointer"
              >
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Compare;
