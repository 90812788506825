import React, { Component } from "react";
import GooglePartnerBadgeW from "../images/GooglePartnerBadge.webp";
import GooglePartnerBadge from "../images/GooglePartnerBadge.png";
import GooglePartnerBadge2x from "../images/GooglePartnerBadge@2x.png";

class Trusted extends Component {
  render() {
    return (
      <section className="trusted" id="trusted">
        <div className="flex flex-col items-center justify-center py-[28px]">
          <img
            src={GooglePartnerBadgeW}
            srcSet={`${GooglePartnerBadge} 1x, ${GooglePartnerBadge2x} 2x`}
            alt="HandL Digital LLC - proud Google Partner"
          />
          <div className="text-[24px] font-[poppins] font-[500] text-[#333] mt-[18px] text-center">
            Trusted by more than 100,000 sites globally
          </div>
          <div className="text-[24px] font-[poppins] font-[500] text-[#333] mt-[14px]">
            100+ ⭐⭐⭐⭐⭐ reviews!
          </div>
        </div>
      </section>
    );
  }
}

export default Trusted;
