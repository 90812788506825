import React from 'react'
import styles from './perksList.module.css';
function PerksList({items}) {
    return (
        <ul className={`${styles["checkList"]}`}>
            {
                items.map((item, index) => (
                    <li key={index} className='font-medium text-lg/loose lg:text-xl/loose 2xl:text-2xl/loose md:text-nowrap before:!text-lg lg:before:!text-xl 2xl:before:!text-2xl'>
                        {item}
                    </li>
                ))
            }
        </ul>
    )
}

export default PerksList