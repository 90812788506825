import React from 'react';

export default function WordpressYes({ resetModal }) {
  return (
    <div>
      <h3 className='text-2xl'>
        {' '}
        Hmm... We are not quite sure if we are a good fit 🤨
      </h3>
      <p className='description'>
        You indicated you are using WordPress however, you are not doing any of
        the functions that HandL UTM Grabber is the most useful. Please tell us
        why you are interested in collecting UTM so we can help you better.
      </p>

      <p>
        <a
          className='text-blue-600 cursor-pointer'
          onClick={() => {
            resetModal();
            const { $crisp } = window;
            $crisp?.push(['do', 'chat:toggle']);
            $crisp?.push([
                'set',
                'message:text',
                [
                  "I took the survey but it said it is not quite sure if the plugin is good fit. I'd like to get more information please.",
                ],
              ]);
          }}
          href='#'
        >
          Click here
        </a>{' '}
        to chat with a real person (we usually respond in seconds).
      </p>
    </div>
  );
}
