import { useEffect, useState } from 'react'
import { useGetActiveCampaignsQuery, useGetActiveUserCampaignsQuery } from '../../features/coupon/couponAPI';
import { useDispatch, useSelector } from 'react-redux';
import { openCampaignModal } from '../../features/coupon/couponSlice';
import { propTypes } from 'react-bootstrap/esm/Image';


const useActiveCampaign = ({ types = ["banner"] }) => {
    const { user, tmpUser } = useSelector(state => state.auth.data || {});
    const { data: campaigns, isLoading: campaignsLoading } = useGetActiveCampaignsQuery();
    const { data: userCampaigns, isLoading: userCampaignsLoading } = useGetActiveUserCampaignsQuery(undefined, { skip: !user });
    const [campaign, setCampaign] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (campaignsLoading || userCampaignsLoading) {
            return
        }
        setActiveCampaign();
    }, [campaigns, userCampaigns]);

    const setActiveCampaign = () => {
        const activeCampaigns = campaigns || [];
        const activeUserCampaigns = userCampaigns || [];
        const allCampaigns = [...activeUserCampaigns.map(e => ({ ...e, type: "userCampaign" })), ...activeCampaigns];
        if (allCampaigns.length === 0) {
            return;
        }
        const localStorageKey = ('closedCampaigns' + (tmpUser?.email || ""))
        const closedCampaigns = JSON.parse(localStorage.getItem(localStorageKey)) || [];
        const filteredCampaigns = allCampaigns.filter(campaign => !closedCampaigns.includes(campaign.campaign_key) && types.includes(campaign.promo_location));
        const activeCampaign = filteredCampaigns.length ? filteredCampaigns[0] : null;
        setCampaign(activeCampaign);
    };

    const handleCampaignHide = (event) => {
        event.stopPropagation();
        if (!campaign) {
            return;
        }
        const localStorageKey = ('closedCampaigns' + (tmpUser?.email || ""))
        const closedCampaigns = JSON.parse(localStorage.getItem(localStorageKey)) || [];
        closedCampaigns.push(campaign.campaign_key);
        localStorage.setItem(localStorageKey, JSON.stringify(closedCampaigns));
        setCampaign(null);
        setActiveCampaign();
    };

    const handleCampaignClick = () => {
        if (!campaign) {
            return;
        }
        dispatch(openCampaignModal(campaign));
    };

    return {
        campaign,
        handleCampaignHide,
        handleCampaignClick
    };
};

export default useActiveCampaign;