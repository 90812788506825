import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cancel: {
        openModal: false,
        cancelPlanName: null,
        cancelPlanElement: null,
        isAddon: false,
    },
    status: {
        loading: false,
    },
    updatePaymentMethod: {
        customerId: null,
        modalOpen: false,
    },
    appEligible: false,
    accountType: "free",

};

const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        openConfirmCancelPlanModal(state, action) {
            state.cancel.openModal = true;
            state.cancel.cancelPlanName = action.payload.cancelPlanName;
            state.cancel.cancelPlanElement = action.payload.cancelPlanElement;
            state.cancel.isAddon = action.payload.isAddon;
        },
        closeConfirmCancelPlanModal(state) {
            state.cancel.openModal = false;
        },
        cleanupConfirmCancelPlanModal(state) {
            state.cancel.cancelPlanName = null;
            state.cancel.cancelPlanElement = null;
        },
        setIsLoading(state) {
            state.status.loading = true;
        },
        resetLoading(state) {
            state.status.loading = false;
        },
        setAccountType(state, action) {
            state.accountType = action.payload;
        },
        setAppEligible(state, action) {
            state.appEligible = action.payload;
        },
        openUpdatePaymentMethodModal(state, action) {
            state.updatePaymentMethod.customerId = action.payload.customerId;
            state.updatePaymentMethod.modalOpen = true;
        },
        closeUpdatePaymentMethodModal(state) {
            state.updatePaymentMethod.customerId = null;
            state.updatePaymentMethod.modalOpen = false;
        },
    },
});

export const {
    openConfirmCancelPlanModal,
    closeConfirmCancelPlanModal,
    cleanupConfirmCancelPlanModal,
    setIsLoading,
    resetLoading,
    setAccountType,
    setAppEligible,
    openUpdatePaymentMethodModal,
    closeUpdatePaymentMethodModal
} = planSlice.actions;

export default planSlice.reducer;
