import React, { useEffect } from 'react';

import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { toast } from "react-toastify";
import Modal from '../Modal/Modal';
import { useSelector } from 'react-redux';
import fireLottie from '../../images/lottie/fire.json';
import { useLottie } from 'lottie-react';
import { useOnChargeMutation } from '../../features/payment/paymentAPI';
function SupportOffer({ }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [onPay, { isLoading: payLoading }] = useOnChargeMutation();
    const [getCustomer, { isLoading: cusLoading }] = useOnChargeMutation();
    const paymentLoading = payLoading || cusLoading;
    const { membership, prices, multiplier, quantity, tmpUser } = useSelector(
        (state) => state.auth.data || {}
    );
    const onPayPalPay = async () => {
        const paypal_plan_id = prices.support.paypal;
        const product_price = prices.support.msrp;
        const response = await onPay({
            pay_id: 'paypal',
            email: searchParams.get('email'),
            planId: paypal_plan_id,
            membership: membership,
            quantity: quantity,
            multiplier: multiplier ? 2 : 1,
            marketing: window.HandL.getAll(),
            redirect_urls: {
                return_url:
                    '/support/welcome?' +
                    createSearchParams({
                        pay_id: 'paypal',
                        support: '1',
                    }).toString(),
                cancel_url:
                    '/w/onboarding/special-offer?' +
                    createSearchParams({
                        email: searchParams.get('email'),
                        subscription_id: searchParams.get('subscription_id'),
                        pay_id: 'paypal',
                        support: '0',
                        error: "We're sorry, but it seems you did not approve the payment.",
                    }).toString(),
            },
        }).unwrap();

        window.gtag('event', 'begin_checkout', {
            items: [
                {
                    id: 'HandL002',
                    name: 'HandL UTM Grabber V3',
                    brand: 'HandL',
                    category: 'WordPress Plugins',
                    variant: 'V3',
                    list_position: 2,
                    quantity: quantity,
                    price: `${product_price}.00`,
                },
            ],
            email: searchParams.get('email'),
        });

        window.fbq('track', 'InitiateCheckout', {
            currency: 'USD',
            value: `${product_price}.00`,
        });

        window.rdt('track', 'AddToCart', {
            currency: 'USD',
            itemCount: 1,
            value: `${product_price}.00`,
        });

        let approveLink = response.links.filter((link) => link.rel == 'approve');
        // console.log(approveLink[0].href)
        window.location = approveLink[0].href;
    };
    const onStripePay = async () => {
        if (!searchParams.get('subscription_id')) {
            throw 'Invalid parameters. Please try again or contact support for assistance.';
        }
        const data = await getCustomer({
            pay_id: 'stripe',
            type: 'cus',
            email: searchParams.get('email'),
            name: tmpUser.first_name,
            marketing: window.HandL.getAll(),
            membership: true,
        }).unwrap();
        await onPay({
            pay_id: 'stripe',
            type: 'support_sub',
            customerId: data.customer.id,
            supportPriceId: prices.support.stripe,
            quantity: quantity,
            mainSubscriptionId: searchParams.get('subscription_id'),
            multiplier: multiplier ? 2 : 1,
            marketing: window.HandL.getAll(),
            membership: true,
        }).unwrap();
    };
    const handleUpgrade = async () => {
        try {
            if (searchParams.get('pay_id') === 'stripe') {
                await onStripePay();
                navigate({
                    pathname: '/support/welcome',
                    search: createSearchParams(searchParams).toString(),
                });
            } else if (searchParams.get('pay_id') === 'paypal') {
                await onPayPalPay();
            }
        } catch (error) {
            console.error(error);
            searchParams.set(
                'error',
                error ??
                'An unexpected error occurred while attempting to purchase the support plan. Please try again or contact support for assistance.'
            );
            setSearchParams(searchParams);
        }
    };
    const { View: FireLottieL } = useLottie({
        animationData: fireLottie,
        loop: true,
        className: "h-[24px] inline-block translate-y-[1px] mr-0.5"
    })
    const { View: FireLottieR } = useLottie({
        animationData: fireLottie,
        loop: true,
        className: "h-[24px] inline-block translate-y-[1px] ml-0.5"
    })
    useEffect(() => {
        if (searchParams.get('error')) {

            toast.error(searchParams.get("error"), {
                position: 'bottom-right',
                autoClose: 3000
            });
        }
    }, [searchParams.get('error')]);
    return (
        <>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-6 md:max-w-screen-2xl mx-auto items-center">
                <h2 className="font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl md:!leading-[3.5rem] min-[2000px]:!leading-[4.15rem]">
                    Congratulations and Thank You!
                </h2>
                <p className="text-base sm:text-lg md:text-xl md:max-w-[980px]">
                    We appreciate your choice and faith in our product, HandL UTM Grabber.{' '}
                    <br /> You've made an excellent decision in choosing this powerful
                    tool that is going to revolutionize your WordPress tracking and lead
                    attribution capabilities.
                </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
                <div className="text-center sm:text-left">
                    <h3 className="mb-2.5 text-xl md:text-2xl font-medium">
                        {FireLottieL}One-Time Offer{FireLottieR}
                    </h3>
                    <p className="text-base min-[2000px]:text-lg">
                        Before you install our plugin, take advantage of our exclusive
                        one-time offer! For just {Math.abs(prices.support.msrp)
                                                    .toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    })
                                                    .replace(/\.00$/, '')} per year, let our team handle the
                        complete installation and setup of HandL UTM Grabber for you. Save
                        time and start enjoying the benefits immediately—don't miss out!
                    </p>
                    <div className="flex flex-col gap-3 my-5 sm:mt-3 sm:flex-row">
                        <button
                            className={`bg-red-500 rounded hover:bg-red-800 px-4 py-2 font-medium text-white ext-sm md:text-base lg:text-lg disabled:cursor-wait disabled:bg-gray-400`}
                            as="button"
                            onClick={() => navigate('/w/onboarding/setup?' + createSearchParams(searchParams).toString())}
                        >
                            Skip
                        </button>
                        <button
                            onClick={handleUpgrade}
                            className={`bg-green-500 rounded hover:bg-green-800 px-4 py-2 font-medium text-white ext-sm md:text-base lg:text-lg disabled:cursor-wait disabled:bg-gray-400`}
                            disabled={paymentLoading}
                            as="button"
                        >
                            <div className="fa-1x">
                                Upgrade Now
                                {paymentLoading ? <i className="ml-2 fa fa-spinner fa-spin"></i> : ''}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export { SupportOffer };
