import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChatInput } from './ChatInput';
import { ChatMessage } from './ChatMessage/index';
import useChatHook from './useChat';
import RateChat from './RateChat';
import WelcomeChat from './WelcomeChat';
import ChatHeader from './ChatHeader';
import DeleteSession from './DeleteSession';
export const Chat = () => {
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();
  const { chatLogs, loading, handleSubmit, error, currentSession } = useChatHook({
    chatContainerRef
  });
  const { currentChatId } = useSelector((state) => state.chat)
  const lastMessageRef = useRef(null);
  const { isAuthenticated, user, plan, tmpUser } = useSelector(
    (state) => state.auth.data || {}
  );
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, []);
  return (
    <div className="w-full flex grow overflow-hidden h-[calc(100vh-240px)]">
      <RateChat />
      <DeleteSession key={currentChatId} />
      <div className="flex flex-col mx-auto py-2 md:px-5 lg:px-1 xl:px-5 overflow-hidden md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] flex-1">
        <ChatHeader />
        <div className="mt-1 md:mt-0 flex flex-col flex-1 overflow-hidden rounded-lg md:border border-neutral-300">
          <div
            className="flex flex-col flex-1 overflow-y-auto pb-2 px-2 sm:p-4 [&::-webkit-scrollbar]:[width:3px]
            [&::-webkit-scrollbar-thumb]:bg-gray-200"
            ref={chatContainerRef}
          >
            {chatLogs.map((message, index) => (
              message.role !== "system" &&
              <div key={index} className="">
                <ChatMessage
                  lastMessageRef={index === chatLogs.length - 1 ? lastMessageRef : null}
                  scrollContainerRef={chatContainerRef}
                  message={message}
                  isLastMessage={index === chatLogs.length - 1}
                  loading={loading}
                />
              </div>
            ))}
            {!loading && !currentSession && !error && <WelcomeChat handleSubmit={handleSubmit} />}
            {error && (
              <div className="items-start w-min font-normal text-sm whitespace-nowrap p-2.5 flex-col justify-start text-red-500 bg-white rounded-2xl border border-gray-200">
                {error}
              </div>
            )}
          </div>

          <div className="p-2 sm:p-4 mt-2 sm:mt-4 bottom-[56px] left-0 w-full" key={currentSession}>
            <ChatInput onSend={handleSubmit} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Chat;