import React from 'react';
import ImageOurEmailW from "../images/welcome/our_email@1x.webp";
import ImageOurEmailW_2x from "../images/welcome/our_email@2x.webp";
import ImageOurEmail from "../images/welcome/our_email@1x.jpg";
import ImageOurEmail_2x from "../images/welcome/our_email@2x.jpg";
import { withLayout } from './Layout';

const Welcome = () => {
    return (
        <div className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3 font-[poppins]" >
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 lg:max-w-screen-2xl mx-auto">
                <h2 className="font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl">Thank You for Choosing UTM Grabber!</h2>
                <p className="text-lg md:text-xl">
                    Just follow the following 3 simple steps to get up and running with us.
                </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
                <div className='text-left'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 1: Check Your Inbox</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        We've sent you an email with your login details. Please check your inbox (and the spam/junk folder, just in case) to find your access credentials.
                    </p>
                </div>
                <div className='text-left pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 2: Locate Our Email</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        Look for an email from utmgrabber.com with the subject line: "Welcome to HandL UTM Grabber – Get Started Now!"
                    </p>
                    <p className="text-lg min-[2000px]:text-xl pt-2">
                        Can't miss it, here's what it looks like:
                    </p>
                    <picture className=''>
                        <source srcset={`${ImageOurEmailW} 1x, ${ImageOurEmailW_2x} 2x`} type="image/webp" />
                        <img className='w-full h-auto' src={ImageOurEmail} alt="The email we sent to you" srcset={`${ImageOurEmail_2x} 2x`} />
                    </picture>
                </div>
                <div className='text-left pb-3 pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 3: Log In and Download</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        Once you have your login details, you're all set to log in to our customer area where you can download your UTM Grabber plugin.
                    </p>
                </div>
                <hr />
                <div className='text-left pt-3'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Need Help?</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        If you haven't received the email within a few minutes, or if you encounter any issues, please contact our support team via <a
                            className='cursor-pointer text-blue-500'
                            onClick={() => {
                                window.$crisp.push(['do', 'chat:toggle']);
                            }}
                        >chat.</a>
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-5 md:max-w-[1080px] mx-auto py-5">
                <p className='text-xl md:text-2xl min-[2000px]:text-3xl font-medium'>
                    Thank you for trusting us with your lead tracking and attribution needs!
                </p>
                <p className='text-base min-[2000px]:text-lg'>
                    We're excited for you to start capturing UTM parameters effortlessly with UTM Grabber.
                </p>
            </div>

        </div>
    )
}

export default withLayout(Welcome);