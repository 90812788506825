import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';

export const chatAPI = createApi({
    reducerPath: 'chatAPI',
    baseQuery: amplifyBaseQuery,
    tagTypes: ['Session'],
    endpoints: (builder) => ({
        createGptSession: builder.mutation({
            query: (data) => ({
                url: '/gpt_create_session',
                method: 'POST',
                useAuth: true,
                data: {
                    question: data.question,
                }
            }),
            // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
            //     try {
            //         const { data: createdSession } = await queryFulfilled
            //         const patchResult = dispatch(
            //             chatAPI.util.updateQueryData('getAllSessions', { from: 0, size: 50 }, (draft) => {
            //                 Object.assign(draft, [createdSession, ...draft])
            //             })
            //         )
            //     } catch { }
            // },
        }),
        getAllSessions: builder.query({
            query: ({ from, size }) => ({
                url: '/gpt_session?from=' + from + '&size=' + size + '',
                method: 'GET',
                useAuth: true,
            }),
            providesTags: [{
                type: 'Session',
                id: 'LIST',
            }],
        }),
        getSession: builder.query({
            query: (id) => ({
                url: '/gpt_session/' + id,
                method: 'GET',
                useAuth: true,
            }),
            providesTags: (result, error, arg) => [{
                type: 'Session',
                id: arg,
            }],
        }),
        deleteSession: builder.mutation({
            query: (id) => ({
                url: '/gpt_session/' + id,
                // The method name is just how it is with amplifyBaseQuery, go to src/store/utils.js to see why that is
                method: 'DEL',
                useAuth: true,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Session', id: "LIST" }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                // Optimistic updates
                const patchResult = dispatch(
                    chatAPI.util.updateQueryData('getAllSessions', { from: 0, size: 50 }, (draft) => {
                        Object.assign(draft, draft.filter(e => e.session != id))
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
        rateMessage: builder.mutation({
            query: (data) => ({
                url: '/gpt_feedback',
                method: 'POST',
                useAuth: true,
                data: {
                    rating: data.rating,
                    rating_message: data.rating_message,
                    chat_id: data.chat_id,
                    session: data.session,
                }
            }),
        })
    }),
});

export const {
    useCreateGptSessionMutation,
    useRateMessageMutation,
    useGetAllSessionsQuery,
    useLazyGetSessionQuery,
    useGetSessionQuery,
    useDeleteSessionMutation,

} = chatAPI;