import React from 'react';
import { NavLink } from 'react-router-dom';
import PluginDocs from './PluginDocs';
import LicenseW from '../../images/onboarding/license.webp';
import License from '../../images/onboarding/license.png';
function SelfOnboarding() {
    return (
        <>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-6 md:max-w-screen-2xl mx-auto items-center">
                <h2 className="font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl md:!leading-[3.5rem] min-[2000px]:!leading-[4.15rem]">Let's Get Started: Maximize Your Tracking with HandL UTM Grabber</h2>
                <p className="text-lg md:text-xl md:max-w-[980px]">
                    You've made the right choice. Now, let's take the next step to unlock the full potential of your WordPress tracking. Follow our simple setup guide to start leveraging powerful lead attribution today.
                </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
                <div className='text-left pb-3 pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 1: Download Your Plugin File</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        Your download is ready! Now, it's time to get your WordPress site supercharged. Click the button below to go to the download page for the HandL UTM Grabber plugin.
                        <br />
                        <NavLink target='_blank' to="/infos/downloads" className="underline text-blue-800 mx-auto">Download</NavLink>
                    </p>
                </div>
                <div className='text-left pb-3 pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 2: License Key & Documentation</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        <div className="mx-auto text-lg leading-normal text-left">
                            After downloading the plugin, you'll need your license key for
                            activation. Please check your email for the license key. You can
                            also find the documentation to guide you through the installation
                            and activation process{' '}
                            <a
                                className="text-blue-600"
                                target="_blank"
                                rel="noreferrer"
                                href="https://docs.utmgrabber.com/books/101-lets-start/chapter/how-to-download-activate-handl-utm-grabbertracker-v3"
                            >
                                here
                            </a>
                            .

                            <br />
                            <div className='mt-1' />
                            Here’s a preview of the email you’ll receive with your license key.
                            <picture className=''>
                                <source srcset={`${LicenseW}`} type="image/webp" />
                                <img className='w-full h-auto my-3' src={License} alt="The email we sent to you" />
                            </picture>
                        </div>
                    </p>
                </div>
                <PluginDocs />
                <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-5 md:max-w-[1080px] mx-auto pb-5">
                <p className='text-sm md:text-base min-[2000px]:text-lg font-normal'>
                        Once again, we thank you for choosing HandL UTM Grabber. If you have any questions or need assistance, don't hesitate to <span onClick={() => window.$crisp.push(['do', 'chat:toggle'])} className='text-blue-600 cursor-pointer hover:underline'>reach out</span> to our support team. We're here to help you make the most out of your new tool. Here's to your success!
                    </p>
                </div>
            </div>
        </>
    )
}

export default SelfOnboarding