import React, { useState, Fragment } from 'react'
import { Popover, Transition } from "@headlessui/react";
import * as Yup from 'yup';
import withFixedLayout from '../Layout/withFixedLayout'
import Select from './Select'
import schema from './question_schema.json'
import { useFormik } from 'formik';
import Detail from './Detail'
import { dynamicSchema } from "./validation"
import { useSendSurveyMutation } from '../../features/survey/surveyAPI';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import ThankYouRedirect from './ThankYouRedirect';
import NumberInput from './NumberInput';
function Index() {
  const Cookies = window.Cookies;
  const [currentStep, setCurrentStep] = useState(0);
  const [sendSurvey, {
    isLoading: isSendingSurvey,
  }] = useSendSurveyMutation();
  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const handleSubmit = async (values) => {
    if (currentStep === schema.questions.length - 1) {
      const payload = {
        ...values,
        utm_campaign: Cookies.get("utm_campaign"),
        utm_source: Cookies.get("utm_source"),
        utm_medium: Cookies.get("utm_medium"),
        utm_content: Cookies.get("utm_content"),
        utm_term: Cookies.get("utm_term"),
      }
      try {
        const response = await sendSurvey(payload).unwrap();
        setShowThankyouModal(true);
      }
      catch (error) {
        toast.error(`${error?.message ?? "Unknown error occurred, please try again later"}`, {
          position: 'bottom-right',
          autoClose: 3000
        });
      }
      return
    }
    setCurrentStep(currentStep + 1)
  }
  const validationSchema = currentStep === schema.questions.length - 1 ? dynamicSchema[currentStep]["question-9"] : Yup.object().shape(dynamicSchema[currentStep]);
  const formik = useFormik({
    initialValues: {

    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })
  const renderCurrentQuestion = (currentStep) => {
    const item = schema.questions[currentStep]
    switch (item.type) {
      case "multi-select":
        return <Select key={item.id} showFollowUp={
          Boolean(item.followUp)
        } val={formik.values[item.id] ?? {
          selected: [],
          other: ""
        }} question={item.question} options={item.options.map(e => ({
          key: e,
          value: e
        }))} onChange={(val) => {
          formik.setFieldTouched(item.id, true)
          formik.setFieldValue(item.id, {
            ...val,
            type: item.type,
            question: item.question
          })
        }}
          showError={formik.errors[item.id]} error={formik.errors[item.id]}
          questionIndex={`${currentStep + 1}/${schema.questions.length}`}
          multiSelect={true} />
      case "single-select":
        return <Select key={item.id} showFollowUp={
          Boolean(item.followUp)
        } val={formik.values[item.id] ?? {
          selected: null,
          other: ""
        }} question={item.question} options={item.options.map(e => ({
          key: e,
          value: e
        }))} onChange={(val) => {
          formik.setFieldTouched(item.id, true)
          formik.setFieldValue(item.id, {
            ...val,
            type: item.type,
            question: item.question
          })
        }}
          showError={formik.errors[item.id]} error={formik.errors[item.id]}
          questionIndex={`${currentStep + 1}/${schema.questions.length}`}
          multiSelect={false} />
      case "number":
        return <div key={item.id} className="text-sm">
          <h3 className="text-neutral-800 text-lg font-medium mb-5 flex flex-col">
            <strong className="text-neutral-400 mb-1">{`${currentStep + 1}/${schema.questions.length}`}</strong>
            {item.question}
          </h3>

          <div className="relative mb-4 text-neutral-800">
            <div className='flex flex-row w-1/2 relative'>
              <span className='absolute left-3 top-1/2 -translate-y-1/2 text-gray-900 text-sm font-normal text-center align-middle py-2.5'>{item.label}</span>
              <NumberInput item={item} formik={formik} />
            </div>
          </div>
          {formik.errors[item.id] && formik.touched[item.id] && <div className="text-red-500 text-sm text-start">{
            formik.errors[item.id].value
          }</div>}
        </div>
      case "details":
        return <Detail key={item.id} formik={formik} questionIndex={`${currentStep + 1}/${schema.questions.length}`} />
      default:
        return <>Unknown Question</>
    }
  }
  return (
    <div className='flex-1 flex flex-col font-[poppins] overflow-y-auto gap-4 md:gap-6 py-4 sm:py-8 md:py-10 lg:py-8'>
      <Modal isOpen={showThankyouModal} onClose={() => { }} unmountOnClose>
        <ThankYouRedirect />
      </Modal>
      <div className='flex flex-col items-center px-4 sm:px-14 gap-2 text-center'>
        <h1 className="text-3xl md:text-4xl font-bold text-[#374048]">
          How can we help your business grow?
        </h1>
        <Popover className="relative inline-block">
          <h2 className="text-base font-light md:text-lg min-[2000px]:text-xl">
            Get Your Free, Tailored Insight on Amplifying Your Business Growth!
            <Popover.Button>
              <i className="fa fa-info-circle text-blue-500 mx-2"></i>
            </Popover.Button>
          </h2>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="left-1/2 -translate-x-1/2 absolute z-10 bg-white text-sm font-normal leading-normal text-left rounded-md shadow w-full">
              <div className="mb-2.5 py-2 px-2 relative">
                <p className="text-xs md:text-sm min-[2000px]:text-base font-light !leading-relaxed">
                  Discover the full potential of your marketing efforts with our HandL UTM Grabber. By answering a few simple questions in our quiz, you'll receive a personalized email analysis that highlights how our software can revolutionize your tracking and attribution strategies—completely free, with no credit card required. Transform your data into actionable insights and watch your business thrive. Take the first step towards smarter marketing today!
                </p>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
      <form onSubmit={formik.handleSubmit} className='flex flex-col text-sm md:text-base px-4 sm:px-14 items-center'>
        <div className='flex flex-col w-full sm:w-[400px]'>
          {
            renderCurrentQuestion(currentStep)
          }
          <div className="flex flex-row gap-2 items-center justify-end  w-full border-t-gray-200 border-t-2 pt-5 text-right mt-6 border-solid text-white font-semibold">
            <button type='button' className="bg-gray-300 text-gray-600 cursor-pointer py-2 px-5 text-center text-sm inline-block rounded overflow-visible" onClick={() => {
              if (currentStep !== 0) {
                setCurrentStep(currentStep - 1)
              }
            }}>Prev</button>
            <button disabled={isSendingSurvey} type='submit' className="bg-[#1972f5] cursor-pointer py-2 px-5 text-center text-sm inline-block rounded overflow-visible" onClick={() => {
            }}>{
                currentStep === schema.questions.length - 1 ? "Submit" : "Next"
              } {isSendingSurvey && <i className="fa fa-spin fa-spinner" />}</button>
          </div>
        </div>

      </form>
    </div>
  )
}


export default withFixedLayout(Index)