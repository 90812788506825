import React from "react";
import IframeResizer from "iframe-resizer-react";
import { withLayout } from "./Layout";

const ScheduleFrame = () => {
  return (
    // <iframe
    //   className="min-h-[calc(100vh-305.19px)] mt-[24px] w-full overflow-y-auto"
    //   src="https://utmgrabber.com/schedule.html"
    // ></iframe>

    <IframeResizer
      log
      src="https://utmgrabber.com/schedule.html"
      className="min-h-[calc(100vh-305.19px)] mt-[24px] w-full"
    />
  );
};

export default withLayout(ScheduleFrame);
