import React from 'react';

function ChatSuggestionBubble({ question, answer, onClick }) {
    return (
        <button
            className="relative group whitespace-nowrap rounded-xl px-4 py-3 text-left font-[poppins] md:whitespace-normal border hover:bg-gray-50 border-black border-opacity-10 hover:border-gray-200 hover:border-opacity-100 transition-all ease-in-out "
            as="button"
            onClick={onClick}
        >
            <div className="flex w-full gap-2 items-center justify-center">
                <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col overflow-hidden text-gray-700 text-sm">
                        <div className="truncate font-medium">{question}</div>
                        <div className="truncate font-normal opacity-50">
                            {answer}
                        </div>
                    </div>
                    <div className="absolute bottom-0 right-0 top-0 flex items-center bg-gradient-to-l from-gray-50 from-[60%] rounded-xl pl-6 pr-4 text-gray-700 opacity-0 group-hover:opacity-100">
                        <span>
                            <div className="rounded-lg p-1 shadow-sm bg-white">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    className="text-gray-700 w-5 h-5"
                                >
                                    <path
                                        d="M7 11L12 6L17 11M12 18V7"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </button>
    );
}

export default ChatSuggestionBubble;
