import React from 'react';

import { ImgW } from '../js';
import mp from '../images/mp.png';
import mpW from '../images/mp.webp';

function WhyUs() {
  return (
    <section className='row whyus !mb-10' id='why'>
      <div className='container max-w-screen-xl mx-auto px-12'>
        <h1 className='text-[36px] font-medium mb-[30px]'>
          Why HandL UTM Grabber is{' '}
          <span className='wp-title'>the best UTM tracking solution</span> to
          buy?
        </h1>
        <div className='w-full flex justify-center'>
          <div className='text-[22px] font-light mb-[80px] w-fit'>
            HandL UTM Grabber stands out as the premier UTM tracking plugin for WordPress. Unlike JavaScript-based solutions, our plugin is natively built for WordPress, ensuring seamless integration and superior performance.
            <br />
            <br />
            As the only official WordPress plugin for lead tracking and attribution, HandL UTM Grabber is unaffected by cookieless browsers and ensures data privacy by keeping everything first-party. Only you have access to the data collected—no third-party servers are involved, eliminating any security and privacy risks.
            <br />
            <br />
            Our customers keep coming back, and glowing reviews consistently prove we are the best choice. Choose HandL UTM Grabber for reliable, secure UTM tracking on WordPress.
          </div>
        </div>
        <h3 className='text-center wp-title font-medium text-[24px] mt-[20px] mb-[10px]'>
          They all love our plugin & support ❤️
        </h3>

        <div className='min-h-screen flex justify-center items-center pt-20'>
          <div className='md:px-4 md:grid md:grid-cols-2 gap-5 space-y-4 md:space-y-0'>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-lg shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src='https://secure.gravatar.com/avatar/e3fb80cf80eddd03fd844516c8c920f9?s=100&d=retro&r=g'
                alt='User Avatar'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Best Plugin For Marketing Ever
              </h1>
              <p className='mb-2 !text-base font-light'>
                The support is top-tier, the plugin is easy to use, and you can
                make a direct impact on projects you're working on within 30
                minutes of using the tool. 10/10 would recommend.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @flkwade
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src='https://secure.gravatar.com/avatar/1f9aeb65d8434120e9d36bb28ab29494?s=100&d=retro&r=g'
                alt='https://secure.gravatar.com/avatar/1f9aeb65d8434120e9d36bb28ab29494?s=100&d=retro&r=g'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                A marketer's dream!
              </h1>
              <p className='mb-2 !text-base font-light'>
                Attribution is very difficult. Handl makes it easier. You get a
                ton of features and options, and it's easy to set up. The moment
                you see your campaign data make more sense... you know your
                money was well spent.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @Lawrence
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src='https://secure.gravatar.com/avatar/6eca234eb85a65fe464f6d77a364d6df?s=100&d=retro&r=g'
                alt='https://secure.gravatar.com/avatar/6eca234eb85a65fe464f6d77a364d6df?s=100&d=retro&r=g'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Amazing Plugin. Remarkable Support.
              </h1>
              <p className='mb-2 !text-base font-light'>
                I've struggled with proper utm storage for many years and when I
                switched to wordpress, I needed to find something that would
                take the hassle out of capturing, storing and leveraging utm
                parameters (first and last touch).
                <br />
                This plugin is amazing. It covers all of my needs and then some.
                The team who created it obviously has been around the block, and
                has an elegant solution for so many things: custom parameter
                tracking, easy forwarding of parameters on to other links,
                iframe pass-through, and enhanced FB tracking.
                <br />
                And to add to that, I was blown away with how the team responded
                so quickly to a technical question that was keeping me from my
                launch.
                <br />I can't recommend this strongly enough.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @jscottym
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src='https://secure.gravatar.com/avatar/71f8723e172da7fb39b4ddd99f7892cf?s=100&d=retro&r=g'
                alt='https://secure.gravatar.com/avatar/71f8723e172da7fb39b4ddd99f7892cf?s=100&d=retro&r=g'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Great plugin and service!
              </h1>
              <p className='mb-2 !text-base font-light'>
                Must-have plugin for every digital marketer especially in the
                IOS 14 era!
                <br />
                I'm using this plugin to pass all the data of the campaigns to
                my CRM.
                <br />
                As an affiliate marketer I can easily send conversions to Google
                Analytics and run A/B tests with Optimize.
                <br />
                Nothing of that can't be done without their great customer
                service that helped me with the setup and added the necessary
                features.
                <br />
                I definitely recommend on this plugin.
                <br />
                Thanks a lot!
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @oridigitalsite1
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src='https://secure.gravatar.com/avatar/8652ad83ff58ef3ca9ea8ad397bc3eab?s=100&d=retro&r=g'
                alt='https://secure.gravatar.com/avatar/8652ad83ff58ef3ca9ea8ad397bc3eab?s=100&d=retro&r=g'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Excellent product backed by A+ support
              </h1>
              <p className='mb-2 !text-base font-light'>
                This product has allowed us to track our marketing efforts
                better than any other we've tried – and their support is
                awesome! They helped us through some complicated integrations.
                Hands own the best support we've received from any other
                WordPress plugin team! Thank you!
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @mhpurvis
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src={mp}
                alt='mp'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Amazing plugin with amazing support to match
              </h1>
              <p className='mb-2 !text-base font-light'>
                This plugin works great for transferring your utm parameters to
                every page the user clicks on. I used it to populate contact
                form 7 fields. I needed help setting it up and reached out to
                the developer on Slack and he replied instantly and fixed my
                problem within minutes. I am extremely happy with this plugin
                along with the support for it and highly recommend it.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>@ccshub</h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src={mp}
                alt='mp'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                MAGNIFICENT plugin & support
              </h1>
              <p className='mb-2 !text-base font-light'>
                This is an amazing plugin: simple in is usage, but incredibly
                powerful in its use to track your campaigns. Very helpful and
                capable support. Utmgrabber is the best software to track the
                source of your woocommerce-clients (ads, ...).
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @jessy86
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src={mp}
                alt='mp'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Quick integration with Elementor!
              </h1>
              <p className='mb-2 !text-base font-light'>
                Amazing support. Just downloaded and set up the plugin. Highly
                recommended if you need UTM tracking. Chat support was super
                friendly and helpful. All companies should be like this.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>@dsire</h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src={mp}
                alt='mp'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                Best Plugin Ever
              </h1>
              <p className='mb-2 !text-base font-light'>
                UTM Grabber has been amazing for our agency in so many ways. It
                makes tracking a breeze and the customer support is second to
                none. Leman goes above and beyond to ensure you are getting the
                most from the plugin and was kind enough to answer more
                questions than I can count.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @dpage93
              </h3>
            </div>
            <div className='max-w-lg bg-white px-6 pt-6 pb-2 border border-gray-300 rounded-xl shadow-lg'>
              <img
                className='w-20 h-20 rounded-full float-left mr-3 border-2 border-gray-300'
                src={mp}
                alt='mp'
              />
              <h1 className='mb-2 text-gray-800 text-2xl font-bold cursor-pointer'>
                UTM Grabber Awesomeness!
              </h1>
              <p className='mb-2 !text-base font-light'>
                We've downloaded and used 15 instances of UTM grabber for our
                clients and we have greatly extended the campaign tracking to
                levels our clients never knew were possible. First touch
                attribution and organic sources create an incredible compliment
                to the UTM parameters.
              </p>
              <h3 className='mb-3 text-xl font-medium text-red-500'>
                @intercam
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 lg:mt-20 md:mt-20 px-4 text-center lg:flex md:flex sm:block justify-center items-center'>
        <span className='text-[22px] font-light '>
          We have all the answers you need. &nbsp;
        </span>
        <button
          onClick={() => {
            window.$crisp.push(['do', 'chat:toggle']);
          }}
          className='btn btn-sm btn-primary cursor-pointer'
        >
          Chat Now
        </button>
      </div>
    </section>
  );
}

export default WhyUs;
