import Modal from "../Modal/Modal";

const PaypalDiscountWarning = ({ modalOpen,
    handleCloseModal,
    handleContinueWithPaypal,
    handleContinueWithCreditCard,
    isLoading
}) => {

    return (
        <Modal isOpen={modalOpen} onClose={handleCloseModal}>
            <div
                className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-xl text-left font-[poppins] shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white max-w-[90vw] sm:max-w-[70vw] lg:max-w-[50vw] 2xl:max-w-[40vw] pointer-events-auto"
            >
                <div className="px-4 pb-4 pt-5 sm:p-6 flex items-center justify-between border-b border-black/10">
                    <div className="flex">
                        <div className="flex items-center">
                            <div className="flex grow flex-col gap-1">
                                <h2
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >Important Notice About Your Discount
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 sm:p-6">
                    <p className="leading-4 font-normal">
                        You have successfully applied a discount code to your order. Please be aware that discount codes can only be used with credit card payments. If you choose to proceed with PayPal, the discount will not be applied.
                    </p>
                    <p className="leading-4 font-normal mt-1">
                        Would you like to continue with a credit card to keep your discount, or proceed with PayPal without the discount?
                    </p>
                    <div className="mt-5 sm:mt-4">
                        <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
                            <button onClick={handleContinueWithPaypal} className={`flex items-center justify-center bg-red-500 rounded hover:bg-red-800 px-3 py-2 font-medium text-white text-sm disabled:cursor-wait disabled:bg-gray-400`} as="button">
                                <span className="shrink-0 text-nowrap">
                                    
                                    Continue with PayPal
                                </span>
                                {isLoading ? (<i className="ml-2 fa fa-spinner fa-spin"></i>) : null}
                            </button>
                            <button onClick={handleContinueWithCreditCard} className="rounded px-3 py-2 font-medium border border-gray-200 text-sm bg-blue-500 hover:bg-blue-800 text-white" as="button">
                                <span className="shrink-0 text-nowrap">
                                    Proceed with Credit Card</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default PaypalDiscountWarning;