import { createApi } from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery } from '../../store/utils';
import { planAPI } from '../plan/planAPI';

export const gcildAPI = createApi({
    reducerPath: 'gcildAPI',
    baseQuery: axiosBaseQuery({
        baseUrl:process.env.REACT_APP_API_ENDPOINT
    }),
    tagTypes: ['GCILD'],
    endpoints: (builder) => ({
        setAdwords: builder.mutation({
            query: (data) => ({
                url: 'http/adwords',
                method: 'POST',
                useAuth: true,
                data: data
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        }),
    }),
});

export const {
    useSetAdwordsMutation,

} = gcildAPI;
