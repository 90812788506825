import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector} from 'react-redux';
import Drawer from '../Drawer';
import ChatSessions from './ChatSessions';
import UtmGrabberLogoPng from '../../images/handl-utm-grabber-g.png';
import UtmGrabberLogoWebp from '../../images/handl-utm-grabber-g.webp';
import Tooltip from '../Tooltip';
import { openNewChatSession } from '../../features/chat/chatSlice';
function ChatHeader() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const handleOpenNewChat = () => {
        dispatch(openNewChatSession());
    };
    const { currentSession: { sessionId } } = useSelector((state) => state.chat);
    useEffect(() => {
        setDrawerOpen(false);
    }, [sessionId]);
    return (
        <>
            <Drawer
                isOpen={drawerOpen}
                onClose={function () {
                    setDrawerOpen(false);
                }}
            >
                <div className="max-w-xs w-[70vw] h-full bg-gray-50  py-2 flex flex-col p-2 gap-2 ">
                    <div class="flex py-2 items-center justify-between border-b border-gray-200">
                        <button type="button" onClick={handleOpenNewChat}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-[1.125rem] h-[1.125rem]"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </button>
                        <button type="button" onClick={() => setDrawerOpen(false)}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-[1.125rem] h-[1.125rem]"
                            >
                                <path
                                    d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div
                        className="flex flex-col gap-2 overflow-y-auto -mr-2 pr-2 [&::-webkit-scrollbar]:[width:3px]
            [&::-webkit-scrollbar-thumb]:bg-gray-200"
                    >
                        <ChatSessions />
                    </div>
                </div>
            </Drawer>
            <ChatHeader.Nav
                openDrawer={() => setDrawerOpen(true)}
                openNewChat={handleOpenNewChat}
            />
        </>
    );
}

ChatHeader.Nav = ({ openDrawer, openNewChat }) => {
    return (
        <div class="sticky top-0 z-auto flex min-h-[40px] items-center justify-center border-b border-gray-200 pl-1 md:hidden">
            <button
                onClick={openDrawer}
                type="button"
                class="absolute left-0 -ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white active:opacity-50"
            >
                <span class="sr-only">Open sidebar</span>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-[1.125rem] h-[1.125rem]"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3 8C3 7.44772 3.44772 7 4 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H4C3.44772 9 3 8.55228 3 8ZM3 16C3 15.4477 3.44772 15 4 15H14C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17H4C3.44772 17 3 16.5523 3 16Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </button>
            <div class="absolute bottom-0 right-0 top-0 flex items-center">
                <button type="button" class="px-3" onClick={openNewChat}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-[1.125rem] h-[1.125rem]"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};
const NewChat = ({ }) => {
    const dispatch = useDispatch();
    return (
        <button onClick={() => dispatch(openNewChatSession())} className="flex flex-row justify-between items-center px-4 py-3 hover:bg-gray-200 hover:bg-opacity-70 rounded-lg">
            <div class="relative">
                <div class="w-[32px] h-auto">
                    <picture>
                        <source srcSet={UtmGrabberLogoWebp} />
                        <img src={UtmGrabberLogoPng} alt="utm grabber logo" />
                    </picture>
                </div>
            </div>
            <Tooltip tooltipContent={'New chat'} className="hidden md:inline-block">
                <div className="text-gray-900">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-[1.125rem] h-[1.125rem]"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
            </Tooltip>
            <div className="text-gray-900 md:hidden">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-[1.125rem] h-[1.125rem]"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z"
                        fill="currentColor"
                    ></path>
                </svg>
            </div>
        </button>
    );
};
ChatHeader.NewChat = NewChat;
export default ChatHeader;
