import React from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
const categorizedFaqs = {
    "General": [
        {
            q: "Why buy from you? What makes you so special?",
            a: "We are the first ever published UTM plugin for WordPress. We have 8+ years of experience in tracking and capturing UTM and parameters. You can count on us.",
        },
        {
            q: "Do you provide support if I need help getting the plugin to work?",
            a: "Yes, we are a message away whenever you need. We are happy to help you integrate and get you started collecting the data you need in no time.",
        },
        {
            q: "Are you GDPR compliant?",
            a: "Our plugin is fully compliant with GDPR and can be used with Cookie Notice, Borlabs, and Complianz plugin.",
        },
        {
            q: "Is this plugin secure?",
            a: "You can use the plugin with peace of mind. We never store any PII (personally identifiable information) and the plugin never communicates any user-related data to our server.",
        },
    ],
    "Tracking and Features": [
        {
            q: "I have paid ads campaigns (ppc), can I use this plugin to track which campaign my leads come from?",
            a: "Yes. You can track gclid and fbclid and associate user data with your paid campaigns very easily.",
        },
        {
            q: "Can I see what my leads search before they land on my site via Google Ads?",
            a: "Yes you can append {keyword} ValueTrack parameter to your final URLs and track it within HandL UTM Grabber.",
        },
        {
            q: "How are UTMs stored? Do they need to be in the URL all the time for tracking?",
            a: "UTMs are stored in COOKIES (client's browser) as soon as they are available in the URL. So that means even if the UTMs are no longer in the query string, your visitors are still being tracked. Eventually, when a user is converted (lead gen or sale), UTMs will be captured and associated with the user.",
        },
        {
            q: "Can I track users across subdomains?",
            a: (
                <p>
                    Yes. UTM parameters and other custom variables are always recorded at
                    the highest domain level. For example, if your landing page is on{" "}
                    <code>example.com</code>, and users convert on{" "}
                    <code>sub.example.com</code>. HandL UTM Grabber will still be able to
                    capture the COOKIES from <code>example.com</code>.
                </p>
            ),
        },
        {
            q: "What is first/last touch?",
            a: (
                <p>
                    HandL UTM Grabber allows capturing both first touch and last touch
                    UTMs simultaneously. All the original UTMs parameters such as{" "}
                    <code>utm_campaign</code> is last touch by default. Simply use{" "}
                    <code>first_utm_campaign</code> if you would like to capture the first
                    touch. If a user clicks on the ad and comes to the website and then
                    comes back another day from Google organic, you will be able to
                    capture the first UTM set that belongs to the ad as well as the last
                    one belonging to the organic.
                </p>
            ),
        },
    ],
    "Payment and Refund": [
        {
            q: "If I'm not happy, can I get a refund?",
            a: "Certainly. If you are not happy (for ANY reason) you can refund within 14 days from the date of first payment.",
        },
    ],
};
function Index() {
    return (
        <section className="relative py-20 xl:py-28 bg-gradient-to-b from-landingAccent2-100 to-transparent">
            <div className="container 2xl:max-w-[87.5rem] px-4 mx-auto">
                <div className="mx-auto text-center xl:max-w-3xl mb-10">
                    <h1 className="mb-0 leading-normal capitalize text-slate-800 text-4xl font-semibold">
                        <span className="relative inline-block before:absolute before:h-5 before:w-full before:bg-landingAccent2-200 before:bottom-2 before:-skew-y-6 text-landingAccent2-700">
                            <span className="relative">FAQ</span>
                        </span>
                    </h1>
                    <p className={
                        "mt-4 text-lg text-slate-500"
                    }>
                        If you have questions, we have answers for you here. In case we don't, please feel free to reach out to us <span className='text-blue-500 cursor-pointer' onClick={() => {
                            window.$crisp.push(['do', 'chat:toggle']);
                        }}>here <i></i></span>
                    </p>
                </div>
                <TabGroup className={"grid grid-cols-1 lg:grid-cols-12 items-start justify-start"} >
                    {
                        <TabList className={"col-span-3 grid grid-rows-1 gap-2"} >
                            {
                                Object.keys(categorizedFaqs).map((category, i) => (<>
                                    <Tab key={"faq_tabs" + i} className="border-b border-slate-200 md:border-none text-left text-lg md:text-xl font-semibold lg:px-2 py-1.5 lg:py-2 rounded-md  text-slate-700 data-[selected]:text-landingAccent2-500 ">
                                        {category}
                                    </Tab>
                                </>))
                            }
                        </TabList >

                    }
                    <TabPanels className={"col-span-9 md:ml-2"}>

                        {
                            Object.keys(categorizedFaqs).map((categories, i) => (<>
                                <TabPanel key={"tabpanels" + i} className={"w-full h-full"} unmount={false}>
                                    <h5 className='underline mt-6 lg:mt-0 lg:py-2 font-semibold text-xl text-black align-middle'>
                                        {categories} {" Questions"} 
                                    </h5>
                                    {
                                        categorizedFaqs[categories].map((qa, i) => (<Disclosure key={i} as="div" className="" >
                                            <DisclosureButton className="group text-left flex w-full items-center justify-between py-6 border-b-2 border-black">
                                                <span className="md:text-lg text-slate-800 font-semibold group-data-[hover]:opacity-95">
                                                    {qa.q}
                                                </span>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon" class="size-5 mr-2 fill-slate-800 group-data-[hover]:opacity-95 group-data-[open]:rotate-180"><path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"></path></svg>
                                            </DisclosureButton>
                                            <DisclosurePanel transition className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0 text-base text-left text-slate-500 pt-6 pb-4 pr-5">
                                                {qa.a}
                                            </DisclosurePanel>
                                        </Disclosure>))
                                    }
                                </TabPanel>
                            </>))
                        }
                    </TabPanels>
                </TabGroup>

            </div>
        </section >
    )
}

export default Index