import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import reviews from "../../promo/ReviewCarousel/reviews.json";
function index() {
    return <section className="relative overflow-hidden py-20 xl:pb-28 xl:pt-10" id="feedback">
        {/* return <section className="relative  pt-10 overflow-hidden xl:pt-5" id="feedback"> */}
        <div className="container 2xl:max-w-[87.5rem] px-4 mx-auto">
            <div className="mx-auto mb-14 text-center xl:max-w-3xl">
                <h1 className="mb-0 leading-normal capitalize text-slate-800 text-4xl font-semibold">What Our Customers Say</h1>
            </div>
            <div className="grid items-center grid-cols-12">
                <div className="relative col-span-12 md:col-span-8 md:!col-start-3 lg:col-span-6 lg:!col-start-4">
                    <div>
                        <svg className="absolute top-0 w-24 h-24 opacity-50 stroke-1 !right-2 text-landingAccent2-200 z-50" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z"></path><path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z"></path></svg>
                    </div>
                    <div className="pb-6 swiper feedback-slider">
                        <Swiper
                            slidesPerView={1}
                            loop={true}
                            pagination={{
                                el: ".swiper-pagination-landing",
                                clickable: true,
                                bulletClass: "opacity-20 !bg-landingAccent500 h-4 w-4 border-4 border-white rounded-full outline outline-1 outline-landingAccent500",

                            }}
                            autoplay={{ delay: 3000 }}
                            modules={[Pagination, Autoplay]}
                        >
                            {reviews.map((_, i) => (
                                <SwiperSlide key={_.title}>
                                    <div className='relative pb-8'>
                                        <img src={_.avatar} alt="User Avatar" className="z-50 absolute bottom-0 left-1/2 transform -translate-x-1/2 w-16 h-16 sm:w-20 sm:h-20 rounded-full p-2 bg-white" />
                                        <div className="p-10 bg-landingAccent2-50 rounded-md relative shadow-sm">
                                            <div className="text-lg text-yellow-500 flex items-center">
                                                {Array(5).fill().map((_, i) => (<i><svg key={"stars_r" + i} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="star" class="lucide lucide-star w-5 h-5 text-yellow-500 fill-yellow-500"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></i>))}
                                            </div>
                                            <h6 className="mt-4 text-xl sm:text-2xl text-slate-800">{_.title}</h6>
                                            <p className="mt-4 mb-2 text-base sm:text-lg text-slate-500">" {_.review} "</p>
                                            <p className="font-medium">- {_.author}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div class="swiper-pagination-landing flex gap-2 justify-center !mt-1"></div>
                </div>
            </div>
        </div>
    </section>
}

export default index