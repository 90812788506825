import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { matchPath } from 'react-router';
import { useNavigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorScreen from "../Error/ErrorScreen";
import ChatSidebar from "../Chat/ChatSidebar";
import AccountInfoSidebar from "./AccountInfoSidebar";
import Drawer from "../Drawer";
import { withLayout } from "../Layout";

function AccountInfos() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, tmpUser } = useSelector((state) => state?.auth?.data || {});
  const emailNotVerified = !user && tmpUser;
  const isChatRouteActive = !!matchPath({
    path: '/infos/chat',
  }, location.pathname);
  const [accountInfosDrawerVisible, setAccountInfosDrawerVisible] = useState(false);


  return (
    <div className="md:flex">
      <Drawer className={"md:hidden"} isOpen={accountInfosDrawerVisible} onClose={() => setAccountInfosDrawerVisible(false)}>
        <AccountInfoSidebar />
      </Drawer>
      <button
        type="button"
        onClick={() => setAccountInfosDrawerVisible(true)}
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>
      {/*Temp user means the user just registered but didn't verify their email tmpUser && !user && */}
      <div className="flex flex-row">
        <div className="hidden md:block">

          <AccountInfoSidebar />
        </div>
        {
          isChatRouteActive && <ChatSidebar />
        }
      </div>

      <div className="static w-full p-2 md:p-5 lg:p-10">
        <ErrorBoundary FallbackComponent={ErrorScreen} key={location.pathname}>
          {emailNotVerified ? (
            <div>
              <div className="px-6 py-4 text-center">
                <div className="fa-3x">
                  <p>
                    Please go to your email and validate your email before
                    continuing.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <Outlet />
          )}
        </ErrorBoundary>
        {/*tmpUser && !user &&*/}

        {/* {renderComponent} */}
      </div>
      {showModal && (
        <>
          <div className="handl-modal pt-[40px] lg:w-[700px] md:w-[500px] w-[400px] fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[10001]">
            <div className="pl-[80px] pr-[80px] text-center">
              <h1 className="text-center text-2xl">
                Your account is not verified yet.
              </h1>
              <p className="text-center text-xl">
                Please verify your account to access this page.
              </p>
              <button
                className="my-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                onClick={() => {
                  setShowModal(false);
                  navigate("/");
                }}
              >
                Close
              </button>
            </div>
          </div>

          <div
            className="absolute w-[100%] h-[100%] bg-black/50 top-[0px] left-0 z-[10000]"
            onClick={() => {
              setShowModal(false);
              navigate("/");
            }}
          ></div>
        </>
      )}
    </div>
  );
}
export default withLayout(AccountInfos)