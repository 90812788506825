import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isReviewModalOpen: false,

  currentChatId: null,
  currentSession: {
    sessionId: null,
    isNewSession: true
  },
  currentSelectedRating: null,
  ratedChats: {}, // Hashmap to track if a chat has been rated (chatId: boolean)
  sessionToDelete: {
    sessionToDeleteModalOpen: false,
    sessionTitle: null,
    sessionId: null
  },
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // Action to open the modal for a specific chat ID
    openChatRatingModal(state, action) {
      state.isReviewModalOpen = true;
      state.currentChatId = action.payload.chatId;
      state.currentSelectedRating = action.payload.rating;
    },
    setCurrentChatSession(state, action) {
      state.currentSession.sessionId = action.payload.sessionId;
      state.currentSession.isNewSession =  action.payload.isNewSession || false;
    },
    openNewChatSession(state) {
      state.currentSession.sessionId = null;
      state.currentSession.isNewSession = true;
    },
    closeChatRatingModal(state) {
      state.isReviewModalOpen = false;
      state.currentChatId = null;
    },
    markChatAsRated(state, action) {
      const chatId = action.payload;
      state.ratedChats[chatId] = true;
    },
    openDeleteChatSessionModal(state, action) {
      state.sessionToDelete = {
        sessionId: action.payload.sessionId,
        sessionTitle: action.payload.sessionTitle,
        sessionToDeleteModalOpen: true
      };
    },
    closeDeleteChatSessionModal(state) {
      state.sessionToDelete.sessionId = null;
      state.sessionToDelete.sessionToDeleteModalOpen = false;
    }

  },
});

export const { openChatRatingModal, setCurrentChatSession, closeChatRatingModal, markChatAsRated,openNewChatSession, openDeleteChatSessionModal, closeDeleteChatSessionModal } = chatSlice.actions;

export default chatSlice.reducer;
