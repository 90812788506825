import React from "react";

const PremiumFeatures = () => {
  return (
    <div>
      <ul className="nav">
        <li>
          Supports ALL the forms.{" "}
          <a
            className="scrollable size xsmall learn-more"
            href="#supported-by-handl"
          >
            list
          </a>
        </li>
        <li>Supports WooCommerce.</li>
        <li className="multiple-line">
          <span>Tracks all UTMs (Paid Ads), Social, Organic, Referral, and Direct traffic,
          as well as any user-defined custom parameters (e.g. affiliate).</span>
        </li>
        <li>Cookie/Tracking duration can be customized (e.g. 365 days).</li>
        <li>Compliant with EU GDPR and compatible with consent plugins.</li>
        <li>WordPress multi-site network support.</li>
        <li>
          BONUS: Access to GCLID Reporter{" "}
          <a
            className="size xsmall learn-more"
            target="_blank"
            href="https://docs.utmgrabber.com/books/103-internal-apps/page/handl-gclid-reporter"
          >
            (peak)
          </a>{" "}
          tool.
        </li>
        <li>
        BONUS: AI-Powered Report Insight{" "}
          <a
            className="size xsmall learn-more"
            target="_blank"
            href="https://docs.utmgrabber.com/books/103-internal-apps/page/ai-powered-report-insight"
          >
            (peak)
          </a>
        </li>
        <li>
          Premium Support.{" "}
          <span className="xsmall">and</span>{" "}
          <a className="scrollable size xsmall learn-more" href="#features">
            many more
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PremiumFeatures;
