import React, { useEffect } from "react";
import Google from "./Google";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLazyGetPlansQuery } from "../features/plan/planAPI";
import withFixedLayout from "./Layout/withFixedLayout";

function Apps() {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector(
    (state) => state.auth.data || {}
  );
  const { accountType } = useSelector(state => state.plan)
  const [getPlans, {
    isLoading: plansLoading,
  }] = useLazyGetPlansQuery();
  useEffect(() => {
    if (isAuthenticated && user) {
      getPlans(null,true);
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div id="apps" className="flex flex-col items-center justify-start flex-1">
      <div className="p-2 text-center">
        {plansLoading && (
          <div>
            <div className="fa-3x">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
            <p>Please wait...</p>
          </div>
        )}
        {!plansLoading && accountType == "free" && (
          <div className="row">
            <div className="text-center m-t-40">
              <h3 className="text-2xl font-medium">
                Please upgrade to access the HandL Apps for FREE.
              </h3>
              <h4 className="text-xl mt-2">
                Click below to get many more features and premium customer
                support
              </h4>
              <Link
                to="/#prices"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-5 focus:outline-none inline-block hover:text-white"
              >
                Upgrade Now
              </Link>
            </div>
          </div>
        )}
        {!plansLoading && accountType === "paid" && <Google />}
      </div>
    </div>
  );
}
export default withFixedLayout(Apps);