import { Dialog, Transition } from "@headlessui/react"
import cx from "classnames"
import { Fragment } from "react"
import React from "react"
const Modal = ({
    isOpen,
    // sometimes we want to wait for the Transition to end
    onAfterClosed,
    children,
    className,
    /* what to do on esc or backdrop click. */
    onClose = () => { },
    maskClassName,
    unmountOnClose = false,
    dialogClassName = "",
}) => (
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog
            as="div"
            className={`relative z-[10000] ${dialogClassName}`}
            onClose={onClose}
            unmount={unmountOnClose}
        >
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={onAfterClosed}
            >
                <div className="fixed inset-0  transition-opacity">
                    <div
                        className={cx(
                            "h-full w-full opacity-[0.65] !bg-black",
                            maskClassName
                        )}
                    />
                </div>
            </Transition.Child>

            <div className="fixed inset-0 overflow-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className={cx(
                                "min-w-fit max-w-lg transform rounded-lg bg-white align-middle shadow overflow-clip",
                                className
                            )}
                        >
                            <div className="flex h-full flex-col space-y-6">
                                {children}
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
)

export default Modal