import React from 'react';
import { Link } from 'react-router-dom';
import NotFound from '../images/notfound.jpg';
import { withLayout } from './Layout';

 function NoMatch() {
  return (
    <div className='my-2 mx-2 md:my-10 md:mx-16 md:flex'>
      <div className='md:shrink-0'>
        <img src={NotFound} alt='404' className='w-full md:w-[600px] ' />
      </div>
      <div className=''>
        <h1 className='text-center text-4xl mb-4'>
          404 - Page Not Found. But don't worry, we're on the case!
        </h1>
        <p className='text-lg mb-3'>
          It seems like the page you’re looking for has been moved, deleted or
          simply doesn’t exist. It's a bit like a missing sock, just when you
          need it, it's nowhere to be found.
        </p>
        <p className='text-lg mb-3'>
          While our team of highly trained digital detectives are out searching
          for the missing page, why not take a look at our UTM Grabber WordPress
          plugin? It’s the Sherlock Holmes of the digital marketing world,
          helping businesses like yours track leads and attribute them
          efficiently.
        </p>
        <p className='text-lg mb-3'>With UTM Grabber, you can: </p>
        <p className='text-lg mb-3'>
          1. Easily track the sources of your web traffic. <br></br>2. Attribute
          leads to specific marketing campaigns. <br></br>3. Measure the
          effectiveness of your marketing efforts. <br></br>4. Uncover hidden
          opportunities to grow your business.
        </p>
        <p className='text-lg mb-3'>
          There's no mystery too big or detail too small for our UTM Grabber
          plugin. It's got the brains, the tools, and the grit to help you get a
          handle on your lead tracking and attribution.
        </p>
        <p className='text-lg mb-3'>
          So, while we don't have the page you're looking for, we do have the
          solution to your marketing attribution woes.
        </p>
        <p className='text-lg mb-3'>
          If you need any assistance or have any questions, feel free to reach
          out to our support team. We're always here to help!
        </p>
        <p className='text-lg mb-3'>
          Contact Us:{' '}
          <a
            className='cursor-pointer text-blue-500'
            onClick={() => {
              window.$crisp.push(['do', 'chat:toggle']);
            }}
          >
            Open Chat
          </a>{' '}
          <br></br>Knowledge Base:{' '}
          <a
            className='cursor-pointer text-blue-500'
            href='https://docs.utmgrabber.com/'
          >
            docs.utmgrabber.com
          </a>
          <br></br>
          <Link className='cursor-pointer text-blue-500' to='/'>
            Home
          </Link>
        </p>
      </div>
    </div>
  );
}
export default withLayout(NoMatch);