import React, { Component, useEffect } from "react";
import FaqItem from "./FAQItem";

const faqs = [
  {
    q: "Why buy from you? What makes you so special?",
    a: "We are the first ever published UTM plugin for WordPress. We have 8+ years of experience in tracking and capturing UTM and parameters. You can count on us.",
  },
  {
    q: "If I'm not happy, can I get refund?",
    a: "Certainly. If you are not happy (for ANY reason) you can refund within 14 days from the date of first payment",
  },
  {
    q: "Are you GDPR compliant?",
    a: "Our plugin is full compliant with GDRP and can be used with Cookie Notice, Borlabs, Complianz plugin.",
  },
  {
    q: "Is this plugin secure?",
    a: "You can use the plugin with peace of mind. We never store any PII (personally identifiable information) and plugin never communicates any user related data to our server.",
  },
  {
    q: "I have paid ads campaigns (ppc), can I use this plugin to track which campaign my leads come from?",
    a: "Yes. You can track gclid and fbclid and associate user data with your paid campaigns very easily.",
  },
  {
    q: "Can I see what my leads search before they land on my site via Google Ads",
    a: "Yes you can append {keyword} ValueTrack parameter to your final URLs and track it within HandL UTM Grabber.",
  },
  {
    q: "How UTMs stored? Do they need to be in the URL all the time for tracking?",
    a: "UTMs are stored in COOKIES (client's browser) as soon as they are available in the URL. So that means even the UTMs are no longer in the query string, your visitors are still being tracked. Eventually, when user is converted (lead gen or sale), UTMs will be captured and associated with the user.",
  },
  {
    q: "Can I track users across subdomains?",
    a: (
      <p>
        Yes. UTM parameters and other custom variables are always recorded at
        the highest domain level. For example, if your landing page is on{" "}
        <code>example.com</code>, and users converts on{" "}
        <code>sub.example.com</code>. HandL UTM Grabber will still be able to
        capture the COOKIES from <code>example.com</code>.
      </p>
    ),
  },
  {
    q: "What is first/last touch?",
    a: (
      <p>
        HandL UTM Grabber allows capturing both first touch and last touch UTMs
        simultaneously. All the original UTMs parameters such as{" "}
        <code>utm_campaign</code> is last touch by default. Simply use{" "}
        <code>first_utm_campaign</code> if you would like to capture the first
        touch. If a user clicks on the ad and comes to the website and then
        comes back another day from Google organic, you will be able to capture
        the first UTM set belong to the ad as well as the last one belong to the
        organic.
      </p>
    ),
  },
  {
    q: "Do you provide support if I need help getting the plugin work?",
    a: "Yes, we are a message away whenever you need. We are happy to help you integrate and get you start collecting the data you need in no time.",
  },
];

const Faq = () => {
  useEffect(() => {
    const { $ } = window;
    $("#accordion").on("show.bs.collapse", function () {
      var top = $(window).scrollTop();
      $("html, body").animate(
        {
          scrollTop: top,
        },
        500
      );
    });
  }, []);
  return (
    <section className="my-[100px]" id="faq">
      <div className="container max-w-screen-xl mx-auto px-12">
        <h1 className="text-[36px] font-medium text-center mb-[50px]">
          Still Got Questions?
        </h1>
        <div className="lg:px-32 md:px-16">
          {faqs.map((item, id) => (
            <FaqItem item={item} key={id} id={id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
