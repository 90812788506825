import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import ErrorScreen from '../Error/ErrorScreen';

const Layout = ({ children }) => {
  const location = useLocation();
  const isChatRoute = location.pathname === '/chat';

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      {isChatRoute ? (
        <ErrorBoundary FallbackComponent={ErrorScreen} key={location.pathname}>
          {/* Flex-grow makes sure this area takes up the available space */}
          <div className="flex-grow overflow-hidden">
            {children}
          </div>
        </ErrorBoundary>
      ) : (
        <ErrorBoundary FallbackComponent={ErrorScreen} key={location.pathname}>
          {/* Flex-grow makes sure this area takes up the available space */}
          <div className="flex-grow overflow-y-auto">
            {children}
          </div>
        </ErrorBoundary>
      )}
      {location.pathname !== '/utm' && <Footer />}
    </div>
  );
};

// HOC to wrap the component with Layout
export const withLayout = (Component) => (props) => (
  <Layout>
    <Component {...props} />
  </Layout>
);

export default Layout;
