import React, { useState } from 'react';
import styles from "./select.module.css";

function Select({ questionIndex, val, onChange, question, options, required, multiSelect = false, showFollowUp = false, showError, error }) {
    const [otherValue, setOtherValue] = useState('');
    const [showTextarea, setShowTextarea] = useState(false);

    const handleOptionChange = (selectedOption) => {
        let newSelectedOptions;

        if (multiSelect) {
            if (val.selected.includes(selectedOption)) {
                // If already selected, remove it from the array
                newSelectedOptions = val.selected.filter(option => option !== selectedOption);
            } else {
                // Add to the selected options
                newSelectedOptions = [...val.selected, selectedOption];
            }
            if (onChange) {
                onChange({ selected: newSelectedOptions, other: val.other });
            }
        } else {
            // If single select, replace the current selection
            // Hide the textarea if it was visible and replace the value with empty string
            setShowTextarea(false);
            setOtherValue('');
            if (onChange) {
                onChange({ selected: selectedOption, other: "" });
            }
        }

    };

    const handleOtherChange = (e) => {
        setOtherValue(e.target.value);
        if (onChange) {
            onChange({ selected: val.selected, other: e.target.value });
        }
    };

    const toggleOther = (isChecked) => {
        setShowTextarea(isChecked);
        if (!isChecked) {
            // If unchecked, clear the textarea and update the parent component
            setOtherValue('');
            if (onChange) {
                onChange({ selected: val.selected, other: '' });
            }
        }
        else {
            // Check if its single select, if yes, clear the selected options
            if (!multiSelect) {
                if (onChange) {
                    onChange({ selected: null, other: '' });
                }
            }
        }
    };

    return (
        <>
            <div className="text-sm">
                <h3 className="text-neutral-800 text-lg font-medium mb-5 flex flex-col">
                    <strong className="text-neutral-400 mb-1">{questionIndex}</strong>
                    {question}
                </h3>

                <p className="text-xs mb-6">{multiSelect ? "Select all that apply" : "Select one"}</p>

                <div className="relative mb-4 text-neutral-800">
                    {options.map((option, index) => (
                        <Select.Item key={index} option={option} selected={multiSelect ? val.selected.includes(option.key) : val.selected == option.key} onChange={(key) => handleOptionChange(key)} />
                    ))}
                    {
                        showFollowUp &&
                        <Select.Item option={{ key: "other", value: "Other" }} selected={showTextarea} onChange={(key) => toggleOther(!showTextarea)} />
                    }
                    {showTextarea && (
                        <div className="text-neutral-600 text-sm">
                            <label className="text-neutral-800 inline-block mb-1">If you select other</label>
                            <textarea
                                placeholder="Please specify..."
                                className="text-zinc-600 cursor-text py-1.5 px-3 resize-y w-full h-24 border border-gray-300 border-solid rounded overflow-auto text-sm"
                                onChange={handleOtherChange}
                                value={otherValue}
                            />
                        </div>
                    )}
                    {showError && <div className="text-red-500 text-sm text-start">{error}</div>}
                </div>
            </div>
        </>
    );
}

Select.Item = ({
    selected,
    option,
    onChange
}) => {
    return <div className="relative mb-4 flex flex-row">
        <span
            className={`absolute left-0 text-neutral-800 cursor-pointer w-8 h-8 border border-gray-300 border-solid rounded ${styles["checkmark"]} ${selected ? styles["active"] : ""}`}
        />
        <label className="cursor-pointer pl-12 pt-1.5 relative min-h-[1.88rem] mb-3" onClick={() => onChange(option.key)}>{option.value}</label>
    </div>
}

export default Select;
