import React from 'react';
import V3Logo from '../../images/utm/handl-v3@1x.png'
import V3LogoW from '../../images/utm/handl-v3@1x.webp'
import V3Logo_2x from '../../images/utm/handl-v3@1x.png'
import V3LogoW_2x from '../../images/utm/handl-v3@1x.webp'

const HandLutmGrabber = () => {
    return (
        <div className='flex items-center justify-around border-y-[1px] border-gray-200 py-5 my-4 font-[poppins]'>
            <div className="flex items-center justify-center">
                <a className='w-[12rem]' target="_blank" href="https://utmgrabber.com/?utm_campaign=UTMDefinitiveGuide&utm_source=internal&utm_medium=web">
                    <picture>
                        <source srcset={`${V3LogoW} 1x, ${V3LogoW_2x} 2x`} type="image/webp" />
                        <img className='w-full h-auto' src={V3Logo} alt="utm grabber" srcset={`${V3Logo_2x} 2x`} />
                    </picture>
                </a>
            </div>
            <div className="flex flex-col leading-4 items-center">
                <h4 className='font-medium text-[1.2rem]'>Ready to get started?</h4>
                <p className="font-light text-gray-700">Never lose any UTMs ever!</p>
                <a className="block mt-2 text-center w-40 py-3 bg-[#ffc107] border border-[#ffc107] rounded hover:bg-[#e0a800]" target="_blank" href="https://utmgrabber.com/?utm_campaign=UTMDefinitiveGuide&utm_source=internal&utm_medium=web">Join Now</a>
            </div>
        </div>
    );
}

export default HandLutmGrabber;