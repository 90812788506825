import React from 'react';

export default function PaidAds({ isOpened, setCurrentOpened }) {
  return (
    <div className='mb-2'>
      <h2>
        <button
          onClick={() => setCurrentOpened('paidads')}
          type='button'
          className='flex items-center justify-between w-full py-2 px-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-md focus:ring-4 focus:ring-gray-200 bg-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <i className='fa fa-check-circle text-green-600 m-1'></i>{' '}
            Data-driven budgeting for your Paid Ads
          </span>
        </button>
      </h2>
      <div className={isOpened ? '' : 'hidden'}>
        <div className='px-5 py-2 border border-gray-200'>
          <p className='text-gray-700'>
            We support all the major advertising platforms including{' '}
            <b>Facebook Ads</b> and <b>Google Ads</b>. You can track the
            performance of your paid traffic, tagging your final URLs with
            various important tracking parameters (check here for{' '}
            <a
              href='https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/how-to-track-paid-ads'
              target='_blank'
              rel='noreferrer'
              className='text-blue-600 cursor-pointer'
            >
              some basic implementations
            </a>
            ). And you can track each and{' '}
            <b>individual parameter to the conversion</b> and associate with a
            lead using HandL UTM Grabber. Isn’t it awesome?
          </p>
        </div>
      </div>
    </div>
    // <div className="panel panel-default">
    //     <div className="panel-heading" role="tab" id="headingOne">
    //         <h4 className="panel-title">
    //             <a role="button" data-bs-toggle="collapse" data-parent="#RWF_accordion" href="#paid_ads">
    //                 <i className="fa fa-check-circle text-success"></i> Data-driven budgeting for your Paid Ads
    //             </a>
    //         </h4>
    //     </div>

    //     <div id="paid_ads" className="panel-collapse collapse">
    //         <div className="panel-body"> We support all the major advertising platforms including <b>Facebook Ads</b> and <b>Google Ads</b>. You can track the performance of your paid traffic, tagging your final URLs with various important tracking parameters (check here for <a href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/how-to-track-paid-ads" target="_blank">some basic implementations</a>). And you can track each and <b>individual parameter to the conversion</b> and associate with a lead using HandL UTM Grabber. Isn’t it awesome?
    //         </div>
    //     </div>
    // </div>
  );
}
