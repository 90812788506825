import React from "react";

const FreeFeatures = () => {
  return (
    <div>
      <ul className="nav lg:px-0 md:px-0 sm:px-4">
        <li>
          Supports limited number of WordPress form plugins (e.g. Contact Form
          7).
        </li>
        <li>Tracks only the five UTM variables (No custom parameters).</li>
        <li>No organic traffic tracking.</li>
        <li>Cookie/Tracking duration is locked to 30 days.</li>
        <li>Not compatible with EU GDPR / consent plugins.</li>
      </ul>
    </div>
  );
};

export default FreeFeatures;
