import React from 'react';

export default function Sales({ isOpened, setCurrentOpened }) {
  return (
    <div className='mb-2'>
      <h2>
        <button
          onClick={() => setCurrentOpened('sales')}
          type='button'
          className='flex items-center justify-between w-full py-2 px-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-md focus:ring-4 focus:ring-gray-200 bg-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <i className='fa fa-check-circle text-green-600 m-1'></i> Revenue
            source tracking
          </span>
        </button>
      </h2>
      <div className={isOpened ? '' : 'hidden'}>
        <div className='px-5 py-2 border border-gray-200'>
          <p className='text-gray-700'>
            Which channel generate the <b>MOST revenue</b> for your business?
            You will know when you use HandL UTM Grabber. HandL UTM Grabber
            integrates and work great with ecommerce solutions (e.g.{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/woocommerce-integration'
              target='_blank'
              rel='noreferrer'
            >
              WooCommerce
            </a>
            ). And the best thing is there is not even set up required, you will
            start collecting the data as soon as you activate the plugin.{' '}
            <a
              className='text-blue-600 cursor-pointer'
              target='_blank'
              href='https://docs.utmgrabber.com/books/woocommerce-integration/page/collect-utms-in-woocommerce-orderpage'
              rel='noreferrer'
            ></a>
            .
          </p>
        </div>
      </div>
    </div>
    // <div className='panel panel-default'>
    //   <div className='panel-heading' role='tab' id='headingOne'>
    //     <h4 className='panel-title'>
    //       <a
    //         role='button'
    //         data-bs-toggle='collapse'
    //         data-parent='#RWF_accordion'
    //         href='#sales'
    //       >
    //         <i className='fa fa-check-circle text-success'></i> Revenue source
    //         tracking
    //       </a>
    //     </h4>
    //   </div>

    //   <div id='sales' className='panel-collapse collapse'>
    //     <div className='panel-body'>
    //       Which channel generate the <b>MOST revenue</b> for your business? You
    //       will know when you use HandL UTM Grabber. HandL UTM Grabber integrates
    //       and work great with ecommerce solutions (e.g.{' '}
    //       <a
    //         href='https://docs.utmgrabber.com/books/woocommerce-integration'
    //         target='_blank'
    //       >
    //         WooCommerce
    //       </a>
    //       ). And the best thing is there is not even set up required, you will
    //       start collecting the data as soon as you activate the plugin.{' '}
    //       <a
    //         target='_blank'
    //         href='https://docs.utmgrabber.com/books/woocommerce-integration/page/collect-utms-in-woocommerce-orderpage'
    //       ></a>
    //       .
    //     </div>
    //   </div>
    // </div>
  );
}
