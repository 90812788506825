import React from 'react';

const CouponDetails = ({ coupon, price }) => {
  const getDiscountType = () => {
    switch (coupon.duration) {
      case 'forever':
        return ' for every year';
      case 'once':
        return ' for the first year';
      case 'repeating':
        if (coupon.duration_in_months % 12 === 0) {
          return ` for the first ${coupon.duration_in_months / 12} years`;
        }
        return ` for the first ${coupon.duration_in_months} months`;
      default:
        return '';
    }
  };

  const getDiscountAmount = () => {
    if (coupon.amount_off) {
      return `-$${coupon.amount_off.toFixed(2).replace(/\.00$/, '')}`;
    } else if (coupon.percent_off) {
      return `${coupon.percent_off}% off`;
    }
    return '';
  };

  return (
    <div className='flex justify-between border-b-[1px] border-gray-200'>
      <div
        className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-6 md:pl-10 lg:pl-16 text-wrap"
      >
        {`Discount${getDiscountType()}`}
      </div>
      <div className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 text-right">
        {getDiscountAmount()} {coupon.percent_off ? `(-$${((price * coupon.percent_off) / 100).toFixed(2).replace(/\.00$/, '')})` : ''}
      </div>
    </div>
  );
};

export default CouponDetails;
