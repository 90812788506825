import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreData } from '../../store/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResendVerificationMutation } from '../../features/myAccount/myAccountAPI';
import { toast } from 'react-toastify';


const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [tempCognitoUser, setTempCognitoUser] = useState(null);
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const showLoginParam = params.get('login') === "1";
  const emailParam = params.get('email');
  const [resendEmail, {
    isLoading: isConfirmLoading
  }] = useResendVerificationMutation()
  const { showLogin } = useSelector((state) => state.auth.data || {});
  const [userNotConfirmed, setUserNotConfirmed] = useState({
    userNotConfirmed: false,
    email: '',
  });
  const onEmailVerificationResend = async () => {
    try {
      await resendEmail({ email: userNotConfirmed.email }).unwrap();
      toast.success(
        `Verification email resent. Please check your inbox at ${userNotConfirmed.email} to confirm.
        `, {
        position: 'bottom-right',
        autoClose: 3000,
      });
      setUserNotConfirmed({
        userNotConfirmed: false,
        email: '',
      });
    }
    catch (error) {
      toast.error(error, {
        position: 'bottom-right',
        autoClose: 3000,
      });
    }
  }
  const validateSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required'),
    password: Yup.string().required('This field is required'),
    ...(isNewPasswordRequired && tempCognitoUser) && {
      // if new password is required
      newPassword: Yup.string()
        .required('This field is required')
        .min(8, 'Password must be 8 or more characters'),
      confirmNewPassword: Yup.string().when('newPassword', (password, field) => {
        if (password) {
          return field
            .required('The passwords do not match')
            .oneOf([Yup.ref('newPassword')], 'The passwords do not match');
        }
      }),
    }
  });

  const onForgotPassword = (event) => {
    event.preventDefault();
    dispatch(
      setStoreData({
        data: {
          showForgotPassword: true,
          showLogin: false,
        },
      })
    );
  };

  const onSignup = (event) => {
    event.preventDefault();
    dispatch(
      setStoreData({
        data: {
          showSignup: true,
          showLogin: false,
        },
      })
    );
  };

  return (
    (showLogin) && (
      <>
        <div
          id='login'
          className='handl-modal pt-[40px] lg:w-[700px] md:w-[500px] w-[400px] fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[1000]'
        >
          <div className='px-5 md:px-8 2xl:px-20'>
            <Formik
              initialValues={{
                email: emailParam || '',
                password: '',
                newPassword: '',
              }}
              validationSchema={validateSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                let user;
                try {
                  if (isNewPasswordRequired && tempCognitoUser) {
                    // user should set a new password and then login
                    await Auth.completeNewPassword(
                      tempCognitoUser,
                      values.newPassword,
                      {}
                    );

                    user = await Auth.signIn(
                      values.email,
                      values.newPassword
                    )
                  }
                  else {
                    user = await Auth.signIn(values.email, values.password);
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                      setTempCognitoUser(user);
                      setIsNewPasswordRequired(true);
                      setLoading(false);
                    }
                  }


                  if (user.signInUserSession !== null) {
                    dispatch(
                      setStoreData({
                        data: {
                          isAuthenticated: true,
                          user,
                          tmpUser: {
                            first_name: user.attributes.given_name,
                            email: user.attributes.email,
                          },
                        },
                      })
                    );

                    const { $crisp, gtag } = window;
                    gtag?.('event', 'login', {
                      email: values.email,
                    });
                    
                    $crisp?.push([
                        'set',
                        'user:nickname',
                        [user.attributes.given_name],
                    ]);
                    $crisp?.push(['set', 'user:email', [user.attributes.email]]);

                    dispatch(
                      setStoreData({
                        data: {
                          showLogin: false,
                        },
                      })
                    );
                  }
                } catch (error) {
                  let msg;
                  if (error.code === 'UserNotConfirmedException') {
                    msg = "User is not confirmed. Please go to your mail box and verify your email address.";
                    setUserNotConfirmed({
                      userNotConfirmed: true,
                      email: values.email,
                    });
                  } else {
                    msg = error.message;
                  }
                  setErrMsg(msg);
                  setLoading(false);
                }
                setLoading(false);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  {
                    (isNewPasswordRequired && tempCognitoUser) ? (<>
                      <div className='text-center mb-[10px] font-[poppins]'>
                        Please set a new password for your account
                      </div>
                      <div className='form-group'>
                        <Field
                          type='password'
                          name='newPassword'
                          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                          placeholder='Enter your new password'
                          autoComplete='new-password'
                        />
                        {touched.newPassword && errors.newPassword && (
                          <div className='text-red-400 mt-2'>{errors.newPassword}</div>
                        )}
                      </div>
                      <div className='form-group'>
                        <Field
                          type='password'
                          name='confirmNewPassword'
                          autoComplete='new-password'
                          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                          placeholder='Confirm your new password'
                        />
                        {touched.confirmNewPassword && errors.confirmNewPassword && (
                          <div className='text-red-400 mt-2'>
                            {errors.confirmNewPassword}
                          </div>
                        )}
                      </div>

                    </>) : <>
                      <div className='form-group'>
                        <Field
                          type='email'
                          name='email'
                          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                          placeholder='Email address'
                        />
                        {touched.email && errors.email && (
                          <div className='text-red-400 mt-2'>{errors.email}</div>
                        )}
                      </div>
                      <div className='form-group'>
                        <Field
                          type='password'
                          name='password'
                          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                          placeholder='Password'
                        />
                        {touched.password && errors.password && (
                          <div className='text-red-400 mt-2'>{errors.password}</div>
                        )}
                      </div>
                    </>
                  }
                  <div className='form-group'>
                    <div className='text-right'>
                      <p
                        className='text-[#0084ff] cursor-pointer'
                        onClick={onForgotPassword}
                      >
                        Forgot my password
                      </p>
                    </div>
                    <div className='text-right'>
                      <p
                        className='text-[#0084ff] cursor-pointer'
                        onClick={onSignup}
                      >
                        You don't have account yet? Signup here.
                      </p>
                    </div>
                  </div>
                  {errMsg && errMsg !== '' && (
                    <p className='text-red-400 mt-2'>{errMsg}</p>
                  )}
                  {
                    userNotConfirmed.userNotConfirmed &&
                    <p className='flex flex-row items-center'>
                      Haven't received the email?
                      <p
                        className='ml-2 text-[#0084ff] cursor-pointer'
                        onClick={onEmailVerificationResend}
                      >
                        Resend
                      </p>
                      {
                        isConfirmLoading &&
                        <i className="ml-1 fa fa-spin fa-spinner" />
                      }
                    </p>
                  }

                  <div className='form-group'>
                    <button
                      type='submit'
                      className='btn btn-default btn-block btn-sm bg-[#0084ff] w-full'
                    >
                      {!loading ? (
                        <span>{
                          (isNewPasswordRequired && tempCognitoUser) ? 'Continue' : 'Login'  // if new password is required
                        }</span>
                      ) : (
                        <i className='fa fa-spin fa-spinner' />
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className='absolute w-[100%] h-[100%] bg-black/50 top-[-100px] left-0 z-[999]'
          onClick={() => {
            dispatch(
              setStoreData({
                data: {
                  showLogin: false,
                },
              })
            );
            if (showLoginParam) {
              navigate('/', { replace: true })
            }
          }
          }
        ></div>
      </>
    )
  );
};

export default Login;
