import { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatAPI, useCreateGptSessionMutation, useLazyGetSessionQuery } from "../../features/chat/chatAPI";
import { openNewChatSession, setCurrentChatSession } from "../../features/chat/chatSlice";

const useChatHook = ({ chatContainerRef }) => {
    const dispatch = useDispatch();
    const [chatLogs, setChatLogs] = useState([
        { role: "system", content: "Hello, how can I assist you." },
    ]);
    const { user } = useSelector(
        (state) => state.auth.data || {}
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [createSession] = useCreateGptSessionMutation()
    const { currentSession: { sessionId, isNewSession } } = useSelector((state) => state.chat)
    // Ref to store the abort controller for the ongoing stream
    const abortControllerRef = useRef(null);
    useEffect(() => {
        return () => {
            // Clean up on unmount
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [sessionId]);
    const postChatRequest = useCallback(async (chatLog) => {
        const cognitoUserSession = user.getSignInUserSession();
        try {
            let session = sessionId;
            let newSession = undefined;
            if (!session) {
                // No chat session found, create a new one
                const response = await createSession({
                    question: chatLog[chatLog.length - 1].content
                }).unwrap()
                // A new session was created
                newSession = response;
                session = response.session_id;
                // session = '1hjmoi4h10e63ee79f9fb98'

            }
            const response = await fetch(process.env.REACT_APP_ENV_MODE === "prod" ? "https://gpt-prod.utmgrabber.com/" : "https://gpt-dev.utmgrabber.com", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${cognitoUserSession.getAccessToken().getJwtToken()}`,
                },
                body: JSON.stringify({
                    session,
                    chatLog
                }),
            });
            // Throw if the response is not ok
            if (!response.ok) {
                let errorMessage = "Error in sending the chat request";
                // Try and parse the response body as JSON
                try {
                    const errorBody = await response.json();
                    if (errorBody && errorBody.message) {
                        errorMessage = errorBody.message;
                    }
                } catch (error) {
                }
                // If the response body could not be parsed as JSON, throw a generic error (Could a 500 from aws)
                throw new Error(errorMessage)
            }
            else {

                return {
                    response,
                    chatSessionId: session,
                    newSession
                };
            }

        } catch (error) {
            console.error("Error in sending the chat request:", error);
            throw error;
        }
    }, [sessionId, createSession]);
    const [getSession] = useLazyGetSessionQuery();
    const loadChatLogsFromCurrentSession = async (currentSession) => {
        // Abort any ongoing stream reading before starting a new one
        if (abortControllerRef.current) {

            abortControllerRef.current.abort();
        }
        try {
            const session = await getSession(currentSession).unwrap();
            if (session) {
                // When the user switches the active session reset the chat logs and load the messages from the new session
                setError("")
                setLoading(false)
                setChatLogs(session.messages)
                // Scroll to the bottom of the chat, but wait till the messages are rendered
                setTimeout(() => {
                    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                }, 0)

            }
        }
        catch (error) {
            setError(error.message || "An error occurred, please try again")
            setChatLogs([])
            setLoading(false)
        }
    }
    useEffect(() => {
        if (sessionId && !isNewSession) {
            loadChatLogsFromCurrentSession(sessionId)
        }
        else if (!sessionId) {
            setError("")
            setLoading(false)
            setChatLogs([
                { role: "system", content: "Hello, how can I assist you." },
            ])
        }
    }, [sessionId,])
    const readStream = useCallback(async (reader, chatLog, abortSignal) => {
        const decoder = new TextDecoder();
        let content = "";
        let streamingChat = { chatLog: chatLog };
        // Function to handle aborting
        const handleAbort = () => {
            if (abortSignal.aborted) {
                reader.cancel(); // Cancel the reading process
                return true; // Signal that the reading was aborted
            }
            return false; // Reading not aborted
        };
        if (handleAbort()) return;
        while (true) {
            if (handleAbort()) break;
            const { value, done } = await reader.read();
            if (done) break;

            const decodedText = decoder.decode(value);
            const jsonRegex = /{[^{}]*}/g; // Regular expression to match JSON objects
            let match;

            while ((match = jsonRegex.exec(decodedText)) !== null) {
                if (handleAbort()) break;
                try {
                    const json = JSON.parse(match[0]);
                    content += json.text;
                    streamingChat = {
                        chatLog: [
                            ...chatLog,
                            { role: "assistant", content: content, id: json.id },
                        ],
                    };
                    setChatLogs(streamingChat.chatLog);
                } catch (e) {
                    console.error(e)
                    console.error("Error in parsing the response:", e);
                }
            }
        }
    }, [setChatLogs]);


    const handleSubmit = useCallback(async (text) => {
        // Abort any ongoing stream reading before starting a new one
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        setLoading(true);
        setError("")
        const chatRequest = {
            chatLog: [...chatLogs, { role: "user", content: text }],
        };
        setChatLogs(chatRequest.chatLog);

        try {
            const { response, chatSessionId, newSession } = await postChatRequest(chatRequest.chatLog.map((chat) => ({
                ...chat,
                id: undefined, // Remove the id from the chat request
                rating: undefined, // Remove the rating from the chat request
                rating_message: undefined // Remove the rating_message from the chat request
            })));

            const streamResponse = response.body;
            if (!streamResponse) {
                throw new Error("No response body found");
            }
            const reader = streamResponse.getReader();
            await readStream(reader, chatRequest.chatLog, abortControllerRef.current.signal);
            // If a new session was created, update the current session and update the session query cache
            if (newSession) {
                dispatch(setCurrentChatSession({
                    sessionId: chatSessionId,
                    isNewSession: true
                }))
                dispatch(
                    chatAPI.util.updateQueryData('getAllSessions', { from: 0, size: 50 }, (draft) => {
                        Object.assign(draft, [newSession, ...draft])
                    })
                )
            }
        } catch (error) {
            console.error("Error in handling the response:", error)
            setError(error.message || "An error occurred, please try again")
        } finally {
            setLoading(false);
        }
    }, [chatLogs, postChatRequest, readStream, setLoading]);

    return {
        currentSession: sessionId,
        chatLogs,
        loading,
        handleSubmit,
        error,
    };
};

export default useChatHook;
