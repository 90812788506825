import React from 'react';
import Split1 from '../images/logo-v3.png';
import Split2 from '../images/logo-free-orange.png';
import Split1W from '../images/logo-v3.webp';
import Split2W from '../images/logo-free-orange.webp';
import { ImgW } from '../js';

import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from '../store/auth';
import { useNavigate } from 'react-router-dom';

function SplitColumns() {
  const { prices, isAuthenticated } = useSelector(
    (state) => state.auth.data || {}
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFreeClick = (event) => {
    event.preventDefault();
    if (!isAuthenticated) {
      dispatch(
        setStoreData({
          data: {
            showLogin: true,
          },
        })
      );
      return;
    }
    dispatch(
      setStoreData({
        data: {
          flowSelected: 'free',
          showPayment: true,
        },
      })
    );
  };

  const onPaidClick = async (event) => {
    if (!isAuthenticated) {
      dispatch(
        setStoreData({
          data: {
            showLogin: true,
          },
        })
      );
      return;
    }
    event.preventDefault();
    dispatch(
      setStoreData({
        data: {
          flowSelected: 'paid',
          showPayment: true,
          selected_plan: 'starter',
        },
      })
    );

    const tmpSelectedPlan = 'starter';
    const tmpPrices = { ...prices };
    let price = tmpPrices[tmpSelectedPlan];
    let payload = {
      selected_plan: tmpSelectedPlan,
      product_price: price['product_price'],
      original_price: price['original_price'],
      paypal_plan_id: price['paypal'],
      stripe_plan_id: price['stripe'],
      msrp: price['msrp'],
      membership: price['membership'],
      title: price['title'],
      subtitle: price['subtitle'],
    };
    dispatch(
      setStoreData({
        data: {
          ...payload,
        },
      })
    );
    navigate('/payment');
  };

  const onFreeDownload = (event) => {
    event.preventDefault();
    window.location =
      'https://downloads.wordpress.org/plugin/handl-utm-grabber.latest-stable.zip';
  };
  return (
    <section className='split-columns grey-section' id='split-columns'>
      <div className='container max-w-screen-xl mx-auto px-12'>
        <div className='flex md:flex-row flex-col'>
          <div className='md:basis-1/2 p-[48px] flex justify-center'>
            <ImgW src={Split2W} fallback={Split2} alt='' />
          </div>
          <div className='md:basis-1/2 pl-[37px]'>
            <div className='texts-inner row m0 text-center'>
              <h2 className='text-[35px] font-bold mt-[42px] mb-[20px]'>
                Lots to Track <br /> Free to Download
              </h2>
              <p>
                {!isAuthenticated && (
                  <button
                    className='btn btn-primary btn-sm wow fadeIn py-3 cursor-pointer'
                    onClick={onFreeClick}
                  >
                    DOWNLOAD
                  </button>
                )}

                {isAuthenticated && (
                  <button
                    className='btn btn-primary btn-sm wow fadeIn py-3 cursor-pointer'
                    onClick={onFreeDownload}
                  >
                    DOWNLOAD
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className='flex md:flex-row flex-col'>
          <div className='md:basis-1/2 pl-[37px]'>
            <div className='text-center'>
              <h2 className='text-[35px] font-bold mt-[42px] mb-[20px]'>
                Create your own custom
                <br /> field to capture & track
              </h2>
              <p>
                <button
                  className='btn btn-primary btn-sm wow fadeIn py-2 cursor-pointer'
                  onClick={onPaidClick}
                >
                  DOWNLOAD
                </button>
              </p>
            </div>
          </div>
          <div className='md:basis-1/2 p-[48px] image text-center flex justify-center'>
            <ImgW src={Split1W} fallback={Split1} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SplitColumns;
