import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


function Detail({ formik, questionIndex }) {
    const { user } = useSelector(state => state.auth.data || {});

    useEffect(() => {
        formik.setFieldValue('firstName', '');
        formik.setFieldValue('email', '');
        formik.setFieldValue('website', '');
        if (user?.attributes?.email) {
            formik.setFieldValue('email', user.attributes.email);
        }
    }, [user?.attributes?.email]);
    // Set initial values if user is logged in


    return (
        <div className="text-sm">
            <h3 className="text-neutral-800 text-lg font-medium mb-5 flex flex-col">
                <strong className="text-neutral-400 mb-1">{questionIndex}</strong>
                Please fill with your details
            </h3>
            <div>
                <div className="mb-4 text-zinc-600 font-normal">
                    <input
                        className="cursor-text py-1.5 px-3 w-full h-11 border border-gray-300 border-solid rounded overflow-visible"
                        placeholder="First Name"
                        type="text"
                        {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="text-red-500 text-xs mt-1">{formik.errors.firstName}</div>
                    ) : null}
                </div>

                <div className="mb-4 text-zinc-600 font-normal">
                    <input
                        className="cursor-text py-1.5 px-3 w-full h-11 border border-gray-300 border-solid rounded overflow-visible"
                        placeholder="Your Email"
                        type="email"
                        {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="text-red-500 text-xs mt-1">{formik.errors.email}</div>
                    ) : null}
                </div>

                <div className="mb-4 text-zinc-600 font-normal">
                    <input
                        className="cursor-text py-1.5 px-3 w-full h-11 border border-gray-300 border-solid rounded overflow-visible"
                        placeholder="Your Website"
                        type="text"
                        {...formik.getFieldProps('website')}
                    />
                    {formik.touched.website && formik.errors.website ? (
                        <div className="text-red-500 text-xs mt-1">{formik.errors.website}</div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default Detail;
