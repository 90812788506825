import React from 'react';
import ReviewCarousel from './ReviewCarousel';
import sadEmoji from '../../images/lottie/sad_emoji.json';
import { useLottie } from 'lottie-react';
import FeaturesList from './FeaturesList';
import { UpgradeButton } from './OneTimeOffer';

const OfferExpired = (props) => {

    const { View: LottieView } = useLottie({
        animationData: sadEmoji,
        loop: true,
        autoplay: true,
        className: "w-[150px] md:w-[200px] min-[2000px]:w-[250px] mx-auto"
    });

    return (
        <div className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3 font-[poppins]">
            <div>{LottieView}</div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 lg:max-w-screen-2xl mx-auto">
                <h2 className="font-semibold text-[#374048] text-2xl md:text-4xl min-[2000px]:text-5xl">
                    Oops! Offer Expired! <br /> Unfortunately, the {props.expiredDiscount} Off {props.name} Offer has Ended.
                </h2>
                <p className="text-lg md:text-2xl font-medium">
                    Don't worry, you can still unlock all Premium Features and take your WordPress and marketing efforts to the next level!
                </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
                <div className="text-left">
                    <p className="text-base min-[2000px]:text-lg">
                        We hope you’ve enjoyed exploring our platform! While the exclusive {props.expiredDiscount} discount on our {props.name} has expired, you can still upgrade and enjoy all the benefits.
                    </p>
                </div>
                <div className="text-left pt-2">
                    <h3 className="mb-2.5 text-xl md:text-2xl font-medium">Here’s what you can get with Premium:</h3>
                    <FeaturesList />
                </div>
                <div className="text-left pt-2">
                    <h3 className="mb-2.5 text-xl md:text-2xl font-medium">Upgrade to Premium:</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        Although the special discount has ended, you can still upgrade to Premium and unlock all these amazing features with a {props.newDiscount} discount!
                    </p>
                    <p className="text-base min-[2000px]:text-lg mt-1.5">
                        Take your experience to the next level and enhance your marketing efforts today!
                    </p>
                    <UpgradeButton coupon={props.newCoupon} label={`Upgrade Now and Save ${props.newDiscount}`} />
                    <p className="text-base min-[2000px]:text-lg mt-1.5">
                        Still unsure? Here’s what our existing premium members are saying:
                    </p>
                    <ReviewCarousel />
                    <p className="text-base min-[2000px]:text-lg mt-2">
                        Don't miss out on these incredible features. Upgrade today!
                    </p>
                    <UpgradeButton coupon={props.newCoupon} label={`Upgrade Now and Save ${props.newDiscount}`} />
                </div>
                <hr />
            </div>
            <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-5 md:max-w-[1080px] mx-auto py-5">
                <p className="text-xl md:text-2xl min-[2000px]:text-3xl font-medium">
                    Thank you for trusting us with your lead tracking and attribution needs!
                </p>
                <p className="text-base min-[2000px]:text-lg">
                    We're excited for you to start capturing UTM parameters effortlessly with UTM Grabber.
                </p>
            </div>
        </div>
    );
};

export default OfferExpired;
