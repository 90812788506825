import React, { useEffect, useState } from 'react';
import PerksList from './PerksList';
import CtaButton from '../CtaButton';
import MONEYBACK from '../../../images/14daymoneyback.png';
import MONEYBACKW from '../../../images/14daymoneyback.webp';
import CountdownTimer from './CountdownTimer';
function Index({
    title,
    subtitle,
    perksItems,
    oldPrice,
    price,
    buttonText,
    offerEndsText,
    definedTimeStamp,
}) {


    return (
        <section className='relative py-16 overflow-hidden bg-cover bg-pattern-wave'>
            <div className='absolute inset-0 bg-landingAccent2-950/80 mix-blend-darken'></div>
            <div className='2xl:max-w-[87.5rem] px-4 mx-auto relative text-center'>
                <h2 className='mx-auto text-white text-4xl font-semibold mb-10'>{title}</h2>
                <div className='flex justify-center'>
                    <div className='bg-white p-6 lg:p-12 rounded-md shadow-sm text-center'>
                        <h3 className='text-slate-800 text-2xl 2xl:text-4xl font-bold mb-4'>
                            {subtitle}
                        </h3>
                        <div className='text-left md:w-min mx-auto'>
                            <PerksList items={perksItems} />
                        </div>
                        <h2 className='text-slate-800 text-2xl 2xl:text-4xl font-semibold mt-5 text-center mb-4'>
                            <span className='relative text-red-500'>{oldPrice}
                            <span class="absolute left-0 top-1/2 right-0 border-t-[3px] z-50 border-current transform rotate-[-7deg]"></span>
                                </span>{' '}
                            Now Only <span className='font-bold text-green-500'>{price}</span>
                        </h2>
                        <div className='flex flex-col gap-5 items-center'>
                            <CtaButton className={'px-6 uppercase'}>
                                {buttonText}
                            </CtaButton>
                            <div className='flex flex-col md:flex-row gap-4 items-center max-w-screen-md'>
                                <div className='shrink-0'>
                                    <picture>
                                        <source srcSet={MONEYBACKW} />
                                        <img className="h-28 md:h-32" src={MONEYBACK} alt={"money-back-guarantee"} />
                                    </picture>
                                </div>
                                <div className='text-left'>
                                    <h2 className='text-xl lg:text-2xl font-medium mb-2'>
                                        Risk-Free 14 Days Money Back Guarantee
                                    </h2>
                                    <p className='font-normal text-slate-800'>
                                        In the highly unlikely event, you don't feel HandL UTM Grabber
                                        lives up to every claim on this page, just &nbsp;
                                        <button
                                            className='pointer text-[#1972f5]'
                                            onClick={() => {
                                                window.$crisp.push(['do', 'chat:toggle']);
                                            }}
                                        >
                                            let us know
                                        </button>
                                        . We'll be happy to work with you to find solution. If you are
                                        still unsatisfied, we'll{' '}
                                        <span className='font-bold'>
                                            return 100% of your investment
                                        </span>
                                        .
                                    </p>

                                </div>
                            </div>
                            <div>
                                <p className='uppercase font-semibold text-xl xl:text-2xl 2xl:text-3xl mb-2'>{offerEndsText}</p>
                                {/* <p className='text-3xl 2xl:text-4xl uppercase font-bold'>
                                    <span className='text-red-500'>{formatTime(timeLeft)}</span>
                                </p> */}
                                <CountdownTimer targetDate={definedTimeStamp}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Index;
