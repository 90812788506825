import React, { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import { LandingPageContext } from './LandingPageContext';

function CtaButton({ children, className }) {
    const {  handleCTAClick } = useContext(LandingPageContext);
    return (
        <div className='flex flex-col items-center gap-4'>

            <button onClick={handleCTAClick} type="button" className={twMerge("shadow-lg text-white font-bold inline-block px-3 py-4 md:py-4 md:px-8 text-center border rounded-md border-transparent text-2xl 2xl:text-3xl transition-all duration-200 ease-linear bg-landingAccent500 hover:bg-landingAccent500/80", className)}>
                {children}
            </button>

        </div>
    )
}

export default CtaButton