import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import ReactPlayer from 'react-player';
import CtaButton from '../CtaButton';
import Modal from '../../Modal/Modal';
import free_badge from '../../../images/lottie/free_badge.json';
import Lottie from 'lottie-react';
import { useLottie } from 'lottie-react';
const items = [
    {
        name: 'HandL GCLID Reporter',
        description: (
            <>
                Track Google Click Identifiers (GCLID) throughout conversions.
                <br />
                Generate daily reports associating GCLIDs with campaigns, ad groups,
                locations, and keywords.
            </>
        ),
        value: 199,
        video: 'https://www.youtube.com/watch?v=Zpo527S4Okg',
    },
    {
        name: 'AI-Powered Report Insight',
        description: (
            <>
                Experience AI-driven reporting insights directly within WordPress.
                <br />
                Understand your marketing strategies better with pinpointed successes
                and actionable improvement suggestions.
            </>
        ),
        value: 300,
        video: 'https://www.youtube.com/watch?v=87yw-Xg_cok',
    },
    {
        name: 'Facebook Conversion API (FB CAPI)',
        description:
            'Pass all conversion data back to Facebook to supercharge their algorithm and improve ad performance.',
        value: 200,
        video: 'https://www.youtube.com/watch?v=XBM30e4FG2k',
    },
    {
        name: 'Define Your Own Custom Logic',
        description: (
            <>
                Create custom logic for tracking and manipulating UTM parameters and
                other fields.
                <br />
                Define conditional rules to create new parameters based on existing
                ones.
            </>
        ),
        value: 100,
        video: 'https://www.youtube.com/watch?v=-xs5Qs1jXWc',
    },
    {
        name: 'Enable Full Tracking Mode',
        description:
            'Capture all data from every page load, similar to Google Analytics 4 capabilities.',
        value: 100,
        video: 'https://www.youtube.com/watch?v=xIwoTZJJxko',
    },
];

function Index() {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    // Handle video click
    const handleVideoClick = (video) => {
        setSelectedVideo(video);
        setShowModal(true);
    };

    // Handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const getYouTubeVideoID = (url) => {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|watch\?.+&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };
    const stylesArray = [
        'border-b md:border-r border-slate-300', // i1
        'border-b lg:border-r border-slate-300', // i2
        'border-b md:border-r lg:border-r-0 border-slate-300', // i3
        'border-b lg:border-b-0 lg:border-r border-slate-300', // i4
        'border-b md:border-b-0 md:border-r border-slate-300', // i5
    ];

    return (
        <section className="relative py-20 xl:py-32 bg-gradient-to-b from-landingAccent2-100 to-transparent">
            <div className="container 2xl:max-w-[87.5rem] px-4 mx-auto">
                <div className="mx-auto mb-10 sm:mb-20 text-center xl:max-w-3xl">
                    <h1 className="mb-0 leading-normal capitalize text-slate-800 text-3xl sm:text-4xl font-semibold">
                        Plus, Get These Exclusive{' '}
                        <span className="relative inline-block before:absolute before:h-5 before:w-full before:bg-landingAccent2-200 before:bottom-2 before:-skew-y-6 text-landingAccent2-700">
                            <span className="relative">Bonuses</span>
                        </span>{' '}
                        When You Join Today!
                    </h1>
                </div>

                <div className="mt-5 sm:mt-16 grid items-start grid-cols-1 gap-0 md:grid-cols-2 lg:grid-cols-3">
                    {items.map((_, i) => {
                        const videoID = getYouTubeVideoID(_.video);
                        const thumbnailUrl = videoID
                            ? `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`
                            : 'https://via.placeholder.com/320x180?text=No+Thumbnail';
                        return (
                            <BonusItem
                                key={i}
                                {..._}
                                className={stylesArray[i]}
                                thumbnail={thumbnailUrl}
                                onClick={() => handleVideoClick(_.video)}
                            />
                        );
                    })}
                </div>
                <div className="mt-16 text-center">
                    <h2 className="text-2xl text-slate-800 font-semibold mb-4">
                        Total Bonus Value:{' '}
                        <span className="relative text-red-500">$899
                            <span class="absolute left-0 top-1/2 right-0 border-t-2 z-50 border-current transform rotate-[-5deg]"></span>
                        </span>
                        <span className="text-green-500 block italic">
                            (Included for free in the bundle)
                        </span>
                    </h2>
                    <CtaButton>
                        <span className="uppercase">Unlock These Bonuses Today</span>
                        <span className="mt-1 block text-lg lg:text-xl 2xl:text-2xl">
                            Limited Time Only!
                        </span>
                    </CtaButton>
                </div>
            </div>

            <Modal
                isOpen={showModal}
                className={'!bg-transparent !shadow-none'}
                onClose={handleCloseModal}
                onAfterClosed={() => setSelectedVideo(null)}
            >
                <div className="w-[90vw] 2xl:w-[70vw] flex flex-col">
                    <button
                        onClick={handleCloseModal}
                        type="button"
                        className="mb-2.5 self-end"
                    >
                        <svg
                            className="text-white ml-auto w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                        </svg>
                    </button>
                    <div className="relative w-full aspect-video max-h-[calc(95vh-35px)]">
                        <ReactPlayer
                            url={selectedVideo}
                            playing
                            controls={true}
                            width={'100%'}
                            height={'100%'}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </section>
    );
}

const BonusItem = ({
    name,
    description,
    value,
    thumbnail,
    onClick,
    className,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const badgeRef = useRef(null);
    const { View: Badge, goToAndPlay } = useLottie({
        animationData: free_badge,
        loop: false,
        className: "w-20 h-20"
    })
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.5, // Trigger when 50% of the element is visible
            }
        );

        if (badgeRef.current) {
            observer.observe(badgeRef.current);
        }

        return () => {
            if (badgeRef.current) {
                observer.unobserve(badgeRef.current);
            }
        };
    }, []);
    useEffect(() => {
        if (isVisible) {
            goToAndPlay(0, true);
        }
    }, [isVisible]);
    return (
        <div
            className={twMerge(
                'flex flex-col gap-4 p-9 w-full flex-grow h-full',
                className
            )}
            ref={badgeRef}
        >
            <div className="relative group cursor-pointer" onClick={onClick}>
                <div className="z-50 absolute inset-0 flex items-center justify-center transition-all">
                    <button
                        className="bg-landingAccent2-500 group-hover:!bg-white drop-shadow-lg rounded-md px-5 py-2"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-white group-hover:text-landingAccent2-500" viewBox="0 0 24 24" fill="currentColor"><path d="M19.376 12.4161L8.77735 19.4818C8.54759 19.635 8.23715 19.5729 8.08397 19.3432C8.02922 19.261 8 19.1645 8 19.0658V4.93433C8 4.65818 8.22386 4.43433 8.5 4.43433C8.59871 4.43433 8.69522 4.46355 8.77735 4.5183L19.376 11.584C19.6057 11.7372 19.6678 12.0477 19.5146 12.2774C19.478 12.3323 19.4309 12.3795 19.376 12.4161Z"></path></svg>
                    </button>
                </div>
                <div className="absolute top-[-15%] right-[-10%] z-50">
                    {Badge}
                </div>
                <img
                    src={thumbnail}
                    alt="Placeholder"
                    className="w-full h-auto rounded"
                />
            </div>
            <div className=" flex flex-col">
                <h5 className="mb-3 text-3xl text-slate-800 font-semibold">{name}</h5>
                <p className=" text-xl/normal min-[2000px]:text-2xl/normal mb-3 text-slate-500">
                    {description}
                </p>
                <p className="text-3xl font-medium text-red-500">Value: <span className='relative'>${value}<span class="absolute left-0 top-1/2 right-0 border-t-[3px] z-50 border-current transform rotate-[-7deg]"></span></span> <span className='italic text-green-500'>(FREE)</span></p>
            </div>
        </div>
    );
};
export default Index;
