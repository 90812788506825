import React, { useEffect, useRef, useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import ReactPlayer from 'react-player'
import click from '../../../images/lottie/click.json'
import parameter from '../../../images/lottie/parameter.json'
import path from '../../../images/lottie/path.json'
import network from '../../../images/lottie/network.json'
import privacy from '../../../images/lottie/privacy.json'
import cloud_storage from '../../../images/lottie/cloud_storage.json'
import handshake from '../../../images/lottie/handshake.json'
import privacy_icon from '../../../images/landing_pages/features/privacy.svg';
import handshake_icon from '../../../images/landing_pages/features/handshake.svg';
import Lottie from 'lottie-react';
import useWindowWidth from '../../../hooks/global/useWindowWidth'

const items = [
    {
        title: "One-Click Integration",
        description: "Add over 20+ tracking parameters to all your Gravity Forms with just one click.",
        icon: click,
        video: "https://www.youtube.com/watch?v=fQ9QTAvbt-s",
    },
    {
        title: "Custom Parameter Tracking",
        description: "Bring your own custom parameters (affiliate IDs, partner tracking, etc.) and track them just like UTMs.",
        icon: parameter,
        video: "https://www.youtube.com/watch?v=Rk903KrVon0",
    },
    {
        title: "First Touch and Last Touch Attribution",
        description: "Capture both first and last touch UTMs for accurate lead attribution.",
        icon: path,
        video: "https://www.youtube.com/watch?v=uKYE-MO1e1g",
    },
    {
        title: "Page-to-Page Tracking",
        description: "Never lose data as users navigate your site—track every step effortlessly.",
        icon: network,
        video: "https://www.youtube.com/watch?v=9ptlEbpe6RY",
    },
    {
        title: "Unlimited Data Storage in WordPress",
        description: "Collect and store all your lead data directly in WordPress without submission limits.",
        icon: cloud_storage,
        video: "https://www.youtube.com/watch?v=sXMGd5SIMqg"
    },
    {
        title: "Seamless CRM Integration",
        description: "Pass data to your favorite CRMs like Salesforce, GoHighLevel, HubSpot, Infusionsoft, Zoho, and more.",
        icon: handshake,
        content: {
            title: "Seamless CRM Integration",
            subtitle: "Easily connect your WordPress site to leading CRM platforms like Salesforce, GoHighLevel, HubSpot, Infusionsoft, Zoho, and more. Our seamless integration feature ensures that your valuable lead data flows directly from WordPress to your CRM, keeping your sales and marketing teams aligned and proactive. No more manual exports or data syncing issues — just a streamlined process that enables you to act on leads faster and more effectively, driving better engagement and sales outcomes.",
            icon: handshake_icon
        }
    },
    {
        title: "Privacy-Aware Data Collection",
        description: "Fully GDPR and CCPA compliant to ensure user data privacy.",
        icon: privacy,
        content: {
            title: "Privacy-Aware Data Collection",
            subtitle: "Safeguard user data and build trust with GDPR and CCPA-compliant data collection. Our solution ensures that all lead data gathered in WordPress respects privacy regulations, providing you with a secure, compliant framework to protect user privacy effortlessly.",
            icon: privacy_icon
        }
    },


]
function Index() {
    const windowWidth = useWindowWidth();
    return <section class="relative py-16 overflow-hidden">
        <div class="2xl:max-w-[87.5rem] px-4 mx-auto relative">
            <h2 class="mx-auto text-4xl 2xl:text-5xl font-semibold text-center mb-16 text-slate-800">Unlock the Full Potential of Gravity Forms with HandL UTM Grabber</h2>
            {
                windowWidth > 1024 ? <FeatureTabs /> : <FeatureAccordions />
            }
        </div>
    </section>

}
function FeatureAccordions() {
    const [activePanel, setActivePanel] = useState(0);
    return <div className={"grid grid-cols-1 lg:grid-cols-12"}>
        <div className={"col-span-5 grid grid-rows-1 gap-2"}>
            {
                items.map((item, i) => (<div key={item.title} defaultOpen={i === 0} as="div" className="" >
                    <AccordionButton

                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        onClick={() => {
                            if (activePanel === i) {
                                setActivePanel(-1)
                            }
                            else {
                                setActivePanel(i);
                            }
                        }}
                        active={activePanel === i}
                    />
                    {
                        activePanel === i &&
                        <div className={"col-span-7 mt-5 lg:mt-0 lg:ml-[6%] w-full h-full "}>

                            {
                                item.video ?
                                    <div className='relative aspect-video max-h-[calc(90vh)]'>
                                        <ReactPlayer url={item.video} controls={true} playing={true} width={"100%"} height={"100%"} style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                        }}
                                        />

                                    </div> :
                                    <div className='rounded-[10px] border-2 border-[#666666] py-12 px-7 lg:px-20 lg:py-20 flex flex-col gap-6 text-left' >
                                        <h2 className='flex flex-col items-start lg:flex-row lg:items-center'>
                                            <img className="h-[60px] align-middle mb-2" alt={item.content.title} src={item.content.icon} />
                                            <span className='align-middle text-[28px] lg:text-[30px] font-semibold text-slate-800'>{item.content.title}</span>
                                        </h2>
                                        <p className='text-lg/7 lg:text-lg/8 font-normal text-slate-700'>{item.content.subtitle}</p>
                                    </div>
                            }
                        </div>
                    }

                </div>))
            }
        </div>
    </div>
}
function FeatureTabs() {
    const [activeIndex, setActiveIndex] = useState(-1);
    const titleRefs = useRef([]);

    const handleTabClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        if (activeIndex === -1) {
            return
        }
        if (titleRefs.current[activeIndex]) {
            titleRefs.current[activeIndex].scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [activeIndex]);

    return (
        <TabGroup className={"grid grid-cols-1"}>
            <TabPanels className={"mt-0"}>
                {items.map((_, i) => (
                    <TabPanel
                        key={"feat_tabpanel" + i}
                        ref={(el) => {
                            titleRefs.current[i] = el
                        }}
                    >
                        {_.video ? (
                            <div className='relative aspect-video lg:max-w-[65%] mx-auto min-[2000px]:max-w-full'>

                                <ReactPlayer
                                    url={_.video}
                                    controls={true}
                                    width={"100%"}
                                    height={"100%"}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                    }}
                                    playing={true}
                                />
                            </div>
                        ) : (
                            <div className="rounded-[10px] border-2 border-[#666666] py-12 px-7 lg:px-20 lg:py-20 flex flex-col gap-6 text-left">
                                <h2 className="flex flex-col items-start lg:flex-row lg:items-center">
                                    <img
                                        className="h-[60px] align-middle mr-4"
                                        alt={_.content.title}
                                        src={_.content.icon}
                                    />
                                    <span className="align-middle text-[30px] font-semibold text-slate-800">
                                        {_.content.title}
                                    </span>
                                </h2>
                                <p className="text-lg/8 font-normal text-slate-700">
                                    {_.content.subtitle}
                                </p>
                            </div>
                        )}
                    </TabPanel>
                ))}
            </TabPanels>
            <div className="text-center mb-6 mt-12">
                <p className="lg:text-4xl 2xl:text-5xl font-semibold text-slate-800">
                    Click on the features below to view detailed insights
                </p>
            </div>
            <TabList className={"grid grid-cols-3 gap-3"}>
                {items.map((_, i) => (
                    <FeaturesTab
                        key={"feat_tab" + i}
                        title={_.title}
                        description={_.description}
                        icon={_.icon}
                        onClick={() => handleTabClick(i)}
                    />
                ))}
            </TabList>
        </TabGroup>
    );
}
const FeaturesTab = ({ title, icon, description, onClick }) => {
    const lottieRef = useRef()
    return <Tab onClick={() => {
        lottieRef.current?.goToAndPlay(0, true)
        onClick()
    }} className="grid gap-4 grid-cols-6 items-center justify-center text-left text-lg 2xl:text-xl font-semibold p-7 rounded-md border-b-gray-200 border-transparent border text-slate-700 data-[selected]:bg-landingAccent2-200 hover:bg-landingAccent2-200">
        <Lottie loop={false} lottieRef={lottieRef} className="col-span-1 my-auto mx-auto" animationData={icon} />
        <div className="col-span-5">
            {title}
            <span className='text-base font-normal block mt-[5px]'>{description}</span>
        </div>
    </Tab>
}
const AccordionButton = ({ title, icon, description, onClick, active }) => {
    const lottieRef = useRef()
    return <button onClick={(e) => {
        lottieRef.current?.goToAndPlay(0, true)
        onClick()
        e.stopPropagation()
    }}
        className={`grid gap-4 grid-cols-6 items-center justify-center text-left text-lg 2xl:text-xl font-semibold p-5 sm:p-7 rounded-md border-b-gray-200 border-transparent border text-slate-700 ${active && "bg-landingAccent2-200"}`}>
        <Lottie loop={false} lottieRef={lottieRef} className="col-span-1 my-auto mx-auto" animationData={icon} />
        <div className="col-span-5">
            {title}
            <span className='text-base font-normal block mt-[5px]'>{description}</span>
        </div>
    </button>
}
export default Index