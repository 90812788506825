import React from 'react';
import { useTransition, animated } from '@react-spring/web';
import { withLayout } from './Layout';
import { Route, Routes, useLocation } from 'react-router-dom';
import { SupportOffer } from './Onboarding/SupportOffer';
import SelfOnboarding from './Onboarding/SelfOnboarding';

const Welcome = () => {
    const location = useLocation();
    const transitions = useTransition(location.pathname, {
        from: { opacity: 0, transform: 'translateX(20%)' },
        enter: { opacity: 1, transform: 'translateX(0%)' },
        leave: { opacity: 0, transform: 'translateX(-20%)', },

    });
    return <div className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3 font-[poppins] flex flex-col flex-1 min-h-[calc(100vh-305.19px)] overflow-x-hidden" >
        {transitions((props, item) => (
            <animated.div style={props}>
                <Routes>
                    <Route exact path='/' Component={SupportOffer} />
                    <Route exact path='/special-offer' Component={SupportOffer} />
                    <Route exact path='/setup' Component={SelfOnboarding} />
                </Routes>

            </animated.div>
        ))}

    </div>

};

export default withLayout(Welcome);
