import React from "react";

const PayFlowHeader = ({ payflowOption, currentStep }) => {
  const totalSteps = payflowOption.length;
  return (
    <>
      <div className="sm:hidden flex items-center px-2 gap-1 w-full font-[poppins]">
        {payflowOption.map((option, idx) => {
          return (
            <div className={`w-1/${totalSteps} h-14 pt-4`}>
              <div className={`${(currentStep < idx + 1) ? 'bg-gray-300' : (currentStep == idx + 1) ? 'bg-blue-600' : 'bg-blue-600'}  h-1 relative`}>
                <div className={`${currentStep > idx + 1 ? 'block' : 'hidden'} size-4 z-10 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2`}>
                  <svg version="1.1" viewBox="0 0 78.369 78.369" className="size-4 fill-white">
                    <g>
                      <path d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704
		c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704
		C78.477,17.894,78.477,18.586,78.049,19.015z"/>
                    </g>
                  </svg>

                </div>

                <div className={`${currentStep == idx + 1 ? 'block' : 'hidden'} size-4 z-10 absolute left-1/2 -translate-x-1/2 top-1/3 -translate-y-1/2`}>
                  <span class="animate-ping absolute inline-flex size-full rounded-full bg-blue-500 opacity-75"></span>
                  <span class="relative inline-flex rounded-full size-full bg-blue-600"></span>
                </div>

                <div className={`size-7 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 rounded-full ${(currentStep < idx + 1) ? 'border-gray-300 bg-white' : (currentStep == idx + 1) ? 'border-blue-600 bg-white' : 'bg-blue-600'} border-2`}></div>
                <div className="text-center pt-4 text-sm">{option.title}</div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="lg:border-b lg:border-t lg:border-gray-200 hidden sm:block">
        <nav
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            {payflowOption.map((option, index) => {
              if (option.stepNumber < currentStep) {
                return (
                  <li className="relative overflow-hidden lg:flex-1" key={index}>
                    <div className="overflow-hidden border border-gray-200 rounded-t-md border-b-0 lg:border-0">
                      {/* <!-- Completed Step --> */}
                      <div className="group">
                        <span className="flex items-start px-6 py-5 text-sm font-medium">
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full bg-[#1972f5]">
                              <svg
                                className="h-6 w-6 text-white"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </span>
                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                            <span className="text-sm font-medium">
                              {option.title}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {option.subtitle}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </li>
                );
              } else if (option.stepNumber === currentStep) {
                return (
                  <li className="relative overflow-hidden lg:flex-1" key={index}>
                    <div className="overflow-hidden border border-gray-200 lg:border-0">
                      {/* <!-- Current Step --> */}
                      <div href="#" aria-current="step">
                        <span
                          className="absolute left-0 top-0 h-full w-1 bg-[#1972f5] lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        ></span>
                        <span className="flex items-start px-6 py-5 text-sm font-medium lg:pl-9">
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-[#1972f5]">
                              <span className="text-[#1972f5]">
                                {option.stepNumber}
                              </span>
                            </span>
                          </span>
                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-[#1972f5]">
                              {option.title}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {option.subtitle}
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* <!-- Separator --> */}
                      <div
                        className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li className="relative overflow-hidden lg:flex-1" key={index}>
                    <div className="overflow-hidden border border-gray-200 rounded-b-md border-t-0 lg:border-0">
                      {/* <!-- Upcoming Step --> */}
                      <div href="#" className="group">
                        <span className="flex items-start px-6 py-5 text-sm font-medium lg:pl-9">
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                              <span className="text-gray-500">
                                {option.stepNumber}
                              </span>
                            </span>
                          </span>
                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-500">
                              {option.title}
                            </span>
                            <span className="text-sm font-medium text-gray-500">
                              {option.subtitle}
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* <!-- Separator --> */}
                      <div
                        className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 12 82"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0.5 0V31L10.5 41L0.5 51V82"
                            stroke="currentcolor"
                            vectorEffect="non-scaling-stroke"
                          />
                        </svg>
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      </div>
    </>
  );
};

export default PayFlowHeader;
