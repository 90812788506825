import React, { Component } from 'react';
import $ from 'jquery';
import Wordpress from './Fit/Wordpress';
import LeadTracking from './Fit/LeadTracking';
import Sales from './Fit/Sales';
import FreeButton from './Fit/FreeButton';
import Affiliate from './Fit/Affiliate';
import PaidAds from './Fit/PaidAds';
import Organic from './Fit/Organic';
import WhyGood from './Fit/WhyGood';
import WordpressYes from './Fit/WordpressYes';
import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from '../store/auth';
import { useEffect, useState } from 'react';
import { useSendAreWeFitSurveyMutation } from '../features/survey/surveyAPI';


export default function AreWeFit() {
  var [wordpress, setWordpress] = useState('No');
  var [lead_tracking, setLeadTracking] = useState('No');
  var [sales, setSales] = useState('No');
  var [affiliate, setAffiliate] = useState('No');
  var [paid_ads, setPaidAds] = useState('No');
  var [organic, setOrganic] = useState('No');
  var [yescount, setYescount] = useState(0);
  var [ready, setReady] = useState('No');
  var [step, setStep] = useState(0);
  var [currentOpened, setCurrentOpened] = useState('whygood');

  const [sendSurvey] = useSendAreWeFitSurveyMutation();
  // useEffect(() => {

  // }, [])
  const dispatch = useDispatch();

  const { showAreWeFit } = useSelector((state) => state.auth.data || {});

  const resetModal = () => {
    dispatch(
      setStoreData({
        data: {
          showAreWeFit: false,
        },
      })
    );
    setYescount(0);
    setStep(0);
  };

  const stateSetterFunctions = {
    wordpress: setWordpress,
    lead_tracking: setLeadTracking,
    sales: setSales,
    affiliate: setAffiliate,
    paid_ads: setPaidAds,
    organic: setOrganic,
    yescount: setYescount,
    ready: setReady,
  };

  const setState = (key, value) => {
    if (stateSetterFunctions[key]) {
      stateSetterFunctions[key](value);
    } else {
      console.error(`State setter function for "${key}" does not exist.`);
    }
  };

  let insertRadio = (key, value) => {
    return (
      <label>
        <button
          className='text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2'
          onClick={() => Quiz(key, value)}
        >
          {value}
        </button>
        {/*<input type="radio" name={key} id={key} value={value} onClick={this.Quiz.bind(this,key,value)} /> {value}*/}
      </label>
    );
  };

  let Quiz = async (q, v) => {
    console.log(q, v);
    setState(q, v);
    if (v === 'Yes') {
      setYescount((yescount) => {
        return yescount + 1;
      });
      // this.setState({ yescount: this.state.yescount + 1 });
    }
    setStep((step) => {
      return step + 1;
    });
    // $('#utm-quiz').carousel('next');
    if (q === 'organic') {
      setTimeout(function () {
        // $('#utm-quiz').carousel('next');
        setStep((step) => {
          return step + 1;
        });
      }, 3000);

      const { gtag } = window;
      gtag?.('event', 'AreWeFit', {
        event_category: 'UTM Sales Prospect',
        event_label: 'FitOK',
      });

      let data = {
        wordpress,
        lead_tracking,
        sales,
        affiliate,
        paid_ads,
        organic,
        yescount,
      };
      let dataArr = [];
      for (var key in data) {
        dataArr.push({ name: key, value: data[key] });
      }

      const { HandL } = window;
      let params = HandL?.getAll() ?? {};
      for (var param in params) {
        if (params[param]) {
          dataArr.push({ name: param, value: params[param] });
        }
      }

      try {
        const response = await sendSurvey(dataArr).unwrap();
        return response;
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    showAreWeFit && (
      <>
        <div
          id='arewefit'
          className='handl-modal pt-[20px] lg:w-[700px] md:w-[500px] w-[400px] fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[10001]'
        >
          <div id='utm-quiz' className='carousel slide' data-interval='false'>
            <div className='carousel-inner p-2' role='listbox'>
              <div className={`${step !== 0 ? 'hidden' : ''}`}>
                <h3 className='text-2xl '>
                  💨💨💨 In only 6 steps,
                  <br /> We'll tell you if we are a good fit. <br /> Ready?
                </h3>
                <p className='description m-b-40'>
                  It takes less than a minute.
                </p>
                {insertRadio('ready', "Let's go!")}
              </div>
              <div className={`${step !== 1 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>Do you have a WordPress site?</h3>
                {insertRadio('wordpress', 'Yes')}
                {insertRadio('wordpress', 'No')}
              </div>
              <div className={`${step !== 2 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>Do you have signup/opt-in forms?</h3>
                {insertRadio('lead_tracking', 'Yes')}
                {insertRadio('lead_tracking', 'No')}
              </div>
              <div className={`${step !== 3 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>Do you do sales?</h3>
                {insertRadio('sales', 'Yes')}
                {insertRadio('sales', 'No')}
              </div>
              <div className={`${step !== 4 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>Do you do affiliate marketing?</h3>
                {insertRadio('affiliate', 'Yes')}
                {insertRadio('affiliate', 'No')}
              </div>
              <div className={`${step !== 5 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>Running Paid Ads?</h3>
                {insertRadio('paid_ads', 'Yes')}
                {insertRadio('paid_ads', 'No')}
              </div>
              <div className={`${step !== 6 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>
                  Do you get organic traffic to your site?
                </h3>
                {insertRadio('organic', 'Yes')}
                {insertRadio('organic', 'No')}
              </div>
              <div className={`${step !== 7 ? 'hidden' : ''}`}>
                <h3 className='text-2xl'>Please wait...</h3>
                <i className='fa fa-spin fa-spinner'></i>
              </div>
              <div className={`${step !== 8 ? 'hidden' : ''} text-left p-2`}>
                {wordpress === 'No' && <Wordpress resetModal={resetModal} />}

                {wordpress === 'Yes' && yescount === 1 && (
                  <WordpressYes resetModal={resetModal} />
                )}

                <div className='container mx-auto p-1'>
                  {wordpress === 'Yes' && yescount > 1 && (
                    <h3 className='text-center text-2xl'>
                      🎉 Yay! We are a good fit!
                    </h3>
                  )}
                  {wordpress === 'Yes' && yescount > 1 && (
                    <WhyGood
                      isOpened={currentOpened === 'whygood'}
                      setCurrentOpened={setCurrentOpened}
                    />
                  )}
                  {wordpress === 'Yes' &&
                    yescount > 1 &&
                    lead_tracking === 'Yes' && (
                      <LeadTracking
                        isOpened={currentOpened === 'leadtracking'}
                        setCurrentOpened={setCurrentOpened}
                      />
                    )}
                  {wordpress === 'Yes' && yescount > 1 && sales === 'Yes' && (
                    <Sales
                      isOpened={currentOpened === 'sales'}
                      setCurrentOpened={setCurrentOpened}
                    />
                  )}
                  {wordpress === 'Yes' &&
                    yescount > 1 &&
                    paid_ads === 'Yes' && (
                      <PaidAds
                        isOpened={currentOpened === 'paidads'}
                        setCurrentOpened={setCurrentOpened}
                      />
                    )}
                  {wordpress === 'Yes' && yescount > 1 && organic === 'Yes' && (
                    <Organic
                      isOpened={currentOpened === 'organic'}
                      setCurrentOpened={setCurrentOpened}
                    />
                  )}
                  {wordpress === 'Yes' &&
                    yescount > 1 &&
                    affiliate === 'Yes' && (
                      <Affiliate
                        isOpened={currentOpened === 'affiliate'}
                        setCurrentOpened={setCurrentOpened}
                      />
                    )}

                  {wordpress === 'Yes' && yescount > 1 && <FreeButton />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='absolute w-[100%] h-[100%] bg-black/50 top-[-100px] left-0 z-[10000]'
          onClick={() => {
            resetModal();
          }}
        ></div>
      </>
    )
  );
}
