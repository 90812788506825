import { useDispatch } from 'react-redux';
import { setActiveCoupon } from '../../features/coupon/couponSlice';
import { useNavigate } from 'react-router-dom';
import { useValidateCouponMutation } from '../../features/coupon/couponAPI';
const useApplyCoupon = ({ couponCode, redirectUrl, campaign_key }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [validateCouponMutation, {
        isLoading: couponLoading,
    }] = useValidateCouponMutation();
    const applyCoupon = async () => {
        const couponData = await validateCouponMutation({
            coupon: couponCode,
        }).unwrap();
        dispatch(setActiveCoupon({
            couponStripe: couponCode,
            coupon: couponData,
            campaignKey: campaign_key
        }));
        navigate(redirectUrl || '/#prices');
    }
    return {
        applyCoupon,
        couponLoading
    }
}

export default useApplyCoupon;