import React from 'react';
import { Disclosure } from '@headlessui/react';

const FaqItem = ({ item }) => {
    return (
        <div className="w-full font-[poppins]">
            <div className="w-full bg-white">
                <Disclosure>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between px-5 py-3 text-left text-base font-normal hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                                <span>{item.q}</span>
                                <svg width="24" height="24" className={`fill-none ${open ? '-rotate-90 transform' : 'rotate-90 transform'}`} viewBox='0 0 24 24'>
                                    <path xmlns="http://www.w3.org/2000/svg" id="Vector" d="M10 8L14 12L10 16" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-5 text-base text-gray-500">
                                {item.a}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}

export default FaqItem;