import React from "react";
import Lottie from "lottie-react";
import satellite from "../../images/lottie/satellite.json";

function NetworkErrorScreen({ onRetry, loading = false }) {
  return (
    <div className="flex flex-col w-full flex-grow p-10 items-center justify-center overflow-y-auto font-[poppins]">
      <Lottie
        animationData={satellite}
        loop={true}
        className="w-[402px] h-[345px]"
      />
      <div className="flex flex-col items-center gap-4">
        <div className="text-center text-title-1 text-[40px] font-bold tracking-tight">
          Oops!
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="text-center text-gray-700 text-2xl font-medium leading-normal tracking-tight">
            Looks like we're having some network issues.
          </div>
        </div>
        <button
          onClick={onRetry}
          disabled={loading}
          className={`px-5 py-2.5 bg-blue-600 rounded-[5px] justify-center items-center gap-[5px] inline-flex ${loading ? 'cursor-not-allowed !bg-gray-400' : 'cursor-pointer'}`}
        >
          <span className="text-center text-white text-sm font-semibold leading-snug">
            Try Again
          </span>
          {
            loading && <i className="fa fa-spin fa-spinner text-white" />
          }
        </button>
      </div>
    </div>
  );
}

export default NetworkErrorScreen;
