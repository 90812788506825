import React, { useEffect, useState } from 'react';
import $ from "jquery"

import Logo from '../../images/logo.png';
import Logo2x from '../../images/logo@2x.png';
import LogoW from '../../images/logo.webp';
import LogoW2x from '../../images/logo@2x.webp';

import Image1_1x from '../../images/utm/image1@1x.png'
import Image3_1x from '../../images/utm/image3@1x.png'
import Image4_1x from '../../images/utm/image4@1x.png'
import Image5_1x from '../../images/utm/image5@1x.png'
import Image6_1x from '../../images/utm/image6@1x.png'

import Image1W_1x from '../../images/utm/image1@1x.png'
import Image3W_1x from '../../images/utm/image3@1x.webp'
import Image4W_1x from '../../images/utm/image4@1x.webp'
import Image5W_1x from '../../images/utm/image5@1x.webp'
import Image6W_1x from '../../images/utm/image6@1x.webp'

import Image1_2x from '../../images/utm/image1@2x.png'
import Image3_2x from '../../images/utm/image3@2x.png'
import Image4_2x from '../../images/utm/image4@2x.png'
import Image5_2x from '../../images/utm/image5@2x.png'
import Image6_2x from '../../images/utm/image6@2x.png'

import Image1W_2x from '../../images/utm/image1@2x.png'
import Image3W_2x from '../../images/utm/image3@2x.webp'
import Image4W_2x from '../../images/utm/image4@2x.webp'
import Image5W_2x from '../../images/utm/image5@2x.webp'
import Image6W_2x from '../../images/utm/image6@2x.webp'

import FaqItem from "./FaqItem";
import MainTitle from "./MainTitle";
import SidebarNavItem from "./SidebarNavItem";
import HandLutmGrabber from "./HandLUTMGrabber";
import "./utm.css"
import Drawer from '../Drawer';
import { withLayout } from '../Layout';

const chatWithUs = () => {
    const { $crisp } = window;
    $crisp?.push(["do", "chat:toggle"])
}

const faqs = [
    {
        'q': 'Where are UTMs Stored?',
        'a': <p>UTMs, or Urchin Tracking Modules, are stored in the <strong>cookies</strong> of a client's browser as soon as they become available in the URL. This means that even if the UTMs are no longer present in the query string, the tracking of your visitors continues. When a user eventually converts, either through lead generation or a sale, these UTMs are then captured and associated with that user. This process ensures the accuracy of tracking and attribution in understanding user behavior and the effectiveness of various marketing efforts.</p>
    },
    {
        'q': 'Is it possible to track users across subdomains?',
        'a': <p>Yes, it certainly is. UTM (Urchin Tracking Module) parameters and other custom variables are always recorded at the highest domain level. This means that if your landing page is located at <code>example.com</code> and a user converts at <code>sub.example.com</code>, HandL UTM Grabber will still be able to capture the cookies from <code>example.com</code>. Simply put, even if a user moves from a main domain to a subdomain, their activities can still be tracked. This is due to the fact that the UTM parameters, along with other custom variables, are logged on the top-level domain. So, whether a user is interacting with your main site or a subdomain, you'll still have the ability to monitor their actions and understand their behavior, thanks to UTM tracking and tools like HandL UTM Grabber.</p>
    },
    {
        'q': 'Can I capture first or/and last touch?',
        'a': <p>Yes, you certainly can. The HandL UTM Grabber is designed to capture both first touch and last touch UTMs simultaneously. By default, all original UTM parameters such as <code>utm_campaign</code> are set to capture the last touch. If you want to capture the first touch, simply use <code>first_utm_campaign</code>. Let's say a user clicks on an ad and is directed to your website. If they return another day via Google organic search, you'll be able to capture both the first UTM set (associated with the ad) and the last UTM set (associated with the organic search).</p>
    },
    {
        'q': 'The UTM codes are getting stripped out',
        'a': <p>This is a common issue associated with hosting companies that use Varnish Cache, such as WP Engine and Pantheon. With Pantheon, all cookies are removed and replaced with <code>PANTHEON_STRIPPED</code>. Fortunately, this problem can be resolved by contacting your hosting company and asking them to whitelist UTM variables, as explained <a href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/there-is-no-utm-collected" target="_blank" className='text-blue-700'>here</a> </p>
    },
    {
        'q': 'Shortcodes are passed to CRM instead of UTM parameters',
        'a': <p>if shortcodes are incorrectly placed, this could lead to them being passed to your CRM instead of the intended UTM parameters, causing potential issues with your tracking and attribution. If you're experiencing this problem, please <strong>contact us</strong>. We would be happy to help you sort it out and ensure that your tracking is set up correctly</p>
    },
    {
        'q': 'Which form plugin do you recommend?',
        'a': <div><p>If you are seeking recommendations for form plugins compatible with HandL UTM Grabber V3 in WordPress, we have several suggestions for you. HandL UTM Grabber V3 supports a wide range of WordPress forms, both free and premium.</p><p>However, based on our experience and client feedback, certain form plugins consistently outperform others. Specifically, the following three plugins are frequently used and highly recommended by our clients:</p>
            <ol>
                <li><strong>Contact Form 7</strong></li>
                <li><strong>Ninja Form</strong></li>
                <li><strong>WPForms</strong></li>
            </ol><p>These form plugins are not only compatible with HandL UTM Grabber V3 but also offer excellent performance and user-friendly interfaces. If you are new to UTM (Urchin Tracking Module), lead tracking, and attribution, these form plugins will make the process of implementing and managing your tracking easier and more efficient.</p></div>
    }
]

const content = [
    {
        title: 'What is UTM?',
        content: <p>UTM, which stands for Urchin Tracking Module, is a helpful tool that lets you see where the people visiting your website are coming from. More importantly, it shows you if these visits are turning into beneficial actions like leads or sales.</p>,
    },
    {
        title: 'What is a UTM parameter?',
        content: <p>A UTM Parameter is a tag that gets attached to the end of a URL. Its primary function is to track visits to that particular URL, thereby helping measure the success of campaigns, emails, orders, and advertisements. By using a combination of UTM Parameters, you can obtain detailed insight into your tracking activities. Essentially, UTM Parameters provide a narrative of how your traffic arrives at your site.</p>
    },
    {
        title: 'What are UTM parameters?',
        content: <div>
            <p>
                <b>Source:</b> The "<b>source</b>" parameter is designed to answer the question: "Where are the visitors coming from?" <br />
                Example: <i>utm_source=Facebook</i>
            </p>
            <p>This parameter is part of the UTM or Urchin Tracking Module. In this example, 'Facebook' is the source, indicating that the visitors are coming from this particular social media platform. This information is crucial for tracking and attributing leads, helping you understand where your audience is coming from.</p>
            <p>
                <b>Medium:</b> The <strong>medium parameter</strong> is a key tool that identifies the marketing channels driving visitors to your site. When we talk about the medium in the context of the <strong>HandL UTM Grabber</strong>, we're referring to the various platforms or avenues that can be used. These include, but are not limited to, channels such as email and social media<br />
                <i>Example: utm_medium=socialmedia</i>
            </p>
            <p>This example indicates that the medium, or channel, through which visitors are coming to your site is social media. By understanding and utilizing these parameters, you can better track and analyze your website's traffic, allowing you to adapt and optimize your marketing strategies effectively.

                Remember, for someone new to these concepts, the medium parameter is simply a way to identify how visitors are reaching your site - be it through an email campaign, social media, or other marketing channels.</p>

            <p>
                <b>Campaign Title:</b>  The campaign title parameter is a tool that assists you in identifying and tracking how well a particular campaign is performing. <br />
                <i>Example: utm_campaign=earlyaccess</i>
            </p>
            <p>This means if your campaign's title is "early access", you can track its performance by using the parameter "utm_campaign=earlyaccess". This way, you can easily monitor the success of your campaign.</p>
            <p>By using this parameter, you're not just tracking your campaign, but also ensuring the accuracy and correctness of your results. It's a simple yet effective way to measure your campaign's reach and impact. So, remember to use this parameter to keep a tab on your campaign's progress. </p>
            <p><b>Remember:</b> Keep your campaign title unique and specific for easy identification and tracking.</p>

            <p>
                <b>Term:</b> The term parameter, also known as the keyword parameter, is a component of UTM that helps to identify specific user interactions. Specifically, it tracks when a visitor clicks through individual products or offerings that you are targeting.<br />
                <i>Example: utm_term=software</i>
            </p>
            <p>By utilizing the term parameter, you can gain insights into which specific products or services are garnering the most interest from your audience. This information can be extremely valuable for tailoring your marketing efforts and improving your overall business strategy.</p>

            <p><b>Remember</b>: the key to successfully using UTM tracking is consistency. Always use the same parameters for the same types of links to ensure accurate data collection and analysis.</p>

            <p>
                <b>Content:</b> Content parameter is utilized to identify the specific widget that was clicked on, either in an email, an advertisement, or a promotion. This tag can be particularly useful when you have two identical links on the same page, but with different descriptions.<br />
                <i>Example: utm_content=headerlink</i>

            </p>
            <p>By using this tag, it becomes easier to track and understand which specific elements in your campaign are driving the most engagement. This helps not only in better lead tracking, but also in improving your overall marketing attribution.</p>
        </div>,
        subs: [
            {
                title: 'What does a URL with UTM parameters look like?',
                content: <p>Take a look at this example of a URL with a UTM code. Although it might seem lengthy, the string of text following the "?" (question mark) can be easily dissected. The UTM parameters following the "?" do not alter anything on the page. Instead, they aid your analytics program in providing precise information about where your traffic is coming from and how it reaches you.<br />
                    <code>http://yourURL.com/landingpage?utm_campaign=earlyaccess&utm_medium=socialmedia&utm_source=Twitter</code><br />
                    In this instance, the campaign is "earlyaccess", the "medium" is social media, and the source is "Twitter". When people click on this link, it indicates that the traffic is originating from Twitter.</p>,
            },
            {
                title: 'How to generate a URL with UTM parameters?',
                content: <div><p>Creating a campaign URL with UTM (Urchin Tracking Module) parameters is a straightforward process. Here's how to do it:</p>

                    <p><b>Step 1</b>: Open <a href="https://ga-dev-tools.appspot.com/campaign-url-builder/" target="_blank" className='text-blue-700'> Google's Campaign Builder</a></p>

                    <div className="text-left mb-4">
                        <a href={Image3_1x}>
                            <picture>
                                <source srcset={`${Image3W_1x} 1x, ${Image3W_2x} 2x`} type="image/webp" />
                                <img className='w-full h-auto' src={Image3_1x} alt="Google's campaign builder" srcset={`${Image3_2x} 2x`} />
                            </picture>
                        </a>
                    </div>

                    <p><b>Step 2</b>: You'll find a form where you need to fill in each link attribute. These include the full website URL, Campaign Source, Campaign Medium, and Campaign Name.</p>

                    <p><b>Step 3</b>: If you want a more specific tracking, you can fill in the 'Campaign Term' and 'Campaign Content' boxes.</p>

                    <p><b>Step 4</b>: Once you have filled in all the necessary information, Google's Campaign Builder will generate a ready-to-use URL.</p>

                    <p><b>Step 5</b>: Simply copy the link and use it in your campaign.</p>

                    <p>This process allows you to customize your URL for specific campaigns, making it easier to track leads and attribution. Even if you are new to these concepts, this easy-to-follow guide should help you to create effective UTM parameters for your marketing campaign.</p>

                    <div className="text-left mb-4">
                        <a href={Image3_1x}>
                            <picture>
                                <source srcset={`${Image1W_1x} 1x, ${Image1W_2x} 2x`} type="image/webp" />
                                <img className='w-full h-auto' src={Image1_1x} alt="Share generated campaign url" srcset={`${Image1_2x} 2x`} />
                            </picture>
                        </a>
                    </div></div>,
            },
            {
                title: 'Understanding How UTM Parameters Work',
                content: <div><p>Google Analytics is an incredibly useful tool for tracking incoming traffic to your website. However, it isn't as simple as setting up Google Analytics and letting it do all the work. A key factor in this process involves UTM parameters, also known as Urchin Tracking Modules.</p>

                    <h5 className='font-medium text-[1.15rem] font-medium'>Understanding the Potential Hurdles</h5>
                    <p>One nuance of using UTMs involves potential redirection or referral from third-party websites. For instance, PayPal, a common UTM suppressor, can hijack the original UTMs and replace them with its own. Consequently, Google Analytics may mistakenly attribute the sales to PayPal instead of the original UTMs. This results in misleading data, which inaccurately attributes sales to the referral rather than the original UTMs.</p>
                    <div className="text-left mb-4">
                        <a href={Image4_1x}>
                            <picture>
                                <source srcset={`${Image4W_1x} 1x, ${Image4W_2x} 2x`} type="image/webp" />
                                <img className='w-full h-auto' src={Image4_1x} alt="Potential hurdles" srcset={`${Image4_2x} 2x`} />
                            </picture>
                        </a>
                    </div>

                    <h5 className='font-medium text-[1.15rem]'>Solution: Accurate Tracking and Conversion</h5>
                    <p>To resolve this issue, you need to accurately track and capture the UTMs, then send them to Google Analytics or your preferred Customer Relationship Management (CRM) system at the time of conversion. By doing so, you can ensure an accurate measurement of your traffic and conversions, leading to more reliable data and insights.</p>
                    <p>By understanding and correctly using UTM parameters, you can harness the full power of Google Analytics to better understand your website's traffic and improve your conversion rates.</p></div>,
            },
            {
                title: 'Which UTM parameters are required?',
                content: <div><p>For Google Analytics to function correctly, only three UTM parameters are really required: <strong>campaign source</strong>, <strong>campaign medium</strong>, and <strong>campaign name</strong>. However, it is highly recommended to collect additional metrics such as the <strong>landing page URL</strong>, <strong>referral URL</strong>, and <strong>organic source</strong>.</p>
                    <p>Collecting these additional data points is beneficial because if one data point is missing, you always have backup data to fill in the gaps or provide support. The HandL UTM Grabber tool is designed to facilitate this data collection process, making it significantly easier for you. It can track and collect these additional metrics right out of the box. </p>

                    <p>For a complete list of the parameters that can be tracked by HandL UTM Grabber, <a target="_blank" href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/page/native-wp-shortcodes" className='text-blue-700'>click here</a>.</p></div>
            },
            {
                title: 'Why are UTM Parameters are so valuable?',
                content: <div><p><b>Data is power.</b> UTM (Urchin Tracking Module) parameters are highly beneficial tools that allow you to track where your website traffic is originating from. They are particularly useful when testing the effectiveness of different campaigns, promotions, or strategies. These parameters also allow you to measure the performance of specific pieces of content and links, providing invaluable insights to guide your marketing decisions.</p>

                    <p>For instance, UTM parameters can help you decide when it's the right time to increase or decrease the budget of a Paid Ads campaign. They can also identify which parts of your site aren't converting as well as they could be. By examining the detailed performance data (as shown in the image below), you can make necessary improvements to boost conversion rates and ultimately increase revenue.</p>

                    <p>The power of UTM parameters lies in their ability to facilitate <b>data-driven decisions</b>, improving the effectiveness and efficiency of your marketing efforts.</p>
                    <div className="text-left mb-4">
                        <a href={Image5_1x}>
                            <picture>
                                <source srcset={`${Image5W_1x} 1x, ${Image5W_2x} 2x`} type="image/webp" />
                                <img className='w-full h-auto' src={Image5_1x} alt="UTM parameters" srcset={`${Image5_2x} 2x`} />
                            </picture>
                        </a>
                    </div></div>
            },
            {
                title: 'How to Test UTM Parameters',
                content: <div>
                    <p>To ascertain if your UTM parameters (Urchin Tracking Module) are captured accurately, you can utilize the powerful Developer Tools available in most web browsers. These parameters are crucial for lead tracking and attribution, enabling you to understand where your website's traffic is coming from.</p>

                    <h5 className='font-medium text-[1.15rem]'>Here's a simple step-by-step guide on how to test UTM parameters:</h5>

                    <ol>
                        <li>Click on a link that has UTM parameters appended to it.</li>
                        <li>Open the Developer Console in your browser. This is usually accessible from the browser's menu, or by right-clicking on a webpage and selecting 'Inspect' or 'Inspect Element'.</li>
                        {/* <li>Look for the UTM parameters. These should be located under 'Application > Cookies > [Your Website]' as shown in the reference image below.</li> */}
                    </ol>

                    <a href={Image6_1x}>
                        <picture>
                            <source srcset={`${Image6W_1x} 1x, ${Image6W_2x} 2x`} type="image/webp" />
                            <img className='w-full h-auto' src={Image6_1x} alt="Test UTM parameters" srcset={`${Image6_2x} 2x`} />
                        </picture>
                    </a>

                    <p>If you don't see the UTM parameters listed in this section, it indicates that your website might not be tracking UTMs efficiently.</p>

                    <p><b>Remember</b>: using UTM parameters is a straightforward and effective method to understand your website's traffic sources. However, accurate implementation and tracking are essential for reliable results.</p>
                </div>
            }
        ]
    },
    {
        title: 'How to capture UTM parameters during website visits?',
        content: <div>
            <p>It's crucial to capture UTM (Urchin Tracking Module) parameters as soon as users land on your website page. This moment, often referred to as the 'first touch', is the golden opportunity for data capture. But you might wonder, how can this be done?</p>

            <p>The most basic method involves capturing these parameters from the query arguments. This step is critical as it forms the foundation for all subsequent data collection efforts. It's essential to capture and store these UTMs in a place where you can easily access them later.</p>

            <h5 className='font-medium text-[1.15rem]'>Why is the timing so important?</h5>

            <p>The reason behind this is simple - if a user navigates away from the landing page, all attached UTMs vanish. Once gone, there's no way to retrieve them, leaving you without crucial data.</p>

            <h5 className='font-medium text-[1.15rem]'>What's the solution?</h5>

            <p>The <a target="_blank" href="https://utmgrabber.com/" className='text-blue-700'>HandL UTM Grabber V3</a> comes to the rescue in this critical process. Our unique method depends on both server-side and client-side data collection, ensuring the capturing of UTM parameters in COOKIES for all cases. This approach guarantees a reliable and thorough data capture, leaving no stone unturned.</p>
        </div>
    },
    {
        title: 'When Should You Use HandL UTM Grabber?',
        content: <div>
            <p>If your website is designed for user registration (<strong>lead generation</strong>) or sales (<strong>ecommerce</strong>), it's essential to tag your URLs with <strong>UTM (Urchin Tracking Module) parameters</strong>. UTM parameters allow you to track where your users are originating from and identify the most effective conversion sources. This information is vital for scaling up your business. </p>

            <p>Many website owners and marketers rely on Pay-Per-Click (PPC) ads, directing traffic to their landing pages and relying solely on provided conversion metrics to decide whether to continue or discontinue a campaign. However, our case studies have revealed that conversion reports in Ad platforms are often under-reported and can be misleading. </p>

            <p>To make accurate decisions about ad performance, the use of orthogonal data is highly recommended. Orthogonal data, such as <strong>Google Analytics, Internal Tracking, CRM</strong> and others, have proven to provide better marketing decisions. Therefore, tracking UTMs and incorporating this data back into your repository is crucial.</p>

            <p>In simple terms, HandL UTM Grabber can streamline your marketing efforts by providing a clear understanding of where your website traffic is coming from. This valuable insight can help you optimize your marketing strategies, making them more effective and profitable.</p>

        </div>
    },
    {
        title: 'How to use the HandL UTM Grabber?',
        content: <p>The HandL UTM Grabber V3 is primarily a plug-and-play tool, requiring minimal configuration. You just need to add a few hidden fields to your opt-in forms. For certain applications like UTM tracking in WooCommerce and associating them with an order, there is no configuration needed. Simply activating the plugin is sufficient to start collecting UTMs into your orders in WooCommerce. Detailed information about capturing UTMs in WooCommerce can be found on our <a target="_blank" href="https://docs.utmgrabber.com/books/woocommerce-integration/page/collect-utms-in-woocommerce-orderpage" className='text-blue-700'><strong>documentation page</strong></a>.</p>,
        subs: [
            {
                title: 'Installing HandL UTM Grabber',
                content: <p>The installation of HandL UTM Grabber V3 is as straightforward as any other WordPress plugin. Simply upload the plugin file in the WordPress plugin section and you're good to go. For more detailed information, please refer to our  <a target="_blank" href="https://docs.utmgrabber.com/books/101-lets-start/page/how-to-install-handl-utm-grabbertracker-v3" className='text-blue-700'><strong>documentation page</strong></a>.</p>
            },
            {
                title: 'What Can You Do with the HandL UTM Grabber?',
                content: <p>The HandL UTM Grabber is not your regular WordPress plugin. It allows you to capture UTM variables from your landing page and sync them to your CRM when a user converts. The HandL UTM Grabber V3 supports many major opt-in form plugins right out of the box. A list of some of these plugins can be found on our <a target="_blank" href="https://docs.utmgrabber.com/books" className='text-blue-700'><strong>documentation page</strong></a>. If your form is not listed there, we probably still support it. <a onClick={chatWithUs} className="cursor-pointer text-blue-700">Chat with us</a> and we'll assist you in implementing it with your preferred form.</p>
            },
            {
                title: 'How to Use the HandL UTM Grabber?',
                id: 'how-to-use-utm-grabber',
                content: <p>There are three common ways to use the HandL UTM Grabber V3.<br /><br />
                    <b>1) WordPress Native Shortcodes:</b> You can use all the UTM variables, other parameters, and custom parameters you created as a shortcode. Each shortcode resolves into the real captured value. For example, [utm_campaign] will resolve into the corresponding utm_campaign value. More information can be found on our <a target="_blank" href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/page/native-wp-shortcodes" className='text-blue-700'><strong>documentation page</strong></a>.<br /><br />
                    <b>2) Input Field Wrapper:</b> In some cases, shortcodes may not resolve depending on where they are used due to WordPress restrictions. If you want to create a hidden form field and populate the value for one of the UTM parameters, you'll want to use our respective input wrapper. Every shortcode has its corresponding input wrapper. For example, the [utm_campaign] shortcode's corresponding input wrapper is [utm_campaign_i][/utm_campaign_i]. More details can be found on our <a target="_blank" href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/page/input-field-wrapper" className='text-blue-700'><strong>documentation page</strong></a><br /><br />

                    <b>3) Hassle-free Tracking:</b> As the name suggests, this is the easiest way of integrating the plugin with your forms. It's as simple as adding a class, name, or id attribute to your fields. Every shortcode has its corresponding name/class/id. For example, the <code>[utm_campaign]</code> shortcode's corresponding class is <code>class=utm_campaign</code>. Detailed information can be found on our <a target="_blank" href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/page/hassle-free-implementation-no-shortcode" className='text-blue-700'><strong>documentation page</strong></a>.
                </p>
            }
        ]
    },
    {
        title: 'Why use UTM-based URLs in your social media posts, newsletter and Ads?',
        content: '',
        subs: [
            {
                title: 'How to use UTM in your newsletter?',
                content: ''
            },
            {
                title: 'How to use UTM in Google Ads?',
                content: ''
            },
            {
                title: 'What is UTM on instagram?',
                content: ''
            },
            {
                title: 'What is UTM on facebook?',
                content: ''
            },
            {
                title: 'How to capture UTM parameters during website visits?',
                content: ''
            }
        ]
    },
    {
        title: 'Frequently Asked Questions',
        content: faqs.map((item, id) =>
            <FaqItem item={item} key={id} id={id} />
        )
    }
]

const UTM = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        const handleUTMDocScroll = (event) => {
            const scroll = event.currentTarget.scrollTop;
            const anchors = document.querySelectorAll('nav a[href^="#"]:not([href="#"])');
            anchors.forEach(anchor => {
                const target = document.querySelector(anchor.getAttribute('href'));
                if (target.offsetTop - 120 <= scroll) {
                    anchors.forEach(anchor => anchor.classList.remove('active'));
                    anchor.classList.add('active');
                    // Scroll the sidebar to the active anchor
                    $('#utm-sidebar').stop().animate({
                        scrollTop: (anchor.offsetTop - 20)
                    }, 0);
                }
            });
        };


        const handleAnchorClick = (event) => {
            event.preventDefault();
            const anchor = event.currentTarget;
            const target = document.querySelector(anchor.getAttribute('href'));
            utm_doc.removeEventListener('scroll', handleUTMDocScroll);
            $('#utm-doc').stop().animate({
                scrollTop: (target.offsetTop - 20)
            }, 300, 'linear', () => {
                const anchors = document.querySelectorAll('nav a[href^="#"]:not([href="#"])');
                anchors.forEach(anchor => anchor.classList.remove('active'));
                anchor.classList.add('active');
                setTimeout(() => {
                    utm_doc.addEventListener('scroll', handleUTMDocScroll);
                }, 100);
            });
        };

        // Attach the scroll event listener to the utm doc div
        const utm_doc = document.querySelector('#utm-doc');
        utm_doc.addEventListener('scroll', handleUTMDocScroll);

        // Attach the anchor click event listener to all anchor tags that match the selector
        const anchors = document.querySelectorAll('nav a[href^="#"]:not([href="#"])');
        anchors.forEach(anchor => anchor.addEventListener('click', handleAnchorClick));

        // Cleanup 
        return () => {
            anchors.forEach(anchor => anchor.removeEventListener('click', handleAnchorClick));
            utm_doc.removeEventListener('scroll', handleUTMDocScroll);
        };
    }, []);

    const getID = (text) => {
        return text
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[?,]/g, '')
    }

    const handleAnchorClickDrawerAnchor = (event) => {
        event.preventDefault();
        const anchor = event.currentTarget;
        const target = document.querySelector(anchor.getAttribute('href').replace('/utm', ''));
        $('#utm-doc').stop().animate({
            scrollTop: (target.offsetTop - 60)
        }, 300, 'linear', () => {
            const anchors = document.querySelectorAll('nav a[href^="/utm#"]:not([href="#"])');
            anchors.forEach(anchor => anchor.classList.remove('!text-white'));
            anchors.forEach(anchor => anchor.classList.remove('bg-[#1966be]'));
            anchor.classList.add('!text-white');
            anchor.classList.add('bg-[#1966be]');

            // close the drawer?
            setDrawerOpen(false);
        });
    };

    const onDrawerOpened = () => {
        const anchors = document.querySelectorAll('nav a[href^="/utm#"]:not([href="#"])');
        const scroll = document.querySelector('#utm-doc').scrollTop;
        anchors.forEach(anchor => {
            const target = document.querySelector(anchor.getAttribute('href').replace('/utm', ''));
            if (target.offsetTop - 100 <= scroll) {
                anchors.forEach(anchor => anchor.classList.remove('!text-white'));
                anchors.forEach(anchor => anchor.classList.remove('bg-[#1966be]'));
                anchor.classList.add('!text-white');
                anchor.classList.add('bg-[#1966be]');
            }
        });
        // Attach the anchor click event listener to all anchor tags that match the selector
        anchors.forEach(anchor => anchor.addEventListener('click', handleAnchorClickDrawerAnchor));

    }

    const onDrawerClosed = () => {
        const anchors = document.querySelectorAll('nav a[href^="/utm#"]:not([href="#"])');
        anchors.forEach(anchor => anchor.removeEventListener('click', handleAnchorClickDrawerAnchor)); // not sure this is necessary since the element is destroyed when the drawer is closed
    }
    return (
        <div className={`flex flex-row items-stretch utm-section relative h-[calc(100vh-120px)] w-full overflow-hidden`}>
            <Drawer isOpen={drawerOpen}
                className={"md:hidden"}
                onAfterOpen={onDrawerOpened}
                onClose={function () {
                    onDrawerClosed();
                    setDrawerOpen(false);
                }}>
                <div className="max-w-xs w-[70vw] h-full bg-gray-50  py-2 flex flex-col p-2 gap-2 overflow-auto ">
                    <nav className='justify-center items-center' id='drawer-nav'>
                        <ul className="flex flex-col ">
                            {content.map((item, id) =>
                                <SidebarNavItem.DrawerNav item={item} key={id} getID={getID} />
                            )}
                        </ul>
                    </nav>
                </div>
            </Drawer>
            <div className='w-full fixed bg-white flex flex-row items-center justify-start md:hidden p-2'>
                <button
                    type="button"
                    onClick={() => {
                        setDrawerOpen(!drawerOpen);
                    }}
                    className="bg-white left-0 inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                >
                    <span className="sr-only">Open sidebar</span>
                    <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                        ></path>
                    </svg>
                </button>
            </div>
            <div className='hidden bg-gray-50 md:block w-[20rem] shrink-0 md:mx-1 md:px-4 md:pt-5 md:pb-8 gap-2 overflow-y-auto [&::-webkit-scrollbar]:[width:3px] [&::-webkit-scrollbar-thumb]:bg-gray-200' id='utm-sidebar'>
                <nav className='justify-center items-center'>
                    <ul className="flex flex-col ">
                        {content.map((item, id) =>
                            <SidebarNavItem item={item} key={id} getID={getID} />
                        )}
                    </ul>
                </nav>
            </div>
            <div className='px-2 flex flex-col overflow-y-auto w-full mt-[56px] md:mt-0' id='utm-doc'>
                <div className='mx-auto py-2 max-w-screen-lg pr-4 mt-5'>
                    <div className="flex items-center justify-center">
                        <picture>
                            <source srcset={`${LogoW} 1x, ${LogoW2x} 2x`} type="image/webp" />
                            <img src={Logo} alt='HandL UTM Grabber Logo' srcset={`${Logo2x} 2x`} className={`w-full h-[200px]`} />
                        </picture>
                    </div>
                    <div>
                        {content.map((item, id) =>
                            <MainTitle item={item} key={id} iid={id} getID={getID} />
                        )}
                    </div>
                    <HandLutmGrabber />
                </div>
            </div>
        </div>
    );
}

export default withLayout(UTM);