import React from 'react';

export default function Organic({ isOpened, setCurrentOpened }) {
  return (
    <div className='mb-2'>
      <h2>
        <button
          onClick={() => setCurrentOpened('organic')}
          type='button'
          className='flex items-center justify-between w-full py-2 px-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-md focus:ring-4 focus:ring-gray-200 bg-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <i className='fa fa-check-circle text-green-600 m-1'></i> Get the
            most out of your organic traffic
          </span>
        </button>
      </h2>
      <div className={isOpened ? '' : 'hidden'}>
        <div className='px-5 py-2 border border-gray-200'>
          <p className='text-gray-700'>
            HandL UTM Grabber also tracks organic traffic full referral URL as
            well as the categorical organic source (such as{' '}
            <b>Google, Bing, Instagram</b> etc.). See some{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/how-to-track-organic-traffic'
              target='_blank'
              rel='noreferrer'
            >
              basic implementation here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
    // <div className="panel panel-default">
    //     <div className="panel-heading" role="tab" id="headingOne">
    //         <h4 className="panel-title">
    //             <a role="button" data-bs-toggle="collapse" data-parent="#RWF_accordion" href="#organic">
    //                 <i className="fa fa-check-circle text-success"></i> Get the most out of your organic traffic
    //             </a>
    //         </h4>
    //     </div>

    //     <div id="organic" className="panel-collapse collapse">
    //         <div className="panel-body"> HandL UTM Grabber also tracks organic traffic full referral URL as well as the categorical organic source (such as <b>Google, Bing, Instagram</b> etc.). See some <a href="https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/how-to-track-organic-traffic" target="_blank">basic implementation here</a>. </div>
    //     </div>
    // </div>
  );
}
