import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import Plans from '../LicenseKey/Plans';
import FreeFeatures from '../Common/FreeFeatures';
import PremiumFeatures from '../Common/PremiumFeatures';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLazyGetPlansQuery } from '../../features/plan/planAPI';
import NetworkErrorScreen from '../Error/NetworkErrorScreen';
import Modal from '../Modal/Modal';
import ImageInvoiceW from "../../images/upgrade_subscription/invoice@1x.webp";
import ImageInvoiceW_2x from "../../images/upgrade_subscription/invoice@2x.webp";
import ImageInvoice from "../../images/upgrade_subscription/invoice@1x.png";
import ImageInvoice_2x from "../../images/upgrade_subscription/invoice@2x.png";
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Subscriptions() {
  const navigate = useNavigate();
  const { accountType } = useSelector(state => state.plan)
  const [getPlans, {
    data: plans = {
      subscriptions: [],
      invoices: [],
      billings: {},
    },
    isLoading: plansLoading,
    isError: plansError,
  }] = useLazyGetPlansQuery();

  const { isAuthenticated, user } = useSelector(
    (state) => state.auth.data || {}
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      getPlans(null, true);
    } else if (!isAuthenticated) {
      navigate('/');
    }
  }, []);
  // Check and see if paypal_upgrade=1 is in the URL if so then show a modal telling them to pay proration invoice
  const [searchParams, setSearchParams] = useSearchParams();
  const closeProrationReminderModal = () => {
    searchParams.delete('paypal_upgrade');
    setSearchParams(searchParams);
  }

  if (plansError) return <NetworkErrorScreen onRetry={() => getPlans()} loading={plansLoading} />;
  return (
    <div id='license' className='container mx-auto'>
      <Modal isOpen={Boolean(searchParams.get('paypal_upgrade') === '1')} onClose={closeProrationReminderModal}>
        <div
          className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-xl text-left font-[poppins] shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white pointer-events-auto"
        >
          <div className="p-4 sm:p-6">
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 lg:max-w-screen-2xl mx-auto">
              <h2 className="font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl text-center">Thank You for Upgrading Your Subscription!</h2>
              <p className="text-lg md:text-xl">
                You have successfully upgraded your plan for the next billing cycle. To prorate your account for this year, please follow these three simple steps to start utilizing your new plan right away:
              </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
              <div className='text-left'>
                <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 1: Check Your Inbox</h3>
                <p className="text-base min-[2000px]:text-lg">
                  We've sent you a proration invoice via PayPal. Please check your inbox (and the spam/junk folder, just in case) to find this email.
                </p>
              </div>
              <div className='text-left pt-2'>
                <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 2: Locate Our PayPal Invoice</h3>
                <p className="text-base min-[2000px]:text-lg">
                  Look for an email from service@paypal.com with the subject line: "Invoice from HandL UTM Grabber v3"
                </p>
                <p className="text-lg min-[2000px]:text-xl pt-2">
                  Here's what it looks like:
                </p>
                <picture className=''>
                  <source srcset={`${ImageInvoiceW} 1x, ${ImageInvoiceW_2x} 2x`} type="image/webp" />
                  <img className='w-full h-auto' src={ImageInvoice} alt="The email we sent to you" srcset={`${ImageInvoice_2x} 2x`} />
                </picture>
              </div>
              <div className='text-left pb-3 pt-2'>
                <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 3: Complete Your Payment</h3>
                <p className="text-base min-[2000px]:text-lg">
                  Open the email and click on the “View and Pay Invoice” button to proceed with your payment. This will ensure your subscription upgrade is applied without delay.
                </p>
              </div>
              <hr />
              <div className='text-left pt-3'>
                <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Need Help?</h3>
                <p className="text-base min-[2000px]:text-lg">
                  If you haven't received the email within a few minutes, or if you encounter any issues, please contact our support team via <a
                    className='cursor-pointer text-blue-500'
                    onClick={() => {
                      window.$crisp.push(['do', 'chat:toggle']);
                    }}
                  >chat.</a>
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-4">
              <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
                <button onClick={closeProrationReminderModal} className="hover:bg-gray-100 rounded px-3 py-2 font-medium border border-gray-200 text-sm" as="button">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div></Modal>
      <div className='row'>
        <div className='col-md-12 text-center'>
          {plansLoading && (
            <div>
              <div className='fa-3x'>
                <i className='fa fa-spinner fa-spin'></i>
              </div>
              <p>Please wait while we are pulling your account data...</p>
            </div>
          )}
          {!plansLoading && accountType == 'paid' && (
            <Elements stripe={promise}>
              <ElementsConsumer>
                {({ elements, stripe }) => (
                  <Plans
                    refetchPlans={() => getPlans(null, false).unwrap()}
                    plans={plans}
                    elements={elements}
                    stripe={stripe}
                    accountType={accountType}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          )}
          {!plansLoading && accountType == 'free' && (
            <div className='lg:flex md:flex sm:block offer-box'>
              <div className='col-md-6'>
                <div className='panel panel-default'>
                  <div className='panel-heading'>
                    <h3 className='my-4 text-[24px] font-medium'>
                      UTM Grabber V2 (FREE)
                    </h3>
                  </div>
                  <div className='panel-body greencheck nope text-left'>
                    <FreeFeatures />
                  </div>
                  <div className='panel-footer my-4'>
                    <a
                      className='btn btn-sm btn-primary py-3 cursor-pointer'
                      href='https://downloads.wordpress.org/plugin/handl-utm-grabber.latest-stable.zip'
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='panel panel-success'>
                  <div className='panel-heading'>
                    <h3 className='my-4 text-[24px] font-medium'>
                      UTM Grabber V3
                    </h3>
                  </div>
                  <div className='panel-body greencheck text-left'>
                    <PremiumFeatures />
                  </div>
                  <div className='panel-footer my-4'>
                    <button
                      className='btn btn-sm btn-primary cursor-pointer'
                      onClick={() => navigate('/#prices')}
                    >
                      Upgrade Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
