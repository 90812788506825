import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import { useLazyGetPlansQuery, useLazyGetCustomInvoiceQuery } from "../../features/plan/planAPI";
import NetworkErrorScreen from "../Error/NetworkErrorScreen";
import { toast } from "react-toastify";
export default function Billings() {
  const { isAuthenticated, user, } = useSelector(
    (state) => state.auth.data || {}
  );
  const [getPlans, {
    data: plans = {
      invoices: [],
    },
    isLoading: plansLoading,
    isError: plansError,
  }] = useLazyGetPlansQuery();

  const [getCustomInvoice, {
    isFetching: customInvoiceLoading,
    originalArgs,
  }] = useLazyGetCustomInvoiceQuery();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated && user) {
      getPlans(null, true);
    } else if (!isAuthenticated) {
      navigate('/');
    }
  }, []);
  const downloadCustomInvoice = ({ invoice_id, subscription_id }) => {
    getCustomInvoice({ invoice_id, subscription_id }).unwrap()
      .then((response) => {
        const url = response.url
        // Force download on a new page 
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.remove();

      })
      .catch((error) => {
        toast.error(`
        Error downloading invoice: ${error}`, {
          position: 'bottom-right',
          autoClose: 3000
        });
      });
  }
  const [receiptUnavailableModal, setReceiptUnavailableModal] = useState(false);
  if (plansError) return <NetworkErrorScreen onRetry={() => getPlans()} loading={plansLoading} />;
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-[70%] max-w-[40rem] m-auto">
      <Modal isOpen={receiptUnavailableModal} onClose={() => setReceiptUnavailableModal(false)}>
        <div className="flex flex-col p-3 font-[poppins]">
          <div className="text-center w-full bg-white z-[500] relative text-sm">
            To download the invoice, please input your billing information under <Link className="text-blue-600" to={"/infos/billings"}>Billings</Link>.
          </div>
        </div>
      </Modal>
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Amount
            </th>
            <th scope="col" className="px-6 py-3">
              Service
            </th>
            <th scope="col" className="px-6 py-3">
              Date
            </th>
            <th scope="col" className="px-6 py-3">
              Invoice
            </th>
          </tr>
        </thead>
        <tbody>
          {plansLoading ? (
            <tr>
              <td className="px-6 py-4 text-center" colSpan={4}>
                <div className="fa-3x">
                  <i className="fa fa-spinner fa-spin"></i>
                  <p>Please wait while we are pulling your account data...</p>
                </div>
              </td>
            </tr>
          ) : plans.invoices.length === 0 ? (
            <tr>
              <td className="px-6 py-4 text-center" colSpan={4}>
                You do not have any invoices yet. Your invoices will appear here
                when they become available.
              </td>
            </tr>
          ) : (
            plans.invoices.map((invoice, index) => {
              return (
                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {invoice.amount_paid?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    }) || "N/A"}
                  </th> 
                  <td className="px-6 py-4 text-gray-900">
                    {invoice.plan_name}
                  </td>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                  <td className="px-6 py-4">
                    {new Date(invoice.date * 1000).toDateString()}
                  </td>
                  <td className="px-6 py-4">
                    {
                      (customInvoiceLoading && originalArgs?.invoice_id === invoice.id) ? <>
                        <i className="fa fa-spinner fa-spin"></i>
                      </> : <>
                        {invoice.amount_paid !== null &&
                          (invoice.amount_paid !== undefined ? (
                            invoice.hasCustomBillingInfo ? (
                              <button
                                onClick={() =>
                                  downloadCustomInvoice({
                                    subscription_id: invoice.subscription_id,
                                    invoice_id: invoice.id,
                                  })
                                }
                              >
                                <i className="fa fa-download"></i>
                              </button>
                            ) : invoice.receipt_url ? (
                              <Link to={invoice.receipt_url} target="_blank">
                                <i className="fa fa-download"></i>
                              </Link>
                            ) :
                              // Account info not filled out and no receipt url
                              <button className="text-gray-300" onClick={() => setReceiptUnavailableModal(true)}>
                                <i className="fa fa-download"></i>
                              </button>
                          ) : null)}</>
                    }
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
