import { useSelector } from "react-redux";
import { useOnChargeMutation } from "../../features/payment/paymentAPI";
import { useSearchParams } from "react-router-dom";

const useCheckout = ({ setCurrentStep, coupon, resetActiveCoupon, setSelectedMethod }) => {
    const { activeCoupon } = useSelector((state) => state.coupon);
    const {
        quantity,
        tmpUser,
        stripe_plan_id,
        isSupportable,
        prices,
        multiplier,
    } = useSelector((state) => state.auth.data || {});
    let [_,setSearchParams] = useSearchParams();
    const [onPay] = useOnChargeMutation();
    const createCustomer = async () => {
        const result = await onPay({
            pay_id: 'stripe',
            membership: true,
            type: 'cus',
            email: tmpUser.email,
            name: tmpUser.first_name,
            marketing: window.HandL?.getAll(),
        }).unwrap();
        return result.customer
    };

    function onSubscriptionComplete(payload) {
        setCurrentStep(2);
        setSelectedMethod('cc');
        setSearchParams({
            pay_id: 'stripe',
            subscription_id: payload.subscription.id,
            thanks: 1,
            ...isSupportable && {
                support: "1"
            }
        });
        let amount, txid;

        if (payload.paymentIntent !== undefined) {
            amount = payload.paymentIntent.amount;
            txid = payload.paymentIntent.id;
        } else if (payload.subscription !== undefined) {
            amount = payload.subscription.latest_invoice.amount_paid;
            txid = payload.subscription.latest_invoice.charge;
        } else {
            //hope never goes here :)
            amount = 1;
            txid = 'Not Captured';
        }

        window.gtag('event', 'purchase', {
            transaction_id: txid,
            value: amount / 100,
            currency: 'USD',
            tax: 0.0,
            shipping: 0,
            items: [
                {
                    id: 'HandL002',
                    name: 'HandL UTM Grabber V3',
                    brand: 'HandL',
                    category: 'WordPress Plugins',
                    variant: 'V3',
                    list_position: 2,
                    quantity,
                    price: amount / 100,
                },
            ],
            email: tmpUser.email,
        });

        window.gtag('event', 'conversion', {
            send_to: 'AW-604754791/ySYVCOmm8NoBEOemr6AC',
            transaction_id: txid,
            value: amount / 100,
            currency: 'USD',
        });

        window.fbq('track', 'Purchase', { currency: 'USD', value: amount / 100 });

        window.twq('event', 'tw-nytpn-onjy3', {
            value: amount / 100,
            currency: 'USD',
            conversion_id: txid,
            email: tmpUser.email,
            // phone_number: tmpUser.phone_number,
        });

        window.rdt('track', 'Purchase', {
            currency: 'USD',
            itemCount: 1,
            value: amount / 100,
        });

    }
    async function createSubscription({ customerId, paymentMethodId, quantity, address }) {
        const mainPriceId = stripe_plan_id;
        const supportPriceId = prices.support.stripe;
        localStorage.setItem('paidMethod', 'stripe');
        try {
            const response = await onPay({
                pay_id: 'stripe',
                membership: true,
                type: 'sub',
                email: tmpUser.email,
                customerId: customerId,
                paymentMethodId: paymentMethodId,
                quantity: quantity,
                coupon,
                campaign_key: activeCoupon?.campaignKey,
                mainPriceId: mainPriceId,
                multiplier: multiplier ? 2 : 1,
                marketing: window.HandL?.getAll(),
                address: address,
                ...isSupportable && {
                    supportPriceId: supportPriceId,
                }
            }).unwrap();
            const result = response.mainSubscription;
            console.log(result);
            onSubscriptionComplete({
                paymentMethodId: paymentMethodId,
                quantity: quantity,
                subscription: result,
                subscription_status: result.status,
            });
        } catch (error) {
            console.log(error);
            if (error === "This promotion code is not specified for use with this customer.") {
                resetActiveCoupon();
            }
            throw new Error(error?.message ?? error)
        }
    }
    return { createSubscription, createCustomer }
}

export default useCheckout;