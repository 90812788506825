import React from 'react'

function PromoSkeleton() {
    return (
        <div className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3 font-[poppins]" >
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 lg:max-w-screen-2xl mx-auto animate-pulse">
                <div className="flex justify-center items-center gap-1">
                    <div className="bg-gray-300 h-[24px] md:h-[32px] min-[2000px]:h-[48px] w-8"></div>
                    <div className="bg-gray-300 h-8 md:h-10 min-[2000px]:h-12 w-[300px] md:w-[400px] min-[2000px]:w-[500px]"></div>
                    <div className="bg-gray-300 h-[24px] md:h-[32px] min-[2000px]:h-[48px] w-8"></div>
                </div>
                <div className="bg-gray-300 h-4 md:h-6 min-[2000px]:h-8 w-3/4 mx-auto"></div>
            </div>

            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5 animate-pulse">
                <div className='bg-gray-300 h-4 min-[2000px]:h-6 w-full'></div>
                <div className='bg-gray-300 h-4 min-[2000px]:h-6 w-3/4'></div>

                <div className='pt-2'>
                    <div className='bg-gray-300 h-4 md:h-6 w-1/2 mb-2.5'></div>
                    <div className='space-y-2'>
                        <div className="bg-gray-300 h-3 min-[2000px]:h-4 w-full"></div>
                        <div className="bg-gray-300 h-3 min-[2000px]:h-4 w-full"></div>
                        <div className="bg-gray-300 h-3 min-[2000px]:h-4 w-full"></div>
                    </div>
                </div>

                <div className='pt-2'>
                    <div className='bg-gray-300 h-4 md:h-6 w-1/2 mb-2.5'></div>
                    <div className='space-y-2'>
                        <div className="bg-gray-300 h-3 min-[2000px]:h-4 w-full"></div>
                        <div className="bg-gray-300 h-3 min-[2000px]:h-4 w-full"></div>
                        <div className="bg-gray-300 h-3 min-[2000px]:h-4 w-full"></div>
                    </div>
                </div>

                <div className="flex justify-center mt-1.5">
                    <div className="bg-gray-300 h-10 md:h-12 min-[2000px]:h-14 w-48 rounded-md"></div>
                </div>
                <div className='pt-2'>
                    <div className="bg-gray-300 h-4 md:h-6 w-2/3 mb-2"></div>
                    <div className="bg-gray-300 h-40 w-full rounded-md"></div>
                </div>

                <div className="flex justify-center mt-1.5">
                    <div className="bg-gray-300 h-10 md:h-12 min-[2000px]:h-14 w-48 rounded-md"></div>
                </div>

                <hr className="border-gray-300" />
            </div>

            <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-5 md:max-w-[1080px] mx-auto py-5 animate-pulse">
                <div className='bg-gray-300 h-6 md:h-8 min-[2000px]:h-10 w-full'></div>
                <div className='bg-gray-300 h-4 min-[2000px]:h-6 w-3/4'></div>
            </div>
        </div>
    )
}

export default PromoSkeleton