import React from "react";
import { ImgW } from "../js/index";
import DataTracking from "../images/features/data_tracking.png";
import GDPR from "../images/features/GDPR.png";
import GA from "../images/features/GA.png";
import clientID from "../images/features/clientID.png";
import connector from "../images/features/connector.png";
import cookie_time from "../images/features/cookie_time.png";
import cookie from "../images/features/cookie.png";
import crm from "../images/features/crm.png";
import domain_subdomain from "../images/features/domain_subdomain.png";
import domain from "../images/features/domain.png";
import ecommerce from "../images/features/ecommerce.png";
import first_touch from "../images/features/first_touch.png";
import form from "../images/features/form.png";
import organic_traffic from "../images/features/organic_traffic.png";
import php from "../images/features/php.png";
import s2s from "../images/features/s2s.png";
import server_and_client from "../images/features/server_and_client.png";
import shortcode from "../images/features/shortcode.png";
import wp from "../images/features/wp.png";
import LightWeight from "../images/features/light_weight.png";
import FBCAPI from "../images/features/fbcapi.png";

import DataTrackingW from "../images/features/data_tracking.webp";
import GDPRW from "../images/features/GDPR.webp";
import GAW from "../images/features/GA.webp";
import clientIDW from "../images/features/clientID.webp";
import connectorW from "../images/features/connector.webp";
import cookie_timeW from "../images/features/cookie_time.webp";
import cookieW from "../images/features/cookie.webp";
import crmW from "../images/features/crm.webp";
import domain_subdomainW from "../images/features/domain_subdomain.webp";
import domainW from "../images/features/domain.webp";
import ecommerceW from "../images/features/ecommerce.webp";
import first_touchW from "../images/features/first_touch.webp";
import formW from "../images/features/form.webp";
import organic_trafficW from "../images/features/organic_traffic.webp";
import phpW from "../images/features/php.webp";
import s2sW from "../images/features/s2s.webp";
import server_and_clientW from "../images/features/server_and_client.webp";
import shortcodeW from "../images/features/shortcode.webp";
import wpW from "../images/features/wp.webp";
import LightWeightW from "../images/features/light_weight.webp";
import FBCAPIW from "../images/features/fbcapi.webp";

function Features() {
  return (
    <section className="row the-watch" id="features">
      <div className="container max-w-screen-xl mx-auto px-12">
        <div className="row">
          <div className="col-md-12 greencheck text-center">
            <h2 className="wp-title text-center text-[35px] mb-[30px] font-medium">
              #1 UTM Tracking Plugin for WordPress*
            </h2>
            <p className="text-[16px] mb-[8px]">
              HandL UTM Grabber is the best UTM tracking plugin for WordPress.
              Here are the prominent features that makes HandL UTM Grabber V3
              the most powerful WordPress UTM tracking plugin in the market.
            </p>
            <p className="text-[14px] mb-[20px]">
              *Based on rates and downloads in WordPress
            </p>
            <div className="md:grid md:grid-cols-2 lg:grid-cols-3">
              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={LightWeightW} fallback={LightWeight} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Light Weight
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Performance is our motto. Just functional code, no
                  non-essential licensing related codes/logics 😊.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={DataTrackingW} fallback={DataTracking} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Data Tracking
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Track 25+ parameters with one click setup.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={GDPRW} fallback={GDPR} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  EU GDPR Compliant
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  It takes only one click to be GDPR compliant and compatible
                  with other consent plugins.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={shortcodeW} fallback={shortcode} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Custom parameters
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Bring your own parameter to track e.g. gclid, fbclid, affid,
                  ValueTrack etc.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={cookie_timeW} fallback={cookie_time} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Cookie Time
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  How long you want to track the parameters? 24 hours, 1 day,
                  365 days. You name it!
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={domainW} fallback={domain} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Cross-domain and iframe
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  You know how hard to send UTMs from one domain to another? It
                  is no more.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={s2sW} fallback={s2s} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Site 2 Site Postback
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Pass your tracked data from WP to other APIs making POST
                  requests. Great for affiliate tracking.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={domain_subdomainW} fallback={domain_subdomain} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Domain Level Tracking
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  All cookies by default associated with the main domain to
                  maximize the coverage and access.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={server_and_clientW} fallback={server_and_client} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Server & Client Side
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Tracking is bullet-proof using both server and client side
                  tracking. No more missing data.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={organic_trafficW} fallback={organic_traffic} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Organic Traffic
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Tracks source and referrer URL from your organic traffic.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={clientIDW} fallback={clientID} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  GA ClientID
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Tag your leads with respect to their gaclientid to track them
                  in Google Analytics.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={first_touchW} fallback={first_touch} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  First/Last Touch Attribute
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Track all the UTMs by first and last touch values. No setup
                  required.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={cookieW} fallback={cookie} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Consent Management
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Seamlessly compatible with leading cookie consent management plugins such as Complianz, CookieBot, and many others.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={wpW} fallback={wp} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  WordPress Support
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Latest WordPress support and compatible with other plugins.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={phpW} fallback={php} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  PHP 7.3+ Support
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Latest PHP support compatible.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={ecommerceW} fallback={ecommerce} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Ecommerce Tracking
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Capture and associate all the tracked data with WooCommerce
                  orders. No setup required.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={GAW} fallback={GA} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  GA Offline Conversion
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Sync WooCommerce orders to Google Analytics using advanced
                  ecommerce tracking.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <div className="flex justify-center">
                  <div className="w-[150px] h-[150px]">
                    <ImgW src={FBCAPIW} fallback={FBCAPI} />
                  </div>
                </div>
                <h3 className="text-[18px] font-medium my-[10px]">
                  FB Conversion API (FB CAPI)
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Send Lead and Purchase event conversions to Facebook.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={crmW} fallback={crm} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Works with most CRMs
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Zoho, SendGrid, ActiveCampaign, MailChimp, GetResponse,
                  ConvertKit, Drop, MailerLite, Salesforce ready.
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={connectorW} fallback={connector} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Automation / Connectors
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Send form data (Contact Form 7, Ninja Form, Gravity Form,
                  Formidable) to Zapier or Integromat to integrate with 100s of
                  other tools.{" "}
                </p>
              </div>

              <div className="border border-[#ddd] rounded-xl m-2 p-5 flex flex-col">
                <ImgW src={formW} fallback={form} />
                <h3 className="text-[18px] font-medium my-[10px]">
                  Works with most forms
                </h3>
                <p className="text-[15px] font-[400] flex-1 text-left">
                  Opt-in forms such as Contact Form 7, Ninja Form, Gravity Form,
                  WPForms, Elementor, Thrive Leads, Caldera Forms, Formidable,
                  Ultimate Member, Cognito Forms, FormTitan, Divi, JotForms, Nex
                  Forms. Very easy setup (video tutorials available)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
