import React from 'react';
import HandLutmGrabber from "./HandLUTMGrabber";

const MainTitle = ({item, level=null, getID, iid})=> {
        return (
            item.content !== '' &&
            <div>
                <h2 id={item.id ? item.id : getID(item.title)} className={level ? 'font-medium text-2xl mb-2 mt-6' : 'font-medium text-[2rem] mb-2 mt-6 underline'}>{item.title}</h2>
                {item.content}

                {item.subs && item.subs.map((item, id) =>
                    <MainTitle item={item} key={id} iid={id} getID={getID} level={2} />
                )}
                
                {
                    iid % 5 === 3 &&
                    <HandLutmGrabber />
                }
            </div>
        );
}

export default MainTitle;