import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getParameterByName } from '../../js/params';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useOnChargeMutation } from '../../features/payment/paymentAPI';

const ThankYou = () => {
  const [state, setState] = useState({
    result: 0,
    msg: '',
    email: '',
  });
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [paypalProcessing, setPaypalProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  let [searchParams] = useSearchParams();
  const { isSupportable, prices, tmpUser } =
    useSelector((state) => state.auth.data || {});
  const [onPay] = useOnChargeMutation();
  useEffect(() => {
    const startFunc = async () => {
      if (localStorage.getItem('paidMethod')) {
        setPaymentMethod(localStorage.getItem('paidMethod'))
      } else {
        if (localStorage.getItem('isPaypalLastPay')) {
          setPaymentMethod('paypal')
        } else {
          setPaymentMethod('stripe')
        }
      }
      let pay_id = getParameterByName('pay_id');
      let orderid = getParameterByName('token');
      let subscriptionId = getParameterByName('subscription_id');
      if (pay_id == "paypal") {
        //This is PayPal
        try {
          console.log(tmpUser);
          const { gtag, fbq, twq, rdt, twttr, HandL } = window;
          const data = await onPay({
            pay_id: 'paypal_capture',
            token: orderid,
            subscriptionId,
            marketing: typeof HandL === 'object' ? HandL.getAll() : {},
          }).unwrap();
          console.log(data.status);
          if (data.status === 'COMPLETED' || data.status === 'ACTIVE') {
            setState({ ...state, email: data.email, result: 1 });

            let amount, txid;
            let quantity = data.quantity;
            if (!subscriptionId) {
              txid = data.result.id;
              amount = data.result.billing_info.last_payment.amount.value;
            } else {
              txid = data.result.purchase_units[0].payments.captures[0].id;
              amount =
                data.result.purchase_units[0].payments.captures[0].amount.value;
            }

            gtag?.('event', 'purchase', {
              transaction_id: txid,
              value: amount,
              currency: 'USD',
              tax: 0.0,
              shipping: 0,
              items: [
                {
                  id: 'HandL002',
                  name: 'HandL UTM Grabber V3',
                  brand: 'HandL',
                  category: 'WordPress Plugins',
                  variant: 'V3',
                  list_position: 2,
                  quantity: quantity,
                  price: amount,
                },
              ],
            });

            gtag?.('event', 'conversion', {
              send_to: 'AW-604754791/ySYVCOmm8NoBEOemr6AC',
              transaction_id: txid,
              value: amount,
              currency: 'USD',
            });

            fbq?.('track', 'Purchase', { currency: 'USD', value: amount });

            twq('track', 'Purchase', {
              value: amount,
              currency: 'USD',
              num_items: '1',
            });

            twttr?.conversion.trackPid('o4yaj', {
              tw_sale_amount: amount,
              tw_order_quantity: 1,
            });

            rdt?.('track', 'Purchase', {
              currency: 'USD',
              itemCount: 1,
              value: amount,
            });

          } else if (data.status === 'ERROR') {
            //This is probably due to double processing, go check log :)
            setState({ ...state, result: -1 });
          }
        } catch (err) {
          //setTimeout( function () {getClientSecret (tries+1)} , 500)
          console.log('error', err);
        }
      } else {
        console.log({ tmpUser });
        setState({ ...state, result: 1, email: tmpUser.email });
      }
    };
    if (getParameterByName('thanks')) startFunc();
  }, []);

  const cancelSupportPayment = () => {
    localStorage.removeItem('paidMethod');
    searchParams.append("support", "0")
    searchParams.append("email",tmpUser ? tmpUser.email : localStorage.getItem('tmpUser')?.email)
    searchParams.delete("thanks")
    navigate({
      pathname: "/w/onboarding/special-offer",
      search: createSearchParams(searchParams).toString()
    })
  }

  useEffect(() => {
    if (state.result !== 1) return;
  
    const isPaidWithPaypal = localStorage.getItem('paidMethod') === 'paypal';
  
    if (isPaidWithPaypal && isSupportable) return;
  
    localStorage.removeItem('paidMethod');
    localStorage.removeItem('isPaypalLastPay');
    searchParams.append("email",tmpUser ? tmpUser.email : localStorage.getItem('tmpUser')?.email)  
    searchParams.delete('thanks');
  
    const pathname =
      searchParams.get('support') === '1'
        ? '/support/welcome'
        : '/w/onboarding/special-offer';
  
    navigate({
      pathname,
      search: createSearchParams(searchParams).toString(),
    });
  }, [state.result]);
  
  const onPaySupport = async (event) => {
    event.preventDefault();
    setPaypalProcessing(true);
    try {
      const response = await onPay({
        pay_id: 'paypal',
        email: tmpUser ? tmpUser.email : localStorage.getItem('tmpUser').email,
        planId: prices.support.paypal,
        membership: prices.support.membership,
        quantity: prices.support.quantity,
        multiplier: 1,
        redirect_urls: {
          return_url: '/?thanks=1&pay_id=paypal&support=1',
          cancel_url: '/w/onboarding/special-offer',
        }
      }).unwrap();

      const { gtag } = window;

      if (gtag) {
        gtag('event', 'begin_checkout', {
          items: [
            {
              id: 'HandL002',
              name: 'HandL UTM Grabber V3',
              brand: 'HandL',
              category: 'WordPress Plugins',
              variant: 'V3',
              list_position: 2,
              quantity: prices.support.quantity,
              price: `${prices.support.product_price}.00`,
            },
          ],
          email: state.email,
        });
      }

      let approveLink = response.links.filter(
        (link) => link.rel === 'approve'
      );
      localStorage.removeItem('paidMethod');
      localStorage.setItem('isPaypalLastPay', true);

      window.location = approveLink[0].href;
    } catch (error) {
      // handle error
      localStorage.removeItem('paidMethod');
      console.log(error);
    }
  };

  return (
    <div className='container max-w-screen-xl mx-auto px-12 h-[calc(100vh-363.18px)] overflow-y-auto flex flex-col justify-center'>
      <div className='flex flex-col items-center text-center p-t-20 p-b-20'>
        {state.result === 0 && (
          <div>
            <h1 className='text-4xl font-extrabold mb-8'>
              We are processing your payment...{' '}
            </h1>
            <p className='text-xl font-bold mb-4'>
              Please hold while we are working on your order..
            </p>
          </div>
        )}
        {state.result === 1 &&
          (localStorage.getItem('paidMethod') === 'paypal' && isSupportable ? (
            <div>
              <h1 className='text-4xl font-extrabold mb-8'>
                Proceed with the Support upgrade
              </h1>
              <p className='text-xl font-bold mb-4'>
                You'll be redirected to PayPal to complete the order.
              </p>
              <p className='text-xl mb-4 font-bold'>Please note! Paypal payments may take a few minutes to process</p>
              <div className='flex justify-center space-x-2'>
                <button
                  href=''
                  onClick={cancelSupportPayment}
                  className={`btn btn-rounded btn-block handlPayButton  selected hover:!bg-gray-500 mt-2`}
                >
                  Cancel
                </button>
                <button
                  href=''
                  onClick={onPaySupport}
                  className={`btn btn-rounded btn-block handlPayButton`}
                >
                  {paypalProcessing ? (
                    <>
                      <i className='fa fa-spin fa-spinner'></i> Processing...
                    </>
                  ) : (
                    <span>Pay Support</span>
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className='mb-auto'>
              <i className='fa fa-spin fa-3x fa-spinner'></i>
            </div>
          ))}
        {state.result === -1 && (
          <div>
            <h1 className='text-4xl font-extrabold mb-8'>Uh oh! </h1>
            <p className='text-xl font-bold mb-4'>
              Some unexpected problem, please inform support.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankYou;
