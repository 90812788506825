import React from 'react';
import { useFormik } from 'formik';
import Modal from '../Modal/Modal';
import EmojiRating from '../Common/EmojiRating';
import { useSelector, useDispatch } from 'react-redux';
import { closeChatRatingModal, markChatAsRated } from "../../features/chat/chatSlice"
import { useRateMessageMutation } from "../../features/chat/chatAPI"
import { useEffect } from 'react';
import { toast } from 'react-toastify';
function RateChat() {
  const dispatch = useDispatch();
  const { isReviewModalOpen, currentSelectedRating, currentChatId, currentSession: {
    sessionId
  } } = useSelector((state) => state.chat)
  const [rateMessage, { isLoading }] = useRateMessageMutation();
  const handleCloseModal = () => {
    dispatch(closeChatRatingModal())
  };
  const formik = useFormik({
    initialValues: {
      rating: currentSelectedRating,
      review: '',
    },
    onSubmit: async (values, { resetForm, form }) => {
      const { rating, review } = values;
      try {
        const response = await rateMessage({ chat_id: currentChatId, session: sessionId, rating, rating_message: review }).unwrap();
        toast.success(response.message, {
          position: 'bottom-right',
          autoClose: 3000
        });
        dispatch(markChatAsRated(currentChatId))
        handleCloseModal();
        setTimeout(() => formik.resetForm(), 500)
      }
      catch (error) {
        toast.error(`Error submitting review: ${error?.message}`, {
          position: 'bottom-right',
          autoClose: 3000
        });
      }
    },
  });
  useEffect(() => {
    formik.setFieldValue('rating', currentSelectedRating);
  }, [currentSelectedRating,isReviewModalOpen]);

  return (
    <Modal isOpen={isReviewModalOpen} onClose={handleCloseModal} unmountOnClose>
      <div className="flex flex-col max-w-6xl w-[90vw] font-[poppins]">
        <div className="px-4 pb-4 pt-5 sm:p-6 flex items-center justify-between border-b w-full border-gray-200">
          <div className="flex items-center justify-center flex-1">
            <h2 className="text-lg font-medium leading-6 text-gray-900 ">
              Was this response useful? How would you rate your experience?
            </h2>
          </div>
          <button className="text-gray-500 transition hover:text-gray-700 " onClick={handleCloseModal}>
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div className="text-center w-full bg-white z-[500] relative">
          <div className="p-4 sm:p-6">
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-row items-center justify-center">
                <EmojiRating
                  rating={formik.values.rating}
                  onClick={(rating) => {
                    formik.setFieldValue('rating', rating);
                  }}
                />
              </div>
              <textarea
                placeholder={formik.values.rating <= 3 ? "What was the issue with the response? How could it be improved?" : "What do you like about the response?"}
                rows="3"
                className="h-[90px] overflow-y-hidden mb-1 mt-4 w-full resize-none rounded-md "
                name="review"
                onChange={formik.handleChange}
                value={formik.values.review}
              ></textarea>
            </form>
            <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
              <div className="flex items-center gap-3">
                <button disabled={isLoading} className="rounded-md bg-white border-gray-200 border hover:bg-gray-50 px-3 py-2" as="button" onClick={formik.submitForm}>
                  <div className="flex w-full gap-2 items-center justify-center text-sm font-medium">
                    Submit feedback
                    {isLoading && <i className="fa fa-spin fa-spinner" />}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RateChat;
