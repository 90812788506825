import React, { useMemo, useState, useEffect } from 'react';
import PayFlowHeader from './PayFlowHeader';
import PayFlowMain from './PayFlowMain';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ThankYou from './ThankYou';
import { setStoreData } from '../../store/auth';
import { withLayout } from '../Layout';

const payFlowOptions = {
  other: [
    {
      stepNumber: 1,
      title: 'Confirm Payment',
      subtitle: 'Billing Details',
    },
    {
      stepNumber: 2,
      title: 'Thank You',
      subtitle: 'Thank you for purchasing',
    },
  ],
  paypalSupport: [
    {
      stepNumber: 1,
      title: 'Confirm First Payment',
      subtitle: 'Payment For Selected Plan',
    },
    {
      stepNumber: 2,
      title: 'Confirm Second Payment',
      subtitle: 'Payment For Support',
    },
    {
      stepNumber: 3,
      title: 'Thank you',
      subtitle: 'Thank you for purchasing',
    },
  ],
};

const PayFlow = () => {
  const [selectedFlow, setSelectedFlow] = useState('other');
  let [searchParams] = useSearchParams();
  const payflowOption = useMemo(
    () => payFlowOptions[selectedFlow],
    [selectedFlow]
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedMethod, setSelectedMethod] = useState('');
  const isSupportable = useMemo(
    () => localStorage.getItem('isSupportable') === 'true',
    [localStorage.getItem('isSupportable')]
  );

  const isPaypalLastPay = useMemo(
    () => localStorage.getItem('isPaypalLastPay') === 'true',
    [localStorage.getItem('isPaypalLastPay')]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, showPayment } = useSelector(
    (state) => state.auth.data || {}
  );

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(
        setStoreData({
          data: {
            showLogin: true,
          },
        })
      );
      navigate('/');
    }
  }, []);

  useEffect(() => {

    if (searchParams.get("thanks") === "1") {
      console.log('selectedMethod:', selectedMethod);
      if (selectedMethod === 'cc') {
        setSelectedFlow('other');
        setCurrentStep(2);
      } else {
        setSelectedFlow(isSupportable ? 'paypalSupport' : 'other');
        setCurrentStep(isSupportable ? (isPaypalLastPay ? 3 : 2) : 2);
        setSelectedMethod('paypal');
      }
    }
    else {
      if (!showPayment) {
        navigate('/#prices');
      }
    }
  }, [isSupportable, isPaypalLastPay, selectedMethod]);

  return (
    <div className='mt-[30px]'>
      <PayFlowHeader payflowOption={payflowOption} currentStep={currentStep} />
      {searchParams.get("thanks") === "1" ? (
        <ThankYou />
      ) : (
        <>
          <PayFlowMain
            selectedMethod={selectedMethod}
            setCurrentStep={setCurrentStep}
            setSelectedMethod={setSelectedMethod}
            currentStep={currentStep}
            setSelectedFlow={setSelectedFlow}
          />
        </>
      )}
    </div>
  );
};

export default withLayout(PayFlow);
