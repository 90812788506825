import React, { useEffect, useState } from 'react';
import { useOnUpgradeMutation } from '../../features/payment/paymentAPI';
import Modal from '../Modal/Modal';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
function ProrationModal({ prorationProps, onClose }) {
    const [
        previewProration,
        {
            error: upgradePreviewError,
            isLoading: upgradePreviewLoading,
        },
    ] = useOnUpgradeMutation();
    const [prorateSubscriptionLoading, setProrateSubscriptionLoading] = useState(false);
    const [
        prorateSubscription,
    ] = useOnUpgradeMutation();
    const [upgradePreviewPayload, setUpgradePreviewPayload] = useState(null);

    useEffect(() => {
        if (prorationProps) {
            const { subID, priceID, pay_id } = prorationProps;
            previewProration(
                {
                    pay_id: pay_id,
                    type: "preview",
                    subID: subID,
                    priceId: priceID,
                }
            ).unwrap().then((response) => {
                setUpgradePreviewPayload(response);
            }).catch((error) => {
                console.error(error);
                toast.error(`Error while processing the request`, {
                    position: 'bottom-right',
                    autoClose: 3000
                });
                onClose()
            });
        }
    }, [prorationProps]);
    const showUpgradeError = () => {
        toast.error(<div className="alert alert-danger">
            There has been some problem with your upgrade. Please contact
            support. <br />
        </div>, {
            position: 'bottom-right',
            autoClose: 3000
        });
    }
    const handleUpgrade = async () => {
        const { subID, priceID, license_key, pay_id } = prorationProps;
        try {
            setProrateSubscriptionLoading(true);
            const data = await prorateSubscription(
                {
                    pay_id: pay_id,
                    type: "update",
                    subID: subID,
                    priceId: priceID,
                    license_key: license_key,
                }
            ).unwrap();
            if (pay_id === "stripe") {

                if (!data.error) {
                    toast.success("You successfully upgraded your subscription! ", {
                        position: 'bottom-right',
                        autoClose: 3000
                    });
                    onClose();
                } else {
                    showUpgradeError();
                }
                setProrateSubscriptionLoading(false);
            }
            else if (pay_id === "paypal") {
                let approveLink = data.links.filter((link) => link.rel == "approve");
                window.location = approveLink[0].href;
            }
        } catch (data) {
            showUpgradeError();
            setProrateSubscriptionLoading(false);
        }
    };

    return (
        <Modal isOpen={Boolean(prorationProps)} onClose={onClose} dialogClassName={"!z-[100]"}>
            <div className="relative flex items-center justify-center w-full rounded-xl text-left font-[poppins] shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white pointer-events-auto p-5">
                {upgradePreviewLoading && !upgradePreviewError ? (
                    <div className="w-full h-full items-center justify-center flex mx-32 my-16">
                        <ClipLoader
                            loading={true}
                            color="#005EFF"
                            size={50}
                            radius={10}
                        />
                    </div>
                ) : (
                    <>
                        {
                            upgradePreviewError || !upgradePreviewPayload ? (
                                <div className="w-full h-full items-center justify-center flex mx-32 my-16">
                                    <div className="text-center">
                                        <h2 className="text-2xl font-bold text-red-500">
                                            Error Purchasing Support Plan
                                        </h2>
                                        <p className="text-lg text-red-500">
                                            Error while processing the request
                                        </p>
                                    </div>
                                </div>
                            ) : (

                                <div className="">
                                    <h3 className="text-2xl">
                                        You are upgrading your plan from {upgradePreviewPayload?.from} to {upgradePreviewPayload?.to}
                                    </h3>
                                    <br />
                                    <table className="text-sm m-auto text-left w-full">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-5 py-3.5 text-sm font-semibold text-gray-900"
                                                >
                                                    Description
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-5 py-3.5 text-sm font-semibold text-gray-900"
                                                >
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upgradePreviewPayload.rundown.map((item, id) => (
                                                <tr key={id}>
                                                    <td
                                                        className="p-5 text-sm font-medium text-gray-900"
                                                        data-th="Description"
                                                    >
                                                        {item.description}
                                                    </td>
                                                    <td
                                                        className="p-5 text-sm font-medium text-gray-900"
                                                        data-th="Amount"
                                                    >
                                                        {parseInt(item.amount) < 0
                                                            ? "-"
                                                            : " "}
                                                        {(Math.abs(item.amount)).toLocaleString("en-US", {
                                                            style: "currency",
                                                            currency: "USD",
                                                        }).replace(/\.00$/, '')}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className="p-5">Amount Due Now</th>
                                                <th className="p-5">
                                                    {(upgradePreviewPayload.prorate_fee).toLocaleString(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "USD",
                                                        }
                                                    ).replace(/\.00$/, '')}
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div
                                        className="ml-5 text-red-600 font-light text-sm"
                                        role="alert"
                                    >
                                        Your next renewal will be at {" "}
                                        {Number(upgradePreviewPayload.next_billing.amount).toLocaleString(
                                            "en-US",
                                            {
                                                style: "currency",
                                                currency: "USD",
                                            }
                                        ).replace(/\.00$/, '')}/
                                        {upgradePreviewPayload.next_billing.interval} on{" "}
                                        {new Date(upgradePreviewPayload.next_billing.time).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}
                                    </div>
                                    <div className="flex flex-col gap-3 mt-5 sm:mt-3 sm:flex-row-reverse">
                                        <button onClick={handleUpgrade} className={`bg-green-500 rounded hover:bg-green-800 px-3 py-2 font-medium text-white text-sm disabled:cursor-wait disabled:bg-gray-400`} disabled={prorateSubscriptionLoading} as="button">
                                            <div className="fa-1x">
                                                Upgrade
                                                {prorateSubscriptionLoading ? (<i className="ml-2 fa fa-spinner fa-spin"></i>) : ""}
                                            </div>
                                        </button>
                                        <button onClick={onClose} className="hover:bg-gray-100 rounded px-3 py-2 font-medium border border-gray-200 text-sm" as="button">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )}
            </div>
        </Modal>
    );
}

export default ProrationModal;
