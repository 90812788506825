import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function ThankYouRedirect() {
    const [seconds, setSeconds] = useState(10);
    const navigate = useNavigate();
    useEffect(() => {
        if (seconds > 1) {
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
        else if (seconds === 1) {
            navigate("/", { replace: true });
        }
    }, [seconds, navigate]);
    return (
        <div className="flex flex-col max-w-md w-[90vw] md:w-[50vw] font-[poppins]">
            <div className="px-4 pb-4 pt-5 sm:p-4 flex items-center justify-between border-b w-full border-gray-200">
                <div className="flex items-center justify-center flex-1">
                    <h2 className="text-lg font-medium leading-6 text-gray-900 ">
                        Thank you for taking the questionnaire
                    </h2>
                </div>
            </div>
            <div className="text-center w-full bg-white z-[500] relative">
                <div className="p-4 sm:p-4">
                    <div className="flex flex-row items-center justify-center text-sm font-normal opacity-80">
                        We received your responses. We'll get back to you within 1-2 business days via email about your personalized marketing recommendation.
                    </div>
                    <div className="mt-5 flex flex-row justify-end gap-3 sm:mt-4">
                        <div className='text-xs font-light text-left'>Please hold. You are being redirected to the HandL UTM Grabber V3 website in {seconds} {
                            seconds === 1 ? "second" : "seconds"
                        }...</div>
                        <div className="flex items-center gap-3">
                            <Link to={"/"} replace className="rounded-md bg-blue-600 border-blue-200 border hover:bg-blue-500 px-5 py-2">
                                <div className="shrink-0 text-nowrap flex w-full gap-2 items-center justify-center text-sm font-medium text-white">
                                    Go now
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYouRedirect