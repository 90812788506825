import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import cx from "classnames";
import { Fragment } from "react";

const Drawer = ({
    className,
    children,
    isOpen,
    onClose,
    /** Called after drawer closing animation has ended. Use this for
     * any cleanup that would affect the content displayed in the drawer.
     */
    onAfterClosed,
    onOpen,
    onAfterOpen,
    /** Shows a simple footer with a single "Close" button. */
    showCloseFooter,
    title,
    /** Show the title and close button of the drawer, set it to false to get a clean drawer with no prebuilt stuff */
    showTitle = false,
    /** Prevent drawer from closing on ESC or background click. */
    disableCloseOnBlur = false,
}) => {
    const transitionClasses = "transform transition ease-in-out duration-300";

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                data-cy={`Drawer[${title}]`}
                className={cx("relative z-[9999]", className)}
                onClose={() => {
                    if (!disableCloseOnBlur) {
                        onClose();
                    }
                    /* otherwise do not close on esc or backdrop click  */
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter={transitionClasses}
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave={transitionClasses}
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={onAfterClosed}
                    beforeEnter={onOpen}
                    afterEnter={onAfterOpen}
                >
                    <div className="fixed inset-0  transition-opacity">
                        <div className="h-full w-full bg-black opacity-75" />
                    </div>
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 flex -trans ">
                            <Transition.Child
                                as={Fragment}
                                enter={transitionClasses}
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave={transitionClasses}
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto -max-w-xl w-min">
                                    <div className="flex h-full flex-col bg-black shadow-xl">
                                        {showTitle && (
                                            <div className="p-6 flex flex-shrink-0 items-center justify-between border-0 border-b border-solid border-gray-200">
                                                <Dialog.Title className="drawer-title font-semibold text-gray-800 text-xl tracking-tight">
                                                    {title}
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        aria-label="close"
                                                        onClick={onClose}
                                                        data-cy="Close icon"
                                                        className="btn-clear"
                                                    >
                                                        <span className="sr-only">
                                                            Close panel
                                                        </span>
                                                        <svg className="h-5 w-5 text-gray-800"
                                                            aria-hidden="true" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857L10.7914 0Z"
                                                                fill="currentColor" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        )}

                                        {children}
                                        {showCloseFooter && (
                                            <Drawer.CloseFooter
                                                onClose={onClose}
                                            />
                                        )}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

Drawer.Footer = ({ children }) => (
    <div className="border-default border-t px-6 py-4">{children}</div>
);

Drawer.CloseFooter = ({ onClose }) => (
    <Drawer.Footer>
        <div className="flex justify-end space-x-3 text-black ">
            <button
                type="button"
                className="btn-default font-semibold outline-bg-light"
                data-cy="Close button"
                onClick={onClose}
            >
                Close
            </button>
        </div>
    </Drawer.Footer>
);

Drawer.Body = ({ children }) => (
    <div className="flex h-full flex-col overflow-y-auto">{children}</div>
);

export default Drawer;
