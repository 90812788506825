import React from 'react';

export default function Affiliate({ isOpened, setCurrentOpened }) {
  return (
    <div className='mb-2'>
      <h2>
        <button
          onClick={() => setCurrentOpened('affiliate')}
          type='button'
          className='flex items-center justify-between w-full py-2 px-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-md focus:ring-4 focus:ring-gray-200 bg-gray-100 hover:bg-gray-200'
        >
          <span className='flex items-center'>
            <i className='fa fa-check-circle text-green-600 m-1'></i> Affiliate
            marketing support
          </span>
        </button>
      </h2>
      <div className={isOpened ? '' : 'hidden'}>
        <div className='px-5 py-2 border border-gray-200'>
          <p className='text-gray-700'>
            <b>
              I'm running affiliate program and would like to capture UTMs from
              coming leads.{' '}
            </b>{' '}
            <br />
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/affiliate-wp'
              target='_blank'
              rel='noreferrer'
            >
              Affiliate WP
            </a>{' '}
            is one of the WordPress plugin we support out of the box. Your
            affiliates registered will have UTMs associated with their account
            and you will see them in WordPress dashboard.
            <b>I'm an affiliate and drive a traffic to certain sites. </b>{' '}
            <br />
            You can still use HandL UTM Grabber to{' '}
            <a
              className='text-blue-600 cursor-pointer'
              href='https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/how-to-append-utm'
              target='_blank'
              rel='noreferrer'
            >
              append UTM variables
            </a>{' '}
            to your outgoing links so they can be properly tracked and
            attributed by the 3rd party agency or partners.
          </p>
        </div>
      </div>
    </div>
    // <div className='panel panel-default'>
    //   <div className='panel-heading' role='tab' id='headingOne'>
    //     <h4 className='panel-title'>
    //       <a
    //         role='button'
    //         data-bs-toggle='collapse'
    //         data-parent='#RWF_accordion'
    //         href='#affiliate'
    //       >
    //         <i className='fa fa-check-circle text-success'></i> Affiliate
    //         marketing support
    //       </a>
    //     </h4>
    //   </div>

    //   <div id='affiliate' className='panel-collapse collapse'>
    //     <div className='panel-body'>
    //       <b>
    //         I'm running affiliate program and would like to capture UTMs from
    //         coming leads.{' '}
    //       </b>{' '}
    //       <br />
    //       <a
    //         href='https://docs.utmgrabber.com/books/affiliate-wp'
    //         target='_blank'
    //       >
    //         Affiliate WP
    //       </a>{' '}
    //       is one of the WordPress plugin we support out of the box. Your
    //       affiliates registered will have UTMs associated with their account and
    //       you will see them in WordPress dashboard.
    //       <b>I'm an affiliate and drive a traffic to certain sites. </b> <br />
    //       You can still use HandL UTM Grabber to{' '}
    //       <a
    //         href='https://docs.utmgrabber.com/books/102-getting-started-for-handl-utm-grabber-v3/chapter/how-to-append-utm'
    //         target='_blank'
    //       >
    //         append UTM variables
    //       </a>{' '}
    //       to your outgoing links so they can be properly tracked and attributed
    //       by the 3rd party agency or partners.
    //     </div>
    //   </div>
    // </div>
  );
}
