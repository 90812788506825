import React, { useState, useEffect, useRef } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import SimpleReactValidator from 'simple-react-validator';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from '../../store/auth';
import { useLazyGetGeoQuery, useOnChargeMutation } from "../../features/payment/paymentAPI"
import { useSearchParams } from 'react-router-dom';
// const { gtag, fbq, rdt, HandL, data, twq, twttr } = window;

export default function CheckoutForm({ setCurrentStep, setSelectedMethod, coupon, resetActiveCoupon }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { activeCoupon } = useSelector((state) => state.coupon);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('US');
  const [region, setRegion] = useState('');
  const [postal, setPostal] = useState('');
  const [customer, setCustomer] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message, className) => (
        <div className={className}>{message}</div>
      ),
    })
  );
  const dispatch = useDispatch();
  const [onPay] = useOnChargeMutation();
  const {
    quantity,
    product_price,
    tmpUser,
    stripe_plan_id,
    isSupportable,
    prices,
    multiplier,
    membership,
  } = useSelector((state) => state.auth.data || {});

  const [getGeo] = useLazyGetGeoQuery();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    getGeo().unwrap().then((data) => {
      setRegion(data.region);
      setCountry(data.countryCode);
      setPostal(data.zip);
    }).catch((e) => console.error(e));

    $('#payment-form').find('input')[0].focus();
    createCustomer(0);
  }, []);

  function beginCheckoutGA() {
    window.gtag('event', 'begin_checkout', {
      items: [
        {
          id: 'HandL002',
          name: 'HandL UTM Grabber V3',
          brand: 'HandL',
          category: 'WordPress Plugins',
          variant: 'V3',
          list_position: 2,
          quantity: `${quantity}`,
          price: `${product_price}.00`,
        },
      ],
      coupon: coupon,
      email: tmpUser.email,
    });

    window.fbq('track', 'InitiateCheckout', {
      currency: 'USD',
      value: `${product_price}.00`,
    });

    window.rdt('track', 'AddToCart', {
      currency: 'USD',
      itemCount: `${quantity}`,
      value: `${product_price}.00`,
    });
  }

  const createCustomer = async (tries) => {
    console.log('Tries: ' + tries);
    if (tries <= 3) {
      try {
        const result = await onPay({
          pay_id: 'stripe',
          membership: true,
          type: 'cus',
          email: tmpUser.email,
          name: tmpUser.first_name,
          marketing: window.HandL?.getAll(),
        }).unwrap();
        console.log(createCustomer, result);
        setCustomer(result.customer);
        beginCheckoutGA();
      } catch (err) {
        console.log(err);
        setTimeout(function () {
          createCustomer(tries + 1);
        }, 500);
      }
    }
  };

  const cardStyle = {
    style: {
      base: {
        fontFamily: '"Poppins",sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '17px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  async function createSubscription({ customerId, paymentMethodId, quantity, address }) {
    console.log({ customerId, paymentMethodId, quantity });
    const mainPriceId = stripe_plan_id;
    const supportPriceId = prices.support.stripe;
    localStorage.setItem('paidMethod', 'stripe');
    try {
      const response = await onPay({
        pay_id: 'stripe',
        membership: true,
        type: 'sub',
        email: tmpUser.email,
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        quantity: quantity,
        coupon,
        campaign_key: activeCoupon?.campaignKey,
        mainPriceId: mainPriceId,
        multiplier: multiplier ? 2 : 1,
        marketing: window.HandL.getAll(),
        address: address,
        ...isSupportable && {
          supportPriceId: supportPriceId,
        }
      }).unwrap();
      const result = response.mainSubscription;
      console.log(result);
      onSubscriptionComplete({
        paymentMethodId: paymentMethodId,
        quantity: quantity,
        subscription: result,
        subscription_status: result.status,
      });
    } catch (error) {
      console.log(error);
      if (error === "This promotion code is not specified for use with this customer.") {
        resetActiveCoupon();
      }
      setProcessing(false);
      handleChange({ error: { message: error?.message ?? error } });
    }
  }

  function onSubscriptionComplete(result) {
    console.log('onSubscriptionComplete');
    console.log(result);
    // Payment was successful.
    if (result.subscription_status === 'active') {
      console.log(result);
      console.log('Charge successfull!');
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      paymentSuccessful(result);
    }
  }

  function paymentSuccessful(payload) {
    setError(null);
    setProcessing(false);
    setSucceeded(true);
    setSelectedMethod('cc');
    setCurrentStep(2);
    setSearchParams({
      pay_id: 'stripe',
      subscription_id: payload.subscription.id,
      thanks: 1,
      ...isSupportable && {
        support: "1"
      }
    });
    let amount, txid;

    if (payload.paymentIntent !== undefined) {
      amount = payload.paymentIntent.amount;
      txid = payload.paymentIntent.id;
    } else if (payload.subscription !== undefined) {
      amount = payload.subscription.latest_invoice.amount_paid;
      txid = payload.subscription.latest_invoice.charge;
    } else {
      //hope never goes here :)
      amount = 1;
      txid = 'Not Captured';
    }

    window.gtag('event', 'purchase', {
      transaction_id: txid,
      value: amount / 100,
      currency: 'USD',
      tax: 0.0,
      shipping: 0,
      items: [
        {
          id: 'HandL002',
          name: 'HandL UTM Grabber V3',
          brand: 'HandL',
          category: 'WordPress Plugins',
          variant: 'V3',
          list_position: 2,
          quantity,
          price: amount / 100,
        },
      ],
      email: tmpUser.email,
    });

    window.gtag('event', 'conversion', {
      send_to: 'AW-604754791/ySYVCOmm8NoBEOemr6AC',
      transaction_id: txid,
      value: amount / 100,
      currency: 'USD',
    });

    window.fbq('track', 'Purchase', { currency: 'USD', value: amount / 100 });

    window.twq('event', 'tw-nytpn-onjy3', {
      value: amount / 100,
      currency: 'USD',
      conversion_id: txid,
      email: tmpUser.email,
      // phone_number: tmpUser.phone_number,
    });

    window.rdt('track', 'Purchase', {
      currency: 'USD',
      itemCount: 1,
      value: amount / 100,
    });

    //window.location = addQString('thanks', 1)
  }
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (validator.current.allValid()) {
      setProcessing(true);

      const cardElement = elements.getElement(CardElement);
      const addressInfo = {
        line1: address,
        postal_code: postal,
        state: region,
        country: country,
      }
      let payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: tmpUser.first_name,
          email: tmpUser.email,
          address: addressInfo,
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setSelectedMethod('cc');
        const paymentMethodId = payload.paymentMethod.id;
        const customerId = customer.id;
        createSubscription({
          customerId, paymentMethodId, quantity, address: addressInfo
        });
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <div className='flex space-x-3'>
        <div className='form-group w-full'>
          <input
            type='text'
            name='address'
            className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            placeholder='Street Address'
            onChange={(val) => setAddress(val.target.value)}
          />
          {validator.current.message('Address', address, 'required', {
            className: 'text-danger',
          })}
        </div>
      </div>
      {/* <div className='flex space-x-3'>
      </div> */}
      <div className='md:flex md:space-x-3'>
        <div className='form-group'>
          <CountryDropdown
            valueType='short'
            classes='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            value={country}
            onChange={(val) => setCountry(val)}
          />
        </div>
        <div className='form-group w-full'>
          <RegionDropdown
            classes='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            country={country}
            value={region}
            defaultOptionLabel='Select State'
            valueType='short'
            countryValueType='short'
            onChange={(val) => setRegion(val)}
          />
        </div>
        <div className='form-group'>
          <input
            type='text'
            name='postal'
            className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            placeholder='Zip Code'
            value={postal}
            onChange={(val) => setPostal(val.target.value)}
          />
          {validator.current.message('Zip Code', postal, 'required', {
            className: 'text-danger',
          })}
        </div>
      </div>
      <div className='form-group w-full'>
        <CardElement
          className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
          id='card-number'
          options={cardStyle}
          onChange={handleChange}
        />
      </div>

      <div className='flex justify-end'>
        <div className="submit-area-2 row m0 flex-1">
          <button
            disabled={processing || disabled || succeeded}
            className={`btn btn-rounded btn-block w-full disabled:cursor-not-allowed disabled:!opacity-70 ${processing ? " active" : ""}`}
          >
            <span id="button-text">
              {processing ? (
                <span>
                  <i className="fa fa-spin fa-spinner"></i> Processing...
                </span>
              ) : (
                "Pay Now"
              )}
            </span>
          </button>
        </div>
      </div>
      {error && (
        <div className='w-full bg-red-100 border border-red-500 text-red-700 px-3 py-2 rounded relative mx-auto mt-2 font-medium text-center' role='alert'>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>
        Your payment succeeded
      </p>
    </form>
  );
}
