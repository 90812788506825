import React from 'react';

export default function Wordpress({ resetModal }) {
  return (
    <div>
      <h3>
        {' '}
        <i className='fa fa-question-circle text-yellow-600'></i> Great news:
        Have you seen UTMSimple?
      </h3>
      <p className='description'>
        HandL UTM Grabber is a WordPress plugin and you must use WordPress to be
        able to use HandL UTM Grabber plugin. However, we have another product
        we recommend you to check it out. It is called{' '}
        <a
          href='https://utmsimple.com/'
          className='text-blue-600 cursor-pointer'
        >
          UTM Simple
        </a>
        .{' '}
      </p>

      <p className='description'>
        It works similar way but it is meant for non WordPress sites such as
        Click Funnel, Lead Pages, Kajabi, Webflow and more.
      </p>

      <p>
        <a
          className='text-blue-600 cursor-pointer'
          onClick={() => {
            resetModal();
            const { $crisp } = window
            $crisp?.push(['do', 'chat:toggle']);
          }}
          href='#'
        >
          Click here
        </a>{' '}
        to chat with a real person (we usually respond in seconds).
      </p>
    </div>
  );
}
