import React, { useEffect, useState } from 'react';
import { ImgW } from '../js';
import V3W from '../images/handl-v3.webp';
import V3 from '../images/handl-v3.png';
import V2W from '../images/handl-free.webp';
import V2 from '../images/handl-free.png';
import V3LTW from '../images/handl-v3lt.webp';
import V3LT from '../images/handl-v3lt.png';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from '../store/auth';

function Price(props) {
  const [state, setState] = useState({
    priceLine: '',
    perLicensePrice: '',
  });

  const { prices, isAuthenticated } = useSelector(
    (state) => state.auth.data || {}
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { plan, first, colorClass, features, logo, bestseller, price, calculateDiscountPrice, discountAvailable } = props;

  useEffect(() => {
    let price = prices[plan];
    let terms = plan !== 'free' ? price['license'] : 1;
    let sites = terms > 1 ? 'Sites' : 'Site';
    let perLicensePrice = <></>;
    if (plan !== 'free') {
      let total_price  = price['product_price'];
      if (discountAvailable) {
        total_price = calculateDiscountPrice(total_price).discountedPrice;
      }
       perLicensePrice = <span>{(total_price
        / terms).toFixed(2)}</span>
    }
    const priceLine = (
      <span>
        {terms} {sites}
      </span>
    );
    setState({
      priceLine: priceLine,
      perLicensePrice: perLicensePrice,
    });
  }, [discountAvailable,calculateDiscountPrice]);

  const onFreeClick = (event) => {
    event.preventDefault();
    if (!isAuthenticated) {
      dispatch(
        setStoreData({
          data: {
            showSignup: true,
          },
        })
      );
      return;
    }
    dispatch(
      setStoreData({
        data: {
          flowSelected: 'free',
          showPayment: true,
        },
      })
    );
  };

  const onPaidClick = async (event, plan) => {
    // console.log('-------------------', isAuthenticated);
    console.log(plan);
    event.preventDefault();
    dispatch(
      setStoreData({
        data: {
          flowSelected: 'paid',
          showPayment: true,
          selected_plan: plan,
        },
      })
    );

    const tmpSelectedPlan = plan;
    const tmpPrices = { ...prices };
    let price = tmpPrices[tmpSelectedPlan];
    let payload = {
      selected_plan: tmpSelectedPlan,
      product_price: price['product_price'],
      original_price: price['original_price'],
      paypal_plan_id: price['paypal'],
      stripe_plan_id: price['stripe'],
      msrp: price['msrp'],
      membership: price['membership'],
      title: price['title'],
      subtitle: price['subtitle'],
    };
    dispatch(
      setStoreData({
        data: {
          ...payload,
        },
      })
    );
    if (!isAuthenticated) {
      dispatch(
        setStoreData({
          data: {
            showSignup: true,
          },
        })
      );
      return;
    }
    navigate('/payment');
  };

  const onFreeDownload = (event) => {
    event.preventDefault();
    window.location =
      'https://downloads.wordpress.org/plugin/handl-utm-grabber.latest-stable.zip';
  };
  return (
    <div
      className={`md:basis-1/2 lg:basis-1/4 item ${first ? 'col-md-offset-1' : ''
        } ${bestseller ? 'highlight' : ''}`}
    >
      <h3 className='mt-5 mb-[10px] text-2xl font-medium'>
        {bestseller ? 'Best Seller' : '\xa0'}
      </h3>
      <div className={`innerItem ${colorClass}`}>
        <h4 className='title text-lg font-medium'>
          {plan !== 'free' ? price['title'] : '\xa0'}
        </h4>
        {plan !== 'free' && price['product_price'] < price['msrp'] && (
          <span className='label label-warning'>Regular ${price['msrp']}</span>
        )}
        {plan !== 'free' && (<>
          {
            discountAvailable ?
              <div className='mt-[0.3em] mb-[0.6em]'>

                <h4 className='text-[3em] text-center leading-snug'>
                  ${calculateDiscountPrice(price['product_price']).discountedPrice}
                  {plan !== 'lifetime' && <small className='text-[0.3em]'>/year</small>}
                </h4>

                <h4 className='text-[2em] text-center leading-snug'>
                  <span className='line-through'>

                    ${price['product_price']}
                  </span>
                  {plan !== 'lifetime' && <small className='text-[0.3em]'>/year</small>}
                </h4>
              </div> :
              <h4 className='price'>
                ${price['product_price']}
                {plan !== 'lifetime' && <small>/year</small>}
              </h4>
          }
        </>
        )}
        {plan === 'free' && <h4 className='price'>FREE</h4>}
        {
          discountAvailable &&
          <div className='small-desc'>
            {calculateDiscountPrice(price['product_price']).discountDetail}
          </div>
        }
        <div className='license-number'>
          {plan !== 'free' ? state.priceLine : '\xa0'}
        </div>
        <div className='features'>{features}</div>
        {(plan === 'free' || plan === 'lifetime') && (
          <div className='item-footer no-bg'>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        )}
        {plan !== 'free' && plan !== 'lifetime' && (
          <div className='item-footer !pb-1'>
            <p>cost per site per year</p>
            <p>${state.perLicensePrice}</p>
          </div>
        )}
        <div className='highlightBottom'></div>

        <div className='buyContainer'>
          <div className='item-logo'>
            {logo === 'bluev3' && (
              <ImgW src={V3W} fallback={V3} className='img-responsive' />
            )}
            {logo === 'orangev3' && (
              <ImgW src={V3LTW} fallback={V3LT} className='img-responsive' />
            )}
            {logo === 'orangev2' && (
              <ImgW src={V2W} fallback={V2} className='img-responsive' />
            )}
          </div>

          <div className='purchase mt-4'>
            {plan !== 'free' && (
              <button
                className='btn btn-primary btn-xs cursor-pointer'
                onClick={(e) => {
                  onPaidClick(e, plan);
                }}
              >
                Buy Now
              </button>
            )}

            {plan === 'free' && !isAuthenticated && (
              <button
                className='btn btn-primary btn-xs cursor-pointer'
                onClick={(e) => {
                  onFreeClick(e);
                }}
              >
                Try Now
              </button>
            )}

            {plan === 'free' && isAuthenticated && (
              <button
                className='btn btn-primary btn-xs cursor-pointer'
                onClick={(e) => {
                  onFreeDownload(e);
                }}
              >
                Try Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price;
