import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';

export const surveyAPI = createApi({
    reducerPath: 'surveyAPI',
    baseQuery: amplifyBaseQuery,
    tagTypes: ['Survey'],
    endpoints: (builder) => ({
        sendSurvey: builder.mutation({
            query: (data) => ({
                url: '/survey',
                method: 'POST',
                useAuth: false,
                data: data
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
        sendAreWeFitSurvey: builder.mutation({
            query: (data) => ({
                url: '/are-we-fit',
                method: 'POST',
                useAuth: false,
                data: data
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            }
        }),
    }),
});

export const {
    useSendSurveyMutation,
    useSendAreWeFitSurveyMutation

} = surveyAPI;
