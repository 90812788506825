import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from "./Modal/Modal";
import { setStoreData } from "../store/auth";
import { Auth } from "aws-amplify";
import VerificationInput from "react-verification-input";
import { useNavigate, useLocation } from "react-router-dom";
import { useForgotPasswordMutation } from "../features/myAccount/myAccountAPI";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const showForgotPasswordParam = params.get('forgotPassword') === "1";
  const emailParam = params.get('email');
  const [submitForgotPasswordRequest] = useForgotPasswordMutation();
  const {
    showForgotPassword,
  } = useSelector((state) => state.auth.data || {});

  const closeForgotPasswordModal = () => {
    dispatch(
      setStoreData({
        data: {
          showForgotPassword: false,
        },
      })
    );
    setTimeout(() => {
      setCurrentStep(0);
    }, 1000)
    if (showForgotPasswordParam) {
      navigate('/', { replace: true })
    }
  };
  return (
    <Modal isOpen={showForgotPassword} onClose={closeForgotPasswordModal}>
      <div className={`lg:w-[700px] md:w-[500px] w-[400px] px-10 md:px-20 p-5 font-[poppins] ${currentStep === 2 && "success-bg"}`}>
        {currentStep === 0 && (
          <div>
            <EmailInputForm
              email={emailParam || ''}
              onSubmit={async (values) => {
                try {
                  const response = await submitForgotPasswordRequest({ email: values.email }).unwrap();
                  if (response.message?.includes("Check your email for a verification link.")) {
                    throw response.message
                  }
                  setEmail(values.email);
                  setCurrentStep(1);
                }
                catch (error) {
                  throw new Error(error);
                }
              }}
            />
          </div>
        )}
        {currentStep === 1 && (
          <NewPasswordInputForm email={email}
            onSubmit={async (values) => {
              await Auth.forgotPasswordSubmit(email, values.code, values.password)
              setCurrentStep(2);
            }}
            handleNotMyEmail={() => setCurrentStep(0)}
          />
        )}
        {
          currentStep === 2 && (
            <div className='flex flex-col'>
              <div className="row p-6">
                <i className="fa text-white fa-check-circle text-[9rem]"></i>
              </div>
              <div className='flex flex-col gap-2 items-center text-center'>

                <div className="row text-white text-base font-normal">
                  Your password has been successfully reset.
                </div>
                <div className="row text-white text-sm font-light">
                  You may log in using your new password.
                </div>

              </div>
            </div>
          )
        }
      </div>
    </Modal>
  )
}

const EmailInputForm = ({ onSubmit, email = '' }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('This field is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await onSubmit(values);
      } catch (error) {
        console.error('Error reseting password:', error);
        formik.setErrors({ email: error.message });
      }
      finally {
        setLoading(false);
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
      <div className='text-center'>
        Reset Your Password
      </div>
      <div className="flex flex-col items-start">
        <input
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Your email"
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="text-red-400 mt-2 text-sm">{formik.errors.email}</div>
        ) : null}
      </div>

      <button
        type="submit"
        disabled={loading}
        className="btn btn-default btn-block btn-sm bg-[#0084ff] w-full"
      >
        {loading ? <i className="fa fa-spin fa-spinner" /> : 'Continue'}
      </button>
    </form>
  );
}

const NewPasswordInputForm = ({ onSubmit, email, handleNotMyEmail }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: { code: '', password: '', confirmPassword: true },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(/^[0-9]+$/, 'Code must consist of digits only')
        .required('This field is required')
        .min(6, 'Code must be exactly 6 digits')
        .max(6, 'Code must be exactly 6 digits'),
      password: Yup.string()
        .required('This field is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('This field is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await onSubmit(values);
      } catch (error) {
        console.error('Error reseting password:', error);
        formik.setErrors({ code: error.message });
      }
      finally {
        setLoading(false);
      }
    },
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col gap-4"
    >
      <p className="text-base font-light">
        Please input your new password along with the six digit code we sent to{' '}
        <span>{email}, </span>{' '}
        <span
          onClick={handleNotMyEmail}
          className="text-blue-500 cursor-pointer"
        >
          not you?
        </span>
      </p>
      <div className="flex flex-col self-center items-center">
        <VerificationInput
          inputProps={{ className: 'opacity-0' }}
          classNames={{
            character:
              'border-2 border-gray-200 rounded text-xl font-semibold text-center flex flex-col items-center justify-center',
            characterInactive: 'bg-white text-blue-500',
            characterSelected: 'border-blue-500 bg-blue-100',
            characterFilled: 'border-blue-600 bg-blue-50 text-blue-600',
          }}
          onChange={(val) => {
            formik.setFieldValue('code', val);
          }}
          value={formik.values.code}
          validChars="0-9"
        />
        {formik.touched.code && formik.errors.code ? (
          <div className="text-red-400 mt-2 text-sm">{formik.errors.code}</div>
        ) : null}
      </div>
      <div className="flex flex-col items-start">
        <input
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className="g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="New password"
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="text-red-400 mt-2 text-sm">{formik.errors.password}</div>
        ) : null}
      </div>

      <div className="flex flex-col items-start">
        <input
          name="confirmPassword"
          type="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          className="g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Confirm new password"
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <div className="text-red-400 mt-2 text-sm">
            {formik.errors.confirmPassword}
          </div>
        ) : null}
      </div>
      <button
        type="submit"
        disabled={loading}
        className="btn btn-default btn-block btn-sm bg-[#0084ff] w-full"
      >
        {loading ? <i className="fa fa-spin fa-spinner" /> : 'Continue'}
      </button>
    </form>
  );
}
export default ForgotPassword;
