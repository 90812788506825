import * as Yup from 'yup';
import schema from './question_schema.json'
// Custom validation for single-select
const singleSelectValidation = (withOther = false) => Yup.object({
    selected: Yup.string().nullable(true),
    other: Yup.string().nullable(true)
}).test(
    'singleSelectTest',
    withOther ? 'You must either select an option or specify one in the "other" field' : 'You must select an option',
    (value) => {
        return Boolean(value.selected || value.other);
    }
);

// Custom validation for multi-select
const multiSelectValidation = (withOther = false) => Yup.object({
    selected: Yup.array().of(Yup.string()).nullable(true),
    other: Yup.string().nullable(true)
}).test(
    'multiSelectTest',

    withOther ? 'You must either select one or more options or specify one in the "other" field' : 'You must select one or more options',
    (value) => {
        return Boolean(value.selected?.length > 0 || value.other);
    }
);

// Helper function to generate Yup validation based on question type
function generateValidation(question) {
    switch (question.type) {
        case 'multi-select':
            return multiSelectValidation(Boolean(question.followUp));
        case 'single-select':
            return singleSelectValidation(Boolean(question.followUp));
        case 'number':
            return Yup.object({
                value: Yup.number()
                    .min(question.range.min, `Value must be at least ${Intl.NumberFormat().format(Number(question.range.min))}`)
                    .max(question.range.max, `Value cannot exceed ${Intl.NumberFormat().format(Number(question.range.max))}`)
                    .required('This field is required')
            });
        default:
            return Yup.object().shape({
                firstName: Yup.string().required('This field is required'),
                email: Yup.string()
                    .email('Please enter a valid email')
                    .required('This field is required'),
                website: Yup.string().required('This field is required'),
            });
    }
}
// Construct the schema object dynamically
export const dynamicSchema = schema.questions.map((question) => {
    return {
        [question.id]: generateValidation(question)
    };
});