import{ useEffect, useState } from 'react';
function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(
      typeof window !== 'undefined' ? window.innerWidth : undefined
    );
  
    useEffect(() => {
      if (typeof window === 'undefined') return;
  
      function handleResize() {
        setWindowWidth(window.innerWidth);
      }
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Call once to set initial size
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowWidth;
  }

  export default useWindowWidth;