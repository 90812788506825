import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const withAuthCheck = (Component) => {
    return (props) => {
        const { isAuthenticated, user } = useSelector(
            (state) => state.auth.data || {}
        );

        useEffect(() => {
            if (Boolean(isAuthenticated && user) === false) {
                window.location.href = "/?login=1"
            }
        }, [isAuthenticated, user]);

        if (isAuthenticated && user) {
            return <Component {...props} />;
        }
    };
};

export default withAuthCheck;
