import React, { useEffect, useRef, useState } from 'react';
import { BeatLoader } from 'react-spinners';
export const ChatInput = ({ onSend, loading }) => {
  const [content, setContent] = useState("");

  const textareaRef = useRef(null);

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length > 4000) {
      alert('Message limit is 4000 characters');
      return;
    }

    setContent(value);
  };

  const handleSend = () => {
    if (loading) {
      return;
    }
    if (!content) {
      alert('Please enter a message');
      return;
    }
    onSend(content);
    setContent('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
    }
  }, [content]);

  return (
    <div className="relative font-[poppins] [&:has(textarea:focus)]:border-gray-200 [&:has(textarea:focus)]:shadow w-full border border-token-border-heavy rounded-2xl bg-white shadow-md ">
      <textarea
        ref={textareaRef}
        value={content}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        // style="max-height: 200px; height: 52px; overflow-y: hidden;"
        style={{
          maxHeight: '200px',
          minHeight: '52px',
          resize: "none"
        }}
        rows="1"
        placeholder="Type a message"
        class="m-0 pl-4 w-full text-base resize-none border-0 bg-transparent py-3.5 focus:ring-0 focus-visible:ring-0 placeholder-black/50 pr-10 md:pr-12"
      ></textarea>
      {
        loading ? <div className='absolute bottom-3 md:right-3 right-2  p-0.5'>
          <BeatLoader loading={true} color="#005EFF" size={5} />
        </div> : <button as="button" onClick={handleSend} disabled={
          !content
        } class="absolute bottom-3 md:right-3 right-2 enabled:cursor-pointer disabled:bg-gray-200  enabled:bg-blue-500 text-white p-0.5 border rounded-lg transition-colors">
          <span class="" data-state="closed">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="text-white"
            >
              <path
                d="M7 11L12 6L17 11M12 18V7"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>
        </button>
      }

    </div>
  );
};
