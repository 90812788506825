import { API, Auth } from 'aws-amplify';
import axios from 'axios'
export const amplifyBaseQuery = async ({ url, method, data, params, headers: passedHeaders = {}, useAuth = true }) => {
    let headers = { ...passedHeaders };

    // Fetch the authentication token if useAuth is true and no Authorization header is passed
    // In some cases we may want to pass a custom Authorization header, for example when calling a function url directly
    if (useAuth && !headers.Authorization) {
        try {
            const user = await Auth.currentAuthenticatedUser();
            headers.Authorization = `Bearer ${user?.getSignInUserSession().getIdToken().getJwtToken()}`;
        } catch (error) {
            console.error('Error fetching auth token', error);
            // Throw an error if authentication fails
            throw new Error('Authentication failed');
        }
    }

    try {
        const result = await API[method.toLowerCase()]('HandLAPI', url, {
            body: data,
            queryStringParameters: params,
            headers,
        });
        return { data: result };
    } catch (apiError) {
        return { error: apiError };
    }
};


export const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async ({ url, method, data, params, headers={}, useAuth = false }) => {
            // Fetch the authentication token if useAuth is true and no Authorization header is passed
            // In some cases we may want to pass a custom Authorization header, for example when calling a function url directly
            if (useAuth && !headers.Authorization) {
                try {
                    const user = await Auth.currentAuthenticatedUser();
                    headers.Authorization = `Bearer ${user?.getSignInUserSession().getIdToken().getJwtToken()}`;
                } catch (error) {
                    console.error('Error fetching auth token', error);
                    // Throw an error if authentication fails
                    throw new Error('Authentication failed');
                }
            }
            try {
                const result = await axios({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers,
                })
                return { data: result.data }
            } catch (axiosError) {
                const err = axiosError
                return {
                    error: err,
                }
            }
        }