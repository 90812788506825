import React from 'react';
import OfferExpired from './OfferExpired';
import { useStartUserCampaignQuery } from '../../features/coupon/couponAPI';
import NetworkErrorScreen from '../Error/NetworkErrorScreen';
import PromoSkeleton from './PromoSkeleton';
import { Navigate } from 'react-router-dom';

const withOfferCheck = (WrappedComponent, campaignKey) => {

    return (props) => {

        const { data, error, isLoading, refetch } = useStartUserCampaignQuery(campaignKey);

        if (isLoading) {
            return <PromoSkeleton />;
        }
        if (error) {

            if (error.status === 410) {
                return <OfferExpired {...{
                    name: 'Premium Membership',
                    expiredDiscount: '20%',
                    newDiscount: '5%',
                    newCoupon: "5POFFEX"
                }} />;
            }
            if (error.status === 403) {
                // Ineligible for the offer
                return <Navigate to="/404" />;
            }
            return <NetworkErrorScreen onRetry={() => refetch()} loading={isLoading} />
        }

        return <WrappedComponent {...props} campaign={data} />;
    };
};

export default withOfferCheck;
