import React, { Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
class BasicSupport extends Component {
  render() {
    return (
      <li>
        Basic Support&nbsp;
        <span>
        <Popover className="relative inline-block">
            <Popover.Button>
              <i className="fa fa-info-circle text-white"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="left-1/2 -translate-x-1/2 absolute z-10 bg-white text-sm font-normal leading-normal text-left rounded-md shadow min-w-[290px]">
                <div className="mb-2.5 py-1 px-2 relative">
                  <div class="absolute left-1/2 transform -translate-x-1/2 top-0 -translate-y-1/2 fa fa-caret-up text-[40px]"></div>
                  <ul className="!list-outside !list-disc !ml-8">
                    <li className="!text-black !text-sm !leading-[26px] !font-light before:!content-none">
                      Initial response within two business days
                    </li>
                    <li className="!text-black !text-sm !leading-[26px] !font-light before:!content-none">
                      Basic installation/integration help via chat
                    </li>
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </span>
      </li>
    );
  }
}

export default BasicSupport;
