import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Plan from "./Plan";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import Modal from "../Modal/Modal"
import {
  cleanupConfirmCancelPlanModal,
  closeConfirmCancelPlanModal,
  openConfirmCancelPlanModal,
  setIsLoading,
  resetLoading,
  openUpdatePaymentMethodModal,
  closeUpdatePaymentMethodModal
} from "../../features/plan/planSlice";
import { toast } from "react-toastify";
import { useOnUpgradeMutation } from "../../features/payment/paymentAPI";
import ProrationModal from "./ProrationModal";
import CardPreview from "./CardPreview";
import UpdatePaymentMethodModal from "./UpdatePaymentMethodModal";


const CancelConfirmModal = ({ cancelSub, handleCancelApprove, handleCloseModal, hadnleCleanupModal, cancelStatus }) => {
  const cancelPlanText = () => {
    return <>You are about to cancel your subscription to HandL UTM Grabber V3 plugin {cancelSub.cancelPlanName} plan.  You will have access to the upgrades for the duration of your subscription.<span className="font-semibold"> Please note that once your subscription ends, your access to the plugin{!cancelSub.isAddon && " and tracking"}  will stop.</span> <br /> Do you still want to continue?</>
  }
  return (
    <Modal isOpen={cancelSub.openModal} onAfterClosed={hadnleCleanupModal}>
      <div
        className="relative col-auto col-start-2 row-auto row-start-2 w-full rounded-xl text-left font-[poppins] shadow-xl transition-all left-1/2 -translate-x-1/2 bg-white max-w-md pointer-events-auto"
      >
        <div className="px-4 pb-4 pt-5 sm:p-6 flex items-center justify-between border-b border-black/10">
          <div className="flex">
            <div className="flex items-center">
              <div className="flex grow flex-col gap-1">
                {cancelSub.cancelPlanElement == 'full' ? (
                  <h2
                    id="radix-:r66:"
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Fully cancel your {cancelSub.cancelPlanName} plan?
                  </h2>)
                  : (
                    <h2
                      id="radix-:r66:"
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Cancel support for your {cancelSub.cancelPlanName} plan?
                    </h2>)}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 sm:p-6">
          {cancelSub.cancelPlanElement == 'full' ? (<p className="leading-5 font-normal">
            {cancelPlanText()}
          </p>)
            : (<p className="leading-4 font-normal">You are about to lose your support plan on your HandL UTM Grabber V3 plugin {cancelSub.cancelPlanName} subscription.<br /> Do you still want to continue?</p>)}
          <div className="mt-5 sm:mt-4">
            <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
              <button onClick={handleCancelApprove} className={`bg-red-500 rounded hover:bg-red-800 px-3 py-2 font-medium text-white text-sm disabled:cursor-wait disabled:bg-gray-400`} disabled={cancelStatus.loading} as="button">
                <div className="fa-1x">
                  Yes, Continue
                  {cancelStatus.loading ? (<i className="ml-2 fa fa-spinner fa-spin"></i>) : ""}
                </div>
              </button>
              <button onClick={handleCloseModal} className="hover:bg-gray-100 rounded px-3 py-2 font-medium border border-gray-200 text-sm" as="button">
                No, Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}



const Plans = ({ plans, elements, stripe, accountType, refetchPlans }) => {
  const upgradePaymentModalState = useSelector((state) => state.plan.updatePaymentMethod)
  const [priceIDselected, setPriceIDselected] = useState(null);
  const [subIDselected, setSubIDselected] = useState(null);
  const [licenseSelected, setLicenseSelected] = useState(null);
  const [route, setRoute] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const cancelSubscription = useSelector((state) => state.plan.cancel || {})
  const cancelStatus = useSelector((state) => state.plan.status || {})
  const dispatch = useDispatch()
  const [doUpgrade] = useOnUpgradeMutation();
  const [prorationModalProps, setProrationModalProps] = useState(null);
  const proratePreview = async (event) => {
    if (!event) {
      return;
    }
    let { priceID, license_key } = event;

    let item = plans.subscriptions.filter((item) => {
      if (item.license_key == license_key) return item;
    })[0];
    let subID = item.subID;
    setPriceIDselected(priceID);
    setSubIDselected(subID);
    setLicenseSelected(license_key);

    setProrationModalProps({
      priceID,
      subID,
      license_key,
      pay_id: item.pays_id == "cc" ? "stripe" : "paypal",
    })
  };

  const cancelSub = (license_key, element, plan_name, isAddon) => {
    setTimeout(() => {
      let item = plans.subscriptions.filter((item) => {
        if (item.license_key == license_key) return item;
      })[0];

      if (element == "support" && !item.supportSubID) {
        return;
      }

      let subID = item.supportSubID;
      if (element == "full") {
        subID = item.subID;
      }
      setLicenseSelected(license_key);
      setSubIDselected(subID);

      dispatch(openConfirmCancelPlanModal({
        cancelPlanName: plan_name,
        cancelPlanElement: element,
        isAddon: isAddon
      }))
    }, 0);
  };

  const cancelRevert = () => {
    setRoute(null);
    setLicenseSelected(null);
    setSubIDselected(null);
  };

  const handleCloseModal = () => {
    dispatch(closeConfirmCancelPlanModal())
  }
  const handleCleanupModal = () => {
    dispatch(cleanupConfirmCancelPlanModal())
  }
  const handleOpenUpdatePaymentMethodModal = () => {
    dispatch(openUpdatePaymentMethodModal({ customerId: plans.subscriptions.find(sub => sub.pays_id == 'cc').customer_id }))
  }

  const handleCancelConfirmed = async () => {
    dispatch(setIsLoading())
    try {
      await cancelSubApproved()
      dispatch(resetLoading())
      handleCloseModal()
      setTimeout(handleCleanupModal(), 2000)
      toast.success(`Plan cancelled successfully, your changes will be reflected soon`, {
        position: 'bottom-right',
        autoClose: 3000
      });
    } catch (error) {
      dispatch(resetLoading())
      handleCloseModal()
      setTimeout(handleCleanupModal(), 2000)
      toast.error(`Error canceling plan: ${error?.message}`, {
        position: 'bottom-right',
        autoClose: 3000
      });
    }
  }
  const cancelSubApproved = async () => {
    let license_key = licenseSelected;
    let item = plans.subscriptions.filter((item) => {
      if (item.license_key == license_key) return item;
    })[0];
    let subID = subIDselected;
    try {
      const data = await doUpgrade(
        {
          pay_id: item.pays_id == "cc" ? "stripe" : "paypal",
          type: "cancel",
          cancelType: cancelSubscription.cancelPlanElement,
          subID: subID,
        }
      ).unwrap();
      console.log(data);
      console.log("Procced to cancel");
    } catch (err) {
      cancelRevert();
      throw err;
    }
  };



  return (
    plans &&
    plans.subscriptions.length > 0 && (
      <div className="row">
        <div className="col-md-12">
          {plans.billings && (
            <div>
              <CardPreview editCard={handleOpenUpdatePaymentMethodModal} plans={plans} />
            </div>
          )}
          <div className="row">
            <div className="col-md-12 table-responsive shadow-md">
              <table className="w-full text-sm text-left text-gray-500 rounded-lg my-3">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Plan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Terms
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Start
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      End
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Payment Method
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      License
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Upgrade
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Cancel
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {plans.subscriptions.map((item, id) => (
                    <Plan
                      key={id}
                      dataKey={id}
                      handleUpgrade={proratePreview}
                      handlerCancel={cancelSub}
                      item={item}
                      accountType={accountType}
                    ></Plan>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="mt-5">
              <span className="label label-success">
                Click <i className="fa fa-copy" /> above to retrieve your
                license key
              </span>
            </p>
          </div>

          <CancelConfirmModal
            cancelSub={cancelSubscription}
            handleCloseModal={handleCloseModal}
            handleCancelApprove={handleCancelConfirmed}
            hadnleCleanupModal={handleCleanupModal}
            cancelStatus={cancelStatus}
          />
          <ProrationModal prorationProps={prorationModalProps} onClose={() => setProrationModalProps(null)} />
          <Modal isOpen={upgradePaymentModalState.modalOpen} unmountOnClose onClose={() => dispatch(closeUpdatePaymentMethodModal())}>
            <Elements stripe={stripe} options={{
              mode: "setup",
              currency: "usd",
              setupFutureUsage: "off_session",
              paymentMethodCreation: "manual",
            }}>

              <UpdatePaymentMethodModal />
            </Elements>
          </Modal>
        </div>
      </div>
    )
  );
};

export default Plans;
