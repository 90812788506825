const location = window.location;

export const getParameterByName = (name) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const removeQString = (key) => {
  var urlValue = document.location.href;

  //Get query string value
  let searchUrl = location.search;

  if (key !== '') {
    let oldValue = getParameterByName(key);
    let removeVal = key + '=' + oldValue;
    // console.log(removeVal, searchUrl, urlValue);
    if (searchUrl.indexOf('?' + removeVal + '&') != '-1') {
      urlValue = urlValue.replace('?' + removeVal + '&', '?');
    } else if (searchUrl.indexOf('&' + removeVal + '&') != '-1') {
      urlValue = urlValue.replace('&' + removeVal + '&', '&');
    } else if (searchUrl.indexOf('?' + removeVal) != '-1') {
      urlValue = urlValue.replace('?' + removeVal, '');
    } else if (searchUrl.indexOf('&' + removeVal) != '-1') {
      urlValue = urlValue.replace('&' + removeVal, '');
      // console.log('urlValue: ', urlValue);
    }
  } else {
    searchUrl = location.search;
    urlValue = urlValue.replace(searchUrl, '');
  }
  return urlValue.replace(/#$/, '');
};

export const addQString = (key, value) => {
  //Get query string value
  var searchUrl = location.search;
  if (searchUrl.indexOf('?') === '-1') {
    var urlValue = '?' + key + '=' + value;
  } else {
    //Check for key in query string, if not present
    if (searchUrl.indexOf(key) === '-1') {
      urlValue = searchUrl + '&' + key + '=' + value;
    } else {
      //If key present in query string
      var oldValue = getParameterByName(key);
      if (searchUrl.indexOf('?' + key + '=') !== '-1') {
        urlValue = searchUrl.replace(
          '?' + key + '=' + oldValue,
          '?' + key + '=' + value
        );
      } else {
        urlValue = searchUrl.replace(
          '&' + key + '=' + oldValue,
          '&' + key + '=' + value
        );
      }
    }
  }

  return urlValue;
};
