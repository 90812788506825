import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';
import { setAccountType, setAppEligible } from './planSlice';
export const planAPI = createApi({
    reducerPath: 'planAPI',
    baseQuery: amplifyBaseQuery,
    tagTypes: ['Plans'],
    endpoints: (builder) => ({
        getPlans: builder.query({
            query: () => ({
                url: '/myaccount',
                method: 'GET',
                useAuth: true,
                params: {
                    action: "plan"
                }
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
            providesTags: [{ type: "Plans", id: "LIST" }],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const { data: plans } = await queryFulfilled;
                    const getAccountStatus = function (element) {
                        var account_status = "paid";
                        if (
                            element["subscription_status"].toLowerCase() === "canceled" ||
                            element["subscription_status"].toLowerCase() === "cancelled"
                        ) {
                            account_status = "free";
                        }
                        return account_status;
                    };
                    if (plans.subscriptions.length > 0) {
                        let accountType = "free";
                        let appEligible = false;
                        plans.subscriptions.forEach((element) => {
                            if (getAccountStatus(element) === "paid") {
                                accountType = "paid";
                            }
                            if (
                                (element["plan_name"] == "Starter+" ||
                                    element["plan_name"] == "Premium" || element.addon_id === "GCILD_REPORTER") &&
                                (element["subscription_status"].toLowerCase() === "active" || element["subscription_status"].toLowerCase() === "expiring")
                            ) {
                                appEligible = true;
                            }

                        });
                        const setCrispSessionData = function (plans) {
                            let crispData = [];
                            plans.subscriptions.forEach((element, id) => {
                                let license_key = element['license_key'];
                                crispData.push([
                                    id + 1 + '_AccountType',
                                    getAccountStatus(element),
                                ]);
                                crispData.push([id + 1 + '_LicenseLast4', license_key]);
                                crispData.push([
                                    id + 1 + '_Plan',
                                    element['plan_name'] ? element['plan_name'] : '',
                                ]);
                                crispData.push([
                                    id + 1 + '_PlanAmount',
                                    element['plan_amount'] ? element['plan_amount'] : '',
                                ]);
                                crispData.push([
                                    id + 1 + '_Start',
                                    element['subscription_date_start'],
                                ]);
                                crispData.push([
                                    id + 1 + '_End',
                                    element['subscription_date_end'] ? element['subscription_date_end'] : 'N/A',
                                ]);
                                crispData.push([id + 1 + '_PaysId', element['pays_id']]);
                                crispData.push([
                                    id + 1 + '_LicenseNum',
                                    element['license_number'],
                                ]);
                                crispData.push([id + 1 + '_Used', element['domains'].length]);
                                crispData.push([
                                    id + 1 + '_UsedSites',
                                    element['domains'].join('\n'),
                                ]);
                            });
                            window.$crisp.push(['set', 'session:data', [crispData]]);
                        }
                        dispatch(setAccountType(accountType));
                        dispatch(setAppEligible(appEligible));
                        setCrispSessionData(plans);
                    }
                } catch (error) {
                    console.error('Failed to fetch plans:', error);
                }
            },
        }),
        getCustomInvoice: builder.query({
            query: ({
                subscription_id,
                invoice_id
            }) => {
                const params = new URLSearchParams()
                if (subscription_id) {
                    params.set('subscription_id', subscription_id)
                }
                if (invoice_id) {
                    params.set('invoice_id', invoice_id)
                }
                return ({
                    url: `/custom-invoice?${params.toString()}`,
                    method: 'GET',
                    useAuth: true,
                })
            },
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        }),
        getSignedDownloadUrl: builder.query({
            query: () => ({
                url: `/download`,
                method: 'GET',
                useAuth: true,
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        }),
        updateBillingInfo: builder.mutation({
            query: (body) => ({
                url: `/myaccount/update_billing`,
                method: 'POST',
                useAuth: true,
                params: {
                    action: "update billing"
                },
                data:body
            }),
            invalidatesTags: [{ type: "Plans", id: "LIST" }],
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        }),
    }),
});

export const {
    useGetPlansQuery,
    useLazyGetPlansQuery,
    useLazyGetCustomInvoiceQuery,
    useLazyGetSignedDownloadUrlQuery,
    useUpdateBillingInfoMutation,

} = planAPI;
