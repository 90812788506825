import React from 'react';
import { Link } from 'react-router-dom';
import styles from './featuresList.module.css';

const FeaturesList = () => (
    <ul className={`${styles["checkList"]}`}>
        <li>
            <span className='font-semibold'>Supports <Link to='https://utmgrabber.com/#supported-by-handl' target='_blank'>All Forms</Link> in WordPress: {' '}</span>Seamlessly integrate and manage all form types.
        </li>
        <li>
            <span className='font-semibold'>WooCommerce Support:</span> {' '}Optimize and track all your WooCommerce activities.
        </li>
        <li>
            <span className='font-semibold'>Detailed UTM Tracking:</span> {' '}Monitor paid ads, social media, organic traffic, referrers, and custom parameters like affiliate traffic.
        </li>
        <li>
            <span className='font-semibold'>Customizable Cookie/Tracking Duration:</span> {' '}Tailor tracking duration to your needs, even up to 365 days.
        </li>
        <li>
            <span className='font-semibold'>GDPR Compliance:</span> {' '}Ensure data privacy with EU GDPR compliance and compatibility with consent plugins.
        </li>
        <li>
            <span className='font-semibold'>WordPress Multi-site Network Support:</span> {' '}Manage and track across your entire WordPress network.
        </li>
        <li>
            <span className='font-semibold'>BONUS: <Link to='https://docs.utmgrabber.com/books/103-internal-apps/page/handl-gclid-reporter' target='_blank'>GCLID Reporter</Link> Tool: {' '}</span>Connect your Google Ads account for detailed click reports.
        </li>
        <li>
            <span className='font-semibold'>BONUS: <Link to='https://docs.utmgrabber.com/books/103-internal-apps/page/ai-powered-report-insight' target='_blank'>AI-Powered Report Insight</Link>: {' '}</span>Generate insightful AI-powered reports to optimize your marketing efforts.
        </li>
        <li>
            <span className='font-semibold'>Premium Support:</span> {' '}Enjoy priority assistance and expert guidance.
        </li>
    </ul>
);

export default FeaturesList;
