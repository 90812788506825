import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { getParameterByName, removeQString } from '../js/params';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { setStoreData } from '../store/auth';


const ConfirmUser = () => {
  const [state, setState] = useState({
    error: true,
    msg: '',
    showForm: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const startFunc = async () => {
      var confirmation_code = getParameterByName('confirmation_code');
      var client_id = getParameterByName('client_id');
      var user_name = getParameterByName('user_name');
      
      if (confirmation_code !== '' && client_id !== '' && user_name !== '') {
        try {
          await Auth.confirmSignUp(user_name, confirmation_code);
          setState({ error: false });
        } catch (error) {
          setState({ msg: error.message });
        }

        setState({ showForm: true });

        localStorage.removeItem('tmpUser');
        dispatch(
          setStoreData({
            data: {
              isAuthenticated: false,
              tmpUser: null,
            },
          })
        );
        // $.magnificPopup.open(
        //   {
        //     items: {
        //       src: "#confirm-user-popup",
        //     },
        //     type: "inline",
        //     closeOnBgClick: false,
        //     enableEscapeKey: false,
        //     showCloseBtn: false,
        //   },
        //   0
        // );
      }
    };
    startFunc();
  }, []);

  const onCloseModal = (event) => {
    // event.preventDefault();
    // $.magnificPopup.close();
    // console.log(removeQString('confirmation_code'));
    window.location = removeQString('confirmation_code');
  };

  return (
    state.showForm && (
      <>
        <div
          id='confirm-user-popup'
          className='container text-center handl-modal lg:w-[700px] md:w-[500px] w-[400px] fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[10001]'
        >
          <div className='row'>
            <div
              className={`col-md-12 ${
                state.error ? ' danger-bg' : 'success-bg'
              } fs-10 text-white`}
            >
              <i
                className={`fa text-white ${
                  state.error ? ' fa-exclamation-circle' : ' fa-check-circle'
                }`}
              ></i>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 p-t-20 p-b-20'>
              {state.error ? (
                <div>
                  <h1>Some problem occured :( </h1>
                  <p>{state.msg}</p>
                </div>
              ) : (
                <div>
                  <h1>Great!</h1>
                  <p>Your account has been verified successfully.</p>
                </div>
              )}
              <a
                onClick={() => onCloseModal()}
                className={`btn cursor-pointer inline-block text-white bg-green-700 ${
                  state.error ? ' btn-danger' : ' btn-success'
                } btn-xs m-t-20 py-1`}
              >
                OK
              </a>
            </div>
          </div>
        </div>

        <div
          className='absolute w-[100%] h-[100%] bg-black/50 top-[-100px] left-0 z-[10000]'
          onClick={() => onCloseModal()}
        ></div>
      </>
    )
  );
};

export default ConfirmUser;
