import React from 'react';
import ImageFeature1W from '../images/feature1.webp';
import ImageFeature2W from '../images/feature2.webp';
import ImageFeature3W from '../images/feature3.webp';
import ImageFeature4W from '../images/feature4.webp';
import ImageFeature1W_2x from '../images/feature1@2x.webp';
import ImageFeature2W_2x from '../images/feature2@2x.webp';
import ImageFeature3W_2x from '../images/feature3@2x.webp';
import ImageFeature4W_2x from '../images/feature4@2x.webp';
import ImageFeature1 from '../images/feature1.png';
import ImageFeature2 from '../images/feature2.png';
import ImageFeature3 from '../images/feature3.png';
import ImageFeature4 from '../images/feature4.png';
import ImageFeature1_2x from '../images/feature1@2x.png';
import ImageFeature2_2x from '../images/feature2@2x.png';
import ImageFeature3_2x from '../images/feature3@2x.png';
import ImageFeature4_2x from '../images/feature4@2x.png';
import { ImgW } from '../js';
const Landing = () => {
  return (
    <div className='max-w-screen-xl mx-auto px-12' id='landing'>
      <div className='bg-white border border-[#ddd] rounded-xl p-5 m-2 md:border-none md:rounded-none md:!p-0 md:!m-0'>
        <div className='flex flex-col-reverse md:flex-row justify-between items-center md:py-8 md:px-5 gap-3 md:space-x-5'>
          <div className='w-full md:w-1/2'>
            <h3 className='font-medium text-lg md:text-4xl md:font-bold text-gray-800 text-center md:text-left'>
              The one and only official UTM plugin for WordPress.
            </h3>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-6'>
              We enjoy the exclusive privilege of being listed in the official
              WordPress repository.
            </p>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-4'>
              This not only asserts our credibility but also underlines our
              commitment to providing top-tier and user-friendly service to our
              clients
            </p>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-4'>
              Think of us as an OEM for your WordPress environment, seamlessly integrated and
              offering a native experience. Unlike JavaScript-based solutions, our plugin works
              within your WordPress setup, ensuring superior performance and security.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <picture>
              <source srcset={`${ImageFeature1W} 1x, ${ImageFeature1W_2x} 2x`} type="image/webp" />
              <img className='w-full h-auto' src={ImageFeature1} alt="The one and only official UTM plugin for WordPress" srcset={`${ImageFeature1_2x} 2x`} />
            </picture>
          </div>
        </div>
      </div>
      <div className='bg-white border border-[#ddd] rounded-xl p-5 m-2 md:border-none md:rounded-none md:!p-0 md:!m-0'>
        <div className='flex flex-col md:flex-row justify-between items-center md:py-8 md:px-5 gap-3 md:space-x-5'>
          <div className='w-full md:w-1/2'>
            <picture>
              <source srcset={`${ImageFeature2W} 1x, ${ImageFeature2W_2x} 2x`} type="image/webp" />
              <img className='w-full h-auto' src={ImageFeature2} alt="Your data is yours. No one but you can access your data" srcset={`${ImageFeature2_2x} 2x`} />
            </picture>
          </div>
          <div className='w-full md:w-1/2'>
            <h3 className='font-medium text-lg md:text-4xl md:font-bold text-gray-800 text-center md:text-left'>
              Your data is yours. No one but you can access your data
            </h3>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-6'>
              HandL UTM Grabber is designed with a prime focus on data privacy.
              It operates under a first-party data collection policy, meaning,
              all the data it collects resides solely on your domain.
            </p>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-4'>
              This ensures that you are the only one with access to this data,
              neither we nor any third party entities can view or use it
            </p>
          </div>
        </div>
      </div>
      <div className='bg-white border border-[#ddd] rounded-xl p-5 m-2 md:border-none md:rounded-none md:!p-0 md:!m-0'>
        <div className='flex flex-col-reverse md:flex-row justify-between items-center md:py-8 md:px-5 gap-3 md:space-x-5'>
          <div className='w-full md:w-1/2'>
            <h3 className='font-medium text-lg md:text-4xl md:font-bold text-gray-800 text-center md:text-left'>
              Fastest UTM plugin ever: No fluff, just functionality
            </h3>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-6'>
              It has been meticulously engineered to deliver top-of-the-line
              speed.
            </p>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-4'>
              Earning flying colors in notable speed and performance testing
              platforms like Pingdom, GTMatrix, Google PageSpeed, and
              Lighthouse.
            </p>
          </div>
          <div className='w-full md:w-1/2'>
            <picture>
              <source srcset={`${ImageFeature3W} 1x, ${ImageFeature3W_2x} 2x`} type="image/webp" />
              <img className='w-full h-auto' src={ImageFeature3} alt="Fastest UTM plugin ever: No fluff, just functionality" srcset={`${ImageFeature3_2x} 2x`} />
            </picture>
          </div>
        </div>
      </div>
      <div className='bg-white border border-[#ddd] rounded-xl p-5 m-2 md:border-none md:rounded-none md:!p-0 md:!m-0'>
        <div className='flex flex-col md:flex-row justify-between items-center md:py-8 md:px-5 gap-3 md:space-x-5'>
          <div className='w-full md:w-1/2'>
            <picture>
              <source srcset={`${ImageFeature4W} 1x, ${ImageFeature4W_2x} 2x`} type="image/webp" />
              <img className='w-full h-auto' src={ImageFeature4} alt="Fastest UTM plugin ever: No fluff, just functionality" srcset={`${ImageFeature4_2x} 2x`} />
            </picture>
          </div>
          <div className='w-full md:w-1/2'>
            <h3 className='font-medium text-lg md:text-4xl md:font-bold text-gray-800 text-center md:text-left'>
              AI-Powered Reporting Insights
            </h3>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-6'>
              Our first-of-its-kind AI-powered reporting insight is embedded right inside WordPress. It provides you with a deeper understanding of your marketing campaigns by highlighting what is working and what isn't. Moreover, it suggests actionable items to enhance the effectiveness of your marketing strategies.
            </p>
            <p className='text-[15px] font-normal md:text-xl mt-2.5 md:mt-4'>
              With UTM Grabber, you don't just get data; you get valuable insights that drive action and results. Experience the future of UTM tracking with our AI-powered reporting tool.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
