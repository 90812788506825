import React, { useState } from 'react';
import { useStripe, useElements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCheckout from './useCheckout';
import { ClipLoader } from 'react-spinners';

const ExpressCheckout = ({ setCurrentStep, setSelectedMethod, coupon, resetActiveCoupon, setSelectedPaymentMethod }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [visibility, setVisibility] = useState('loading');
    const [loading, setLoading] = useState(false);
    const {
        quantity,
    } = useSelector((state) => state.auth.data || {});
    const { createCustomer, createSubscription } = useCheckout({ setCurrentStep, coupon, resetActiveCoupon, setSelectedMethod });
    const onReady = ({ availablePaymentMethods }) => {
        if (!availablePaymentMethods) {
            setVisibility('hidden');
        } else {
            setVisibility('initial');
        }
    };
    const onConfirm = async (event) => {
        if (!stripe || loading) {
            // Stripe.js hasn't loaded yet.
            return;
        }
        setLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            // `elements` instance used to create the Express Checkout Element
            elements,
            params: {
                billing_details: event.billingDetails
            }
        });

        if (error) {
            toast.error(error.message);
        } else {
            try {
                const customer = await createCustomer();
                await createSubscription({
                    customerId: customer.id,
                    paymentMethodId: paymentMethod.id,
                    quantity: quantity,
                    address: paymentMethod.billing_details.address
                });
            }
            catch (error) {
                console.log(error);
                toast.error(error?.message || "An error occurred, please try again");
            }
        }
        setLoading(false);
    };
    const onClick = ({ resolve }) => {
        setSelectedPaymentMethod(null)
        resolve();
    }

    return <>{visibility === "loading" && <div className='flex flex-row gap-2'><div className='animate-pulse h-[45px] bg-gray-200 rounded w-full'></div><div className='animate-pulse h-[45px] bg-gray-200 rounded w-full'></div></div>}
        <div className='flex flex-col w-full gap-3'>
            <ExpressCheckoutElement options={{
                layout: {
                    maxRows: 2,
                    maxColumns: 2
                },
                paymentMethods: {
                    link: "never"
                }

            }} onConfirm={onConfirm} onReady={onReady} onClick={onClick}
            />
            {
                loading &&
                <div className='flex flex-col gap-2 items-center'>
                    <p className='text-lg text-gray-700 font-medium'>Processing payment...</p>
                    <ClipLoader
                        loading={true}
                        color="#005EFF"
                        size={30}
                    />


                </div>
            }

        </div></>
};


export default ExpressCheckout;