import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeCoupon: null,
    // Keep track of the active campaign modal being shown
    activeCampaign: null,
};

const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        setActiveCoupon(state, action) {
            state.activeCoupon = action.payload;
        },
        removeActiveCoupon(state) {
            state.activeCoupon = null;
        },
        openCampaignModal(state, action) {
            state.activeCampaign = action.payload;
        },
        closeCampaignModal(state, action) {
            state.activeCampaign = null;
        },

    },
});

export const {

    setActiveCoupon,
    openCampaignModal,
    closeCampaignModal,
    removeActiveCoupon,

} = couponSlice.actions;

export default couponSlice.reducer;
