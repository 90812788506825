import React from 'react';
import CtaButton from './CtaButton';
import img1 from "../../images/landing_pages/hero/1.png"
function Hero({ heading, subheading, offerText, buttonText }) {
    return (
        <section className="relative bg-right bg-no-repeat py-20 xl:py-32">
            <div className="absolute bottom-0 w-64 h-64 bg-landingAccent500 right-0 opacity-10 blur-3xl"></div>
            <div className="container 2xl:max-w-[87.5rem] px-4 mx-auto relative">
                <div className="grid items-center grid-cols-12 gap-5 2xl:grid-cols-12">
                    <div className="col-span-12 mb-10 lg:col-span-9 xl:col-span-8 xl:mb-0">
                        <h1 className="mb-3 !leading-snug text-4xl font-bold 2xl:text-6xl text-slate-800">
                            {heading}
                        </h1>
                        <p className="mb-3 text-xl text-slate-500 font-medium">
                            {subheading}
                        </p>
                        <p className="mb-6 text-xl lg:text-2xl text-red-500 font-semibold">
                            {offerText}
                        </p>
                        <div className="flex items-center gap-5">
                            <CtaButton className={"uppercase"}>
                                {buttonText}
                            </CtaButton>
                        </div>
                    </div>

                    <div className="relative col-span-12 lg:col-span-3 xl:col-span-4">
                        <img className="w-full h-auto mx-auto" alt="Utm tracking"  src={img1}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
