import React from 'react';
import Confetti from 'react-confetti'
import ImageSupportBoughtEmailW from "../images/support_bought/support_welcome@1x.webp";
import ImageSupportBoughtEmailW_2x from "../images/support_bought/support_welcome@2x.webp";
import ImageSupportBoughtEmail from "../images/support_bought/support_welcome@1x.png";
import ImageSupportBoughtEmail_2x from "../images/support_bought/support_welcome@2x.png";
import { withLayout } from './Layout';


const SupportBought = () => {
    const [playing, setPlaying] = React.useState(true)
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setPlaying(false)
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className="w-full text-center mx-auto pt-5 min-[2000px]:pt-10 px-3 font-[poppins] overflow-x-hidden min-h-[100vh]" >
            <Confetti recycle={playing} colors={["#2563EB", "#1E40AF", "#3B82F6", "#60A5FA"]} />
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-6 lg:max-w-screen-2xl mx-auto">
                <h2 className="font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl md:!leading-[3.5rem] min-[2000px]:!leading-[4.15rem]">Thank you for upgrading to our premium support! You've made and excellent choice</h2>
                <p className="text-lg md:text-xl">
                    Here is how this is going to work:
                </p>
            </div>
            <div className="flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5">
                <div className='text-left'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 1: Check Your Inbox</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        We've already sent you an email (Subject: UTM Grabber V3 Support Onboarding) in which we asked some questions. Please reply to that email to get us started with the support process.
                    </p>
                    <p class="text-lg min-[2000px]:text-xl pt-2">Can't miss it, here's what it looks like:</p>
                    <picture className=''>
                        <source srcset={`${ImageSupportBoughtEmailW} 1x, ${ImageSupportBoughtEmailW_2x} 2x`} type="image/webp" />
                        <img className='w-full h-auto' src={ImageSupportBoughtEmail} alt="The email we sent to you" srcset={`${ImageSupportBoughtEmail_2x} 2x`} />
                    </picture>
                </div>
                <div className='text-left pb-3 pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 2: Access Permissions</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        Via email, we'll request that you provide us with certain access permissions to help us integrate UTM Grabber in the best way possible for your business.
                    </p>
                </div>
                <div className='text-left pb-3 pt-2'>
                    <h3 className='mb-2.5 text-xl md:text-2xl font-medium'>Step 3: Integration Guide</h3>
                    <p className="text-base min-[2000px]:text-lg">
                        After the integration is completed, our team will get back to you with their observations, and they will guide you through how they performed the integration so you can manage it yourself in the future if needed.
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-2 md:px-5 min-[2000px]:gap-5 md:max-w-[1080px] mx-auto py-5">
                <p className='text-lg md:text-xl min-[2000px]:text-2xl font-medium'>
                    Our support team is excited to work with you and help you get the best out of our service.
                </p>
            </div>

        </div>
    )
}

export default withLayout(SupportBought);